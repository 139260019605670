import React, { useState, useMemo } from "react";
import { z } from "zod";
import { Form } from "../../layout/form/form";
import ModalDialog from "../../layout/modal-dialog";
import { useForm } from "../../hooks/useForm";
import { SelectField } from "../../layout/form/select-field";
import { useMandates } from "../../mandates/hooks/use-mandates";
import { useAuth } from "../../auth/use-auth";
import { orderBy, startCase } from "lodash";
import { useMutation } from "@tanstack/react-query";
import { TextField } from "../../layout/form/text-field";
import { TextAreaField } from "../../layout/form/text-area-field";
import { createMandateMandateEffect } from "../actions/create-mandate-mandate-effect";
import { MandateMandateEffect } from "../domain/mandate-mandate-effect";
import { useMandateEffects } from "../../mandate-effects/hooks/use-mandate-effects";
import { useFieldArray } from "react-hook-form";
import ButtonNeoGen from "../../layout/button-neogen";

const schema = z.object({
    mandateEffectId: z.number(),
    description: z.string().nullish(),
    references: z.array(z.any()).nullish(),
});

type Data = z.infer<typeof schema>;

export const AddMandateEffectModal = ({
    onClose,
    onCreated,
    mandateId,
    userId,
}: {
    onClose: () => void;
    onCreated?: (mandateMandateEffect: MandateMandateEffect) => void;
    mandateId: number;
    userId?: string;
}) => {
    const auth = useAuth();
    const authToken = auth.expectAuthToken();
    const form = useForm<Data>({ schema, defaultValues: { references: [{ value: "" }] } });

    const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
        control: form.control,
        name: "references",
    });

    const mandateEffectsQuery = useMandateEffects({ authToken });
    const mandateEffects = useMemo(() => mandateEffectsQuery.data || [], [mandateEffectsQuery.data]);
    const mandateEffectOptions = useMemo(
        () =>
            orderBy(
                mandateEffects.map((m) => ({ label: startCase(m.name || "No name"), value: m.id })),
                (op) => op.label,
            ),
        [mandateEffects],
    );

    const createMandateMandateEffectMutation = useMutation({
        mutationFn: (data: Partial<MandateMandateEffect>) =>
            createMandateMandateEffect({
                authToken,
                data: { ...data, mandateId, createdBy: "human", createdByUserId: userId },
            }),
    });

    const handleSubmit = async (data: Data) => {
        const references = (data.references || []).map((r: any) => r.value).filter((v) => !!v);

        const formattedData: Partial<MandateMandateEffect> = {
            mandateEffectId: data.mandateEffectId,
            references,
            humanReferences: references,
            description: data.description,
            humanDescription: data.description,
        };

        const mandateMandateEffect = await createMandateMandateEffectMutation.mutateAsync(formattedData);

        if (mandateMandateEffect && onCreated) {
            onCreated(mandateMandateEffect);
        }
    };

    return (
        <Form onSubmit={form.handleSubmit(handleSubmit)}>
            <ModalDialog
                title={"Add Mandate Effect"}
                close={onClose}
                show
                okText="Add Mandate Effect"
                okAction={form.handleSubmit(handleSubmit)}
                size="md"
            >
                <SelectField
                    isRequired
                    label="Effect"
                    {...form.getFieldProps("mandateEffectId")}
                    options={mandateEffectOptions}
                />
                <TextAreaField label="Description" {...form.getFieldProps("description")} />
                <div className="flex justify-between w-full">
                    <div className="inline-block relative text-sm font-normal tracking-wider mb-2 leading-normal text-gray-400 dark:text-gray-400">
                        References
                    </div>
                    <ButtonNeoGen onClick={() => append({})} size="xs">
                        Add Reference
                    </ButtonNeoGen>
                </div>
                {fields.length > 0 ? (
                    fields.map((field, index) => {
                        return (
                            <div key={field.id} className="flex items-start space-x-2 w-full">
                                <div className="flex-1">
                                    <TextAreaField {...form.getFieldProps(`references.${index}.value`)} />
                                </div>
                                <ButtonNeoGen onClick={() => remove(index)} type="warning" size="xs">
                                    Remove
                                </ButtonNeoGen>
                            </div>
                        );
                    })
                ) : (
                    <div className="text-gray-400">No references</div>
                )}
            </ModalDialog>
        </Form>
    );
};
