import React from "react";
import PageDescription from "../../../layout/page-description";
import TableNeogen from "../../../layout/table-neogen";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import mandateEffectGroupService, { MandateEffectGroup } from "../../../services/mandate-effect-group.service";
import AddMandateEffectGroup from "./modals/add-mandate-effect-group";
import SwalNeogenFire from "../../../layout/swal-neogen";
import { ca } from "date-fns/locale";

function MandateEffectGroups() {
    const [showAddMandateEffectGroup, setShowAddMandateEffectGroup] = React.useState(false);
    const [currentMandateEffectGroup, setCurrentMandateEffectGroup] = React.useState<MandateEffectGroup | null>(null);
    const queryCache = useQueryClient();
    const mandateEffectGroupsQuery = useQuery(["mandateEffectGroups"], async () => {
        const response = await mandateEffectGroupService.getAll();
        if (response) {
            return response.data;
        }
    });
    if (mandateEffectGroupsQuery.isLoading) return <div>Loading...</div>;
    return (
        <>
            <PageDescription
                title="Mandate Effect Groups"
                description="Manage mandate effect groups"
                buttons={[
                    {
                        label: "Add Mandate Effect Group",
                        onClick: () => {
                            setCurrentMandateEffectGroup(null);
                            setShowAddMandateEffectGroup(true);
                        },
                    },
                ]}
            />
            {mandateEffectGroupsQuery.data?.length === 0 && <div>No mandate effect groups found</div>}
            <TableNeogen
                entries={mandateEffectGroupsQuery.data ?? []}
                actions={[
                    // Edit
                    {
                        label: "Edit",
                        icon: "fas fa-edit",
                        onClick: (id: number) => {
                            setCurrentMandateEffectGroup(
                                mandateEffectGroupsQuery.data?.find((x) => x.id === id) ?? null,
                            );
                            setShowAddMandateEffectGroup(true);
                        },
                    },
                    // Delete
                    {
                        label: "Delete",
                        type: "danger",
                        icon: "fas fa-trash",
                        onClick: (id: number) => {
                            SwalNeogenFire({
                                title: "Are you sure?",
                                text: "This action cannot be undone",
                                icon: "warning",
                                showCancelButton: true,
                                confirmButtonText: "Yes, delete it!",
                                confirmButtonColor: "#d33",
                                cancelButtonColor: "#3085d6",
                                cancelButtonText: "Cancel",
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    mandateEffectGroupService.deleteByID(id).then(() => {
                                        queryCache.invalidateQueries(["mandateEffectGroups"]);
                                        mandateEffectGroupsQuery.refetch();
                                    });
                                }
                            });
                        },
                    },
                ]}
            />
            {showAddMandateEffectGroup && (
                <AddMandateEffectGroup
                    show={showAddMandateEffectGroup}
                    close={() => setShowAddMandateEffectGroup(false)}
                    mandateEffectGroup={currentMandateEffectGroup}
                />
            )}
        </>
    );
}

export default MandateEffectGroups;
