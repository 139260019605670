import { useMutation } from "@tanstack/react-query";
import ModalDialog from "../../layout/modal-dialog";
import { useAuth } from "../../auth/use-auth";
import { Mandate } from "../domain/mandate";
import { updateMandate } from "../actions/update-mandate";
import { z } from "zod";
import { Form } from "../../layout/form/form";
import { useForm } from "../../hooks/useForm";
import { Table, TBody, Td, Tr } from "../../layout/table";
import { TextAreaField } from "../../layout/form/text-area-field";
import { RadioField } from "../../layout/form/radio-field";
import { DateTimeField } from "../../layout/form/date-field";
import { DateDisplay2 } from "../../layout/date-display";

const schema = z.object({
    aiEffectiveDate: z.coerce.date().nullish(),
    isAiEffectiveDateCorrect: z.coerce.boolean().nullish(),
    humanEffectiveDate: z.coerce.date().nullish(),
    effectiveDateNotes: z.string().nullish(),
});

type Data = z.infer<typeof schema>;

export const EditMandateEffectiveDateModal = ({
    onClose,
    mandate,
    onUpdate,
    userId,
}: {
    onClose: () => void;
    mandate: Mandate;
    onUpdate: (updatedMandate: Mandate) => void;
    userId?: string;
}) => {
    const auth = useAuth();
    const authToken = auth.expectAuthToken();

    const form = useForm({
        schema,
        defaultValues: { ...mandate },
    });

    const triggerAiMutation = useMutation({
        mutationFn: async (data: Partial<Mandate>) => {
            const updatedMandate = await updateMandate({ authToken, id: mandate.id, data });
            onUpdate(updatedMandate);
            onClose();
        },
    });

    const handleSubmit = async (data: Partial<Mandate>) => {
        const isSubmittingAiFeedback = data.isAiEffectiveDateCorrect !== mandate.isAiEffectiveDateCorrect;

        const d: Partial<Mandate> =
            isSubmittingAiFeedback === true
                ? {
                      isAiEffectiveDateCorrect: data.isAiEffectiveDateCorrect,
                      aiEffectiveDateAtFeedbackTime: data.aiEffectiveDate,
                      aiEffectiveDateFeedbackSubmittedAt: new Date(),
                      effectiveDate:
                          data.isAiEffectiveDateCorrect === true ? data.aiEffectiveDate : data.humanEffectiveDate,
                  }
                : { effectiveDate: data.isAiEffectiveDateCorrect !== true ? data.humanEffectiveDate : undefined };

        const updatedData: Partial<Mandate> = {
            ...d,
            humanEffectiveDate: data.humanEffectiveDate,
            effectiveDateNotes: data.effectiveDateNotes,
            feedbackOnEffectiveDateUserId: userId,
        };

        await triggerAiMutation.mutateAsync(updatedData);
    };

    const values = form.watch();

    const useEditableField = values.isAiEffectiveDateCorrect !== true || !mandate.aiEffectiveDate;

    return (
        <ModalDialog
            title="Edit Mandate Effective Date"
            show
            close={onClose}
            okAction={form.handleSubmit(handleSubmit)}
            okText="Update Effective Date"
            isLoading={triggerAiMutation.isLoading}
            size="md"
        >
            <Form onSubmit={form.handleSubmit(handleSubmit)}>
                <Table>
                    <TBody>
                        <Tr>
                            <Td style={{ color: "gray", width: 220 }}>Confirmed Date</Td>
                            <Td style={{ fontWeight: 800, paddingTop: 0, paddingBottom: 0 }}>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                    <div className="flex-1">
                                        {useEditableField ? (
                                            <DateTimeField
                                                isRequired={values.isAiEffectiveDateCorrect === false}
                                                {...form.getFieldProps("humanEffectiveDate")}
                                            />
                                        ) : (
                                            <>
                                                <DateDisplay2
                                                    date={
                                                        values.isAiEffectiveDateCorrect === true
                                                            ? mandate.aiEffectiveDateAtFeedbackTime ||
                                                              mandate.aiEffectiveDate
                                                            : mandate.effectiveDate
                                                    }
                                                />
                                            </>
                                        )}
                                    </div>
                                </div>
                            </Td>
                        </Tr>
                        <Tr>
                            <Td style={{ color: "gray", width: 185 }}>AI Suggested Date</Td>
                            <Td style={{ fontWeight: 500, paddingTop: 0, paddingBottom: 0 }}>
                                <div style={{ display: "flex", flexDirection: "row", gap: 6, alignItems: "center" }}>
                                    <div>
                                        <DateDisplay2
                                            date={
                                                mandate.isAiEffectiveDateCorrect === true
                                                    ? mandate.aiEffectiveDateAtFeedbackTime
                                                    : mandate.aiEffectiveDate
                                            }
                                        />
                                    </div>
                                </div>
                            </Td>
                        </Tr>
                        <Tr>
                            <Td style={{ color: "gray", width: 180 }}>Is the AI suggestion correct?</Td>
                            <Td style={{ fontWeight: 500, paddingTop: 0, paddingBottom: 0 }}>
                                <div style={{ display: "flex", flexDirection: "row", gap: 6, alignItems: "center" }}>
                                    <RadioField
                                        disabled={!mandate.aiEffectiveDate}
                                        {...form.getFieldProps("isAiEffectiveDateCorrect")}
                                        options={[
                                            { label: "Yes", value: true },
                                            { label: "No", value: false },
                                        ]}
                                    />
                                </div>
                            </Td>
                        </Tr>
                        <Tr>
                            <Td style={{ color: "gray", width: 185 }}>Google Sheet Date</Td>
                            <Td style={{ fontWeight: 500, paddingTop: 0, paddingBottom: 0 }}>
                                <div style={{ display: "flex", flexDirection: "row", gap: 6, alignItems: "center" }}>
                                    <div>
                                        <DateDisplay2 date={mandate.googleSheetDate} />
                                    </div>
                                </div>
                            </Td>
                        </Tr>
                    </TBody>
                </Table>
                <TextAreaField label="Notes" {...form.getFieldProps("effectiveDateNotes")} />
            </Form>
        </ModalDialog>
    );
};
