import APIService from "./api.service";

export type MandateEffectGroupEntry = {
    id: number;
    mandateEffectGroup: number;
    mandateEffect: number;
};

class MandateEffectGroupEntryService extends APIService<MandateEffectGroupEntry> {
    constructor() {
        super("/mandate-effect-group-entries");
    }
}

export default new MandateEffectGroupEntryService();
