import { useController } from "react-hook-form";
import { GetFieldPropsReturn } from "../../hooks/useForm";
import { Field, FormFieldProps } from "./field";
import { TextInputProps } from "./text-input";
import { Checkbox } from "./checkbox";

export const RadioField = ({
    name,
    form,
    isRequired,
    label,
    options,
    ...other
}: FormFieldProps<string | boolean | number | null> &
    GetFieldPropsReturn<any> & {
        options: { label: string; value: string | boolean | number | null }[];
    } & TextInputProps) => {
    const { field, fieldState } = useController({
        name,
        control: form.control,
        rules: { required: isRequired ? "This field is required" : undefined },
        defaultValue: other.defaultValue || false,
    });

    const error = fieldState.error?.message;
    return (
        <div className="flex flex-row items-center gap-4">
            {options.map((o) => {
                return (
                    <div
                        key={o.value + o.label}
                        onClick={() => {
                            if (other.disabled) {
                                return;
                            }
                            field.onChange(o.value);
                        }}
                        style={{ cursor: "default" }}
                    >
                        <input
                            {...field}
                            disabled={other.disabled}
                            type="radio"
                            onChange={(v) => {
                                field.onChange(o.value);
                            }}
                            checked={field.value === o.value}
                        />
                        <span style={{ marginLeft: 6 }}>{o.label}</span>
                    </div>
                );
            })}
            {label && <Field label={label} isRequired={isRequired} helpText={other.helperText} error={error}></Field>}
        </div>
    );
};
