import React, { useEffect } from "react";
import ModalDialog from "../../../../layout/modal-dialog";
import bulletPointGroupService, { BulletPointGroup } from "../../../../services/bullet-point-group.service";
import PrintPre from "../../../../layout/print-pre";
import InputControlled from "../../../../layout/input-controlled";
import { useQueryClient } from "@tanstack/react-query";

function AddBulletPointGroup({
    show,
    close,
    bulletPointGroup,
}: {
    show: boolean;
    close: () => void;
    bulletPointGroup: null | BulletPointGroup;
}): JSX.Element {
    const [name, setName] = React.useState<string>("");
    const queryCache = useQueryClient();
    useEffect(() => {
        if (bulletPointGroup) {
            setName(bulletPointGroup.name);
        } else {
            setName("");
        }
    }, [bulletPointGroup, show]);
    async function save() {
        if (bulletPointGroup) {
            await bulletPointGroupService.update(bulletPointGroup.id, { name });
            queryCache.invalidateQueries(["bulletPointsGroup"]);
            close();
        } else {
            const newGroup: BulletPointGroup = {
                name,
            };
            await bulletPointGroupService.create(newGroup);
            queryCache.invalidateQueries(["bulletPointsGroup"]);
            close();
        }
    }
    return (
        <ModalDialog
            close={() => close()}
            show={show}
            title={bulletPointGroup ? "Edit Bulletpoint Group" : "Add Bulletpoint Group"}
            okAction={() => save()}
        >
            <>
                <InputControlled value={name} label="Group Name" onChange={(v) => setName(v)} />
            </>
        </ModalDialog>
    );
}

export default AddBulletPointGroup;
