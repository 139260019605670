import ButtonNeoGen from "../../../layout/button-neogen";
import ModalDialog from "../../../layout/modal-dialog";
import { useForm } from "../../../hooks/useForm";
import { Form } from "../../../layout/form/form";
import { SelectField } from "../../../layout/form/select-field";
import { z } from "zod";
import { User } from "../../../jason-proof-of-concept/users/domain/user";
import { useMemo } from "react";
import { CompanyStatus } from "../../domain/company";

const schema = z.object({
    affiliateIds: z.array(z.string().uuid()).nullish(),
    ownedByIds: z.array(z.string().uuid()).nullish(),
    statuses: z.array(z.string()).nullish(),
    stageIds: z.array(z.string()).nullish(),
});

export type CompanyFilters = z.infer<typeof schema>;

const companyStatusOptions = [
    // {
    //     label: "In review",
    //     value: CompanyStatus.inReview,
    // },
    {
        label: "Active",
        value: CompanyStatus.active,
    },
    {
        label: "Archived",
        value: CompanyStatus.archived,
    },
];

const stagesOptions = [
    {
        label: "Doc Collection Complete",
        value: "Doc Collection Complete",
    },
];

export const CompanyFiltersModal = ({
    onClose,
    companyFilters,
    affiliates,
    companyOwners,
    handleSubmit,
}: {
    onClose: () => void;
    companyFilters: CompanyFilters;
    affiliates?: User[];
    companyOwners?: User[];
    handleSubmit: (filters: CompanyFilters) => void;
}) => {
    const form = useForm({ schema, defaultValues: { ...companyFilters } });

    const submitFilters = (data: any) => {
        handleSubmit(data);
    };

    const affiliateOptions = useMemo(
        () =>
            (affiliates || []).map((a) => {
                const email = a.email ? ` (${a.email})` : "";
                return { value: a.id, label: `${a.firstName} ${a.lastName}${email}` };
            }),
        [affiliates],
    );

    const companyOwnersOptions = useMemo(
        () =>
            (companyOwners || []).map((a) => {
                const email = a.email ? ` (${a.email})` : "";
                return { value: a.id, label: `${a.firstName} ${a.lastName}${email}` };
            }),
        [companyOwners],
    );

    return (
        <ModalDialog show title="Filters" close={onClose} showOk={false} showCancel={false} size="sm">
            <Form onSubmit={form.handleSubmit(submitFilters)}>
                <SelectField
                    isMultiple
                    label="Affiliate"
                    {...form.getFieldProps("affiliateIds")}
                    options={affiliateOptions}
                />
                <SelectField
                    isMultiple
                    label="Company status"
                    {...form.getFieldProps("statuses")}
                    options={companyStatusOptions}
                />
                <SelectField
                    isMultiple
                    label="Company owner"
                    {...form.getFieldProps("ownedByIds")}
                    options={companyOwnersOptions}
                />
                <SelectField isMultiple label="Stages" {...form.getFieldProps("stageIds")} options={stagesOptions} />
                <ButtonNeoGen type="submit">Apply filters</ButtonNeoGen>
            </Form>
        </ModalDialog>
    );
};
