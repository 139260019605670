import React, { useEffect } from "react";
import PageDescription from "../../../layout/page-description";
import ButtonNeoGen from "../../../layout/button-neogen";
import { useQuery } from "@tanstack/react-query";
import roleGroupService from "../../../services/role-group.service";
import PrintPre from "../../../layout/print-pre";
import { RoleGroup } from "../../../typings/api/role-group";
import { set } from "lodash";

export default function TempRoleGroups() {
    const [currentRoleGroup, setCurrentRoleGroup] = React.useState<number | null>(null);
    const roleGroupQuery = useQuery(["roleGroups"], async () => {
        const response = await roleGroupService.getAll();
        if (response) {
            return response.data;
        }
    });
    // useEffect(() => {
    //     setCurrentRoleGroup(Number(window.localStorage.getItem("tempRoleGroup")));
    // }, [window.localStorage.getItem("tempRoleGroup")]);
    if (roleGroupQuery.isLoading) {
        return <div>Loading...</div>;
    }
    return (
        <>
            <PageDescription title="Temp Role Groups" description="This page lets you manage temporary role groups." />
            <h1 className="text-xl mb-5">
                Current Temp Role Group:{" "}
                {roleGroupQuery.data?.find((r) => r.id === Number(window.localStorage.getItem("tempRoleGroup")))?.name}
            </h1>
            <ButtonNeoGen
                type="danger"
                onClick={() => {
                    window.localStorage.removeItem("tempRoleGroup");
                    setCurrentRoleGroup(null);
                }}
            >
                Remove All
            </ButtonNeoGen>
            <div className="mt-5 grid grid-cols-6 gap-5">
                {roleGroupQuery.data?.map((rg: RoleGroup) => (
                    <ButtonNeoGen
                        key={rg.id}
                        onClick={() => {
                            window.localStorage.setItem("tempRoleGroup", (rg.id ?? "").toString());
                            setCurrentRoleGroup(rg.id ?? -1);
                        }}
                    >
                        {rg.name}
                    </ButtonNeoGen>
                ))}
            </div>
        </>
    );
}
