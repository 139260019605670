import { useState } from "react";
import { Task } from "../types";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import ButtonNeoGen from "../button-neogen";
import { useNavigate } from "react-router";
import { useQuery } from "@tanstack/react-query";
import companyService from "../../services/company.service";

interface Props {
    task: Task;
}

function TaskCard({ task }: Props) {
    const [mouseIsOver, setMouseIsOver] = useState(false);
    const navigate = useNavigate();
    const companyQuery = useQuery(["company", task.id], async () => {
        const response = await companyService.getOne(task.id);
        if (response) {
            return response.data;
        }
    });

    const { setNodeRef, attributes, listeners, transform, transition, isDragging } = useSortable({
        id: task.id,
        data: {
            type: "Task",
            task,
        },
    });

    const style = {
        transition,
        transform: CSS.Transform.toString(transform),
    };

    if (isDragging) {
        return (
            <div
                ref={setNodeRef}
                style={style}
                className="opacity-30 bg-white p-2.5 h-[100px] min-h-[100px] items-center flex text-left dark:bg-gray-800 rounded-xl border-2 border-rose-500 w-[250px] cursor-grab relative"
            />
        );
    }

    if (companyQuery.isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <div
            ref={setNodeRef}
            style={style}
            {...attributes}
            {...listeners}
            className="bg-white dark:bg-gray-800 text-sm p-1 min-h-[70px] items-center text-center rounded-xl hover:ring-2 hover:ring-inset hover:ring-rose-500 cursor-grab relative task border-gray-700"
            onMouseEnter={() => {
                setMouseIsOver(true);
            }}
            onMouseLeave={() => {
                setMouseIsOver(false);
            }}
        >
            <div>
                <p className="bg-white dark:bg-gray-800 my-auto h-[90%] w-full overflow-y-auto overflow-x-hidden whitespace-pre-wrap">
                    {task.content}
                </p>
            </div>
            <div>
                <ButtonNeoGen
                    onClick={() => {
                        navigate(`/companies/${task.id}`);
                    }}
                    size="xs"
                >
                    Open Company
                </ButtonNeoGen>
            </div>
        </div>
    );
}

export default TaskCard;
