import { z } from "zod";

export const mandateMandateEffectSchema = z.object({
    id: z.number(),
    mandateId: z.number(),
    mandateEffectId: z.number(),
    description: z.string().nullish(),
    aiDescription: z.string().nullish(),
    humanDescription: z.string().nullish(),
    impact: z.string().nullish(),
    aiImpact: z.string().nullish(),
    humanImpact: z.string().nullish(),
    createdBy: z.enum(["ai", "human"]).nullish(),
    doesActuallyApply: z.coerce.boolean().nullish(),
    references: z.array(z.string()).nullish(),
    aiReferences: z.array(z.string()).nullish(),
    humanReferences: z.array(z.string()).nullish(),
    archivedAt: z.coerce.date().nullish(),
    aiVersion: z.number().nullish(),
    aiIterationNumber: z.number().nullish(),
    incorrectReason: z.string().nullish(),
    feedbackByUserId: z.string().nullish(),
    createdByUserId: z.string().nullish(),
    notes: z.string().nullish(),
});

export type MandateMandateEffect = z.infer<typeof mandateMandateEffectSchema>;

export const getMandateMandateEffectDetails = (mandateMandateEffect: MandateMandateEffect) => {
    const statusDetails = [
        {
            source: "confirmed",
            condition: () =>
                mandateMandateEffect.createdBy === "human" || mandateMandateEffect.doesActuallyApply === true,
        },
        {
            source: "ai suggested",
            condition: () => mandateMandateEffect.createdBy === "ai",
        },
    ];

    const statusDetail = statusDetails.find((n) => !!n.condition());

    return { statusDetail };
};
