/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import { TBody, Table, Td, Tr } from "../../layout/table";
import { timeAgo } from "../../sections/users/cards/calls-list";
import mandateEffectService from "../../services/mandate-effect.service";
import { useMandateMandateEffects } from "../../mandate-mandate-effects/hooks/use-mandate-mandate-effects";
import { getAuthTokenNoThrow } from "../../services/auth-header";
import { useMemo, useState } from "react";
import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import ButtonNeoGen from "../../layout/button-neogen";
import { orderBy, startCase } from "lodash";
import { AddMandateEffectModal } from "../../mandate-mandate-effects/components/add-mandate-effect-modal";
import { MandateContentsModal } from "../components/mandate-contents-modal";
import { getMandate } from "../actions/get-mandate";
import { EditMandateModal } from "../components/edit-mandate-modal";
import { TriggerAIModal } from "../components/trigger-ai-modal";
import { getMandateDetails } from "../domain/mandate";
import { EditMandateNameModal } from "../components/edit-mandate-name-modal";
import { formateDateNew } from "../utils";
import { EditMandateEffectiveDateModal } from "../components/edit-mandate-effective-date-modal";
import { EditMandateUntilDateModal } from "../components/edit-mandate-until-date-modal";
import { EditMandateExtendedUntilDateModal } from "../components/edit-mandate-extended-until-date-modal";
import { EditMandateAuthorityModal } from "../components/edit-mandate-authority-modal";
import { EditMandateStateModal } from "../components/edit-mandate-state-modal";
import { EditMandateCountiesModal } from "../components/edit-mandate-counties-modal";
import { EditMandateCitiesModal } from "../components/edit-mandate-cities-modal";
import { Link } from "react-router-dom";
import { updateMandateMandateEffect } from "../../mandate-mandate-effects/actions/update-mandate-mandate-effect";
import {
    getMandateMandateEffectDetails,
    MandateMandateEffect,
} from "../../mandate-mandate-effects/domain/mandate-mandate-effect";
import { IncorrectMandateMandateEffectModal } from "../components/incorrect-mandate-mandate-effect-modal";
import { useRecoilState } from "recoil";
import userAtom from "../../atoms/userAtom";
import OptionsDropDown from "../../layout/options-dropdown";
import { MandateEffect } from "../../mandate-effects/domain/mandate-effect";
import { User } from "../../jason-proof-of-concept/users/domain/user";
import { EditMandateMandateEffectModal } from "../../mandate-mandate-effects/components/edit-mandate-mandate-effect-modal";
import { getMandateIndustryGroups } from "../../mandate-industry-groups/actions/get-mandate-industry-groups";
import { MandateIndustryGroup } from "../../mandate-industry-groups/domain/mandate-industry-group";
import { getIndustryGroups } from "../../industry-groups/actions/get-industry-groups";
import { IndustryGroup } from "../../industry-groups/domain/industry-group";

const MandateMandateEffectRow = ({
    user,
    mandateMandateEffect,
    mandateEffect,
    effectMandateIndustryGroups,
    industryGroups,
    onCorrectClick,
    onIncorrectClick,
    onEditClick,
}: {
    user: User;
    mandateMandateEffect: MandateMandateEffect;
    mandateEffect: MandateEffect;
    effectMandateIndustryGroups?: MandateIndustryGroup[];
    industryGroups?: IndustryGroup[];
    onCorrectClick: () => void;
    onIncorrectClick: () => void;
    onEditClick: () => void;
}) => {
    const details = getMandateMandateEffectDetails(mandateMandateEffect);

    return (
        <Tr key={mandateMandateEffect.id}>
            <Td style={{ width: "80%" }}>
                <div style={{ display: "flex", flexDirection: "column", gap: 6 }}>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: 4,
                        }}
                    >
                        <div style={{ fontWeight: 700, fontSize: 17 }}>{startCase(mandateEffect?.name || "")}</div>
                        <div style={{ color: "gray", fontSize: 13 }}>
                            ({details.statusDetail?.source || "Unknown status"})
                        </div>
                    </div>
                    <div style={{ lineHeight: 1.3 }}>{mandateMandateEffect.description}</div>
                    {industryGroups && industryGroups.length > 0 && (
                        <div>
                            <div style={{ fontWeight: 500 }}>Industries:</div>
                            <ul style={{ listStyle: "outside", paddingLeft: 20 }}>
                                {(effectMandateIndustryGroups || []).map((emig) => {
                                    const ig = industryGroups.find((ig) => ig.id === emig.industryGroupId);
                                    if (!ig) {
                                        return null;
                                    }
                                    return (
                                        <li
                                            key={ig.id}
                                            style={{ color: "gray", fontSize: 13, lineHeight: 1.0, padding: 1 }}
                                        >
                                            {ig.name}
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    )}
                    <div>
                        <div style={{ fontWeight: 500 }}>References:</div>
                        <ul style={{ listStyle: "outside", paddingLeft: 20 }}>
                            {(mandateMandateEffect.references || []).map((r) => {
                                return (
                                    <li key={r} style={{ color: "gray", fontSize: 13, lineHeight: 1.0, padding: 1 }}>
                                        {`"`}
                                        {r}
                                        {`"`}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>
            </Td>
            <Td style={{ verticalAlign: "top", width: 180 }}>
                <div style={{ display: "flex", flexDirection: "row", gap: 6, justifyContent: "flex-end" }}>
                    <div>
                        <ButtonNeoGen
                            disabled={mandateMandateEffect.doesActuallyApply === true}
                            size="xs"
                            onClick={onCorrectClick}
                        >
                            ✅
                        </ButtonNeoGen>
                    </div>
                    <div>
                        <ButtonNeoGen
                            disabled={mandateMandateEffect.doesActuallyApply === false}
                            size="xs"
                            onClick={onIncorrectClick}
                        >
                            ❌
                        </ButtonNeoGen>
                    </div>
                    <div>
                        <ButtonNeoGen size="xs" onClick={onEditClick}>
                            Edit
                        </ButtonNeoGen>
                    </div>
                </div>
            </Td>
        </Tr>
    );
};

function MandatePage({ darkMode }: { darkMode?: boolean }) {
    const authToken = getAuthTokenNoThrow() || "no-auth";
    const [user] = useRecoilState(userAtom);
    const { mandateId: m } = useParams();
    const mandateId = parseInt(m || "", 10);
    const [showAddEffectModal, setShowAddEffectModal] = useState(false);
    const [showContents, setShowContents] = useState(false);
    const [showEditMandateModal, setShowEditMandateModal] = useState(false);
    const [showTriggerAiModal, setShowTriggerAiModal] = useState(false);
    const [showEditNameModal, setShowEditNameModal] = useState(false);
    const [showEditAuthorityModal, setShowEditAuthorityModal] = useState(false);
    const [showEditStateModal, setShowEditStateModal] = useState(false);
    const [showEditEffectiveDateModal, setShowEffectiveDateModal] = useState(false);
    const [showEditUntilDateModal, setShowUntilDateModal] = useState(false);
    const [showExtendedUntilDateModal, setShowExtendedUntilDateModal] = useState(false);
    const [showEditCountiesModal, setShowEditCountiesModal] = useState(false);
    const [showEditCitiesModal, setShowEditCitiesModal] = useState(false);
    const [incorrectMandateMandateEffectId, setIncorrectMandateMandateEffectId] = useState<undefined | number>();
    const [showEditMandateMandateEffectModal, setShowEditMandateMandateEffectModal] = useState<undefined | number>();

    const mandateQuery = useQuery(["mandate", { authToken, mandateId }], async () => {
        if (!mandateId) return;
        const mandate = await getMandate({ authToken, id: mandateId });
        return mandate;
    });
    const mandate = mandateQuery.data;

    const mandateIndustryGroupsQuery = useQuery(["mandateIndustryGroups", { authToken, mandateId }], async () => {
        const mandateIndustryGroups = await getMandateIndustryGroups({ authToken, filters: { where: { mandateId } } });
        return mandateIndustryGroups;
    });
    const mandateIndustryGroups = mandateIndustryGroupsQuery.data || [];

    const industryGroupsQuery = useQuery(["industryGroups"], async () => {
        const result = await getIndustryGroups({ authToken });
        return result || [];
    });
    const industryGroups = industryGroupsQuery.data || [];

    const mandatesEffectQuery = useQuery(["mandateEffects", { authToken }], async () => {
        const result = await mandateEffectService.getAll();
        return result?.data || [];
    });

    const mandateMandateEffectQuery = useMandateMandateEffects(
        {
            authToken,
            filters: { ...(mandateId !== null ? { where: { mandateId: mandateId } } : {}) },
        },
        { enabled: !!mandateId },
    );
    const mandateMandateEffects = useMemo(() => mandateMandateEffectQuery.data || [], [mandateMandateEffectQuery.data]);
    const activeMandateMandeEffects = mandateMandateEffects.filter((me) => !me.archivedAt);
    const incorrectMandateEffects = mandateMandateEffects.filter((me) => me.doesActuallyApply === false);

    const updateMandateMandateEffectMutation = useMutation({
        mutationFn: function ({ id, data }: { id: number; data: Partial<MandateMandateEffect> }) {
            return updateMandateMandateEffect({ authToken, id, data });
        },
        onSuccess: () => {
            mandateMandateEffectQuery.refetch();
        },
    });

    // @ts-ignore
    const { name, effectiveDate, url, untilDate, extendedUntilDate, authority, state, counties, cities } = mandate
        ? getMandateDetails(mandate)
        : {};
    function toUTC(datetime: any) {
        const myDate = typeof datetime === "number" ? new Date(datetime) : datetime;

        if (!myDate || typeof myDate.getTime !== "function") {
            return 0;
        }

        const getUTC = myDate.getTime();
        const offset = myDate.getTimezoneOffset() * 60000; // It's in minutes so convert to ms
        return getUTC - offset; // UTC - OFFSET
    }

    function fromUTC(datetime: any) {
        const myDate = typeof datetime === "number" ? new Date(datetime) : datetime;

        if (!myDate || typeof myDate.getTime !== "function") {
            return 0;
        }

        const getUTC = myDate.getTime();
        const offset = myDate.getTimezoneOffset() * 60000; // It's in minutes so convert to ms
        return getUTC + offset; // UTC + OFFSET
    }

    const mandateMandateEffectToEdit = mandateMandateEffects.find(
        (mme) => mme.id === showEditMandateMandateEffectModal,
    );

    return (
        <>
            {showAddEffectModal && mandateId && (
                <AddMandateEffectModal
                    userId={user?.id}
                    mandateId={mandateId}
                    onClose={() => setShowAddEffectModal(false)}
                    onCreated={() => {
                        mandateMandateEffectQuery.refetch();
                        setShowAddEffectModal(false);
                    }}
                />
            )}
            {showContents && mandate && (
                <MandateContentsModal mandate={mandate} onClose={() => setShowContents(false)} />
            )}
            {showEditMandateModal && mandate && (
                <EditMandateModal
                    mandate={mandate}
                    onClose={() => setShowEditMandateModal(false)}
                    onUpdate={() => {
                        setShowEditMandateModal(false);
                        mandateQuery.refetch();
                    }}
                />
            )}
            {showTriggerAiModal && mandate && (
                <TriggerAIModal
                    mandateId={mandate.id}
                    onClose={() => setShowTriggerAiModal(false)}
                    onProcessed={() => {
                        mandateQuery.refetch();
                        mandateMandateEffectQuery.refetch();
                        mandateIndustryGroupsQuery.refetch();
                        setShowTriggerAiModal(false);
                    }}
                />
            )}
            {showEditNameModal && mandate && (
                <EditMandateNameModal
                    mandate={mandate}
                    onClose={() => setShowEditNameModal(false)}
                    onUpdate={() => mandateQuery.refetch()}
                    userId={user?.id}
                />
            )}
            {showEditAuthorityModal && mandate && (
                <EditMandateAuthorityModal
                    mandate={mandate}
                    onClose={() => setShowEditAuthorityModal(false)}
                    onUpdate={() => mandateQuery.refetch()}
                    userId={user?.id}
                />
            )}
            {showEditStateModal && mandate && (
                <EditMandateStateModal
                    mandate={mandate}
                    onClose={() => setShowEditStateModal(false)}
                    onUpdate={() => mandateQuery.refetch()}
                    userId={user?.id}
                />
            )}
            {showEditEffectiveDateModal && mandate && (
                <EditMandateEffectiveDateModal
                    mandate={mandate}
                    onClose={() => setShowEffectiveDateModal(false)}
                    onUpdate={() => mandateQuery.refetch()}
                    userId={user?.id}
                />
            )}
            {showEditUntilDateModal && mandate && (
                <EditMandateUntilDateModal
                    mandate={mandate}
                    onClose={() => setShowUntilDateModal(false)}
                    onUpdate={() => mandateQuery.refetch()}
                    userId={user?.id}
                />
            )}
            {showExtendedUntilDateModal && mandate && (
                <EditMandateExtendedUntilDateModal
                    mandate={mandate}
                    onClose={() => setShowExtendedUntilDateModal(false)}
                    onUpdate={() => mandateQuery.refetch()}
                    userId={user?.id}
                />
            )}
            {showEditCountiesModal && mandate && (
                <EditMandateCountiesModal
                    mandate={mandate}
                    onClose={() => setShowEditCountiesModal(false)}
                    onUpdate={() => mandateQuery.refetch()}
                    userId={user?.id}
                />
            )}
            {showEditCitiesModal && mandate && (
                <EditMandateCitiesModal
                    mandate={mandate}
                    onClose={() => setShowEditCitiesModal(false)}
                    onUpdate={() => mandateQuery.refetch()}
                    userId={user?.id}
                />
            )}
            {incorrectMandateMandateEffectId && mandate && (
                <IncorrectMandateMandateEffectModal
                    onClose={() => setIncorrectMandateMandateEffectId(undefined)}
                    onSubmit={(reason: string) =>
                        updateMandateMandateEffectMutation.mutate({
                            id: incorrectMandateMandateEffectId,
                            data: {
                                doesActuallyApply: false,
                                archivedAt: new Date(),
                                incorrectReason: reason,
                                feedbackByUserId: user?.id,
                            },
                        })
                    }
                />
            )}
            {mandateMandateEffectToEdit && (
                <EditMandateMandateEffectModal
                    mandateMandateEffect={mandateMandateEffectToEdit}
                    onClose={() => setShowEditMandateMandateEffectModal(undefined)}
                    onUpdated={() => {
                        mandateMandateEffectQuery.refetch();
                    }}
                />
            )}
            {mandate ? (
                <div className="flex flex-col">
                    <div style={{ width: "80vw", margin: "auto" }}>
                        <div className="flex justify-between align-middle p-3">
                            <div className="flex items-center gap-1">
                                <Link to="/mandates">
                                    <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                                        <div>
                                            <ChevronLeftIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
                                        </div>
                                        <div>Mandates</div>
                                    </div>
                                </Link>
                                <div>/</div>
                                <div>
                                    <span style={{ fontWeight: 600 }}>{name?.value || "No mandate name"}</span>
                                </div>
                            </div>
                            <div className="flex gap-2">
                                <ButtonNeoGen type="outline-primary" onClick={() => setShowContents(true)}>
                                    View Text
                                </ButtonNeoGen>
                                <ButtonNeoGen
                                    type="outline-primary"
                                    disabled={!url?.value}
                                    onClick={() => window.open(url?.value!, "_blank")}
                                >
                                    View PDF/Image
                                </ButtonNeoGen>
                                <ButtonNeoGen onClick={() => setShowTriggerAiModal(true)}>Process with AI</ButtonNeoGen>
                            </div>
                        </div>
                        <div className="flex flex-row gap-4">
                            <div style={{ flex: 1 }}>
                                <div>
                                    <div style={{ backgroundColor: "white", padding: 24 }}>
                                        {mandate.summary ? (
                                            <div style={{ lineHeight: 1.3 }}>{mandate?.summary}</div>
                                        ) : (
                                            <div style={{ textAlign: "center" }}>No summary</div>
                                        )}
                                    </div>
                                </div>
                                <div style={{ marginTop: 20 }}>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            padding: 12,
                                        }}
                                    >
                                        <div>
                                            <b>Mandate Effects ({activeMandateMandeEffects.length})</b>
                                        </div>
                                        <div>
                                            <ButtonNeoGen onClick={() => setShowAddEffectModal(true)}>
                                                Add Effect
                                            </ButtonNeoGen>
                                        </div>
                                    </div>
                                    <div style={{ maxHeight: "calc(100vh - 380px)", overflow: "auto" }}>
                                        <Table>
                                            <TBody>
                                                {activeMandateMandeEffects.length === 0 && (
                                                    <Tr>
                                                        <Td colSpan={3} style={{ textAlign: "center" }}>
                                                            No effects
                                                        </Td>
                                                    </Tr>
                                                )}
                                                {activeMandateMandeEffects.length > 0 &&
                                                    orderBy(
                                                        activeMandateMandeEffects.map((mf) => {
                                                            const mandateEffect = mandatesEffectQuery.data?.find(
                                                                (m) => m.id === mf.mandateEffectId,
                                                            );
                                                            return {
                                                                mandateMandateEffect: mf,
                                                                mandateEffect,
                                                            };
                                                        }),
                                                        ({ mandateEffect }) => mandateEffect?.name,
                                                    ).map(({ mandateEffect, mandateMandateEffect }) => {
                                                        const effectMandateIndustryGroups =
                                                            mandateIndustryGroups.filter(
                                                                (mig) =>
                                                                    mig.mandateMandateEffectId ===
                                                                    mandateMandateEffect.id,
                                                            );

                                                        return (
                                                            <MandateMandateEffectRow
                                                                key={mandateMandateEffect.id}
                                                                mandateEffect={mandateEffect as any}
                                                                mandateMandateEffect={mandateMandateEffect}
                                                                effectMandateIndustryGroups={
                                                                    effectMandateIndustryGroups
                                                                }
                                                                industryGroups={industryGroups}
                                                                user={user as any}
                                                                onCorrectClick={() => {
                                                                    updateMandateMandateEffectMutation.mutate({
                                                                        id: mandateMandateEffect.id,
                                                                        data: {
                                                                            doesActuallyApply: true,
                                                                            archivedAt: null,
                                                                            feedbackByUserId: user?.id,
                                                                        },
                                                                    });
                                                                }}
                                                                onIncorrectClick={() => {
                                                                    setIncorrectMandateMandateEffectId(
                                                                        mandateMandateEffect.id,
                                                                    );
                                                                }}
                                                                onEditClick={() => {
                                                                    setShowEditMandateMandateEffectModal(
                                                                        mandateMandateEffect.id,
                                                                    );
                                                                }}
                                                            />
                                                        );
                                                    })}
                                            </TBody>
                                        </Table>
                                        {incorrectMandateEffects && incorrectMandateEffects.length > 0 && (
                                            <div style={{ marginTop: 20 }}>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        justifyContent: "space-between",
                                                        alignItems: "center",
                                                        padding: 12,
                                                    }}
                                                >
                                                    <div>
                                                        <b>
                                                            Incorrect Mandate Effects ({incorrectMandateEffects.length})
                                                        </b>
                                                    </div>
                                                </div>
                                                <Table>
                                                    <TBody>
                                                        {incorrectMandateEffects.length === 0 && (
                                                            <Tr>
                                                                <Td colSpan={3} style={{ textAlign: "center" }}>
                                                                    No effects
                                                                </Td>
                                                            </Tr>
                                                        )}
                                                        {incorrectMandateEffects.length > 0 &&
                                                            orderBy(
                                                                incorrectMandateEffects.map((mf) => {
                                                                    const mandateEffect =
                                                                        mandatesEffectQuery.data?.find(
                                                                            (m) => m.id === mf.mandateEffectId,
                                                                        );
                                                                    return {
                                                                        mandateMandateEffect: mf,
                                                                        mandateEffect,
                                                                    };
                                                                }),
                                                                ({ mandateEffect }) => mandateEffect?.name,
                                                            ).map(({ mandateEffect, mandateMandateEffect }) => {
                                                                const effectMandateIndustryGroups =
                                                                    mandateIndustryGroups.filter(
                                                                        (mig) =>
                                                                            mig.mandateMandateEffectId ===
                                                                            mandateMandateEffect.id,
                                                                    );

                                                                return (
                                                                    <MandateMandateEffectRow
                                                                        key={mandateMandateEffect.id}
                                                                        mandateEffect={mandateEffect as any}
                                                                        mandateMandateEffect={mandateMandateEffect}
                                                                        effectMandateIndustryGroups={
                                                                            effectMandateIndustryGroups
                                                                        }
                                                                        industryGroups={industryGroups}
                                                                        user={user as any}
                                                                        onCorrectClick={() => {
                                                                            updateMandateMandateEffectMutation.mutate({
                                                                                id: mandateMandateEffect.id,
                                                                                data: {
                                                                                    doesActuallyApply: true,
                                                                                    archivedAt: null,
                                                                                    feedbackByUserId: user?.id,
                                                                                },
                                                                            });
                                                                        }}
                                                                        onIncorrectClick={() => {
                                                                            setIncorrectMandateMandateEffectId(
                                                                                mandateMandateEffect.id,
                                                                            );
                                                                        }}
                                                                        onEditClick={() => {
                                                                            setShowEditMandateMandateEffectModal(
                                                                                mandateMandateEffect.id,
                                                                            );
                                                                        }}
                                                                    />
                                                                );
                                                            })}
                                                    </TBody>
                                                </Table>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div style={{ width: 420 }}>
                                <div className="flex flex-col gap-2">
                                    <div>
                                        <Table>
                                            <TBody>
                                                <Tr>
                                                    <Td style={{ width: 145, verticalAlign: "top", color: "gray" }}>
                                                        Mandate Name
                                                    </Td>
                                                    <Td
                                                        onClick={() => setShowEditNameModal(true)}
                                                        style={{ cursor: "pointer" }}
                                                    >
                                                        {name?.value ? (
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    flexDirection: "row",
                                                                    gap: 4,
                                                                }}
                                                            >
                                                                <div>{name.value}</div>
                                                                <div style={{ color: "gray", fontSize: 13 }}>
                                                                    ({name.source})
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            "-"
                                                        )}
                                                    </Td>
                                                </Tr>
                                            </TBody>
                                        </Table>
                                    </div>
                                    <div>
                                        <Table>
                                            <TBody>
                                                <Tr>
                                                    <Td style={{ width: 145, verticalAlign: "top", color: "gray" }}>
                                                        Effective
                                                    </Td>
                                                    <Td
                                                        onClick={() => setShowEffectiveDateModal(true)}
                                                        style={{ cursor: "pointer" }}
                                                    >
                                                        {effectiveDate?.value ? (
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    flexDirection: "row",
                                                                    gap: 4,
                                                                }}
                                                            >
                                                                <div>
                                                                    {formateDateNew({
                                                                        date: fromUTC(effectiveDate.value),
                                                                    })}
                                                                </div>
                                                                <div style={{ color: "gray", fontSize: 13 }}>
                                                                    ({effectiveDate.source})
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            "-"
                                                        )}
                                                    </Td>
                                                </Tr>
                                                <Tr>
                                                    <Td style={{ width: 145, verticalAlign: "top", color: "gray" }}>
                                                        Until
                                                    </Td>
                                                    <Td
                                                        onClick={() => setShowUntilDateModal(true)}
                                                        style={{ cursor: "pointer" }}
                                                    >
                                                        {untilDate?.value ? (
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    flexDirection: "row",
                                                                    gap: 4,
                                                                }}
                                                            >
                                                                <div>{formateDateNew({ date: untilDate.value })}</div>
                                                                <div style={{ color: "gray", fontSize: 13 }}>
                                                                    ({untilDate.source})
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            "-"
                                                        )}
                                                    </Td>
                                                </Tr>
                                                <Tr>
                                                    <Td style={{ verticalAlign: "top", color: "gray" }}>
                                                        Extended Until
                                                    </Td>
                                                    <Td
                                                        onClick={() => setShowExtendedUntilDateModal(true)}
                                                        style={{ cursor: "pointer" }}
                                                    >
                                                        {extendedUntilDate?.value ? (
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    flexDirection: "row",
                                                                    gap: 4,
                                                                }}
                                                            >
                                                                <div>
                                                                    {formateDateNew({ date: extendedUntilDate.value })}
                                                                </div>
                                                                <div style={{ color: "gray", fontSize: 13 }}>
                                                                    ({extendedUntilDate.source})
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            "-"
                                                        )}
                                                    </Td>
                                                </Tr>
                                            </TBody>
                                        </Table>
                                    </div>
                                    <div>
                                        <Table>
                                            <TBody>
                                                <Tr>
                                                    <Td style={{ width: 145, verticalAlign: "top", color: "gray" }}>
                                                        Authority
                                                    </Td>
                                                    <Td
                                                        onClick={() => setShowEditAuthorityModal(true)}
                                                        style={{ cursor: "pointer" }}
                                                    >
                                                        {authority?.value ? (
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    flexDirection: "row",
                                                                    gap: 4,
                                                                }}
                                                            >
                                                                <div>{authority.value}</div>
                                                                <div style={{ color: "gray", fontSize: 13 }}>
                                                                    ({authority.source})
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            "-"
                                                        )}
                                                    </Td>
                                                </Tr>
                                                <Tr>
                                                    <Td style={{ verticalAlign: "top", color: "gray" }}>State</Td>
                                                    <Td
                                                        onClick={() => setShowEditStateModal(true)}
                                                        style={{ cursor: "pointer" }}
                                                    >
                                                        {state?.value ? (
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    flexDirection: "row",
                                                                    gap: 4,
                                                                }}
                                                            >
                                                                <div>{state.value}</div>
                                                                <div style={{ color: "gray", fontSize: 13 }}>
                                                                    ({state.source})
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            "-"
                                                        )}
                                                    </Td>
                                                </Tr>
                                                <Tr>
                                                    <Td style={{ verticalAlign: "top", color: "gray" }}>Counties</Td>
                                                    <Td
                                                        onClick={() => setShowEditCountiesModal(true)}
                                                        style={{ cursor: "pointer" }}
                                                    >
                                                        {counties?.value ? (
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    flexDirection: "row",
                                                                    gap: 4,
                                                                }}
                                                            >
                                                                <div>{(counties.value || []).join(", ")}</div>
                                                                <div style={{ color: "gray", fontSize: 13 }}>
                                                                    ({counties.source})
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            "-"
                                                        )}
                                                    </Td>
                                                </Tr>
                                                <Tr>
                                                    <Td style={{ verticalAlign: "top", color: "gray" }}>Cities</Td>
                                                    <Td
                                                        onClick={() => setShowEditCitiesModal(true)}
                                                        style={{ cursor: "pointer" }}
                                                    >
                                                        {cities?.value ? (
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    flexDirection: "row",
                                                                    gap: 4,
                                                                }}
                                                            >
                                                                <div>{(cities.value || []).join(", ")}</div>
                                                                <div style={{ color: "gray", fontSize: 13 }}>
                                                                    ({cities.source})
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            "-"
                                                        )}
                                                    </Td>
                                                </Tr>
                                            </TBody>
                                        </Table>
                                    </div>
                                    <div>
                                        <Table>
                                            <TBody>
                                                <Tr>
                                                    <Td style={{ width: 145, verticalAlign: "top", color: "gray" }}>
                                                        Status
                                                    </Td>
                                                    <Td>
                                                        <div>
                                                            {mandate.status || "Unprocessed"}
                                                            {mandate.status === "processed" &&
                                                            mandate?.processedByAiAt ? (
                                                                <>
                                                                    {" "}
                                                                    {timeAgo(mandate?.processedByAiAt).toLocaleString()}
                                                                </>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </div>
                                                        <div>
                                                            {mandate?.aiVersion ? (
                                                                <>
                                                                    {`v${mandate.aiVersion}`}
                                                                    {" - "}
                                                                    {mandate.aiIterationNumber || 0}
                                                                </>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </div>
                                                    </Td>
                                                </Tr>
                                            </TBody>
                                        </Table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : mandateQuery.isLoading ? (
                <p>Loading...</p>
            ) : (
                <p>No Mandate Found</p>
            )}
        </>
    );
}

export default MandatePage;
