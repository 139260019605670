import { useMemo } from "react";
import { z } from "zod";
import { Form } from "../../layout/form/form";
import ModalDialog from "../../layout/modal-dialog";
import { useForm } from "../../hooks/useForm";
import { useAuth } from "../../auth/use-auth";
import { orderBy, startCase } from "lodash";
import { TextAreaField } from "../../layout/form/text-area-field";
import { MandateMandateEffect } from "../domain/mandate-mandate-effect";
import { useMandateEffects } from "../../mandate-effects/hooks/use-mandate-effects";
import { updateMandateMandateEffect } from "../actions/update-mandate-mandate-effect";
import { useMutation } from "@tanstack/react-query";
import { useFieldArray } from "react-hook-form";
import ButtonNeoGen from "../../layout/button-neogen";

const schema = z.object({
    description: z.string().nullish(),
    impact: z.string().nullish(),
    notes: z.string().nullish(),
    references: z.array(z.any()).nullish(),
});

type Data = z.infer<typeof schema>;

export const EditMandateMandateEffectModal = ({
    mandateMandateEffect,
    userId,
    onClose,
    onUpdated,
}: {
    mandateMandateEffect: MandateMandateEffect;
    userId?: string;
    onClose: () => void;
    onUpdated?: (mandateMandateEffect: MandateMandateEffect) => void;
}) => {
    const auth = useAuth();
    const authToken = auth.expectAuthToken();

    const form = useForm<Data>({
        schema,
        defaultValues: {
            description: mandateMandateEffect.description,
            impact: mandateMandateEffect.impact,
            notes: mandateMandateEffect.notes,
            references: (mandateMandateEffect.references || mandateMandateEffect.aiReferences || []).map((v) => ({
                value: v,
            })),
        },
    });

    const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
        control: form.control,
        name: "references",
    });

    const updateMandateMandateEffectMutation = useMutation({
        mutationFn: async (data: Partial<MandateMandateEffect>) => {
            const updatedMandateMandateEffect = await updateMandateMandateEffect({
                authToken,
                id: mandateMandateEffect.id,
                data,
            });
            if (onUpdated) {
                onUpdated(updatedMandateMandateEffect);
            }
            onClose();
        },
    });

    const handleSubmit = async (data: Data) => {
        const references = (data.references || []).map((r: any) => r.value).filter((v) => !!v);

        const formattedData: Partial<MandateMandateEffect> = {
            description: data.description,
            humanDescription: data.description,
            impact: data.impact,
            humanImpact: data.impact,
            notes: data.notes,
            references,
            humanReferences: references,
        };

        updateMandateMandateEffectMutation.mutate(formattedData);
    };

    return (
        <Form onSubmit={form.handleSubmit(handleSubmit)}>
            <ModalDialog
                title={"Edit Mandate Effect"}
                close={onClose}
                show
                okText="Update Mandate Effect"
                okAction={form.handleSubmit(handleSubmit)}
                isLoading={updateMandateMandateEffectMutation.isLoading}
            >
                <TextAreaField label="Description" {...form.getFieldProps("description")} />
                <div className="flex justify-between w-full">
                    <div className="inline-block relative text-sm font-normal tracking-wider mb-2 leading-normal text-gray-400 dark:text-gray-400">
                        References
                    </div>
                    <ButtonNeoGen onClick={() => append({ value: "" })} size="xs">
                        Add Reference
                    </ButtonNeoGen>
                </div>
                {fields.length > 0 ? (
                    fields.map((field, index) => {
                        return (
                            <div key={field.id} className="flex items-start space-x-2 w-full">
                                <div className="flex-1">
                                    <TextAreaField {...form.getFieldProps(`references.${index}.value`)} />
                                </div>
                                <ButtonNeoGen onClick={() => remove(index)} type="warning" size="xs">
                                    Remove
                                </ButtonNeoGen>
                            </div>
                        );
                    })
                ) : (
                    <div className="text-gray-400">No references</div>
                )}
                <TextAreaField label="Notes" {...form.getFieldProps("notes")} />
            </ModalDialog>
        </Form>
    );
};
