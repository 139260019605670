import { useMutation } from "@tanstack/react-query";
import ModalDialog from "../../layout/modal-dialog";
import { useAuth } from "../../auth/use-auth";
import { Mandate } from "../domain/mandate";
import { updateMandate } from "../actions/update-mandate";
import { z } from "zod";
import { Form } from "../../layout/form/form";
import { TextField } from "../../layout/form/text-field";
import { useForm } from "../../hooks/useForm";
import { Table, TBody, Td, Tr } from "../../layout/table";
import { TextAreaField } from "../../layout/form/text-area-field";
import { RadioField } from "../../layout/form/radio-field";

const schema = z.object({
    aiAuthority: z.string().nullish(),
    isAiAuthorityCorrect: z.coerce.boolean().nullish(),
    humanAuthority: z.string().nullish(),
    authorityNotes: z.string().nullish(),
});

type Data = z.infer<typeof schema>;

export const EditMandateAuthorityModal = ({
    onClose,
    mandate,
    onUpdate,
    userId,
}: {
    onClose: () => void;
    mandate: Mandate;
    onUpdate: (updatedMandate: Mandate) => void;
    userId?: string;
}) => {
    const auth = useAuth();
    const authToken = auth.expectAuthToken();

    const form = useForm({
        schema,
        defaultValues: { ...mandate },
    });

    const triggerAiMutation = useMutation({
        mutationFn: async (data: Partial<Mandate>) => {
            const updatedMandate = await updateMandate({ authToken, id: mandate.id, data });
            onUpdate(updatedMandate);
            onClose();
        },
    });

    const handleSubmit = async (data: Partial<Mandate>) => {
        const isSubmittingAiFeedback = data.isAiAuthorityCorrect !== mandate.isAiAuthorityCorrect;

        const d: Partial<Mandate> =
            isSubmittingAiFeedback === true
                ? {
                      isAiAuthorityCorrect: data.isAiAuthorityCorrect,
                      aiAuthorityAtFeedbackTime: data.aiAuthority,
                      aiAuthorityFeedbackSubmittedAt: new Date(),
                      authority: data.isAiAuthorityCorrect === true ? data.aiAuthority : data.humanAuthority,
                  }
                : { authority: data.isAiAuthorityCorrect !== true ? data.humanAuthority : undefined };

        const updatedData: Partial<Mandate> = {
            ...d,
            humanAuthority: data.humanAuthority,
            authorityNotes: data.authorityNotes,
            feedbackOnAuthorityUserId: userId,
        };

        await triggerAiMutation.mutateAsync(updatedData);
    };

    const values = form.watch();

    const useEditableField = values.isAiAuthorityCorrect !== true || !mandate.aiAuthority;

    return (
        <ModalDialog
            title="Edit Mandate Authority"
            show
            close={onClose}
            okAction={form.handleSubmit(handleSubmit)}
            okText="Update Authority"
            isLoading={triggerAiMutation.isLoading}
            size="md"
        >
            <Form onSubmit={form.handleSubmit(handleSubmit)}>
                <Table>
                    <TBody>
                        <Tr>
                            <Td style={{ color: "gray", width: 220 }}>Confirmed Authority</Td>
                            <Td style={{ fontWeight: 800, paddingTop: 0, paddingBottom: 0 }}>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                    <div className="flex-1">
                                        {useEditableField ? (
                                            <TextField
                                                className="flex-1"
                                                isRequired={values.isAiAuthorityCorrect === false}
                                                {...form.getFieldProps("humanAuthority")}
                                            />
                                        ) : (
                                            <>
                                                {values.isAiAuthorityCorrect === true
                                                    ? mandate.aiAuthorityAtFeedbackTime || mandate.aiAuthority
                                                    : mandate.authority || "-"}
                                            </>
                                        )}
                                    </div>
                                </div>
                            </Td>
                        </Tr>
                        <Tr>
                            <Td style={{ color: "gray", width: 185 }}>AI Suggested Authority</Td>
                            <Td style={{ fontWeight: 500, paddingTop: 0, paddingBottom: 0 }}>
                                <div style={{ display: "flex", flexDirection: "row", gap: 6, alignItems: "center" }}>
                                    <div>
                                        {mandate.isAiAuthorityCorrect === true
                                            ? mandate.aiAuthorityAtFeedbackTime
                                            : mandate.aiAuthority || "-"}
                                    </div>
                                </div>
                            </Td>
                        </Tr>
                        <Tr>
                            <Td style={{ color: "gray", width: 180 }}>Is the AI suggestion correct?</Td>
                            <Td style={{ fontWeight: 500, paddingTop: 0, paddingBottom: 0 }}>
                                <div style={{ display: "flex", flexDirection: "row", gap: 6, alignItems: "center" }}>
                                    <RadioField
                                        disabled={!mandate.aiAuthority}
                                        {...form.getFieldProps("isAiAuthorityCorrect")}
                                        options={[
                                            { label: "Yes", value: true },
                                            { label: "No", value: false },
                                        ]}
                                    />
                                </div>
                            </Td>
                        </Tr>
                        <Tr>
                            <Td style={{ color: "gray", width: 185 }}>Google Sheet Authority</Td>
                            <Td style={{ fontWeight: 500, paddingTop: 0, paddingBottom: 0 }}>
                                <div style={{ display: "flex", flexDirection: "row", gap: 6, alignItems: "center" }}>
                                    <div>{mandate.googleSheetAuthority || "-"}</div>
                                </div>
                            </Td>
                        </Tr>
                    </TBody>
                </Table>
                <TextAreaField label="Notes" {...form.getFieldProps("authorityNotes")} />
            </Form>
        </ModalDialog>
    );
};
