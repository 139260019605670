import { z } from "zod";
import { getAuthTokenNoThrow } from "../../../services/auth-header";
import { useForm } from "../../../hooks/useForm";
import { useMutation } from "@tanstack/react-query";
import ModalDialog from "../../../layout/modal-dialog";
import { Form } from "../../../layout/form/form";
import ButtonNeoGen from "../../../layout/button-neogen";
import { createReferrer } from "../actions/create-referrer";
import { NumberField } from "../../../layout/form/number-field";
import { useState } from "react";
import { updateReferrer } from "../actions/update-referrer";

const schema = z.object({
    commissionPercentage: z.number().max(100).min(0),
});

type Data = z.infer<typeof schema>;

export const EditCommissionModal = ({
    onClose,
    onUpdated,
    item,
}: {
    onClose: () => any;
    onUpdated: () => void;
    item: any;
}) => {
    const authToken = getAuthTokenNoThrow() || "no-auth-token";
    const form = useForm({ schema, defaultValues: { commissionPercentage: item.commissionPercentage } });
    const [showExistingUsers, setShowExistingUsers] = useState(0);

    const mutation = useMutation({
        mutationFn: async (data: Data) => {
            const result = await updateReferrer({
                id: item.id,
                authToken,
                data: {
                    commissionPercentage: data.commissionPercentage,
                },
            });
        },
    });

    const handleSubmit = async (data: Data) => {
        const result = await mutation.mutateAsync(data);
        onUpdated();
    };

    return (
        <ModalDialog
            size="xs"
            show
            title={`Update commission percentage that ${item.referrer.firstName} ${item.referrer.lastName} receives for for ${item.affiliate?.firstName} ${item.affiliate?.lastName}`}
            close={onClose}
            showOk={false}
            showCancel={false}
        >
            <Form onSubmit={form.handleSubmit(handleSubmit)} error={mutation.error as any}>
                <div className="grid grid-cols-1 gap-3">
                    <NumberField
                        label="Override commission % (default 10%)"
                        {...form.getFieldProps("commissionPercentage")}
                    />
                </div>
                <ButtonNeoGen block type="submit" disabled={mutation.isLoading}>
                    Update commission %
                </ButtonNeoGen>
            </Form>
        </ModalDialog>
    );
};
