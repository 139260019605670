import { useMemo } from "react";
import { Currency } from "../../billing/components";
import ButtonNeoGen from "../../layout/button-neogen";
import { TBody, THead, Table, Td, Th, Tr } from "../../layout/table";
import { Company } from "../domain/company";
import { Payment } from "../../payments/domain/payment";
import { DateDisplay } from "../../layout/date-display";
import { QuartersData } from "./billing";

export const QuartersTable = ({
    data,
    setShowEditQuarters,
    showEditQuarters,
    irsPayments,
}: {
    data: QuartersData;
    setShowEditQuarters: (show: boolean) => void;
    showEditQuarters: boolean;
    irsPayments?: Payment[];
}) => {
    const totalQuarters = useMemo(() => {
        return (
            (data.q2_2020 || 0) +
            (data.q3_2020 || 0) +
            (data.q4_2020 || 0) +
            (data.q1_2021 || 0) +
            (data.q2_2021 || 0) +
            (data.q3_2021 || 0) +
            (data.q4_2021 || 0)
        );
    }, [data]);

    const q2_2020_payment = irsPayments?.find((p) => p.quarter === "Q2 2020");
    const q3_2020_payment = irsPayments?.find((p) => p.quarter === "Q3 2020");
    const q4_2020_payment = irsPayments?.find((p) => p.quarter === "Q4 2020");
    const q1_2021_payment = irsPayments?.find((p) => p.quarter === "Q1 2021");
    const q2_2021_payment = irsPayments?.find((p) => p.quarter === "Q2 2021");
    const q3_2021_payment = irsPayments?.find((p) => p.quarter === "Q3 2021");
    const q4_2021_payment = irsPayments?.find((p) => p.quarter === "Q4 2021");

    const totalPayouts = useMemo(() => {
        return (
            (q2_2020_payment?.amount || 0) +
            (q3_2020_payment?.amount || 0) +
            (q4_2020_payment?.amount || 0) +
            (q1_2021_payment?.amount || 0) +
            (q2_2021_payment?.amount || 0) +
            (q3_2021_payment?.amount || 0) +
            (q4_2021_payment?.amount || 0)
        );
    }, [irsPayments]);

    const rows = [
        {
            quarter: "Q2 2020",
            reason: data.q2_2020_qualificationReason,
            amount: data.q2_2020,
            date: data.q2_2020_filedAt ? new Date(data.q2_2020_filedAt) : null,
            irsStatus: data.q2_2020_irsTranscriptStatus,
            irsDate: data.q2_2020_irsTranscriptDate ? new Date(data.q2_2020_irsTranscriptDate) : null,
            payout: q2_2020_payment ? q2_2020_payment.amount : null,
            payoutDate: q2_2020_payment ? q2_2020_payment.date : null,
        },
        {
            quarter: "Q3 2020",
            reason: data.q3_2020_qualificationReason,
            amount: data.q3_2020,
            date: data.q3_2020_filedAt ? new Date(data.q3_2020_filedAt) : null,
            irsStatus: data.q3_2020_irsTranscriptStatus,
            irsDate: data.q3_2020_irsTranscriptDate ? new Date(data.q3_2020_irsTranscriptDate) : null,
            payout: q3_2020_payment ? q3_2020_payment.amount : null,
            payoutDate: q3_2020_payment ? q3_2020_payment.date : null,
        },
        {
            quarter: "Q4 2020",
            reason: data.q4_2020_qualificationReason,
            amount: data.q4_2020,
            date: data.q4_2020_filedAt ? new Date(data.q4_2020_filedAt) : null,
            irsStatus: data.q4_2020_irsTranscriptStatus,
            irsDate: data.q4_2020_irsTranscriptDate ? new Date(data.q4_2020_irsTranscriptDate) : null,
            payout: q4_2020_payment ? q4_2020_payment.amount : null,
            payoutDate: q4_2020_payment ? q4_2020_payment.date : null,
        },
        {
            quarter: "Q1 2021",
            reason: data.q1_2021_qualificationReason,
            amount: data.q1_2021,
            date: data.q1_2021_filedAt ? new Date(data.q1_2021_filedAt) : null,
            irsStatus: data.q1_2021_irsTranscriptStatus,
            irsDate: data.q1_2021_irsTranscriptDate ? new Date(data.q1_2021_irsTranscriptDate) : null,
            payout: q1_2021_payment ? q1_2021_payment.amount : null,
            payoutDate: q1_2021_payment ? q1_2021_payment.date : null,
        },
        {
            quarter: "Q2 2021",
            reason: data.q2_2021_qualificationReason,
            amount: data.q2_2021,
            date: data.q2_2021_filedAt ? new Date(data.q2_2021_filedAt) : null,
            irsStatus: data.q2_2021_irsTranscriptStatus,
            irsDate: data.q2_2021_irsTranscriptDate ? new Date(data.q2_2021_irsTranscriptDate) : null,
            payout: q2_2021_payment ? q2_2021_payment.amount : null,
            payoutDate: q2_2021_payment ? q2_2021_payment.date : null,
        },
        {
            quarter: "Q3 2021",
            reason: data.q3_2021_qualificationReason,
            amount: data.q3_2021,
            date: data.q3_2021_filedAt ? new Date(data.q3_2021_filedAt) : null,
            irsStatus: data.q3_2021_irsTranscriptStatus,
            irsDate: data.q3_2021_irsTranscriptDate ? new Date(data.q3_2021_irsTranscriptDate) : null,
            payout: q3_2021_payment ? q3_2021_payment.amount : null,
            payoutDate: q3_2021_payment ? q3_2021_payment.date : null,
        },
        {
            quarter: "Q4 2021",
            reason: data.q4_2021_qualificationReason,
            amount: data.q4_2021,
            date: data.q4_2021_filedAt ? new Date(data.q4_2021_filedAt) : null,
            irsStatus: data.q4_2021_irsTranscriptStatus,
            irsDate: data.q4_2021_irsTranscriptDate ? new Date(data.q4_2021_irsTranscriptDate) : null,
            payout: q4_2021_payment ? q4_2021_payment.amount : null,
            payoutDate: q4_2021_payment ? q4_2021_payment.date : null,
        },
    ];

    return (
        <>
            <div style={{ marginBottom: 12, display: "flex", justifyContent: "space-between" }}>
                <p style={{ fontSize: 14, fontWeight: "bold" }}>Quarters Submitted to IRS</p>
                <ButtonNeoGen onClick={() => setShowEditQuarters(!showEditQuarters)}>Edit</ButtonNeoGen>
            </div>
            <Table>
                <THead>
                    <Tr>
                        <Th>Quarter</Th>
                        <Th>Qualification Reason</Th>
                        <Th>Filed Amount</Th>
                        <Th>Filed Date</Th>
                        <Th>IRS Transcript Status</Th>
                        <Th>Received from IRS Amount</Th>
                        <Th>Received from IRS Date</Th>
                    </Tr>
                </THead>
                <TBody>
                    {rows.map((row) => (
                        <Tr key={row.quarter}>
                            <Td>{row.quarter}</Td>
                            <Td>{row.reason || "-"}</Td>
                            <Td>{row.amount ? <Currency amount={row.amount} /> : "-"}</Td>
                            <Td>{row.date ? <DateDisplay date={row.date} /> : "-"}</Td>
                            <Td>
                                {row.irsStatus}
                                <br />
                                {row.irsDate ? <DateDisplay date={row.irsDate} /> : ""}
                            </Td>
                            <Td>{row.payout ? <Currency amount={row.payout} /> : "-"}</Td>
                            <Td>{row.payoutDate ? <DateDisplay date={row.payoutDate} /> : "-"}</Td>
                        </Tr>
                    ))}
                    <Tr className="bg-gray-50">
                        <Td>
                            <b>Total</b>
                        </Td>
                        <Td>
                            <b>
                                <Currency amount={totalQuarters} />
                            </b>
                        </Td>
                        <Td></Td>
                        <Td></Td>
                        <Td>
                            <b>
                                <Currency amount={totalPayouts} />
                            </b>
                        </Td>
                        <Td></Td>
                        <Td></Td>
                    </Tr>
                </TBody>
            </Table>
        </>
    );
};
