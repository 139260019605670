export type RoleGroupUser = {
    id?: number;
    roleGroupsId: number;
    clearErcUserId: string;
};
import APIService from "./api.service";

// const debug        = require('debug')('Neogen:CDRService');

class RoleGroupUsersService extends APIService<RoleGroupUser> {
    constructor() {
        super("role-group-users");
    }
}

export default new RoleGroupUsersService();
