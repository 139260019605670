import { useController } from "react-hook-form";
import { GetFieldPropsReturn } from "../../hooks/useForm";
import { Field, FormFieldProps } from "./field";
import { TextInputProps } from "./text-input";
import { PhoneNumberInput } from "./phone-number-input";

export const PhoneNumberField = ({
    name,
    form,
    isRequired,
    label,
    noMargin,
    ...other
}: FormFieldProps<string> & GetFieldPropsReturn<any> & TextInputProps & { noMargin?: boolean }) => {
    const { field, fieldState } = useController({
        name,
        control: form.control,
        rules: {
            required: isRequired ? "This field is required" : undefined,
            pattern: {
                value: /^\(\d{3}\)\s\d{3}-\d{4}$/,
                message: "Please enter a valid phone number in the format (XXX) XXX-XXXX",
            },
        },
        defaultValue: other.defaultValue || "",
    });
    const error = fieldState.error?.message;

    return (
        <Field label={label} isRequired={isRequired} helpText={other.helperText} error={error} noMargin={noMargin}>
            <PhoneNumberInput
                {...other}
                {...field}
                onChange={(e) => {
                    field.onChange(e.target.value);
                    if (other.onChange) {
                        other.onChange(e);
                    }
                }}
                hasError={!!error}
            />
        </Field>
    );
};
