import { useMutation } from "@tanstack/react-query";
import ModalDialog from "../../layout/modal-dialog";
import { useAuth } from "../../auth/use-auth";
import { Mandate } from "../domain/mandate";
import { updateMandate } from "../actions/update-mandate";
import { z } from "zod";
import { Form } from "../../layout/form/form";
import { TextField } from "../../layout/form/text-field";
import { useForm } from "../../hooks/useForm";
import { Table, TBody, Td, Tr } from "../../layout/table";
import { TextAreaField } from "../../layout/form/text-area-field";
import { RadioField } from "../../layout/form/radio-field";
import { SelectField } from "../../layout/form/select-field";
import { counties } from "../../counties";

const schema = z.object({
    aiCounties: z.array(z.string()).nullish(),
    isAiCountiesCorrect: z.coerce.boolean().nullish(),
    humanCounties: z.array(z.string()).nullish(),
    countiesNotes: z.string().nullish(),
});

type Data = z.infer<typeof schema>;

export const EditMandateCountiesModal = ({
    onClose,
    mandate,
    onUpdate,
    userId,
}: {
    onClose: () => void;
    mandate: Mandate;
    onUpdate: (updatedMandate: Mandate) => void;
    userId?: string;
}) => {
    const auth = useAuth();
    const authToken = auth.expectAuthToken();

    const form = useForm({
        schema,
        defaultValues: { ...mandate },
    });

    const triggerAiMutation = useMutation({
        mutationFn: async (data: Partial<Mandate>) => {
            const updatedMandate = await updateMandate({ authToken, id: mandate.id, data });
            onUpdate(updatedMandate);
            onClose();
        },
    });

    const handleSubmit = async (data: Partial<Mandate>) => {
        const isSubmittingAiFeedback = data.isAiCountiesCorrect !== mandate.isAiCountiesCorrect;
        const humanCounties = (data.humanCounties || []).map((c) => c.replace(/__\d+$/, ""));

        const d: Partial<Mandate> =
            isSubmittingAiFeedback === true
                ? {
                      isAiCountiesCorrect: data.isAiCountiesCorrect,
                      aiCountiesAtFeedbackTime: data.aiCounties || [],
                      aiCountiesFeedbackSubmittedAt: new Date(),
                      counties: data.isAiCountiesCorrect === true ? data.aiCounties || [] : humanCounties,
                  }
                : { counties: data.isAiCountiesCorrect !== true ? humanCounties : undefined };

        const updatedData: Partial<Mandate> = {
            ...d,
            humanCounties: humanCounties,
            countiesNotes: data.countiesNotes,
            feedbackOnCountiesUserId: userId,
        };

        await triggerAiMutation.mutateAsync(updatedData);
    };

    const values = form.watch();

    const useEditableField = values.isAiCountiesCorrect !== true || !mandate.aiCounties;

    return (
        <ModalDialog
            title="Edit Mandate Counties"
            show
            size="md"
            close={onClose}
            okAction={form.handleSubmit(handleSubmit)}
            okText="Update Counties"
            isLoading={triggerAiMutation.isLoading}
        >
            <Form onSubmit={form.handleSubmit(handleSubmit)}>
                <Table>
                    <TBody>
                        <Tr>
                            <Td style={{ color: "gray", width: 220 }}>Confirmed Counties</Td>
                            <Td style={{ fontWeight: 800, paddingTop: 0, paddingBottom: 0 }}>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        width: "100%",
                                    }}
                                >
                                    <div className="w-full">
                                        {useEditableField ? (
                                            <SelectField
                                                isMultiple
                                                options={counties.map((c) => ({
                                                    value: `${c.county}__${c.id}`,
                                                    label: `${c.county} (${c.state})`,
                                                }))}
                                                isRequired={values.isAiCountiesCorrect === false}
                                                {...form.getFieldProps("humanCounties")}
                                            />
                                        ) : (
                                            <>
                                                {values.isAiCountiesCorrect === true
                                                    ? mandate.aiCountiesAtFeedbackTime || mandate.aiCounties
                                                    : mandate.counties || "-"}
                                            </>
                                        )}
                                    </div>
                                </div>
                            </Td>
                        </Tr>
                        <Tr>
                            <Td style={{ color: "gray", width: 185 }}>AI Suggested Counties</Td>
                            <Td style={{ fontWeight: 500, paddingTop: 0, paddingBottom: 0 }}>
                                <div style={{ display: "flex", flexDirection: "row", gap: 6, alignItems: "center" }}>
                                    <div>
                                        {mandate.isAiCountiesCorrect === true
                                            ? mandate.aiCountiesAtFeedbackTime
                                            : mandate.aiCounties || "-"}
                                    </div>
                                </div>
                            </Td>
                        </Tr>
                        <Tr>
                            <Td style={{ color: "gray", width: 180 }}>Is the AI suggestion correct?</Td>
                            <Td style={{ fontWeight: 500, paddingTop: 0, paddingBottom: 0 }}>
                                <div style={{ display: "flex", flexDirection: "row", gap: 6, alignItems: "center" }}>
                                    <RadioField
                                        disabled={!mandate.aiCounties}
                                        {...form.getFieldProps("isAiCountiesCorrect")}
                                        options={[
                                            { label: "Yes", value: true },
                                            { label: "No", value: false },
                                        ]}
                                    />
                                </div>
                            </Td>
                        </Tr>
                        <Tr>
                            <Td style={{ color: "gray", width: 185 }}>Google Sheet Counties</Td>
                            <Td style={{ fontWeight: 500, paddingTop: 0, paddingBottom: 0 }}>
                                <div style={{ display: "flex", flexDirection: "row", gap: 6, alignItems: "center" }}>
                                    <div>{mandate.googleSheetCounties?.join(", ") || "-"}</div>
                                </div>
                            </Td>
                        </Tr>
                    </TBody>
                </Table>
                <TextAreaField label="Notes" {...form.getFieldProps("countiesNotes")} />
            </Form>
        </ModalDialog>
    );
};
