import { useEffect, useMemo, useState } from "react";
import InputControlled from "./input-controlled";
import ModalDialog from "./modal-dialog";
import { v4 as uuidv4 } from "uuid";
import ModalSingleText from "./modal-single-text";
import SelectNeoGen from "./select-neogen";
import { SliderPicker } from "react-color";
import FieldSelector from "../sections/processflows/components/field-selector";
import { SaveDetailsType } from "../typings/save-details-type";
import { useQuery } from "@tanstack/react-query";
import roleGroupService from "../services/role-group.service";
import TextAreaNeoGenControlled from "./text-area-controlled";
import OptionsDropDown from "./options-dropdown";
import { getUsers } from "../jason-proof-of-concept/users/actions/get-users";
import { getAuthTokenNoThrow } from "../services/auth-header";
import { sortUsers } from "../sections/utilities/sortUsers";
import { ClearERCUser } from "../typings/api/clear-erc-user";
import { useRoleGroups } from "../role-groups/hooks/use-role-groups";
import { AlertField, AlertUser } from "./alert-field";
import { RoleGroup } from "../typings/api/role-group";

export type ButtonActionTypes =
    | "nextStep"
    | "nextStepAndSendSlackMessage"
    | "nextStepAndAlert"
    | "nextStage"
    | "download2848Page1"
    | "download2848Page2"
    | "download911Page1"
    | "download911Page2"
    | "download911Page3"
    | "download911Page4"
    | "getRandDReasons"
    | "getIssues"
    | "previousStep"
    | "previousStage"
    | "redirect"
    | "createAccount"
    | "createAccountAndLogin"
    | "createAccountLoginAndRedirect"
    | "sendEmailRep"
    | "sendEmailClient"
    | "sendEmailBoth"
    | "createAccountSendConfirmation"
    | "createAccountAndLogin"
    | "login"
    | "logout"
    | "showModal"
    | "hideModal"
    | "showModalAndClose"
    | "hideModalAndClose"
    | "showModalAndCloseAll"
    | "hideModalAndCloseAll"
    | "closeAllModals"
    | "closeModal"
    | "uploadFile"
    | "setVariable"
    | "setVariableAndNextStage"
    | "addRoleAndNextStep"
    | "showTaxPayerAdvocateModal"
    | "updateAggregateGrossReceipts"
    | "setVariableAndNextStep";

export type ButtonColorType =
    | "danger"
    | "info"
    | "primary"
    | "submit"
    | "secondary"
    | "outline"
    | "warning"
    | "success"
    | "danger"
    | "info"
    | "outline-primary"
    | "custom";

export type ButtonOptions = {
    label: string;
    color: string;
    action: ButtonActionTypes;
    urlToRedirectTo?: string;
    alertUsers?: AlertUser[];
    slackUrl?: string;
    slackHeader?: string;
    slackMessage?: string;
    roleGroupIdToRemove?: number;
    roleGroupIdToAdd?: number;
    emailTemplateRep?: string;
    emailTemplateClient?: string;
    colorType: ButtonColorType;
    size: string;
    usernameField: string;
    passwordField: string;
    usernameGroup: string;
    usernameProcessflow: string;
    passwordGroup: string;
    passwordProcessflow: string;
};

export default function ButtonCreator(props: ButtonCreatorProps) {
    const [field, setField] = useState<string>(props.field ?? "");
    const [urlToRedirectTo, setUrlToRedirectTo] = useState<string>(props.urlToRedirectTo ?? "");
    const [slackUrl, setSlackUrl] = useState<string>(props.slackUrl ?? "");
    const [slackHeader, setSlackHeader] = useState<string>(props.slackHeader ?? "");
    const [slackMessage, setSlackMessage] = useState<string>(props.slackMessage ?? "");
    const [roleGroupIdToAdd, setRoleGroupIdToAdd] = useState<number>(props.roleGroupIdToAdd ?? -1);
    const [roleGroupIdToRemove, setRoleGroupIdToRemove] = useState<number>(props.roleGroupIdToRemove ?? -1);
    const [label, setLabel] = useState<string>(props.value ?? "");
    const [colorType, setColorType] = useState<string>(props.colorType ?? "primary");
    const [selectBoxEntries, setSelectBoxEntries] = useState<string[]>(
        props.entry?.selectBoxEntries ? props.entry.selectBoxEntries : [],
    );
    const [showAddEntry, setShowAddEntry] = useState<boolean>(false);
    const [actionType, setActionType] = useState<string>(props.entry?.actionType ? props.entry.actionType : "nextStep");
    const [size, setSize] = useState<string>(props.entry?.size ?? "");
    const [usernameField, setUsernameField] = useState<string>(props.entry?.size ?? "");
    const [passwordField, setPasswordField] = useState<string>(props.entry?.size ?? "");
    const [buttonColor, setButtonColor] = useState<string>(props.entry?.buttonColor ?? "#ff0000");
    const [usernameGroup, setUsernameGroup] = useState<string>(props.entry?.usernameGroup ?? "");
    const [usernameProcessflow, setUsernameProcessflow] = useState<string>(props.entry?.usernameProcessflow ?? "");
    const [passwordGroup, setPasswordGroup] = useState<string>(props.entry?.passwordGroup ?? "");
    const [passwordProcessflow, setPasswordProcessflow] = useState<string>(props.entry?.passwordProcessflow ?? "");
    const rolesGroupsQuery = useQuery(["roleGroups"], async () => {
        const response = await roleGroupService.getAll();
        if (response) {
            return response.data;
        }
    });
    const authToken = getAuthTokenNoThrow() || "no-token";
    const usersQuery = useQuery(["users"], async () => {
        const response = await getUsers({ authToken });
        return response || [];
    });
    const users = useMemo(() => sortUsers((usersQuery.data as ClearERCUser[]) || []), [usersQuery.data]);
    const roleGroups = useMemo(() => rolesGroupsQuery.data || [], [rolesGroupsQuery.data]);
    const [alertUsers, setAlertUsers] = useState<AlertUser[]>([]);

    function saveRecord() {
        const options: ButtonOptions = {
            label,
            color: buttonColor,
            action: actionType as ButtonActionTypes,
            urlToRedirectTo,
            alertUsers,
            slackUrl,
            slackHeader,
            slackMessage,
            emailTemplateRep: props.entry?.emailTemplateRep ?? "",
            emailTemplateClient: props.entry?.emailTemplateClient ?? "",
            colorType: colorType as ButtonColorType,
            usernameField,
            passwordField,
            size,
            usernameGroup,
            usernameProcessflow,
            passwordGroup,
            passwordProcessflow,
            roleGroupIdToAdd,
            roleGroupIdToRemove,
        };

        if (props.isEdit && props.saveEdit) {
            // // { field, value, description, required, widthOf300Cols, selectBoxEntries, helpDocument, password, ruleGroupsAreOr }
            props.saveEdit({
                field,
                value: JSON.stringify(options),
                description: "",
                required: false,
                widthOf300Cols: "300",
                selectBoxEntries,
                helpDocument: "",
                password: false,
                ruleGroupsAreOr: false,
                defaultVal: "",
                hideWhenFilled: false,
                disabled: false,
            });
        } else {
            props.save({
                field: uuidv4(),
                value: JSON.stringify(options),
                description: "",
                required: false,
                widthOf300Cols: "300",
                selectBoxEntries,
                helpDocument: "",
                password: false,
                ruleGroupsAreOr: false,
                defaultVal: "",
                hideWhenFilled: false,
                disabled: false,
            });
        }
    }

    function addEntry() {
        setShowAddEntry(true);
    }

    useEffect(() => {
        if (props.entry && props.show) {
            const button = JSON.parse(props.entry.value) as ButtonOptions;
            setLabel(button.label);
            setUrlToRedirectTo(button.urlToRedirectTo ?? "");
            setAlertUsers(button.alertUsers ?? []);
            setSlackMessage(button.slackMessage ?? "");
            setSlackHeader(button.slackHeader ?? "");
            setSlackUrl(button.slackUrl ?? "");
            setActionType(button.action);
            setButtonColor(button.color);
            setColorType(button.colorType);
            setSize(button.size);
            setUsernameField(button.usernameField);
            setPasswordField(button.passwordField);
            setSize(button.size);
            setUsernameGroup(button.usernameGroup);
            setUsernameProcessflow(button.usernameProcessflow);
            setPasswordGroup(button.passwordGroup);
            setPasswordProcessflow(button.passwordProcessflow);
            setRoleGroupIdToAdd(button.roleGroupIdToAdd ?? -1);
            setRoleGroupIdToRemove(button.roleGroupIdToRemove ?? -1);
        }
    }, [props.entry, props.label, props.show]);

    if (rolesGroupsQuery.isLoading) {
        return <div>Loading...</div>;
    }

    const addVariable = (val: string) => {
        setSlackMessage(slackMessage + "{{" + val + "}} ");
    };

    return (
        <>
            <ModalDialog
                title={props.title}
                close={props.close}
                size="md"
                show={props.show}
                okAction={saveRecord}
                okText="Close"
                showCancel={false}
            >
                <InputControlled
                    label={"Button Label"}
                    name={props.label}
                    value={label}
                    setValue={(value: string) => setLabel(value)}
                    description="This is the text that appears on the button"
                />

                <SelectNeoGen
                    className="my-5"
                    label={"Button Action"}
                    description="What happens when the button is clicked"
                    options={[
                        { id: "nextStep", name: "Go to the next step" },
                        { id: "nextStepAndSendSlackMessage", name: "Go to the next step and send Slack message" },
                        { id: "nextStepAndAlert", name: "Go to the next step and alert user" },
                        { id: "updateAggregateGrossReceipts", name: "Update Aggregate Company Gross Receipts Details" },
                        { id: "nextStage", name: "Go to the next stage" },
                        { id: "previousStep", name: "Go to the previous step" },
                        { id: "previousStage", name: "Go to the previous stage" },
                        { id: "redirect", name: "Redirect to a URL" },
                        { id: "createAccount", name: "Create an account" },
                        { id: "createAccountAndLogin", name: "Create an account and log the user in" },
                        {
                            id: "createAccountLoginAndRedirect",
                            name: "Create an account, log the user in, and redirect to a URL",
                        },
                        { id: "createAccountSendConfirmation", name: "Create an account, send email confirmation" },
                        { id: "sendEmailRep", name: "Send an email to the representative" },
                        { id: "sendEmailClient", name: "Send an email to the client" },
                        { id: "sendEmailBoth", name: "Send an email to the client and the representative" },
                        { id: "login", name: "Log the user in" },
                        { id: "logout", name: "Log the user out" },
                        {
                            id: "completeAffiliateTraining",
                            name: "Complete affiliate training and navigate to dashboard",
                        },
                        { id: "showModal", name: "Show a modal" },
                        { id: "hideModal", name: "Hide a modal" },
                        { id: "showModalAndClose", name: "Show a modal and close this one" },
                        { id: "hideModalAndClose", name: "Hide a modal and close this one" },
                        { id: "showModalAndCloseAll", name: "Show a modal and close all modals" },
                        { id: "hideModalAndCloseAll", name: "Hide a modal and close all modals" },
                        { id: "closeAllModals", name: "Close all modals" },
                        { id: "closeModal", name: "Close this modal" },
                        { id: "uploadFile", name: "Upload a file" },
                        { id: "setVariable", name: "Set a variable" },
                        { id: "setVariableAndNextStage", name: "Set a variable and go to the next stage" },

                        { id: "setVariableAndNextStep", name: "Set a variable and go to the next step" },
                        { id: "addRoleGroup", name: "Add a role group" },
                        { id: "removeRoleGroup", name: "Remove a role group" },
                        { id: "openBillingModal", name: "Open Billing Modal" },
                        { id: "showTaxPayerAdvocateModal", name: "Show Tax Payer Advocate Modal" },
                        { id: "download2848Page1", name: "Download Page 1 of the completed 2848 Form" },
                        { id: "download2848Page2", name: "Download Page 2 of the completed 2848 Form" },
                        { id: "download911Page1", name: "Download Page 1 of the completed 911 Form" },
                        { id: "download911Page2", name: "Download Page 2 of the completed 911 Form" },
                        { id: "download911Page3", name: "Download Page 3 of the completed 911 Form" },
                        { id: "download911Page4", name: "Download Page 4 of the completed 911 Form" },
                        { id: "getRandDReasons", name: "Get a list of ways a customer might qualify for R&D Credit" },
                        { id: "getIssues", name: "Get a list of ways a customer might qualify for ERC" },
                    ]}
                    onChange={(value: number | string) => setActionType(value.toString())}
                    value={actionType}
                />

                {(actionType === "redirect" || actionType === "createAccountLoginAndRedirect") && (
                    <InputControlled
                        label={"URL to redirect to"}
                        name={"urlToRedirectTo"}
                        value={urlToRedirectTo}
                        setValue={(value: string) => setUrlToRedirectTo(value)}
                    />
                )}
                {actionType === "nextStepAndAlert" && (
                    <AlertField
                        alertUsers={alertUsers}
                        setAlertUsers={setAlertUsers}
                        users={users}
                        roleGroups={roleGroups}
                    />
                )}
                {actionType === "addRoleGroup" && (
                    <>
                        <SelectNeoGen
                            options={rolesGroupsQuery.data?.map((role) => {
                                return { id: role.id, name: role.name };
                            })}
                            label={"Role group to add"}
                            value={roleGroupIdToAdd}
                            onChange={(value: number | string) => setRoleGroupIdToAdd(Number(value))}
                        />
                    </>
                )}
                {actionType === "removeRoleGroup" && (
                    <>
                        <SelectNeoGen
                            options={rolesGroupsQuery.data?.map((role) => {
                                return { id: role.id, name: role.name };
                            })}
                            label={"Role group to remove"}
                            value={roleGroupIdToRemove}
                            onChange={(value: number | string) => setRoleGroupIdToRemove(Number(value))}
                        />
                    </>
                )}

                {(actionType === "createAccountSendConfirmation" ||
                    actionType === "createAccountAndLogin" ||
                    actionType === "createAccount" ||
                    actionType === "createAccountLoginAndRedirect") && (
                    <>
                        <FieldSelector
                            label={"Username Field"}
                            name={"usernameField"}
                            field={usernameField}
                            setField={(value: string) => setUsernameField(value)}
                            group={usernameGroup}
                            setGroup={setUsernameGroup}
                            processflow={usernameProcessflow}
                            setProcessflow={setUsernameProcessflow}
                        />
                    </>
                )}
                {actionType === "nextStepAndSendSlackMessage" && (
                    <>
                        <InputControlled
                            label={"Slack URL"}
                            name={"slackUrl"}
                            value={slackUrl}
                            setValue={(value: string) => setSlackUrl(value)}
                        />
                        <InputControlled
                            label={"Slack header"}
                            name={"slackHeader"}
                            value={slackHeader}
                            setValue={(value: string) => setSlackHeader(value)}
                        />
                        <OptionsDropDown
                            options={[
                                { label: "First name", action: () => addVariable("firstName") },
                                { label: "Last name", action: () => addVariable("lastName") },
                                { label: "Email", action: () => addVariable("email") },
                                { label: "Company name", action: () => addVariable("companyName") },
                            ]}
                        />
                        <TextAreaNeoGenControlled
                            label={"Slack message"}
                            name={"slackMessage"}
                            value={slackMessage}
                            setValue={(value: string) => setSlackMessage(value)}
                        />
                    </>
                )}
                {(actionType === "createAccountAndLogin" ||
                    actionType === "createAccount" ||
                    actionType === "createAccountLoginAndRedirect") && (
                    <>
                        <FieldSelector
                            label={"Password Field"}
                            name={"passwordField"}
                            field={passwordField}
                            setField={(value: string) => setPasswordField(value)}
                            group={passwordGroup}
                            setGroup={setPasswordGroup}
                            processflow={passwordProcessflow}
                            setProcessflow={setPasswordProcessflow}
                        />
                    </>
                )}

                <SelectNeoGen
                    className="my-5"
                    label={"Color Type"}
                    options={[
                        { id: "danger", name: "Danger" },
                        { id: "info", name: "Info" },
                        { id: "primary", name: "Primary" },
                        { id: "submit", name: "Submit" },
                        { id: "secondary", name: "Secondary" },
                        { id: "outline", name: "Outline" },
                        { id: "warning", name: "Warning" },
                        { id: "success", name: "Success" },
                        { id: "danger", name: "Danger" },
                        { id: "info", name: "info" },
                        { id: "outline-primary", name: "Outline-primary" },
                        { id: "custom", name: "Custom Color" },
                    ]}
                    onChange={(value: number | string) => setColorType(value.toString())}
                    value={colorType}
                />
                {colorType === "custom" && (
                    <div className="w-full mb-5 border border-gray-300  rounded-lg p-5">
                        <label
                            htmlFor="email"
                            className={"block text-sm font-medium  text-gray-500 dark:text-gray-400 mb-2"}
                        >
                            Custom Button Color
                        </label>
                        <SliderPicker color={buttonColor} onChange={(color) => setButtonColor(color.hex)} />
                    </div>
                )}

                <SelectNeoGen
                    className="my-5"
                    label={"Size"}
                    options={[
                        { id: "xs", name: "Extra Small" },
                        { id: "sm", name: "Small" },
                        { id: "", name: "Normal" },
                        { id: "lg", name: "Large" },
                        { id: "xl", name: "Extra Large" },
                    ]}
                    onChange={(value: number | string) => setSize(value.toString())}
                    value={size}
                />

                <ModalSingleText
                    title="Add Entry"
                    label="Entry"
                    name="entry"
                    show={showAddEntry}
                    close={() => setShowAddEntry(false)}
                    save={(value: string) => {
                        setSelectBoxEntries([...selectBoxEntries, value]);
                        setShowAddEntry(false);
                    }}
                />
            </ModalDialog>
        </>
    );
}

type ButtonCreatorProps = {
    title: string;
    show: boolean;
    close: () => void;
    colorType?: string;
    label: string;
    urlToRedirectTo?: string;
    alertUsers?: AlertUser[];
    slackUrl?: string;
    slackHeader?: string;
    slackMessage?: string;
    roleGroupIdToAdd?: number;
    roleGroupIdToRemove?: number;
    name: string;
    entries: any;
    entry: any;
    field: any;
    value: any;
    isEdit?: boolean;
    save: ({
        field,
        value,
        description,
        required,
        widthOf300Cols,
        selectBoxEntries,
        helpDocument,
        password,
        ruleGroupsAreOr,
    }: SaveDetailsType) => void;
    saveEdit?: ({
        field,
        value,
        description,
        required,
        widthOf300Cols,
        selectBoxEntries,
        helpDocument,
        password,
        ruleGroupsAreOr,
    }: SaveDetailsType) => void;
};
