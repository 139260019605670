import { z } from "zod";

export const mandateSchema = z.object({
    id: z.number(),
    createdAt: z.coerce.date(),
    updatedAt: z.coerce.date(),

    // name
    aiName: z.string().nullish(),
    aiNameFeedbackSubmittedAt: z.coerce.date().nullish(),
    aiNameAtFeedbackTime: z.string().nullish(),
    isAiNameCorrect: z.coerce.boolean().nullish(),
    googleSheetName: z.string().nullish(),
    humanName: z.string().nullish(),
    name: z.string().nullish(), // Final name
    nameNotes: z.string().nullish(),

    // authority
    aiAuthority: z.string().nullish(),
    aiAuthorityFeedbackSubmittedAt: z.coerce.date().nullish(),
    aiAuthorityAtFeedbackTime: z.string().nullish(),
    isAiAuthorityCorrect: z.coerce.boolean().nullish(),
    googleSheetAuthority: z.string().nullish(),
    humanAuthority: z.string().nullish(),
    authority: z.string().nullish(), // Final authority
    authorityNotes: z.string().nullish(),

    // state
    aiState: z.string().nullish(),
    aiStateFeedbackSubmittedAt: z.coerce.date().nullish(),
    aiStateAtFeedbackTime: z.string().nullish(),
    isAiStateCorrect: z.coerce.boolean().nullish(),
    googleSheetState: z.string().nullish(),
    humanState: z.string().nullish(),
    state: z.string().nullish(), // Final state
    stateNotes: z.string().nullish(),

    // counties
    aiCounties: z.array(z.string()).nullish(),
    aiCountiesFeedbackSubmittedAt: z.coerce.date().nullish(),
    aiCountiesAtFeedbackTime: z.array(z.string()).nullish(),
    isAiCountiesCorrect: z.coerce.boolean().nullish(),
    googleSheetCounties: z.array(z.string()).nullish(),
    humanCounties: z.array(z.string()).nullish(),
    counties: z.array(z.string()).nullish(), // Final counties
    countiesNotes: z.string().nullish(),

    // cities
    aiCities: z.array(z.string()).nullish(),
    aiCitiesFeedbackSubmittedAt: z.coerce.date().nullish(),
    aiCitiesAtFeedbackTime: z.array(z.string()).nullish(),
    isAiCitiesCorrect: z.coerce.boolean().nullish(),
    googleSheetCities: z.array(z.string()).nullish(),
    humanCities: z.array(z.string()).nullish(),
    cities: z.array(z.string()).nullish(), // Final cities
    citiesNotes: z.string().nullish(),

    // effectiveDate
    aiEffectiveDate: z.coerce.date().nullish(),
    aiEffectiveDateFeedbackSubmittedAt: z.coerce.date().nullish(),
    aiEffectiveDateAtFeedbackTime: z.coerce.date().nullish(),
    isAiEffectiveDateCorrect: z.coerce.boolean().nullish(),
    humanEffectiveDate: z.coerce.date().nullish(),
    effectiveDate: z.coerce.date().nullish(), // Final effective date.
    effectiveDateNotes: z.string().nullish(),

    // untilDate
    aiUntilDate: z.coerce.date().nullish(),
    aiUntilDateFeedbackSubmittedAt: z.coerce.date().nullish(),
    aiUntilDateAtFeedbackTime: z.coerce.date().nullish(),
    isAiUntilDateCorrect: z.coerce.boolean().nullish(),
    humanUntilDate: z.coerce.date().nullish(),
    untilDate: z.coerce.date().nullish(), // Final until date. Could be extended by extendedUntilDate below.
    untilDateNotes: z.string().nullish(),

    // extendedUntilDate
    aiExtendedUntilDate: z.coerce.date().nullish(),
    aiExtendedUntilDateFeedbackSubmittedAt: z.coerce.date().nullish(),
    aiExtendedUntilDateAtFeedbackTime: z.coerce.date().nullish(),
    isAiExtendedUntilDateCorrect: z.coerce.boolean().nullish(),
    humanExtendedUntilDate: z.coerce.date().nullish(),
    extendedUntilDate: z.coerce.date().nullish(), // Final until date. Could be extended by extendedUntilDate below.
    extendedUntilDateNotes: z.string().nullish(),

    // URL
    googleSheetUrl: z.string().nullish(),
    humanUrl: z.string().nullish(),
    url: z.string().nullish(), // Final URL

    googleSheetDate: z.coerce.date().nullish(),

    googleSheetArea: z.string().nullish(),
    status: z.string().nullish(),
    processedByAiAt: z.coerce.date().nullish(),
    aiVersion: z.number().nullish(),
    aiIterationNumber: z.number().nullish(),
    needsAiSync: z.boolean().nullish(),
    summary: z.string().nullish(),
    date: z.coerce.date().nullish(),
    until: z.coerce.date().nullish(),
    orderText: z.string().nullish(),
    feedbackOnNameUserId: z.string().nullish(),
    feedbackOnEffectiveDateUserId: z.string().nullish(),
    feedbackOnExtendedUntilDateUserId: z.string().nullish(),
    feedbackOnUntilDateUserId: z.string().nullish(),
    feedbackOnAuthorityUserId: z.string().nullish(),
    feedbackOnStateUserId: z.string().nullish(),
    feedbackOnCountiesUserId: z.string().nullish(),
    feedbackOnCitiesUserId: z.string().nullish(),
});

export type Mandate = z.infer<typeof mandateSchema>;

export const getMandateDetails = (mandate: Mandate) => {
    const names = [
        {
            value: mandate.name,
            source: "confirmed",
            condition: () => mandate.name,
        },
        {
            value: mandate.aiNameAtFeedbackTime || mandate.aiName,
            source: "ai suggested",
            condition: () => mandate.aiName && typeof mandate.isAiNameCorrect !== "boolean",
        },
        {
            value: mandate.googleSheetName,
            source: "sheet",
            condition: () => true,
        },
    ];
    const name = names.find((n) => !!n.condition());

    const authorities = [
        {
            value: mandate.authority,
            source: "confirmed",
            condition: () => mandate.authority,
        },
        {
            value: mandate.aiAuthorityAtFeedbackTime || mandate.aiAuthority,
            source: "ai suggested",
            condition: () => mandate.aiAuthority && typeof mandate.isAiAuthorityCorrect !== "boolean",
        },
        {
            value: mandate.googleSheetAuthority,
            source: "sheet",
            condition: () => true,
        },
    ];
    const authority = authorities.find((n) => !!n.condition());

    const states = [
        {
            value: mandate.state,
            source: "confirmed",
            condition: () => mandate.state,
        },
        {
            value: mandate.aiStateAtFeedbackTime || mandate.aiState,
            source: "ai suggested",
            condition: () => mandate.aiState && typeof mandate.isAiStateCorrect !== "boolean",
        },
        {
            value: mandate.googleSheetState,
            source: "sheet",
            condition: () => true,
        },
    ];
    const state = states.find((n) => !!n.condition());

    const countiesArray = [
        {
            value: mandate.counties,
            source: "confirmed",
            condition: () => mandate.counties && mandate.counties.length > 0,
        },
        {
            value: mandate.aiCountiesAtFeedbackTime || mandate.aiCounties,
            source: "ai suggested",
            condition: () => mandate.aiCounties && typeof mandate.isAiCountiesCorrect !== "boolean",
        },
        {
            value: mandate.googleSheetCounties,
            source: "sheet",
            condition: () => true,
        },
    ];
    const counties = countiesArray.find((n) => !!n.condition());

    const citiesArray = [
        {
            value: mandate.cities,
            source: "confirmed",
            condition: () => mandate.cities && mandate.cities.length > 0,
        },
        {
            value: mandate.aiCitiesAtFeedbackTime || mandate.aiCities,
            source: "ai suggested",
            condition: () => mandate.aiCities && typeof mandate.isAiCitiesCorrect !== "boolean",
        },
        {
            value: mandate.googleSheetCities,
            source: "sheet",
            condition: () => true,
        },
    ];
    const cities = citiesArray.find((n) => !!n.condition());

    const effectiveDates = [
        {
            value: mandate.effectiveDate,
            source: "confirmed",
            condition: () => mandate.effectiveDate,
        },
        {
            value: mandate.aiEffectiveDateAtFeedbackTime || mandate.aiEffectiveDate,
            source: "ai suggested",
            condition: () => mandate.aiEffectiveDate && typeof mandate.isAiEffectiveDateCorrect !== "boolean",
        },
        {
            value: mandate.googleSheetDate,
            source: "sheet",
            condition: () => true,
        },
    ];
    const effectiveDate = effectiveDates.find((n) => !!n.condition());

    const untilDates = [
        {
            value: mandate.untilDate,
            source: "confirmed",
            condition: () => mandate.untilDate,
        },
        {
            value: mandate.aiUntilDateAtFeedbackTime || mandate.aiUntilDate,
            source: "ai suggested",
            condition: () => mandate.aiUntilDate && typeof mandate.isAiUntilDateCorrect !== "boolean",
        },
    ];
    const untilDate = untilDates.find((n) => !!n.condition());

    const extendedUntilDates = [
        {
            value: mandate.extendedUntilDate,
            source: "confirmed",
            condition: () => mandate.extendedUntilDate,
        },
        {
            value: mandate.aiExtendedUntilDateAtFeedbackTime || mandate.aiExtendedUntilDate,
            source: "ai suggested",
            condition: () => mandate.aiExtendedUntilDate && typeof mandate.isAiExtendedUntilDateCorrect !== "boolean",
        },
    ];
    const extendedUntilDate = extendedUntilDates.find((n) => !!n.condition());

    const urls = [
        {
            value: mandate.url,
            source: "confirmed",
            condition: () => !!mandate.url,
        },
        {
            value: mandate.humanUrl,
            source: "human",
            condition: () => !!mandate.humanUrl,
        },
        {
            value: mandate.googleSheetUrl,
            source: "sheet",
            condition: () => !!mandate.googleSheetUrl,
        },
    ];
    const url = urls.find((n) => !!n.condition());

    return { name, effectiveDate, untilDate, url, extendedUntilDate, authority, state, counties, cities };
};
