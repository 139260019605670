import { z } from "zod";
import { makeRequest, parseSchema } from "../../jason-proof-of-concept/shared/utils";

export type Data = {
    businessStartDateString: string;
    Q1_2019: number;
    Q2_2019: number;
    Q3_2019: number;
    Q4_2019: number;
    Q1_2020: number;
    Q2_2020: number;
    Q3_2020: number;
    Q4_2020: number;
    Q1_2021: number;
    Q2_2021: number;
    Q3_2021: number;
    Q4_2021: number;
};

export type DataForAggregate = {
    companyGroup: number;
};

const responseSchema = z.object({
    Q1_2020: z.object({
        qualifies: z.boolean(),
        reason: z.string(),
    }),
    Q2_2020: z.object({
        qualifies: z.boolean(),
        reason: z.string(),
    }),
    Q3_2020: z.object({
        qualifies: z.boolean(),
        reason: z.string(),
    }),
    Q4_2020: z.object({
        qualifies: z.boolean(),
        reason: z.string(),
        isAlternativeQuarterEligible: z.boolean().nullish(),
    }),
    Q1_2021: z.object({
        qualifies: z.boolean(),
        reason: z.string(),
        isAlternativeQuarterEligible: z.boolean().nullish(),
    }),
    Q2_2021: z.object({
        qualifies: z.boolean(),
        reason: z.string(),
        isAlternativeQuarterEligible: z.boolean().nullish(),
    }),
    Q3_2021: z.object({
        qualifies: z.boolean(),
        reason: z.string(),
        isAlternativeQuarterEligible: z.boolean().nullish(),
    }),
    Q4_2021: z.object({
        qualifies: z.boolean(),
        reason: z.string(),
        isAlternativeQuarterEligible: z.boolean().nullish(),
    }),
});
const aggregateResponseSchema = z.object({
    Q1_2019: z.object({
        value: z.number(),
    }),
    Q2_2019: z.object({
        value: z.number(),
    }),
    Q3_2019: z.object({
        value: z.number(),
    }),
    Q4_2019: z.object({
        value: z.number(),
    }),
    Q1_2020: z.object({
        qualifies: z.boolean(),
        reason: z.string(),
        value: z.number(),
    }),
    Q2_2020: z.object({
        qualifies: z.boolean(),
        reason: z.string(),
        value: z.number(),
    }),
    Q3_2020: z.object({
        qualifies: z.boolean(),
        reason: z.string(),
        value: z.number(),
    }),
    Q4_2020: z.object({
        qualifies: z.boolean(),
        reason: z.string(),
        isAlternativeQuarterEligible: z.boolean().nullish(),
        value: z.number(),
    }),
    Q1_2021: z.object({
        qualifies: z.boolean(),
        reason: z.string(),
        isAlternativeQuarterEligible: z.boolean().nullish(),
        value: z.number(),
    }),
    Q2_2021: z.object({
        qualifies: z.boolean(),
        reason: z.string(),
        isAlternativeQuarterEligible: z.boolean().nullish(),
        value: z.number(),
    }),
    Q3_2021: z.object({
        qualifies: z.boolean(),
        reason: z.string(),
        isAlternativeQuarterEligible: z.boolean().nullish(),
        value: z.number(),
    }),
    Q4_2021: z.object({
        qualifies: z.boolean(),
        reason: z.string(),
        isAlternativeQuarterEligible: z.boolean().nullish(),
        value: z.number(),
    }),
});

export const retrieveQualificationForRevenueReduction = async ({
    authToken,
    data,
}: {
    authToken: string;
    data: Data;
}) => {
    const response = await makeRequest({
        method: "post",
        path: `/qualification-for-revenue-reduction`,
        authToken,
        data: data,
    });

    const result = parseSchema(responseSchema, response.data?.result);

    return result;
};

export const retrieveQualificationForRevenueReductionForAggregate = async ({
    authToken,
    data,
}: {
    authToken: string;
    data: DataForAggregate;
}) => {
    const response = await makeRequest({
        method: "post",
        path: `/qualification-for-revenue-reduction-aggregate`,
        authToken,
        data: data,
    });

    const result = parseSchema(aggregateResponseSchema, response.data?.result);

    return result;
};
