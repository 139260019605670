import React from "react";
import PageDescription from "../../../layout/page-description";

import appointmentsService, { Appointment } from "../../../services/appointments.service";
import { useQuery } from "@tanstack/react-query";
import TableNeogen from "../../../layout/table-neogen";
import { start } from "repl";
import PrintPre from "../../../layout/print-pre";
import companyService from "../../../services/company.service";
import userCompanyService from "../../../services/user-company.service";
import userService from "../../../services/user.service";
import { ClearERCUser } from "../../../typings/api/clear-erc-user";
import ModalDialog from "../../../layout/modal-dialog";
import { t } from "i18next";
import { set } from "lodash";

export default function Appointments({ companyId }: { companyId?: number }) {
    const [users, setUsers] = React.useState<ClearERCUser[]>([]);
    const [showAddAppointment, setShowAddAppointment] = React.useState(false);
    const [selectedUsers, setSelectedUsers] = React.useState<ClearERCUser[]>([]);
    const convertEventNames = (eventTypeId: string) => {
        switch (eventTypeId) {
            case "BOOKING_CREATED":
                return "Booking Created";
            case "BOOKING_CANCELLED":
                return "Booking Cancelled";
            case "BOOKING_CONFIRMED":
                return "Booking Confirmed";
            case "BOOKING_RESCHEDULED":
                return "Booking Rescheduled";
            case "BOOKING_REMINDER":
                return "Booking Reminder";
            default:
                return eventTypeId;
        }
    };
    const convertAppointment = (appointment: Appointment) => {
        return {
            id: appointment.id,
            // uid: appointment.payload.uid,
            // bookingId: appointment.payload.bookingId,
            // title: appointment.payload.title,
            eventTitle: appointment.payload.eventTitle,
            // createdAt: appointment.createdAt,
            destinationCalendarEmail: appointment.destinationCalendarEmail,
            email: appointment.email,
            // payload: appointment.payload,
            triggerEvent: convertEventNames(appointment.triggerEvent),
            attendeesEmails: appointment.payload.attendees?.map((a) => a.email).join(", "),
            attendeesNames: appointment.payload.attendees?.map((a) => a.name).join(", "),
            // organizerName: appointment.payload.organizer?.name,
            // organizerEmail: appointment.payload.organizer?.email,
            startTime: appointment.payload.startTime,
            endTime: appointment.payload.endTime,
            // status: appointment.payload.status,
            video: appointment.payload.metadata?.videoCallUrl ?? "#",
        };
    };
    const appointmentsQuery = useQuery(["appointments", companyId], async () => {
        if (!companyId) {
            const response = await appointmentsService.getAll();
            if (response) {
                return response.data;
            }
        } else {
            // const company = await companyService.getOne(companyId);
            const userCompanies = await userCompanyService.getFilteredWhere({ companyId });
            // const emails = [];
            const userList = [];
            const appointments = [];
            console.log("userCompanies", userCompanies);
            if (userCompanies) {
                for (const userCompany of userCompanies.data) {
                    console.log({ userCompany });
                    const user = await userService.getOne(userCompany.ercUserId);
                    console.log({ user });
                    if (user) {
                        userList.push(user.data);
                        const response = await appointmentsService.getFilteredWhere({
                            or: [{ email: user.data.email }, { destinationCalendarEmail: user.data.email }],
                        });
                        if (response) {
                            appointments.push(...response.data);
                        }
                    }
                }
                setUsers(userList);
                return appointments;
            }
        }
        return [];
    });
    async function sendAppointment() {
        console.log("selectedUsers", selectedUsers);
        let url = "https://cal.com/team/erc-repair/analysis-review?email=" + selectedUsers[0].email;
        for (let i = 1; i < selectedUsers.length; i++) {
            url += "&guest=" + selectedUsers[i].email;
        }
        window.open(url, "_blank")?.focus();
        setShowAddAppointment(false);
    }
    if (appointmentsQuery.isLoading) {
        return <div>Loading...</div>;
    }
    return (
        <div className="px-5">
            <PageDescription
                title="Appointments"
                description="The Appointments section allows you to view and manage appointments."
                buttons={[
                    {
                        label: "Create Appointment",
                        icon: "fal fa-plus",
                        onClick: () => {
                            setShowAddAppointment(true);
                            // window.location.href = "https://cal.com/team/erc-repair/analysis-review?email=";
                        },
                    },
                ]}
            />

            <TableNeogen
                formatters={[
                    { field: "createdAt", type: "Date" },
                    { field: "startTime", type: "Date" },
                    { field: "endTime", type: "Date" },
                    {
                        field: "video",
                        type: "Link",
                    },
                ]}
                entries={(appointmentsQuery.data ?? []).map((a) => convertAppointment(a))}
            />
            <ModalDialog
                size="md"
                show={showAddAppointment}
                close={() => setShowAddAppointment(false)}
                title="Add Appointment"
                okAction={sendAppointment}
            >
                <div>Please Select the users you would like to add an appointment for.</div>
                <form>
                    {users.map((user) => (
                        <div key={user.id} className="mb-5">
                            <input
                                type="checkbox"
                                id={user.id?.toString()}
                                value={user.id?.toString()}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        setSelectedUsers([...selectedUsers, user]);
                                    } else {
                                        setSelectedUsers(
                                            selectedUsers.filter((selectedUser) => selectedUser.id !== user.id),
                                        );
                                    }
                                }}
                            />
                            <label htmlFor={user.id?.toString()} className="ml-2">
                                {user.firstName + " " + user.lastName + " (" + user.email + ")"}
                            </label>
                        </div>
                    ))}
                </form>
            </ModalDialog>
        </div>
    );
}
