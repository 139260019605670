import { User } from "../../jason-proof-of-concept/users/domain/user";
import { WizardContainer } from "../../jason-proof-of-concept/wizard-v2/wizard-container";

export const Application = ({ user, onChange, companyId }: { user: User; onChange: () => any; companyId: number }) => {
    return (
        <div>
            {user && (
                <WizardContainer
                    processflowGroupId={window.location.hostname === "login.clearerc.com" ? 7 : 28}
                    companyId={companyId}
                    user={user}
                    onChange={() => {
                        onChange();
                    }}
                />
            )}
        </div>
    );
};
