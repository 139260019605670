import React, { useEffect } from "react";
import UtmHitsByDate from "../dashboard/utm-hits-by-date";
import users from "../admin/users/users";
import MyCustomers from "../dashboard/my-customers";
import UtmStats from "../utm/stats";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import usersService from "../../services/users.service";
import utmLogService from "../../services/utm-log.service";
import PrintPre from "../../layout/print-pre";
import companyService from "../../services/company.service";
import callsService from "../../services/calls.service";
import utmLinkService from "../../services/utm-link.service";
import { formatPhonenumber } from "../utilities/formatters";
import TableNeogen from "../../layout/table-neogen";
import SelectNeoGenSearchable from "../../layout/select-neogen-searchable";
import { SelectField } from "../../layout/form/select-field";
import SearchBox from "../../layout/search-box";
import SearchField from "../../layout/search-field";
import SelectNeoGen from "../../layout/select-neogen";
import { Search, Option, Detail } from "searchpal";
import { Button } from "react-bootstrap";
import ButtonNeoGen from "../../layout/button-neogen";
import { set } from "lodash";
import Toggle from "../../layout/toggle";
import { id } from "date-fns/locale";
import Calls from "./modals/calls";

function Advertising({ darkMode }: { darkMode: boolean }) {
    const [utmCode, setUtmCode] = React.useState("");
    const [open, setOpen] = React.useState(false);
    const [listView, setListView] = React.useState(1);
    const [calls, setCalls] = React.useState<any[]>([]);
    const [showCalls, setShowCalls] = React.useState(false);
    const queryCache = useQueryClient();
    const usersQuery = useQuery(["users"], async () => {
        const response = await usersService.getAll();
        if (response) {
            return response.data;
        }
    });
    const companiesQuery = useQuery(["companies"], async () => {
        const response = await companyService.getAll();
        if (response) {
            return response.data;
        }
    });
    const utmLinksQuery = useQuery(["utmLinks"], async () => {
        const response = await utmLinkService.getAll();
        if (response) {
            return response.data;
        }
    });
    const cdrQuery = useQuery(["cdr"], async () => {
        const response = await callsService.getAll();
        if (response) {
            return response.data.sort((a: any, b: any) => {
                return new Date(b.calldate).getTime() - new Date(a.calldate).getTime();
            });
        }
    });
    const utmLogsQuery = useQuery(["utmLogs"], async () => {
        const response = await utmLogService.getAll();
        if (response) {
            return response.data
                .sort((a, b) => {
                    return new Date(b.eventDatetime).getTime() - new Date(a.eventDatetime).getTime();
                })
                .map((log: any) => {
                    try {
                        // console.log(JSON.parse(log.data));
                        if (typeof log.data === "string" && log.data !== "") {
                            const data = JSON.parse(log.data ?? "{}");
                            log.data = data;
                        } else {
                            // log.data = {};
                        }
                    } catch (e) {
                        console.log(e);
                        console.log(log);
                        log.data = {};
                    }
                    return log;
                    // return
                    //     // ...log,
                    // data: JSON.parse(log.data),
                    // };
                })
                .filter((log: any) => {
                    return log.utm !== "Unknown" && log.utm !== "null" && log.utm !== "";
                });
        }
    });
    useEffect(() => {
        // Go through each utm log entry
        // If the companyId is not null
        // Find the company with that ID
        // Add the company name to the log entry
        utmLogsQuery.data?.forEach((log: any) => {
            if (log.data?.companyId) {
                const company = companiesQuery.data?.find((c: any) => c.id === log.data.companyId);
                log.data.companyName = company?.name;
            }
            // Now find any calls to that phone number from the CDR
            // Add the call data to the log entry
            const calls = cdrQuery.data?.filter((c: any) => c.to === "+1" + log.data?.phone);
            log.calls = calls;
            // Attach the actual utm link to the log entry
            log.utmLink = utmLinksQuery.data?.find((l: any) => l.id === log.utm);
            if (!log.utmLink) {
                log.utmLink = utmLinksQuery.data?.find((l: any) => l.code === log.utm);
            }
            if (!log.utmLink) {
                // alert("Can't find " + log.utm);
            }
        });
    }, [utmLogsQuery.data, companiesQuery.data, cdrQuery.data, utmLinksQuery.data]);

    // function renderCallStatus(status: string) {
    //     switch (status) {
    //         case "noanswer":
    //             return (
    //                 <span className="inline-flex items-center rounded-full bg-gray-50 px-2 py-1 text-xxs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
    //                     No Answer
    //                 </span>
    //             );
    //             break;
    //         case "no-answer":
    //             return (
    //                 <span className="inline-flex items-center rounded-full bg-red-50 px-2 py-1 text-xxs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">
    //                     No Answer
    //                 </span>
    //             );
    //         case "completed":
    //             return (
    //                 <span className="inline-flex items-center rounded-full bg-green-50 px-2 py-1 text-xxs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
    //                     Answered
    //                 </span>
    //             );
    //         default:
    //             return (
    //                 <span className="inline-flex items-center rounded-full bg-gray-50 px-2 py-1 text-xxs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
    //                     {status}
    //                 </span>
    //             );
    //     }
    // }

    if (usersQuery.isLoading || utmLogsQuery.isLoading || companiesQuery.isLoading || utmLinksQuery.isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <>
            {/* <PrintPre>{utmLinksQuery.data}</PrintPre> */}

            <Search label="Search for a UTM Code..." open={open} onClose={() => setOpen(false)}>
                {utmLinksQuery.data?.map((l: any) => (
                    <Option
                        key={l.id}
                        label={
                            (l.name !== null ? l.name + " " : "") +
                            (l.campaign !== null ? l.campaign : "") +
                            " (code: " +
                            l.id +
                            ")"
                        }
                        onClick={() => {
                            setUtmCode(l.id);
                            // queryCache.invalidateQueries(["utmLogs"]);
                            // utmLogsQuery.refetch();
                            setOpen(false);
                        }}
                    >
                        <Detail label="Campaign" value={l.campaign} />
                    </Option>
                ))}
            </Search>
            {/* <SelectNeoGen
                label="Filter by UTM Code"
                options={
                    utmLinksQuery.data?.map((l: any) => {
                        return {
                            id: l.id,
                            label:
                                (l.name !== null ? l.name + " " : "") +
                                (l.campaign !== null ? l.campaign : "") +
                                " (code: " +
                                l.id +
                                ")",
                        };
                    }) ?? []
                }
                onChange={(e: any) => {
                    setUtmCode(e.toString());
                }}
            /> */}
            <div className="col-span-2 grid grid-cols-1 xl:grid-cols-2 gap-5 text-xs">
                <div className="col-span-1">
                    <UtmStats
                        darkMode={darkMode}
                        users={usersQuery.data || []}
                        usersQuerySuccess={usersQuery.isSuccess}
                        usersQueryLoading={usersQuery.isLoading}
                    />
                </div>
                <div className="col-span-1">
                    <UtmHitsByDate darkMode={darkMode} usersQuerySuccess={usersQuery.isSuccess} />
                </div>
                {/* {utmLogsQuery.data?.map((log: any) => {
                return <PrintPre key={log.id}>{log}</PrintPre>;
            })} */}
                {/* <PrintPre>{utmLogsQuery.data}</PrintPre> */}
            </div>
            <div className="mt-5 flex">
                <ButtonNeoGen
                    onClick={() => {
                        setOpen(true);
                    }}
                >
                    Filter By UTM Code
                </ButtonNeoGen>
                {utmCode !== "" && (
                    <ButtonNeoGen
                        onClick={() => {
                            setUtmCode("");
                            // queryCache.invalidateQueries(["utmLogs"]);
                            // utmLogsQuery.refetch();
                        }}
                    >
                        Clear Filter
                    </ButtonNeoGen>
                )}
                <div className="flex-grow flex flex-row-reverse">
                    <Toggle
                        options={["List View", "Card View"]}
                        setSelectedIndex={setListView}
                        selectedIndex={listView}
                        className="min-w-[200px]"
                    />
                </div>
            </div>

            {listView === 1 ? (
                <div className="col-span-2 grid grid-cols-1 xl:grid-cols-3 gap-5 text-xs mt-5">
                    {/* <PrintPre>{utmLogsQuery.data}</PrintPre> */}
                    {(utmCode !== "" ? utmLogsQuery.data?.filter((l: any) => l.utm === utmCode) : utmLogsQuery?.data)
                        ?.filter(
                            (e) =>
                                e.utmLink !== undefined &&
                                e.utmLink !== null &&
                                e.utmLink !== "" &&
                                e.utmLink.name !== "" &&
                                e.utmLink.name !== null &&
                                e.utmLink.name !== undefined &&
                                e.data !== undefined &&
                                e.data !== null &&
                                e.data !== "" &&
                                e.data?.companyId !== undefined &&
                                e.data?.companyId !== null &&
                                e.data?.companyId !== "",
                        )
                        ?.map((log: any) => {
                            return (
                                <div
                                    key={log.id}
                                    className=" bg-white border border-gray-200 shadow rounded-xl p-3 dark:border-gray-600 dark:bg-gray-900"
                                >
                                    <div className="text-sm">UTM Campaign: {log.utmLink?.campaign}</div>
                                    <div className="text-sm">UTM Name: {log.utmLink?.name}</div>
                                    <div className="text-sm">UTM Code: {log.utmLink?.id}</div>
                                    <div className="text-sm">Company Name: {log.data?.companyName}</div>
                                    <div className="text-sm">
                                        Link Clicked: {new Date(log.eventDatetime).toLocaleString()}{" "}
                                    </div>
                                    {log.data?.phone && (
                                        <div className="text-xs">
                                            Customer Phone: {formatPhonenumber(log.data?.phone)}
                                        </div>
                                    )}
                                    {log.data?.email && (
                                        <div className="text-xs">Customer Email: {log.data?.email}</div>
                                    )}
                                    <TableNeogen
                                        formatters={[
                                            { field: "status", type: "CallStatus" },
                                            { field: "calldate", type: "DateTime" },
                                        ]}
                                        compact={true}
                                        ignoreFields={["sid", "from", "to"]}
                                        entries={log.calls}
                                    />
                                    {/* {log.calls?.map((call: any) => {
                                return (
                                    <div key={call.uniqueid} className="text-xxs">
                                        Call: {new Date(call.calldate).toLocaleString()} {call.duration} secs{" "}
                                        {renderCallStatus(call.status)}
                                    </div>
                                );
                            })} */}
                                    {/* <div className="text-sm">{log.utm}</div> */}

                                    {/* <PrintPre>{log.utmLink}</PrintPre> */}
                                    {/* <div className="text-sm">{log.data?.companyId}</div> */}
                                </div>
                            );
                        })}
                    {/* <PrintPre>
                {companiesQuery.data?.map((c) => {
                    return {
                        // ...c,
                        id: c.id,
                        name: c.name,
                        phone: c.phone,
                        email: c.email,
                    };
                })}
            </PrintPre>
            <PrintPre>
                {utmLogsQuery.data?.map((u) => {
                    return {
                        date: u.eventDatetime,
                        utm: u.utm,
                        email: u.data?.email,
                        phone: u.data?.phone,
                        companyId: u.data?.companyId,
                    };
                })}
            </PrintPre>
            */}
                    {/* <PrintPre>{cdrQuery.data}</PrintPre> */}
                </div>
            ) : (
                <div className="mt-3">
                    {/* <PrintPre>
                        {utmCode !== ""
                            ? utmLogsQuery.data?.filter((l: any) => l.utmLink?.id === utmCode)
                            : utmLogsQuery?.data}
                    </PrintPre> */}
                    <TableNeogen
                        // ignoreFields={["calls", "notes", "ipAddress", "userId", "site"]}
                        formatters={[{ field: "company", type: "CompanyLink" }]}
                        entries={(
                            (utmCode !== ""
                                ? utmLogsQuery.data?.filter((l: any) => l.utmLink.id === utmCode)
                                : utmLogsQuery?.data) ?? []
                        )
                            ?.filter(
                                (e) =>
                                    e.utmLink !== undefined &&
                                    e.utmLink !== null &&
                                    e.utmLink !== "" &&
                                    e.utmLink.name !== "" &&
                                    e.utmLink.name !== null &&
                                    e.utmLink.name !== undefined &&
                                    e.data !== undefined &&
                                    e.data !== null &&
                                    e.data !== "" &&
                                    e.data?.companyId !== undefined &&
                                    e.data?.companyId !== null &&
                                    e.data?.companyId !== "",
                            )
                            .slice(0, 1000)
                            .map((l: any) => {
                                return {
                                    // ...l,
                                    // data: l.data,
                                    id: l.id,
                                    utm: l.utmLink.name
                                        ? l.utmLink.name + " " + l.utmLink.campaign
                                        : "No UTM for " + l.utmLink.id,
                                    name: l.data?.name ?? "No Name",
                                    date: new Date(l.eventDatetime).toLocaleString(),
                                    phone: formatPhonenumber(l.data?.phone) ?? "No Phone",
                                    email: l.data?.email,
                                    company: l.data?.companyId ?? -1,
                                };
                            })}
                        actions={[
                            {
                                labelActive: (id: any) =>
                                    utmLogsQuery.data?.find((l) => l.id === id).calls.length + " Calls",
                                onClick: (entry: any) => {
                                    console.log(entry);
                                    setCalls(utmLogsQuery.data?.find((l) => l.id === entry).calls);
                                    setShowCalls(true);
                                },
                            },
                        ]}
                    />
                </div>
            )}
            {showCalls && <Calls show={showCalls} close={() => setShowCalls(false)} calls={calls} />}
        </>
    );
}

export default Advertising;
