import ModalDialog from "../../../layout/modal-dialog";
import { Table, Td, Tr } from "../../../layout/table";
import { ClearERCUser } from "../../../typings/api/clear-erc-user";

export const BankingDetailsModal = ({
    onClose,
    bankName,
    accountNumber,
    routingNumber,
    clientAddress,
    currentUser,
    name,
}: {
    onClose: () => void;
    bankName: string | null | undefined;
    accountNumber: number | null | undefined;
    routingNumber: number | null | undefined;
    clientAddress: string | null | undefined;
    currentUser: ClearERCUser;
    name: string;
}) => {
    return (
        <ModalDialog
            show
            title={`Bank Details for ${name}`}
            close={onClose}
            showOk={false}
            showCancel={false}
            size="sm"
        >
            <Table>
                <Tr>
                    <Td>Bank Name</Td>
                    <Td>{bankName ?? "-"}</Td>
                </Tr>
                <Tr>
                    <Td>Account Number</Td>
                    <Td>{accountNumber ?? "-"}</Td>
                </Tr>
                <Tr>
                    <Td>Routing Number</Td>
                    <Td>{routingNumber ?? "-"}</Td>
                </Tr>
                <Tr>
                    <Td>Client Address</Td>
                    <Td>{clientAddress ?? "-"}</Td>
                </Tr>
            </Table>
            <p className="text-center mt-4">
                If you would like to request or confirm these banking details, please contact{" "}
                <a
                    className="underline text-blue-600 font-semibold"
                    href={`mailto:jason@ercrepair.com?subject=Banking%20Details%20Update%20Request%20for%20${name}%20on%20ERC%20Repair&body=Hi%20Jason%2C%0D%0A%0D%0APlease%20could%20you%20update%20the%20banking%20details%20of%20${name}%20on%20ERC%20Repair%3F%20%0D%0A%0D%0AThank%20you%2C%0D%0A${currentUser.firstName}%20${currentUser.lastName}`}
                >
                    jason@ercrepair.com
                </a>
            </p>
        </ModalDialog>
    );
};
