import APIService from "./api.service";

export type MandateEffect = {
    id: number;
    name: string;
};

class MandateEffectService extends APIService<MandateEffect> {
    constructor() {
        super("/mandate-effects");
    }
}

export default new MandateEffectService();
