import { useQuery } from "@tanstack/react-query";
import { useMemo, useState, useEffect } from "react";
import { useLocation } from "react-router";
import { useRecoilState } from "recoil";
import userAtom from "../atoms/userAtom";
import { getUsers } from "../jason-proof-of-concept/users/actions/get-users";
import PageDescription from "../layout/page-description";
import { sortUsers } from "../sections/utilities/sortUsers";
import { getAuthTokenNoThrow } from "../services/auth-header";
import { ClearERCUser } from "../typings/api/clear-erc-user";
import { Select } from "../layout/form/select-input";
import { Currency } from "../billing/components";
import { Section, Stat } from "./affiliate-deals-page";
import repairStagesService, { RepairStagesType } from "../services/repair-stages.service";
import companyRepairStagesService from "../services/company-repair-stages.service";
import { useUserCompanies } from "../companies/hooks/use-user-companies";
import { useCompanies } from "../companies/hooks/use-companies";
import repairStageCategoriesService from "../services/repair-stage-categories.service";
import { UserCompany } from "../user-companies/domain/user-company";
import { Company } from "../companies/domain/company";
import { usePayments } from "../payments/hooks/use-payments";
import { RepairDealsColumn } from "./components/repair-deals-column";
import { Checkbox } from "../layout/form/checkbox";
import invoicesService from "../services/invoices.service";
import invoiceLineItemsService from "../services/invoice-line-items.service";
import lineItemsService from "../services/line-items.service";
import { ArrowTrendingUpIcon, BanknotesIcon, CalculatorIcon, ClockIcon } from "@heroicons/react/24/outline";
import { useReferrers } from "../sections/referrers/hooks/use-referrers";
import { Referrer } from "../sections/referrers/domain/referrer";

export type RepairDeal = {
    affiliate: any;
    company: Company | undefined;
    userCompany: UserCompany | undefined;
    repairStage: RepairStagesType | undefined;
    totalCommissionPaid: number;
    totalCommissionDue: number;
    totalCommissionableLineItems: number;
    referrer?: Referrer;
};

export const affiliateCommissionPercentage = 0.15;
export const commissionOnSubAffiliatePercentage = 0.05;

export const AffiliateRepairDeals = ({ included = false }: { included: boolean }) => {
    const authToken = getAuthTokenNoThrow() || "no-auth-token";
    const [user] = useRecoilState(userAtom);
    const { search } = useLocation();
    const queryParamUserId = useMemo(() => new URLSearchParams(search), [search]);
    const userId = queryParamUserId.get("userId");
    const [affiliateUserId, setAffiliateUserId] = useState(userId);
    const [subAffiliateUserId, setSubAffiliateUserId] = useState<string | null>(null);
    const [dealsWithCommissionableLineItems, setDealsWithCommissionableLineItems] = useState(0);
    const [totalCommission, setTotalCommission] = useState(0);
    const [outstandingCommission, setOutstandingCommission] = useState(0);
    const [paidCommission, setPaidCommission] = useState(0);
    // const [showOnlyAffiliateDeals, setShowOnlyAffiliateDeals] = useState(false);

    useEffect(() => {
        if (user && user.id) {
            setAffiliateUserId(user.id);
        }
    }, [user]);

    const affiliateUsersQuery = useQuery(["affiliates", { authToken }], async () => {
        const users = await getUsers({
            authToken,
            filters: {
                where: {
                    role: "Affiliate",
                },
            },
        });
        return users;
    });

    const affiliateUsers = useMemo(() => affiliateUsersQuery.data, [affiliateUsersQuery.data]);

    const affiliateOptions = useMemo(
        () =>
            sortUsers((affiliateUsers || []) as ClearERCUser[]).map((affiliate) => ({
                value: affiliate.id || "",
                label: `${[affiliate.firstName, affiliate.lastName].join(" ")} (${affiliate.email})`,
            })),
        [affiliateUsers],
    );

    // const subAffiliateOptions = useMemo(
    //     () =>
    //         sortUsers(
    //             (affiliateUsers || []).filter((a) => a.affiliateUserId === affiliateUserId) as ClearERCUser[],
    //         ).map((affiliate) => ({
    //             value: affiliate.id || "",
    //             label: `${[affiliate.firstName, affiliate.lastName].join(" ")} (${affiliate.email})`,
    //         })),
    //     [affiliateUserId, affiliateUsers],
    // );

    const repairStagesQuery = useQuery(["repairStages"], async () => {
        const response = await repairStagesService.getAll();
        if (response) {
            return response.data;
        }
    });

    const repairStages = useMemo(() => repairStagesQuery.data, [repairStagesQuery.data]);

    const repairStageCategoriesQuery = useQuery(["repairStageCategories"], async () => {
        const response = await repairStageCategoriesService.getAll();
        if (response) {
            return response.data;
        }
    });

    const repairStageCategories = repairStageCategoriesQuery.data || [];

    const affiliateAndSubAffiliateIds = useMemo(() => [affiliateUserId], [affiliateUserId]);

    // Fetch data for selected affiliate user + sub-affiliates
    const userCompanies = useUserCompanies({
        authToken,
        filters: {
            where: {
                roleGroupId: 7,
                ercUserId: { inq: affiliateAndSubAffiliateIds },
            },
        },
    });

    const companiesQuery = useCompanies({
        authToken,
        filters: { where: { id: { inq: (userCompanies.data || []).map((uc) => uc.companyId) } } },
    });
    const companies = useMemo(() => companiesQuery.data || [], [companiesQuery.data]);

    const lineItemsQuery = useQuery(["line-items"], async () => {
        const response = await lineItemsService.getAll();
        if (response) {
            return response.data;
        }
    });

    const lineItems = useMemo(() => lineItemsQuery.data || [], [lineItemsQuery.data]);

    const invoicesQuery = useQuery(["invoices", companies], async () => {
        const response = await invoicesService.getFilteredWhere({ companyId: { inq: companies.map((c) => c.id) } });
        if (response) {
            return response.data;
        }
    });

    const companyInvoices = useMemo(() => invoicesQuery.data || [], [invoicesQuery.data]);

    const invoiceLineItemsQuery = useQuery(["invoice-line-items", companies, companyInvoices], async () => {
        const response = await invoiceLineItemsService.getFilteredWhere({
            invoiceId: { inq: companyInvoices.map((c) => c.id) },
        });
        if (response) {
            return response.data;
        }
    });

    const invoiceLineItems = useMemo(() => invoiceLineItemsQuery.data || [], [invoiceLineItemsQuery.data]);

    const companyRepairStageQuery = useQuery(["companyRepairStages"], async () => {
        const response = await companyRepairStagesService.getAll();
        if (response) {
            return response.data;
        }
    });

    const paymentsQuery = usePayments({
        authToken,
        filters: { where: { toUserId: { inq: affiliateAndSubAffiliateIds } } },
    });
    const payments = useMemo(() => paymentsQuery.data || [], [paymentsQuery.data]);

    const referrerRelationsQuery = useReferrers({ authToken });
    const referrerRelations = useMemo(() => referrerRelationsQuery.data || [], [referrerRelationsQuery.data]);

    const affiliateCompaniesRepairDeals = companyRepairStageQuery.data
        ?.filter((item) => companies.find((c) => c.id === item.id))
        .map((c) => {
            const co = companies.find((co) => co.id === c.id);
            const userCompany = (userCompanies?.data || []).find(
                (uc) => uc.companyId === c.id && affiliateAndSubAffiliateIds.includes(uc.ercUserId),
            );
            const affiliate = affiliateUsers?.find((u) => u.id === userCompany?.ercUserId);
            const referrerOfAffiliate = referrerRelations.find((r) => r.affiliateId === affiliate?.id);
            // const affiliateType = affiliate?.id === affiliateUserId ? "affiliate" : "sub-affiliate";
            const totalPaid = payments
                .filter((p) => p.companyId === co?.id)
                .reduce((acc, payment) => acc + payment.amount, 0);
            const invoices = companyInvoices.filter((i) => i.companyId === c.id);
            const totalCommissionableLineItems = invoices.reduce((acc, invoice) => {
                const invLineItems = invoiceLineItems.filter((li) => li.invoiceId === invoice.id);
                invLineItems.map((item) => {
                    const li = lineItems.find((i) => i.id === item.lineItemId);
                    if (li?.isCommisionable === 1) {
                        acc += item.price;
                    }
                });
                return acc;
            }, 0);
            const totalDue =
                totalCommissionableLineItems * affiliateCommissionPercentage -
                totalPaid -
                ((totalCommissionableLineItems || 0) *
                    affiliateCommissionPercentage *
                    (referrerOfAffiliate?.commissionPercentage || 0)) /
                    100;

            return {
                company: co,
                affiliate,
                referrer: referrerOfAffiliate,
                // affiliateType,
                userCompany,
                repairStage: repairStages?.find((rs) => rs.id === c.stage),
                totalCommissionPaid: totalPaid,
                totalCommissionDue: totalDue,
                totalCommissionOutstanding: totalDue - totalPaid,
                totalCommissionableLineItems,
            };
        })
        .filter((result) => (subAffiliateUserId ? result.affiliate?.id === subAffiliateUserId : result));
    // .filter((result) => (showOnlyAffiliateDeals ? result.affiliateType === "affiliate" : result));

    const totalCommissionableLineItems = affiliateCompaniesRepairDeals
        ? affiliateCompaniesRepairDeals.reduce((acc, deal) => acc + (deal.totalCommissionableLineItems || 0), 0)
        : 0;

    const totalDueCommission = affiliateCompaniesRepairDeals
        ? affiliateCompaniesRepairDeals.reduce((acc, deal) => acc + deal.totalCommissionDue, 0)
        : 0;

    const totalOutstandingCommission = affiliateCompaniesRepairDeals
        ? affiliateCompaniesRepairDeals.reduce(
              (acc, deal) =>
                  acc +
                  (deal.totalCommissionableLineItems || 0) * affiliateCommissionPercentage -
                  ((deal.totalCommissionableLineItems || 0) *
                      affiliateCommissionPercentage *
                      (deal.referrer?.commissionPercentage || 0)) /
                      100,
              0,
          )
        : 0;

    const totalPaidCommission = affiliateCompaniesRepairDeals
        ? affiliateCompaniesRepairDeals.reduce((acc, deal) => acc + deal.totalCommissionPaid, 0)
        : 0;

    useMemo(() => setPaidCommission(totalPaidCommission), [totalPaidCommission]);
    useMemo(() => setOutstandingCommission(totalOutstandingCommission), [totalOutstandingCommission]);
    useMemo(() => setDealsWithCommissionableLineItems(totalCommissionableLineItems), [totalCommissionableLineItems]);
    useMemo(() => setTotalCommission(totalDueCommission), [totalDueCommission]);

    return (
        <div>
            <div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
                {!included ? (
                    <>
                        <PageDescription title="Repair Deals">
                            <div style={{ minWidth: 330 }}>
                                <Select
                                    label="Viewing deals for affiliate:"
                                    options={affiliateOptions}
                                    value={
                                        affiliateOptions.find((option) => option.value === affiliateUserId)?.value ||
                                        null
                                    }
                                    onChange={(value) => {
                                        // @ts-ignore
                                        if ([null, undefined].includes(value)) {
                                            setAffiliateUserId(null);
                                            setSubAffiliateUserId(null);
                                        }
                                        setAffiliateUserId(value as string);
                                    }}
                                />
                                {/* {subAffiliateOptions.length > 0 && (
                                    <Select
                                        label="Sub-affiliates:"
                                        options={subAffiliateOptions}
                                        value={subAffiliateUserId}
                                        onChange={(value) => setSubAffiliateUserId(value as string)}
                                    />
                                )} */}
                                {/* <div className="mt-3 ml-auto w-fit flex gap-2 items-center">
                                    <Checkbox
                                        isChecked={showOnlyAffiliateDeals}
                                        onChange={() => setShowOnlyAffiliateDeals(!showOnlyAffiliateDeals)}
                                    />
                                    <p>Show primary affiliate deals only</p>
                                </div> */}
                            </div>
                        </PageDescription>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                gap: 20,
                                flex: 1,
                                justifyContent: "space-between",
                            }}
                        >
                            <Section
                                style={{
                                    display: "flex",
                                    justifyContent: "space-evenly",
                                    alignItems: "center",
                                }}
                                className="w-full"
                            >
                                <Stat
                                    title="Total Commissionable Products"
                                    value={<Currency amount={dealsWithCommissionableLineItems} />}
                                />
                                <Stat title="Total Commission Due" value={<Currency amount={totalCommission} />} />
                                <Stat
                                    title="Outstanding Commission"
                                    value={<Currency amount={outstandingCommission} />}
                                />
                                <Stat title="Paid Commission" value={<Currency amount={paidCommission} />} />
                            </Section>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="mt-5 grid grid-cols-4 gap-5">
                            <div
                                className="
                        relative overflow-hidden bg-gradient-to-br
                        to-white from-slate-100 dark:from-slate-900 dark:to-gray-800
                        border-2 border-white rounded-xl p-4 dark:bg-gray-800
                        dark:border-gray-700 dark:shadow-inner px-5 shadow
                        hover:shadow-none
                        hover:to-slate-200 dark:hover:to-slate-700
                        hover:from-slate-100 dark:hover:from-slate-700
                        transition duration-200 ease-in-out
                        "
                            >
                                <dt>
                                    <div className={"absolute rounded-md bg-[#61baf3] dark:bg-teal-700 p-3"}>
                                        <CalculatorIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                        {/* <i className="fa fa-calculator text-white w-6 h-6" aria-hidden="true" /> */}
                                    </div>
                                    <p className="ml-16 truncate text-sm font-medium text-gray-400">
                                        Commissionable Products
                                    </p>
                                </dt>
                                <dd className="ml-16 flex items-baseline">
                                    <p className="text-2xl font-semibold text-gray-500 dark:text-green-500">
                                        <Currency amount={dealsWithCommissionableLineItems} />
                                    </p>
                                </dd>
                            </div>
                            <div
                                className="
                        relative overflow-hidden bg-gradient-to-br
                        to-white from-slate-100 dark:from-slate-900 dark:to-gray-800
                        border-2 border-white rounded-xl p-4 dark:bg-gray-800
                        dark:border-gray-700 dark:shadow-inner px-5 shadow
                        hover:shadow-none
                        hover:to-slate-200 dark:hover:to-slate-700
                        hover:from-slate-100 dark:hover:from-slate-700
                        transition duration-200 ease-in-out
                        "
                            >
                                <dt>
                                    <div className={"absolute rounded-md bg-[#9ee058] dark:bg-green-900 p-3"}>
                                        <ArrowTrendingUpIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                        {/* <i className="fa fa-calculator text-white w-6 h-6" aria-hidden="true" /> */}
                                    </div>
                                    <p className="ml-16 truncate text-sm font-medium text-gray-400">
                                        Total Commission Due
                                    </p>
                                </dt>
                                <dd className="ml-16 flex items-baseline">
                                    <p className="text-2xl font-semibold text-gray-500 dark:text-green-500">
                                        <Currency amount={totalCommission} />
                                    </p>
                                </dd>
                            </div>
                            <div
                                className="
                        relative overflow-hidden bg-gradient-to-br
                        to-white from-slate-100 dark:from-slate-900 dark:to-gray-800
                        border-2 border-white rounded-xl p-4 dark:bg-gray-800
                        dark:border-gray-700 dark:shadow-inner px-5 shadow
                        hover:shadow-none
                        hover:to-slate-200 dark:hover:to-slate-700
                        hover:from-slate-100 dark:hover:from-slate-700
                        transition duration-200 ease-in-out
                        "
                            >
                                <dt>
                                    <div className={"absolute rounded-md bg-[#ed9609] dark:bg-orange-700 p-3"}>
                                        <ClockIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                        {/* <i className="fa fa-calculator text-white w-6 h-6" aria-hidden="true" /> */}
                                    </div>
                                    <p className="ml-16 truncate text-sm font-medium text-gray-400">
                                        Outstanding Commission
                                    </p>
                                </dt>
                                <dd className="ml-16 flex items-baseline">
                                    <p className="text-2xl font-semibold text-gray-500 dark:text-green-500">
                                        <Currency amount={paidCommission} />
                                    </p>
                                </dd>
                            </div>
                            <div
                                className="
                        relative overflow-hidden bg-gradient-to-br
                        to-white from-slate-100 dark:from-slate-900 dark:to-gray-800
                        border-2 border-white rounded-xl p-4 dark:bg-gray-800
                        dark:border-gray-700 dark:shadow-inner px-5 shadow
                        hover:shadow-none
                        hover:to-slate-200 dark:hover:to-slate-700
                        hover:from-slate-100 dark:hover:from-slate-700
                        transition duration-200 ease-in-out
                        "
                            >
                                <dt>
                                    <div className={"absolute rounded-md bg-[#ed411f] dark:bg-red-700 p-3"}>
                                        <BanknotesIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                        {/* <i className="fa fa-calculator text-white w-6 h-6" aria-hidden="true" /> */}
                                    </div>
                                    <p className="ml-16 truncate text-sm font-medium text-gray-400">Paid Commission</p>
                                </dt>
                                <dd className="ml-16 flex items-baseline">
                                    <p className="text-2xl font-semibold text-gray-500 dark:text-green-500">
                                        <Currency amount={dealsWithCommissionableLineItems} />
                                    </p>
                                </dd>
                            </div>
                        </div>
                    </>
                )}

                <div className={"flex gap-4 overflow-x-auto " + (included ? "max-h-[450px]" : "")}>
                    {[{ id: "unknown", name: "Missing Repair Stage" }, ...repairStageCategories]?.map((s, i) => {
                        return (
                            <RepairDealsColumn
                                key={s.id}
                                title={s.name}
                                repairDeals={(affiliateCompaniesRepairDeals || []).filter(
                                    (c) => c.repairStage?.parent === s.id || (s.id === "unknown" && !c.repairStage),
                                )}
                            />
                        );
                    })}
                </div>
            </div>
        </div>
    );
};
