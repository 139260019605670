import React from "react";
import { Label } from "../label";
import InputControlled from "../input-controlled";
import { set } from "lodash";
import zipCodesService from "../../services/zip-codes.service";

export default function ZipCodeInput({
    setValue,
    value,
    label,
    required,
    name,
    disabled,
    errors,
}: {
    setValue: any;
    value: any;
    label: any;
    required: any;
    name: any;
    disabled: any;
    errors: any;
}) {
    async function internalSetValue(e: string) {
        const response = await zipCodesService.getFilteredWhere({ zipCode: e });
        if (response && response.data?.length > 0) {
            setCity(response.data[0].city);
            setState(response.data[0].state);
            setCounty(response.data[0].county);
        }
        console.log(response);
        const newE = e.replaceAll(/[^0-9]/g, "");
        console.log(newE);
        setValue(newE);
    }
    const [city, setCity] = React.useState("");
    const [state, setState] = React.useState("");
    const [county, setCounty] = React.useState("");
    return (
        <div className="grid grid-cols-4 gap-5">
            <div>
                <Label text={label} isRequired={required} />
                <InputControlled
                    type="text"
                    value={value}
                    onChange={(e) => internalSetValue(e)}
                    name={name}
                    disabled={disabled}
                />
            </div>
            <div>
                <Label text={"City"} isRequired={required} />
                <InputControlled
                    type="text"
                    value={city}
                    onChange={(e) => setCity(e)}
                    name={name}
                    disabled={true}
                    className="bg-gray-100 dark:bg-gray-700 text-gray-500"
                />
            </div>
            <div>
                <Label text={"State"} isRequired={required} />
                <InputControlled
                    type="text"
                    value={state}
                    onChange={(e) => setState(e)}
                    name={name}
                    disabled={true}
                    className="bg-gray-100 dark:bg-gray-700 text-gray-500"
                />
            </div>
            <div>
                <Label text={"County"} isRequired={required} />
                <InputControlled
                    type="text"
                    value={county}
                    onChange={(e) => setCounty(e)}
                    name={name}
                    disabled={true}
                    className="bg-gray-100 dark:bg-gray-700 text-gray-500"
                />
            </div>

            {errors && errors.length > 0 && <div>{errors[0]}</div>}
        </div>
    );
}
