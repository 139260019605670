import { z } from "zod";
import { useMutation } from "@tanstack/react-query";
import ModalDialog from "../../layout/modal-dialog";
import { useForm } from "../../hooks/useForm";
import { Form } from "../../layout/form/form";
import { getAuthTokenNoThrow } from "../../services/auth-header";
import ButtonNeoGen from "../../layout/button-neogen";
import { Interaction } from "../domain/interaction";
import { TextAreaField } from "../../layout/form/text-area-field";
import { useAuth } from "../../auth/use-auth";
import { CheckboxField } from "../../layout/form/checkbox-field";
import { Company } from "../../companies/domain/company";
import { DateTimeField } from "../../layout/form/date-field";
import { SelectField } from "../../layout/form/select-field";
import { User } from "../../jason-proof-of-concept/users/domain/user";
import { useUpdateInteraction } from "../hooks/use-update-interaction";

const schema = z.object({
    activity: z
        .string({
            invalid_type_error: "Please enter a note",
        })
        .min(1),
    isInternal: z.boolean(),
    visibleBySuperUser: z.boolean(),
    visibleByCpa: z.boolean(),
    visibleByTaxAttorney: z.boolean(),
    visibleByPaymaster: z.boolean(),
    visibleByAffiliate: z.boolean(),
    followUpDate: z.date().nullish(),
    assigneeId: z.string().uuid().nullish(),
});

type Data = z.infer<typeof schema>;

export const EditNoteModal = ({
    note,
    onClose,
    onNoteUpdated,
    forUserId,
    company,
    users,
}: {
    note: Interaction;
    onClose: () => any;
    onNoteUpdated: (interaction: Interaction) => any;
    forUserId: string | null | undefined;
    company: Company;
    users: User[];
}) => {
    const authToken = getAuthTokenNoThrow() || "no-auth-token";
    const form = useForm({
        schema,
        defaultValues: {
            activity: note.activity || "",
            assigneeId: note.interaction_to || "",
            followUpDate: note.followupDatetime,
            isInternal: !!note.isInternal,
            visibleByAffiliate: !!note.visibleByAffiliate,
            visibleByCpa: !!note.visibleByCpa,
            visibleByPaymaster: !!note.visibleByPaymaster,
            visibleBySuperUser: !!note.visibleBySuperUser,
            visibleByTaxAttorney: !!note.visibleByTaxAttorney,
        },
    });
    const auth = useAuth();
    const user = auth.expectUser();

    const updateInteractionMutation = useUpdateInteraction();

    const submitMutation = useMutation({
        mutationFn: async (data: Data) => {
            const interaction = await updateInteractionMutation.mutateAsync({
                id: note.id,
                authToken,
                data: {
                    activity: data.activity,
                    interaction_to: data.assigneeId || forUserId,
                    interaction_from: user.id,
                    type: 3, // Note
                    isInternal: data.isInternal,
                    visibleBySuperUser: data.visibleBySuperUser,
                    visibleByCpa: data.visibleByCpa,
                    visibleByTaxAttorney: data.visibleByTaxAttorney,
                    visibleByPaymaster: data.visibleByPaymaster,
                    visibleByAffiliate: data.visibleByAffiliate,
                    companyId: company.id,
                    followupDatetime: data.followUpDate ?? undefined,
                },
            });
            return interaction;
        },
    });

    const handleSubmit = async (data: Data) => {
        const interaction = await submitMutation.mutateAsync(data);
        onNoteUpdated(interaction);
    };

    return (
        <ModalDialog show title={"Edit Note"} close={onClose} showOk={false} showCancel={false} size="sm">
            <Form onSubmit={form.handleSubmit(handleSubmit)} error={updateInteractionMutation.error as any}>
                <TextAreaField label="Note" {...form.getFieldProps("activity")} placeholder="Add note..." />
                <DateTimeField
                    label="Follow up date"
                    isClearable
                    dateFormat={"yyyy-MM-dd HH:mm:ss"}
                    {...form.getFieldProps("followUpDate")}
                    className="text-sm w-full rounded-md border-gray-300 dark:border-none placeholder:text-sm px-2 py-1.5 cursor-pointer flex dark:bg-gray-900 outline-none border focus:border-blue-gray-200 dark:border-gray-700"
                />
                <SelectField
                    label="Assignee"
                    {...form.getFieldProps("assigneeId")}
                    options={users.map((user) => {
                        const email = user.email ? `(${user.email})` : "";
                        return {
                            label: `${[user.firstName, user.lastName].join(" ")} ${email}`,
                            value: user.id ?? "",
                        };
                    })}
                />
                <p className="mb-1">Viewable by:</p>
                <div className="grid-cols-3 gap-2 grid">
                    <CheckboxField label="Internal" {...form.getFieldProps("isInternal")} />
                    <CheckboxField label="Affiliate" {...form.getFieldProps("visibleByAffiliate")} />
                    <CheckboxField label="CPA" {...form.getFieldProps("visibleByCpa")} />
                    <CheckboxField label="Tax Attorney" {...form.getFieldProps("visibleByTaxAttorney")} />
                    <CheckboxField label="Paymaster" {...form.getFieldProps("visibleByPaymaster")} />
                    <CheckboxField label="Super user" {...form.getFieldProps("visibleBySuperUser")} />
                </div>
                <div className="flex justify-end gap-4">
                    <ButtonNeoGen
                        type="outline"
                        disabled={updateInteractionMutation.isLoading}
                        onClick={() => onClose()}
                    >
                        Cancel
                    </ButtonNeoGen>
                    <ButtonNeoGen type="submit" disabled={updateInteractionMutation.isLoading}>
                        Edit Note
                    </ButtonNeoGen>
                </div>
            </Form>
        </ModalDialog>
    );
};
