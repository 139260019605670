/* eslint-disable react/display-name */
import { ExclamationCircleIcon } from "@heroicons/react/24/solid";
import { forwardRef, InputHTMLAttributes, useState } from "react";

export type PhoneInputProps = Omit<InputHTMLAttributes<HTMLInputElement>, "form">;

export const PhoneNumberInput = forwardRef(
    (props: PhoneInputProps & { hasError?: boolean; value?: string }, ref: any) => {
        const [rawValue, setRawValue] = useState(props.value || "");
        const isControlled = props.value !== undefined;

        const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            let input = e.target.value.replace(/\D/g, ""); // Remove all non-digit characters

            if (input.length > 10) {
                input = input.slice(0, 10); // Limit to 10 digits
            }

            if (props.onChange) {
                props.onChange({ ...e, target: { ...e.target, value: input } });
            }

            if (!isControlled) {
                setRawValue(input);
            }
        };

        const formatValue = (value: string) => {
            if (!value) return "";
            const digits = value.replace(/\D/g, "");
            let formatted = "";
            if (digits.length > 0) formatted = "(" + digits.slice(0, 3);
            if (digits.length > 3) formatted += ") " + digits.slice(3, 6);
            if (digits.length > 6) formatted += "-" + digits.slice(6, 10);
            return formatted;
        };

        const finalFormattedValue = isControlled ? formatValue(props.value || "") : formatValue(rawValue);

        return (
            <div style={{ position: "relative" }}>
                {props.hasError && (
                    <div className={"absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none "}>
                        <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                    </div>
                )}
                <input
                    type="tel"
                    {...props}
                    onChange={handleChange}
                    value={finalFormattedValue}
                    className={
                        ` dark:bg-gray-800  ${props.disabled ? "bg-gray-100 text-gray-500" : ""} ` +
                        (props.hasError
                            ? "block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                            : "shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md dark:bg-gray-900  dark:border-gray-900 dark:text-gray-300")
                    }
                    ref={ref}
                    placeholder="(XXX) XXX-XXXX"
                />
            </div>
        );
    },
);
