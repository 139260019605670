import APIService from "./api.service";
import invoicesService from "./invoices.service";

export type InvoiceLineItemType = {
    id?: number;
    invoiceId: number;
    lineItemId: number;
    qty?: number;
    price: number;
};

class InvoiceLineItemService extends APIService<InvoiceLineItemType> {
    constructor() {
        super("/invoice-line-items");
    }
    async checkForPresence(companyId: number, lineItemId: number) {
        // Get all invoices for a customer
        const invoices = await invoicesService.getFilteredWhere({ companyId });
        // Get all line items for each invoice
        if (!invoices?.data.length) return false;
        for (const invoice of invoices.data) {
            const lineItems = await this.getFilteredWhere({ invoiceId: invoice.id });
            // Check if any of the invoices have a line item with the lineItemId
            for (const lineItem of lineItems?.data ?? []) {
                if (lineItem.lineItemId === lineItemId) {
                    return true;
                }
            }
        }
    }
}

export default new InvoiceLineItemService();
