import { useMutation } from "@tanstack/react-query";
import ModalDialog from "../../layout/modal-dialog";
import { useAuth } from "../../auth/use-auth";
import { Mandate } from "../domain/mandate";
import { updateMandate } from "../actions/update-mandate";
import { z } from "zod";
import { Form } from "../../layout/form/form";
import { TextField } from "../../layout/form/text-field";
import { useForm } from "../../hooks/useForm";
import { Table, TBody, Td, Tr } from "../../layout/table";
import { TextAreaField } from "../../layout/form/text-area-field";
import { RadioField } from "../../layout/form/radio-field";

const schema = z.object({
    aiName: z.string().nullish(),
    isAiNameCorrect: z.coerce.boolean().nullish(),
    humanName: z.string().nullish(),
    nameNotes: z.string().nullish(),
});

type Data = z.infer<typeof schema>;

export const EditMandateNameModal = ({
    onClose,
    mandate,
    onUpdate,
    userId,
}: {
    onClose: () => void;
    mandate: Mandate;
    onUpdate: (updatedMandate: Mandate) => void;
    userId?: string;
}) => {
    const auth = useAuth();
    const authToken = auth.expectAuthToken();

    const form = useForm({
        schema,
        defaultValues: { ...mandate },
    });

    const triggerAiMutation = useMutation({
        mutationFn: async (data: Partial<Mandate>) => {
            const updatedMandate = await updateMandate({ authToken, id: mandate.id, data });
            onUpdate(updatedMandate);
            onClose();
        },
    });

    const handleSubmit = async (data: Partial<Mandate>) => {
        const isSubmittingAiFeedback = data.isAiNameCorrect !== mandate.isAiNameCorrect;

        const d: Partial<Mandate> =
            isSubmittingAiFeedback === true
                ? {
                      isAiNameCorrect: data.isAiNameCorrect,
                      aiNameAtFeedbackTime: data.aiName,
                      aiNameFeedbackSubmittedAt: new Date(),
                      name: data.isAiNameCorrect === true ? data.aiName : data.humanName,
                  }
                : { name: data.isAiNameCorrect !== true ? data.humanName : undefined };

        const updatedData: Partial<Mandate> = {
            ...d,
            humanName: data.humanName,
            nameNotes: data.nameNotes,
            feedbackOnNameUserId: userId,
        };

        await triggerAiMutation.mutateAsync(updatedData);
    };

    const values = form.watch();

    const useEditableField = values.isAiNameCorrect !== true || !mandate.aiName;

    return (
        <ModalDialog
            title="Edit Mandate Name"
            show
            close={onClose}
            okAction={form.handleSubmit(handleSubmit)}
            okText="Update Name"
            isLoading={triggerAiMutation.isLoading}
            size="md"
        >
            <Form onSubmit={form.handleSubmit(handleSubmit)}>
                <Table>
                    <TBody>
                        <Tr>
                            <Td style={{ color: "gray", width: 220 }}>Confirmed Name</Td>
                            <Td style={{ fontWeight: 800, paddingTop: 0, paddingBottom: 0 }}>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                    <div className="flex-1">
                                        {useEditableField ? (
                                            <TextField
                                                isRequired={values.isAiNameCorrect === false}
                                                {...form.getFieldProps("humanName")}
                                            />
                                        ) : (
                                            <>
                                                {values.isAiNameCorrect === true
                                                    ? mandate.aiNameAtFeedbackTime || mandate.aiName
                                                    : mandate.name || "-"}
                                            </>
                                        )}
                                    </div>
                                </div>
                            </Td>
                        </Tr>
                        <Tr>
                            <Td style={{ color: "gray", width: 185 }}>AI Suggested Name</Td>
                            <Td style={{ fontWeight: 500, paddingTop: 0, paddingBottom: 0 }}>
                                <div style={{ display: "flex", flexDirection: "row", gap: 6, alignItems: "center" }}>
                                    <div>
                                        {mandate.isAiNameCorrect === true
                                            ? mandate.aiNameAtFeedbackTime
                                            : mandate.aiName || "-"}
                                    </div>
                                </div>
                            </Td>
                        </Tr>
                        <Tr>
                            <Td style={{ color: "gray", width: 180 }}>Is the AI suggestion correct?</Td>
                            <Td style={{ fontWeight: 500, paddingTop: 0, paddingBottom: 0 }}>
                                <div style={{ display: "flex", flexDirection: "row", gap: 6, alignItems: "center" }}>
                                    <RadioField
                                        disabled={!mandate.aiName}
                                        {...form.getFieldProps("isAiNameCorrect")}
                                        options={[
                                            { label: "Yes", value: true },
                                            { label: "No", value: false },
                                        ]}
                                    />
                                </div>
                            </Td>
                        </Tr>
                        <Tr>
                            <Td style={{ color: "gray", width: 185 }}>Google Sheet Name</Td>
                            <Td style={{ fontWeight: 500, paddingTop: 0, paddingBottom: 0 }}>
                                <div style={{ display: "flex", flexDirection: "row", gap: 6, alignItems: "center" }}>
                                    <div>{mandate.googleSheetName || "-"}</div>
                                </div>
                            </Td>
                        </Tr>
                    </TBody>
                </Table>
                <TextAreaField label="Notes" {...form.getFieldProps("nameNotes")} />
            </Form>
        </ModalDialog>
    );
};
