import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { differenceInSeconds } from "date-fns";
import { debounce, omit, set } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import Swal from "sweetalert2";
import { z } from "zod";
import { createAlert } from "../../alerts/actions/create-alert";
import userAtom, { assumedUserAtom } from "../../atoms/userAtom";
import {
    retrieveQualificationForRevenueReduction,
    retrieveQualificationForRevenueReductionForAggregate,
} from "../../companies/actions/retrieve-qualification-for-revenue-reduction";
import { setCompanyIndustries } from "../../companies/actions/set-company-industries";
import { NewInvoiceFlowModal } from "../../companies/components/new-invoice-flow-modal";
import { useCompanies } from "../../companies/hooks/use-companies";
import { useCompany } from "../../companies/hooks/use-company";
import { useBreakpoint } from "../../hooks/appMedia";
import useQueryParams from "../../hooks/useQueryParams";
import { AlertUser } from "../../layout/alert-field";
import { ButtonActionTypes } from "../../layout/button-creator";
import ButtonNeoGen from "../../layout/button-neogen";
import ErrorSection from "../../layout/error-section";
import { Select } from "../../layout/form/select-input";
import ModalDialog from "../../layout/modal-dialog";
import PrintPre from "../../layout/print-pre";
import SwalNeogenFire from "../../layout/swal-neogen";
import { useLineItems } from "../../line-items/hooks/useLineItems";
import { pusher } from "../../pusher";
import TaxPayerAdvocate from "../../sections/dashboard/dashboard-content/taxPayerAdvocate";
import Loader2 from "../../sections/utilities/Loader2";
import { getAuthTokenNoThrow } from "../../services/auth-header";
import authService from "../../services/auth.service";
import businessRuleGroupService from "../../services/business-rule-group.service";
import businessRuleService from "../../services/business-rule.service";
import companyService from "../../services/company.service";
import { LineItemsType } from "../../services/line-items.service";
import processflowStageRuleGroupService from "../../services/processflow-stage-rule-group.service";
import roleGroupService from "../../services/role-group.service";
import { sendSlackDebug, sendSlackMessage, sendSlackSale } from "../../services/slack-notifications";
import userService from "../../services/user.service";
import usersService from "../../services/users.service";
import utmLinkService from "../../services/utm-link.service";
import utmLogService from "../../services/utm-log.service";
import WizardContext from "../../services/wizard-context";
import { ClearERCUser } from "../../typings/api/clear-erc-user";
import { ProcessFlowProgressData } from "../../typings/api/processflow-progress-data";
import { UTMLog } from "../../typings/api/utm-logs";
import { getBusinessRuleTypes } from "../business-rule-types/actions/get-business-rule-types";
import { BusinessRuleType } from "../business-rule-types/domain/business-rule-type";
import IssuesModal from "../components/modals/issues-modal";
import RandDModal from "../components/modals/r-and-d-modal";
import { Step } from "../components/steps";
import { getGeoData } from "../other/actions/getMyIp";
import { createProcessflowProgress } from "../processflow-progresses/actions/create-processflow-progress";
import { getProcessflowProgresses } from "../processflow-progresses/actions/get-processflow-progresses";
import { updateProcessflowProgress } from "../processflow-progresses/actions/update-processflow-progress";
import { getProcessflowStages } from "../processflow-stages/actions/get-processflow-stages";
import { ProcessflowStage } from "../processflow-stages/domain/processflow-stage";
import { getProcessflows } from "../processflows/actions/get-process-flows";
import { checkIfProcessflowPasses, checkIfStagePasses, validateEntry } from "../processflows/actions/validate-entry";
import { Entry, ProcessFlow } from "../processflows/domain/processflow";
import { usePromise, usePromiseLazy } from "../shared/hooks";
import { Stage } from "../stages/domain/stage";
import { triggerAffiliateRegistration } from "../users/actions/trigger-affiliate-registration";
import { triggerRegistration } from "../users/actions/trigger-registration";
import { User } from "../users/domain/user";
import FakeWizard from "./fake-wizard";
import Submitting from "./submitting";
import { Wizard } from "./wizard";
import { Position } from "./Position";

export const getDataFromProgressData = (data: ProcessFlowProgressData) => {
    const entryData = data?.entriesByField || {};
    return Object.keys(entryData).reduce((acc: any, key: any) => {
        return { ...acc, ...(!entryData[key].isHidden ? { [key]: entryData[key]?.fieldValue } : {}) };
    }, {});
};

export const WizardContainer = ({
    hideStages,
    hideSteps,
    processflowGroupId,
    user: passedUser,
    onChange,
    companyId: passedCompanyId,
}: {
    hideStages?: boolean;
    hideSteps?: boolean;
    processflowGroupId?: number;
    inIframeParam?: boolean;
    user?: User;
    onChange?: () => void;
    companyId?: number;
}) => {
    const lsCompanyIdString = localStorage.getItem("companyId");
    const lsCompanyId = lsCompanyIdString ? Number(lsCompanyIdString) : undefined;
    const [error, setError] = useState<any>();
    const [showModal, setShowModal] = useState(false);
    const [entryErrors, setEntryErrors] = useState<Record<string, string[]>>({});
    const [fakeWizardTimeout, setFakeWizardTimeout] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [submitCount, setSubmitCount] = useState(0);
    const [showNewInvoiceFlowModal, setShowNewInvoiceFlowModal] = useState(false);
    const breakpoints = useBreakpoint();
    const isMobile = breakpoints.breakpoint === "mobile";
    const isTablet = breakpoints.breakpoint === "tablet";
    const { processflowGroup, utm, step } = useParams();
    const group: number = processflowGroupId || (processflowGroup ? Number(processflowGroup) : 7);
    const [companyId, setCompanyId] = useState<number | undefined>(passedCompanyId || lsCompanyId);
    const [processflowId, setProcessflowProgressId] = useState<number | undefined>(undefined);
    const [progressData, setProgressData] = useState<ProcessFlowProgressData | undefined>();
    const [data, setData] = useState<Record<string, any>>(progressData ? getDataFromProgressData(progressData) : {});
    const [position, setPosition] = useState<Position | undefined>(
        progressData?.currentStep && progressData.currentStage
            ? {
                  processflowId: progressData.currentStep,
                  processflowStageId: progressData.currentStage,
              }
            : undefined,
    );

    const processflowsAction = usePromise(async () => {
        const processflows = await getProcessflows({ authToken, group });
        return processflows;
    }, []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const processflows = useMemo(
        () => (processflowsAction.result || []).sort((a, b) => (a.order || 0) - (b.order || 0)),
        [processflowsAction.result],
    );

    const handlePositionChange = useCallback(async (position: Position) => {
        setPosition(position);
        setLastActive(new Date());
    }, []);

    // Store in local storage.
    useEffect(() => {
        if (processflowId && position) {
            const positionData = JSON.parse(localStorage.getItem("positions") || "{}");
            localStorage.setItem(
                "positions",
                JSON.stringify({
                    ...positionData,
                    [processflowId]: position,
                }),
            );
            console.log("positionData", positionData);
            const product = window.localStorage.getItem("tempRoleGroup");
            console.log("product", product);
            localStorage.setItem(
                "position-" + product,
                JSON.stringify({
                    ...positionData,
                    [processflowId]: position,
                }),
            );
        }

        console.log("position", position);
        console.log("processflowId", processflowId);
    }, [position, processflowId]);

    // TODO: Someone could choose any group id, so we need to validate that the user has access to this group
    // TODO: Why 9?? What even is 9??
    const enableValidation = group === 9;
    const [loggedInUser, setUser] = useRecoilState(userAtom);
    const [assumedUser] = useRecoilState(assumedUserAtom);
    // TODO: Use proper auth token.
    const authToken = getAuthTokenNoThrow() || "";
    const userCompany = useCompany({ authToken, id: companyId || -1 });
    const query = useQueryParams();
    const user = passedUser || assumedUser || loggedInUser;
    const [canSkipStages, setCanSkipStages] = useState(false);
    const [hasLoaded, setHasLoaded] = useState(false);
    const [showRandDModal, setShowRandDModal] = useState(false);
    const [showIssuesModal, setShowIssuesModal] = useState(false);
    const [rAndDData, setRAndDData] = useState<any>();
    const queryClient = useQueryClient();
    const [lastActive, setLastActive] = useState(new Date());
    const [showTaxPayerAdvocateModal, setShowTaxPayerAdvocateModal] = useState(false);

    const companiesQuery = useCompanies({ authToken }, { enabled: !!authToken });
    const companies = useMemo(() => companiesQuery.data || [], [companiesQuery.data]);

    const lineItemsQuery = useLineItems();
    const lineItems = useMemo(() => lineItemsQuery.data?.data || [], [lineItemsQuery.data]) as LineItemsType[];

    useEffect(() => {
        const channel = pusher.subscribe("my-channel");
        channel.bind("my-event", async function (data: { processflowProgressId: number }) {
            if (data.processflowProgressId) {
                const diff = differenceInSeconds(new Date(), lastActive);
                if (data.processflowProgressId !== processflowId || diff < 20) {
                    return;
                }
                const [usersProgress] = await getProcessflowProgresses({
                    authToken,
                    filters: { id: processflowId, group },
                });
                if (usersProgress) {
                    setProgressData(usersProgress.data as any);
                    setData(getDataFromProgressData(usersProgress.data as any));
                }
            }
        });
        return () => pusher.unsubscribe("my-channel");
    }, [authToken, group, lastActive, processflowId]);

    const company = useMemo(
        () => (companyId ? companies.find((c) => c.id === companyId) : undefined),
        [companies, companyId],
    );

    const getPfUserId = useCallback(() => {
        if (group === 7) {
            return company?.ownedById;
        }
        return user.id;
    }, [company?.ownedById, group, user?.id]);

    useEffect(() => {
        if (!passedCompanyId && !lsCompanyId && companies.length > 0) {
            setCompanyId(companies[0].id);
        }
    }, [companies, lsCompanyId, passedCompanyId]);

    useEffect(() => {
        authService.canIAccess("SKIP_STAGES").then((res) => {
            setCanSkipStages(res);
        });
    }, []);

    const inIframe = useCallback(() => {
        try {
            document.body.classList.add("iframe");
            if (query.get("inIframe")) {
                return true;
            }
            return window.self !== window.top;
        } catch (e) {
            return true;
        }
    }, [query]);

    const expectCurrentPosition = useCallback(() => {
        if (!position) {
            throw new Error("Current position not set");
        }
        return position;
    }, [position]);

    useEffect(() => {
        if (step) {
            console.log(step);
            const processflow = processflows.find((pf) => pf.id === Number(step));
            try {
                const currentPosition = expectCurrentPosition();
                if (currentPosition?.processflowId !== Number(step)) {
                    console.log("Current position", currentPosition);
                    const pos: Position = {
                        processflowId: Number(step),
                        processflowStageId: processflow?.stage ?? -1,
                    };

                    handlePositionChange(pos);

                    setProcessflowProgressId(Number(step));
                    setSubmitCount(0);
                }
            } catch (e) {
                console.error(e);
            }
        }
    }, [expectCurrentPosition, handlePositionChange, processflows, step]);

    useEffect(() => {
        if (inIframe() || query.get("inIframe")) {
            document.body.classList.add("iframe");
            document.body.classList.add("bg-white");
            document.body.classList.remove("bg-slate-300");
            document.body.classList.remove("dark-theme");
            document.body.classList.remove("dark");
        }
    }, [inIframe, query]);

    const updateProgressAction = usePromiseLazy(
        async (d: any, userId: string) => {
            const data = {
                group,
                userId: userId,
                client: 1,
                data: d,
                currentStep: d?.currentStep,
                currentStage: d?.currentStage,
                country: company?.country,
                city: company?.city,
                state: company?.state,
            };

            // TODO: Just get the ID
            const [usersProgress] = await getProcessflowProgresses({
                authToken,
                filters: { userId: userId, group },
            });
            if (usersProgress) {
                await updateProcessflowProgress({
                    authToken,
                    id: usersProgress.id,
                    data,
                });
            } else {
                await createProcessflowProgress({ authToken, data });
            }
            if (onChange) {
                onChange();
            }
        },
        [group],
    );

    const updateProgress = useMemo(
        () =>
            debounce(updateProgressAction.execute, 1500, {
                maxWait: 2000,
                trailing: true,
            }),
        [updateProgressAction.execute],
    );

    const processflowStagesAction = usePromise(async () => {
        const processflowStages = await getProcessflowStages({
            authToken,
            processflowGroup: group,
        });
        return processflowStages;
    }, []);
    const processflowStages = useMemo(
        () => (processflowStagesAction.result || []).sort((a, b) => (a.order || 0) - (b.order || 0)),
        [processflowStagesAction.result],
    );
    const currentProcessflowStageIndex = useMemo(
        () => processflowStages.findIndex((stage) => stage.id === position?.processflowStageId),
        [processflowStages, position],
    );

    const businessRuleTypesAction = usePromise(async () => {
        const businessRuleTypes = await getBusinessRuleTypes({
            authToken,
        });
        return businessRuleTypes;
    }, []);

    const businessRuleTypes = useMemo(() => businessRuleTypesAction.result || [], [businessRuleTypesAction.result]);

    /**
     * Handles the change of data and updates the progress data.
     * @param newData - The new data to be set.
     * @param progressData - The progress data to be updated.
     */
    const handleDataChange = useCallback(
        (newData: any, progressData: ProcessFlowProgressData) => {
            setData(newData);
            setLastActive(new Date());
            const nonInteractiveEntryTypes = ["wysiwyg", "textDisplay", "video", "state", "valueCalculator", "button"];
            const allEntries = processflows.reduce<Entry[]>((acc, curr) => {
                return [...acc, ...(curr.entries || [])];
            }, []);
            const allInteractiveEntries = allEntries.filter((entry) => !nonInteractiveEntryTypes.includes(entry.type));
            const relevantInteractiveEntries = allInteractiveEntries.filter(
                (entry) =>
                    !!validateEntry({
                        entry,
                        businessRuleTypes,
                        data: newData,
                    }).passed,
            );
            const completedEntries = allInteractiveEntries.filter((e) => {
                return (
                    !!newData[e.field as any] &&
                    !!validateEntry({
                        entry: e,
                        businessRuleTypes,
                        data: newData,
                    }).passed
                );
            });
            const numberOfRelevantEntries = relevantInteractiveEntries.length;
            const numberOfCompletedEntries = completedEntries.length;
            const percentageComplete = Math.round((numberOfCompletedEntries / numberOfRelevantEntries) * 100) / 100;
            const updatedProgressData = {
                ...progressData,
                numberOfRelevantInteractiveEntries: numberOfRelevantEntries,
                numberOfCompletedEntries: numberOfCompletedEntries,
                percentageComplete,
            };
            setProgressData(updatedProgressData);
            updateProgress(updatedProgressData, getPfUserId());

            // TODO: 9 is Advertising Landing Page and 11 is affiliate training.
            // This is actually a problem because affiliates should be able to save their progress
            // We should only require auth for updates on process flow groups that are not public
            if (group === 7) {
                userService.update(user.id, { pf7percentage: percentageComplete }, true).catch((e) => {
                    console.error(e);
                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: "There was an error saving your progress. Please try again. " + e.message,
                    });
                });
            }
        },
        [businessRuleTypes, getPfUserId, group, processflows, updateProgress, user.id],
    );

    const setInitialPosition = useCallback(() => {
        const product = window.localStorage.getItem("tempRoleGroup");
        const productPosition = JSON.parse(window.localStorage.getItem("position-" + product) || "{}");
        console.log("Initial product", product, productPosition);

        let positionData; // = JSON.parse(localStorage.getItem("positions") || "{}");
        if (productPosition) {
            console.log("Product position", productPosition);
            positionData = productPosition;
        } else {
            console.log("No product position");
        }
        const stageId = processflowStages[0].id;
        const stageProcessflows = processflows.filter((pf) => pf.stage === stageId);
        const processflowId = stageProcessflows[0]?.id;
        const positionFromLs = positionData[processflowId];
        const newPosition = positionFromLs
            ? positionFromLs
            : {
                  processflowStageId: stageId,
                  processflowId: processflowId,
              };
        setPosition(newPosition);
    }, [processflowStages, processflows]);

    const getProcessflowsForStageId = useCallback(
        (stageId: number) => {
            const stageProcessflows = processflows.filter((pf) => pf.stage === stageId);
            return stageProcessflows;
        },
        [processflows],
    );

    useEffect(() => {
        const product = window.localStorage.getItem("tempRoleGroup");
        const positionData = JSON.parse(localStorage.getItem("position-" + product) || "{}");

        const f = async () => {
            const initialStage: ProcessflowStage | undefined = processflowStages[0];
            const processflows = initialStage ? getProcessflowsForStageId(initialStage.id) : [];
            const initialProcessflow = processflows[0];
            if (initialStage && initialProcessflow && group === 7) {
                if (company?.ownedById) {
                    const [usersProgress] = await getProcessflowProgresses({
                        authToken,
                        filters: { userId: company.ownedById, group },
                    });
                    setProcessflowProgressId(usersProgress?.id);
                    setProgressData((usersProgress?.data as any) || undefined);
                    const data = usersProgress?.data ? getDataFromProgressData(usersProgress.data as any) : {};
                    setData(data);
                    const positionFromLs = usersProgress ? positionData?.[usersProgress.id] : undefined;
                    setPosition(
                        positionFromLs || {
                            processflowId: initialProcessflow.id as any,
                            processflowStageId: initialStage.id as any,
                        },
                    );
                    setHasLoaded(true);
                }
            } else if (initialStage && initialProcessflow && user && group !== 7) {
                const [usersProgress] = await getProcessflowProgresses({
                    authToken,
                    filters: { userId: user.id, group },
                });
                if (usersProgress) {
                    if (usersProgress.data) {
                        setProgressData(usersProgress.data as any);
                    }
                    setProcessflowProgressId(usersProgress?.id);
                    const data = getDataFromProgressData(usersProgress.data as any);
                    setData(data);
                    const positionFromLs = usersProgress ? positionData?.[usersProgress.id] : undefined;
                    setPosition(
                        positionFromLs || {
                            processflowId: usersProgress.currentStep || (initialProcessflow.id as any),
                            processflowStageId: usersProgress.currentStage || (initialStage.id as any),
                        },
                    );
                } else {
                    const [usersProgress] = await getProcessflowProgresses({
                        authToken,
                        filters: { userId: user.id, group: 9 },
                    });

                    const progress = await createProcessflowProgress({
                        authToken,
                        data: {
                            userId: user.id,
                            group,
                            currentStage: initialStage.id,
                            currentStep: initialProcessflow.id,
                            data: usersProgress?.data ?? {
                                completedSteps: [],
                                currentStage: initialStage.id,
                                currentStep: initialProcessflow.id,
                                entriesByField: {},
                            },
                        },
                    });
                    if (progress.data) {
                        setProgressData(progress.data as any);
                    }
                    setProcessflowProgressId(usersProgress?.id);
                    const data = getDataFromProgressData(usersProgress?.data as any);
                    setData(data);
                    setPosition({
                        processflowId: initialProcessflow.id,
                        processflowStageId: initialStage.id,
                    });
                }
                setHasLoaded(true);
            }
        };
        if (!hasLoaded) {
            f();
        }
    }, [
        group,
        user,
        processflows,
        getProcessflowsForStageId,
        assumedUser,
        processflowStages,
        setProgressData,
        authToken,
        company,
        companies,
        hasLoaded,
    ]);

    // Set initial current position on load
    useEffect(() => {
        if (!position && processflowStages[0] && processflows[0]) {
            setInitialPosition();
        }
    }, [processflowStages, processflows, position, setInitialPosition]);

    const getEntriesForProcessFlowId = useCallback(
        ({ processflowId, businessRuleTypes }: { processflowId: number; businessRuleTypes: BusinessRuleType[] }) => {
            const entries: Entry[] = processflows.find((pf) => pf.id === processflowId)?.entries || [];

            const passedEntries = entries.filter((entry: Entry) => {
                return !!validateEntry({
                    entry,
                    data,
                    businessRuleTypes,
                }).passed;
            });

            return passedEntries;
        },
        [data, processflows],
    );

    const grossReceiptsQualificationQuery = useMutation({
        mutationFn: async (data: any) => {
            const response = await retrieveQualificationForRevenueReduction({
                authToken,
                data: {
                    businessStartDateString:
                        data?.["Business start date"] === "After February 15th 2020"
                            ? new Date(2020, 1, 16).toDateString()
                            : new Date(2020, 1, 13).toDateString(),
                    Q1_2019: Number.parseInt(data?.["Gross receipts Q1 2019"] || "0"),
                    Q2_2019: Number.parseInt(data?.["Gross receipts Q2 2019"] || "0"),
                    Q3_2019: Number.parseInt(data?.["Gross receipts Q3 2019"] || "0"),
                    Q4_2019: Number.parseInt(data?.["Gross receipts Q4 2019"] || "0"),
                    Q1_2020: Number.parseInt(data?.["Gross receipts Q1 2020"] || "0"),
                    Q2_2020: Number.parseInt(data?.["Gross receipts Q2 2020"] || "0"),
                    Q3_2020: Number.parseInt(data?.["Gross receipts Q3 2020"] || "0"),
                    Q4_2020: Number.parseInt(data?.["Gross receipts Q4 2020"] || "0"),
                    Q1_2021: Number.parseInt(data?.["Gross receipts Q1 2021"] || "0"),
                    Q2_2021: Number.parseInt(data?.["Gross receipts Q2 2021"] || "0"),
                    Q3_2021: Number.parseInt(data?.["Gross receipts Q3 2021"] || "0"),
                    Q4_2021: Number.parseInt(data?.["Gross receipts Q4 2021"] || "0"),
                },
            });

            return {
                response,
            };
        },
    });
    const grossReceiptsQualificationAggregateQuery = useMutation({
        mutationFn: async (data: any) => {
            const response = await retrieveQualificationForRevenueReductionForAggregate({
                authToken,
                data: {
                    companyGroup: 1,
                },
            });

            return {
                response,
            };
        },
    });

    /**
     * Handles the change event for an entry in the wizard container.
     *
     * @param {Object} params - The parameters for the change event.
     * @param {Entry} params.entry - The entry that triggered the change event.
     * @param {any} params.value - The new value for the entry.
     * @returns {Promise<void>} - A promise that resolves when the change event is handled.
     */
    const handleEntryChange = useCallback(
        async ({ entry, value }: { entry: Entry; value: any }) => {
            /**
             * Checks if the given field is a gross receipts field.
             *
             * @param {string} field - The field to check.
             * @returns {boolean} - Returns true if the field is a gross receipts field, false otherwise.
             */
            const isGrossReceiptsField = [
                "Gross receipts Q1 2020",
                "Gross receipts Q2 2020",
                "Gross receipts Q3 2020",
                "Gross receipts Q4 2020",
                "Gross receipts Q1 2021",
                "Gross receipts Q2 2021",
                "Gross receipts Q3 2021",
                "Gross receipts Q4 2021",
            ].includes(entry.field);

            /**
             * This block of code is designed to handle a specific case within a form submission or data processing workflow,
             * particularly focusing on fields related to gross receipts for the year 2019.
             *
             * `response` is initialized as a variable with a type of `any`. This variable is intended to store the response
             * from an asynchronous mutation query, specifically `grossReceiptsQualificationQuery.mutateAsync`.
             *
             * The `if` condition checks two scenarios:
             * 1. `isGrossReceiptsField` - A boolean that indicates whether the current field being processed is considered
             *    a gross receipts field. The exact criteria for this boolean are not provided in the snippet but are likely
             *    determined elsewhere in the code.
             * 2. The second part of the condition checks if the `entry.field` value matches any of the specified strings
             *    representing the quarters of 2019. These strings are:
             *    - "Gross receipts Q1 2019"
             *    - "Gross receipts Q2 2019"
             *    - "Gross receipts Q3 2019"
             *    - "Gross receipts Q4 2019"
             *
             * If either of these conditions is true, the code proceeds to execute an asynchronous mutation query. This query
             * is called with an object that spreads the existing `data` object and adds or updates the key-value pair where
             * the key is `entry.field` and the value is `value`. The result of this mutation query is then accessed to retrieve
             * the `response` property, which is assigned to the `response` variable.
             *
             * This operation is likely part of a larger form handling or data processing function where the gross receipts
             * information for 2019 needs special handling or validation through an asynchronous API call or database query.
             */
            let response: any;
            if (
                isGrossReceiptsField ||
                [
                    "Gross receipts Q1 2019",
                    "Gross receipts Q2 2019",
                    "Gross receipts Q3 2019",
                    "Gross receipts Q4 2019",
                ].includes(entry.field)
            ) {
                response = (await grossReceiptsQualificationQuery.mutateAsync({ ...data, [entry.field]: value }))
                    .response;
            }

            /**
             * The `queryResultMapping` object maps specific strings related to gross receipts for quarters in 2020 and 2021
             * to a boolean value or undefined. These values indicate whether a certain condition (e.g., a qualification criterion)
             * is met for each quarter. The mapping uses optional chaining (`?.`) to safely access properties within the `response`
             * object, which suggests that `response` may not always contain the nested properties for each quarter.
             *
             * Each key in the `queryResultMapping` corresponds to a specific quarter and year (e.g., "Gross receipts Q1 2020"),
             * and the value is determined by the `qualifies` property of the respective quarter within the `response` object.
             * If the `response` object or the `qualifies` property is undefined for a quarter, the value for that key will be
             * undefined, indicating that the qualification status is unknown or not applicable.
             *
             * Similarly, the `queryReasonMapping` object maps strings indicating the reason a quarter in 2020 or 2021 qualified
             * to either a string value or undefined. The keys in this mapping are structured to reflect the reason for qualification
             * (e.g., "Reason Q1 2020 qualified"), and the values are obtained from the `reason` property of the respective quarter
             * within the `response` object.
             *
             * The use of these mappings suggests a scenario where the qualification status and reasons for each quarter are
             * dynamically determined based on the response from an external source or calculation. These mappings are used
             * to display the qualification status and reasons in a user interface, or for further processing within the application.
             */
            const queryResultMapping: Record<string, boolean | undefined> = {
                "Gross receipts Q1 2020": response?.Q1_2020?.qualifies,
                "Gross receipts Q2 2020": response?.Q2_2020?.qualifies,
                "Gross receipts Q3 2020": response?.Q3_2020?.qualifies,
                "Gross receipts Q4 2020": response?.Q4_2020?.qualifies,
                "Gross receipts Q1 2021": response?.Q1_2021?.qualifies,
                "Gross receipts Q2 2021": response?.Q2_2021?.qualifies,
                "Gross receipts Q3 2021": response?.Q3_2021?.qualifies,
                "Gross receipts Q4 2021": response?.Q4_2021?.qualifies,
            };

            const queryReasonMapping: Record<string, boolean | undefined> = {
                "Reason Q1 2020 qualified": response?.Q1_2020?.reason,
                "Reason Q2 2020 qualified": response?.Q2_2020?.reason,
                "Reason Q3 2020 qualified": response?.Q3_2020?.reason,
                "Reason Q4 2020 qualified": response?.Q4_2020?.reason,
                "Reason Q1 2021 qualified": response?.Q1_2021?.reason,
                "Reason Q2 2021 qualified": response?.Q2_2021?.reason,
                "Reason Q3 2021 qualified": response?.Q3_2021?.reason,
                "Reason Q4 2021 qualified": response?.Q4_2021?.reason,
            };

            /**
             * `updatedGrossReceiptFields` dynamically constructs an object based on the evaluation of `isGrossReceiptsField` or
             * the inclusion of `entry.field` in a predefined list of gross receipt fields from 2019. This object is intended to
             * update or define the qualification status and reasons for various gross receipt fields for the years 2020 and 2021.
             *
             * - If `isGrossReceiptsField` is true, or if `entry.field` matches one of the specified gross receipt fields from 2019,
             *   `updatedGrossReceiptFields` will be an object with keys for each quarter of 2020 and 2021. Each key has a suffix
             *   indicating its purpose, such as "_chipLabel" for displaying qualification status, or a simple "Qualified" suffix
             *   to indicate a yes/no status. Additionally, there are keys for the reasons why a field is qualified for each quarter,
             *   with a "qualified system" suffix.
             *
             * - For each quarter of 2020 and 2021, the qualification status ("Qualified" or "Not qualified") is determined by
             *   checking if there is a corresponding truthy value in `queryResultMapping` for that quarter. Similarly, a "Yes" or
             *   "No" value is assigned based on the presence of a truthy value in `queryResultMapping`.
             *
             * - The reasons for qualification for each quarter are retrieved from `queryReasonMapping`, with the key being the
             *   specific reason for that quarter (e.g., "Reason Q1 2020 qualified").
             *
             * - If neither `isGrossReceiptsField` is true nor `entry.field` matches any of the specified 2019 gross receipt fields,
             *   `updatedGrossReceiptFields` will be `undefined`.
             *
             * This structure allows for a dynamic update of the qualification status and reasons based on the latest data, which
             * can be used to display updated information to the user or for further processing.
             */
            const updatedGrossReceiptFields =
                isGrossReceiptsField ||
                [
                    "Gross receipts Q1 2019",
                    "Gross receipts Q2 2019",
                    "Gross receipts Q3 2019",
                    "Gross receipts Q4 2019",
                ].includes(entry.field)
                    ? {
                          "Gross receipts Q1 2020_chipLabel": queryResultMapping["Gross receipts Q1 2020"]
                              ? "Qualified"
                              : "Not qualified",
                          "Gross receipts Q2 2020_chipLabel": queryResultMapping["Gross receipts Q2 2020"]
                              ? "Qualified"
                              : "Not qualified",
                          "Gross receipts Q3 2020_chipLabel": queryResultMapping["Gross receipts Q3 2020"]
                              ? "Qualified"
                              : "Not qualified",
                          "Gross receipts Q4 2020_chipLabel": queryResultMapping["Gross receipts Q4 2020"]
                              ? "Qualified"
                              : "Not qualified",
                          "Gross receipts Q1 2021_chipLabel": queryResultMapping["Gross receipts Q1 2021"]
                              ? "Qualified"
                              : "Not qualified",
                          "Gross receipts Q2 2021_chipLabel": queryResultMapping["Gross receipts Q2 2021"]
                              ? "Qualified"
                              : "Not qualified",
                          "Gross receipts Q3 2021_chipLabel": queryResultMapping["Gross receipts Q3 2021"]
                              ? "Qualified"
                              : "Not qualified",
                          "Gross receipts Q4 2021_chipLabel": queryResultMapping["Gross receipts Q4 2021"]
                              ? "Qualified"
                              : "Not qualified",
                          "Q1 2020 Qualified": queryResultMapping["Gross receipts Q1 2020"] ? "Yes" : "No",
                          "Q2 2020 Qualified": queryResultMapping["Gross receipts Q2 2020"] ? "Yes" : "No",
                          "Q3 2020 Qualified": queryResultMapping["Gross receipts Q3 2020"] ? "Yes" : "No",
                          "Q4 2020 Qualified": queryResultMapping["Gross receipts Q4 2020"] ? "Yes" : "No",
                          "Q1 2021 Qualified": queryResultMapping["Gross receipts Q1 2021"] ? "Yes" : "No",
                          "Q2 2021 Qualified": queryResultMapping["Gross receipts Q2 2021"] ? "Yes" : "No",
                          "Q3 2021 Qualified": queryResultMapping["Gross receipts Q3 2021"] ? "Yes" : "No",
                          "Q4 2021 Qualified": queryResultMapping["Gross receipts Q4 2021"] ? "Yes" : "No",
                          "Reason Q1 2020 qualified system": queryReasonMapping["Reason Q1 2020 qualified"],
                          "Reason Q2 2020 qualified system": queryReasonMapping["Reason Q2 2020 qualified"],
                          "Reason Q3 2020 qualified system": queryReasonMapping["Reason Q3 2020 qualified"],
                          "Reason Q4 2020 qualified system": queryReasonMapping["Reason Q4 2020 qualified"],
                          "Reason Q1 2021 qualified system": queryReasonMapping["Reason Q1 2021 qualified"],
                          "Reason Q2 2021 qualified system": queryReasonMapping["Reason Q2 2021 qualified"],
                          "Reason Q3 2021 qualified system": queryReasonMapping["Reason Q3 2021 qualified"],
                          "Reason Q4 2021 qualified system": queryReasonMapping["Reason Q4 2021 qualified"],
                      }
                    : undefined;
            /**
             * The `updatedData` constant is an object that represents the updated state of a data object, typically used
             * in the context of a form or data submission process. This object is constructed by merging several sources
             * of data:
             *
             * 1. `...data`: This spread operator includes all existing key-value pairs from the `data` object. `data` is
             *    presumed to be the current state or existing dataset that needs to be updated.
             *
             * 2. `...updatedGrossReceiptFields`: This spread operator includes all key-value pairs from the
             *    `updatedGrossReceiptFields` object. This object is expected to contain updates related to gross receipts
             *    fields, including qualification status and reasons for each quarter, as previously documented. If
             *    `updatedGrossReceiptFields` is `undefined`, this operation will have no effect.
             *
             * 3. `[entry.field]: value`: This line dynamically updates or adds a new key-value pair to the object, where
             *    the key is determined by `entry.field` and the value is set to `value`. This allows for a specific field
             *    within the data to be updated based on user input or another dynamic source.
             *
             * The result is a new object, `updatedData`, which combines the original data with any updates from
             * `updatedGrossReceiptFields` and a specific update defined by `[entry.field]: value`. This pattern is commonly
             * used in UI development to manage state in a predictable and immutable way, ensuring that changes to data are
             * easy to track and manage.
             */
            const updatedData = {
                ...data,
                ...updatedGrossReceiptFields,
                [entry.field]: value,
            };

            if (
                ["Average full time W2 Employees in 2020?", "Average full time W2 Employees in 2021?"].includes(
                    entry.field,
                )
            ) {
                // this is for clearing the cache so that the affiliate stats update
                queryClient.invalidateQueries(["processflowData"]);
            }

            if (enableValidation && submitCount > 0) {
                const currentPosition = expectCurrentPosition();
                const entriesToCheck = businessRuleTypes
                    ? getEntriesForProcessFlowId({
                          processflowId: currentPosition.processflowId,
                          businessRuleTypes: businessRuleTypes,
                      })
                    : [];
                const expectedEntries = entriesToCheck.filter(
                    (entry) =>
                        !!validateEntry({
                            entry,
                            businessRuleTypes,
                            data: updatedData,
                        }).passed,
                );
                const failingEntries = expectedEntries.filter((entry) => {
                    // @ts-ignore
                    return !!entry.required && !updatedData[entry.field];
                });
                const errors = failingEntries.reduce((acc, curr) => {
                    return { ...acc, [curr.field]: ["Required"] };
                }, {});
                setEntryErrors(errors);
            }

            const processFlow = processflows.find((pf) => pf.id === position?.processflowId);
            const entries = processFlow?.entries || [];
            const currentEntryIndex = entries.findIndex((e) => e.id === entry.id);
            const followingEntries = entries.filter((entry, index) => {
                return index > currentEntryIndex;
            });

            let t = updatedData;

            const entriesToReset = entries.filter((entry) => {
                const isValid = validateEntry({
                    entry,
                    businessRuleTypes,
                    data: t,
                }).passed;
                if (!isValid) {
                    t = omit(t, entry.field);
                }
                return !isValid;
            });
            const updatedDataWithFollowingEntriesReset: any = omit(
                updatedData,
                entriesToReset.map((entry) => entry.field),
            );

            /**
             * The `updatedProgressData` constant is an object of type `ProcessFlowProgressData` that represents the updated state
             * of a process flow, such as a multi-step form or wizard. This object is constructed by merging and updating several
             * pieces of information related to the progress of the process flow:
             *
             * - `group`: This property likely represents a grouping identifier for the current set of steps or form fields, allowing
             *   the progress data to be segmented or categorized in some way.
             *
             * - `completedSteps`: Initialized as an empty array, indicating that, at this point in the process, no steps are
             *   marked as completed. This could be a reset operation or an initial setup.
             *
             * - `...progressData`: This spread operator includes all existing key-value pairs from the `progressData` object,
             *   which is presumed to contain the current state of the process flow, including any previously completed steps,
             *   entries, or other relevant data.
             *
             * - `entriesByField`: This property is an object that maps field names to their respective data. It is constructed
             *   using `Object.keys(updatedData).reduce(...)`, which iterates over each key in `updatedData` (presumably the latest
             *   state of the data being processed) and accumulates an object where each key is a field name, and the value is an
             *   object containing:
             *     - `fieldName`: The name of the field, directly taken from the key being iterated over.
             *     - `fieldValue`: The value associated with this field in `updatedData`. The `@ts-ignore` comment is used to bypass
             *       TypeScript's type checking, suggesting that there might be a known type mismatch or an edge case being handled.
             *     - `isHidden`: A boolean indicating whether this field should be hidden. This is determined by checking if the
             *       field's name is included in the list of fields to be reset (`entriesToReset.map((entry) => entry.field)`),
             *       implying that fields undergoing a reset are temporarily hidden in the UI.
             *
             * The `entriesByField` is initialized with `progressData?.entriesByField || {}`, meaning it starts with any existing
             * entries from the previous state, or an empty object if none exist. This approach allows for the dynamic update of
             * field values and visibility based on the latest data and specific fields marked for reset, while preserving any
             * other progress data.
             */
            const updatedProgressData: ProcessFlowProgressData = {
                group,
                completedSteps: [],
                ...progressData,
                entriesByField: Object.keys(updatedData).reduce((acc, fieldKey: string) => {
                    return {
                        ...acc,
                        [fieldKey]: {
                            fieldName: fieldKey,
                            // @ts-ignore
                            fieldValue: updatedData[fieldKey],
                            // Hide fields which will be reset based on current entry change.
                            isHidden: entriesToReset.map((entry) => entry.field).includes(fieldKey),
                        },
                    };
                }, progressData?.entriesByField || {}),
            };

            // Traverse following entries, if entry is valid and is hidden, then add its value back into the 'data' object from 'progressData' because it should now be displayed.
            const dataFromPreviouslyHiddenEntries = followingEntries.reduce((acc, entry) => {
                const fieldData = updatedProgressData.entriesByField[entry.field];
                const isHidden = fieldData?.isHidden;
                const validationResult = validateEntry({
                    entry,
                    businessRuleTypes,
                    data: { ...acc },
                });
                const isValid = validationResult.passed;
                return isValid && isHidden ? { ...acc, [entry.field]: fieldData.fieldValue } : omit(acc, entry.field);
            }, updatedDataWithFollowingEntriesReset);

            const finalData = { ...updatedDataWithFollowingEntriesReset, ...dataFromPreviouslyHiddenEntries };

            const finalProgressData: ProcessFlowProgressData = {
                ...updatedProgressData,
                entriesByField: Object.keys(finalData).reduce((acc, fieldKey: string) => {
                    const entry = entries.find((e) => e.field === fieldKey);
                    const isValid =
                        entry &&
                        validateEntry({
                            entry,
                            businessRuleTypes,
                            data: finalData,
                        }).passed;
                    return {
                        ...acc,
                        [fieldKey]: {
                            fieldName: fieldKey,
                            fieldValue: finalData[fieldKey],
                            // Reset isHidden to false if the entry is now being shown.
                            isHidden: isValid ? false : !finalData[fieldKey],
                        },
                    };
                }, updatedProgressData.entriesByField),
            };

            if (entry.type === "industryPicker" && group === 28 && companyId) {
                // TODO: If this is for a source we shouldn't update the industry for the company
                await setCompanyIndustries({ authToken, id: companyId, data: { industryIds: value || [] } });
            }

            handleDataChange(finalData, finalProgressData);
        },
        [
            data,
            enableValidation,
            submitCount,
            processflows,
            group,
            progressData,
            companyId,
            handleDataChange,
            grossReceiptsQualificationQuery,
            queryClient,
            expectCurrentPosition,
            businessRuleTypes,
            getEntriesForProcessFlowId,
            position?.processflowId,
            authToken,
        ],
    );

    const stageRuleGroupsQuery = useQuery(["stageRuleGroups"], async () => {
        const response = await processflowStageRuleGroupService.getAll();
        if (response) {
            return response.data;
        }
    });
    const businessRuleGroupsQuery = useQuery(["businessRuleGroups"], async () => {
        const response = await businessRuleGroupService.getAllIncludingChildren();
        if (response) {
            return response.data;
        }
    });
    const businessRulesQuery = useQuery(["businessRules"], async () => {
        const response = await businessRuleService.getAll();
        if (response) {
            return response.data;
        }
    });

    const doesProcessflowPass = useCallback(
        (pf: ProcessFlow, data: Record<string, any>) => {
            return checkIfProcessflowPasses({
                processflow: pf as any,
                progressData: data,
                businessRuleGroups: pf.businessRuleGroups as any,
                businessRuleTypes,
            }).passed;
        },
        [businessRuleTypes],
    );
    const doesStagePass = useCallback(
        (stage: Stage, data: Record<string, any>) => {
            // console.log({ stage, data, businessRuleTypes });
            return checkIfStagePasses({
                stage: stage as any,
                progressData: data,
                stageRuleGroups: stageRuleGroupsQuery.data ?? [],
                businessRuleGroups: businessRuleGroupsQuery.data ?? [],
                businessRuleTypes,
                // businessRules: businessRulesQuery.data ?? [],
            })?.passed;
        },
        [businessRuleGroupsQuery.data, businessRuleTypes, stageRuleGroupsQuery.data],
    );

    const getStepsForStageId = useCallback(
        (stageId: number) => {
            const currentPosition = expectCurrentPosition();
            const stageProcessflows = getProcessflowsForStageId(stageId);
            const currentStepIndex = stageProcessflows.findIndex((pf) => pf.id === currentPosition.processflowId);
            const steps: Step[] = stageProcessflows.map((pf, stepIndex) => ({
                id: pf.id,
                name: pf.title,
                hideIcon: isMobile || isTablet,
                status:
                    currentPosition.processflowId === pf.id
                        ? "current"
                        : stepIndex < currentStepIndex
                        ? "complete"
                        : "upcoming",
                action: () => {
                    handlePositionChange({
                        ...currentPosition,
                        processflowId: pf.id,
                    });
                },
            }));
            return steps.filter((step: Step) => {
                const pf = processflows.find((pf) => pf.id === step.id);
                if (!pf) {
                    throw new Error("processflow not found");
                }
                return doesProcessflowPass(pf as any, data);
            });
        },
        [
            data,
            doesProcessflowPass,
            expectCurrentPosition,
            getProcessflowsForStageId,
            handlePositionChange,
            isMobile,
            isTablet,
            processflows,
        ],
    );

    const handleButtonClick = useCallback(
        async (action: ButtonActionTypes) => {
            const emailField = "Email "; // Trailing space is intentional.
            setEntryErrors({});
            setSubmitCount(submitCount + 1);
            const currentPosition = expectCurrentPosition();
            const processflowsForCurrentStage = getProcessflowsForStageId(currentPosition.processflowStageId);
            if (enableValidation) {
                const entries = businessRuleTypes
                    ? getEntriesForProcessFlowId({
                          processflowId: currentPosition.processflowId,
                          businessRuleTypes: businessRuleTypes,
                      })
                    : [];
                const expectedEntries = entries.filter(
                    (entry) => !!validateEntry({ entry, businessRuleTypes, data }).passed,
                );
                const failingEntries = expectedEntries.filter((entry) => {
                    return !!entry.required && !data[entry.field];
                });
                const errors = failingEntries.reduce((acc, curr) => {
                    return { ...acc, [curr.field]: ["Required"] };
                }, {});
                if (Object.keys(errors).length > 0) {
                    setEntryErrors(errors);
                    return;
                }
            }
            const actionMap: Record<string, () => void> = {
                addRoleGroup: async () => {
                    const button = JSON.parse(localStorage.getItem("button") || "")?.roleGroupIdToAdd;
                    await roleGroupService.postURL("role-group-users", {
                        clearErcUserId: user.id,
                        roleGroupsId: button, // set to affiliate role group
                    });
                    const r = await roleGroupService.getURL(
                        "role-group-users?filter=" +
                            JSON.stringify({
                                where: {
                                    clearErcUserId: user.id,
                                    roleGroupsId: button, // find affiliate in training entries
                                },
                            }),
                    );
                    console.log({ r });
                    const response = await usersService.getOne(user.id as string);
                    const updatedUser = response?.data as ClearERCUser;
                    setUser(updatedUser as ClearERCUser);
                    authService.user = updatedUser;
                    localStorage.setItem("user", JSON.stringify({ ...updatedUser, token: authToken }));
                    const rbac_response = await authService.API.getURL(`users/${user.id}/roles`);
                    authService.roles = rbac_response?.data;
                },
                download2848Page1: async () => {
                    // Download the png file from the API and save it to the user's computer using axios post
                    const response = await axios.post(
                        `${process.env.REACT_APP_API_URL}/get-2848-1`,
                        { processflowId },
                        {
                            headers: {
                                Authorization: `Bearer ${authToken}`,
                            },
                            responseType: "blob",
                        },
                    );
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", "2848-1.png");
                    document.body.appendChild(link);
                    link.click();

                    console.log(response);
                },
                download2848Page2: async () => {
                    const [usersProgress] = await getProcessflowProgresses({
                        authToken,
                        filters: { id: processflowId, group },
                    });
                    const response = await axios.post(
                        `${process.env.REACT_APP_API_URL}/get-2848-2`,
                        { processflowId },
                        {
                            headers: {
                                Authorization: `Bearer ${authToken}`,
                            },
                            responseType: "blob",
                        },
                    );
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", "2848-2.png");
                    document.body.appendChild(link);
                    link.click();
                },
                download911Page1: async () => {
                    const [usersProgress] = await getProcessflowProgresses({
                        authToken,
                        filters: { id: processflowId, group },
                    });
                    const response = await axios.post(
                        `${process.env.REACT_APP_API_URL}/get-911-1`,
                        { processflowId },
                        {
                            headers: {
                                Authorization: `Bearer ${authToken}`,
                            },
                            responseType: "blob",
                        },
                    );
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", "911-1.png");
                    document.body.appendChild(link);
                    link.click();
                },
                download911Page2: async () => {
                    const [usersProgress] = await getProcessflowProgresses({
                        authToken,
                        filters: { id: processflowId, group },
                    });
                    const response = await axios.post(
                        `${process.env.REACT_APP_API_URL}/get-911-2`,
                        { processflowId },
                        {
                            headers: {
                                Authorization: `Bearer ${authToken}`,
                            },
                            responseType: "blob",
                        },
                    );
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", "911-2.png");
                    document.body.appendChild(link);
                    link.click();
                },
                download911Page3: async () => {
                    const [usersProgress] = await getProcessflowProgresses({
                        authToken,
                        filters: { id: processflowId, group },
                    });
                    const response = await axios.post(
                        `${process.env.REACT_APP_API_URL}/get-911-3`,
                        { processflowId },
                        {
                            headers: {
                                Authorization: `Bearer ${authToken}`,
                            },
                            responseType: "blob",
                        },
                    );
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", "911-3.png");
                    document.body.appendChild(link);
                    link.click();
                },
                download911Page4: async () => {
                    const [usersProgress] = await getProcessflowProgresses({
                        authToken,
                        filters: { id: processflowId, group },
                    });
                    const response = await axios.post(
                        `${process.env.REACT_APP_API_URL}/get-911-4`,
                        { processflowId },
                        {
                            headers: {
                                Authorization: `Bearer ${authToken}`,
                            },
                            responseType: "blob",
                        },
                    );
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", "911-4.png");
                    document.body.appendChild(link);
                    link.click();
                },
                getRandDReasons: async () => {
                    // Check to make sure they've filled in one of the two fields
                    const [usersProgress] = await getProcessflowProgresses({
                        authToken,
                        filters: { id: processflowId, group },
                    });
                    const entries = usersProgress?.data?.entriesByField;
                    const industry = entries?.["Industry "]?.fieldValue;
                    const industryAlternate = entries?.["Industry If no NAICS code"]?.fieldValue;

                    console.log({ industry, industryAlternate });
                    // If not do swal
                    if (!industry && !industryAlternate) {
                        SwalNeogenFire({
                            title: "Missing Information",
                            text: "Please fill in the Industry",
                            icon: "error",
                            timer: 5000,
                            showConfirmButton: false,
                            showCancelButton: false,
                            okButtonText: "Ok",
                        });
                    } else {
                        setRAndDData({ industry, industryAlternate });
                        setShowRandDModal(true);
                    }
                },
                getIssues: async () => {
                    // Check to make sure they've filled in one of the two fields
                    const [usersProgress] = await getProcessflowProgresses({
                        authToken,
                        filters: { id: processflowId, group },
                    });
                    const entries = usersProgress?.data?.entriesByField;
                    const industry = entries?.["Industry "]?.fieldValue;
                    const industryAlternate = entries?.["Industry If no NAICS code"]?.fieldValue;

                    console.log({ industry, industryAlternate });
                    // If not do swal
                    if (!industry && !industryAlternate) {
                        SwalNeogenFire({
                            title: "Missing Information",
                            text: "Please fill in the Industry",
                            icon: "error",
                            timer: 5000,
                            showConfirmButton: false,
                            showCancelButton: false,
                            okButtonText: "Ok",
                        });
                    } else {
                        setRAndDData({ industry, industryAlternate });
                        setShowIssuesModal(true);
                    }
                },
                nextStep: () => {
                    console.log("nextStep");
                    const processflows = processflowsForCurrentStage.filter((pf) =>
                        doesProcessflowPass(pf as any, data),
                    );
                    console.log({ processflows });
                    const currentProcessflowIndex = processflows.findIndex(
                        (pf) => pf.id === currentPosition.processflowId,
                    );
                    const nextProcessflow = processflows[currentProcessflowIndex + 1];
                    if (nextProcessflow) {
                        handlePositionChange({
                            ...currentPosition,
                            processflowId: nextProcessflow.id,
                        });
                    } else {
                        console.log("No next processflow found");
                        // Go through stages and make sure they pass
                        const passingStages = processflowStages.filter((stage) => doesStagePass(stage, data));
                        console.log({ passingStages });
                        let pfs;
                        let nextStage;
                        let offset = 1;
                        while (!pfs) {
                            nextStage = processflowStages[currentProcessflowStageIndex + offset];
                            if (!processflowStages[currentProcessflowStageIndex + offset]) {
                                console.error("No next stage found");
                                throw new Error("No next stage or processflows found");
                            }
                            if (!passingStages.includes(nextStage)) {
                                offset++;
                                console.log({ currentProcessflowStageIndex });
                                continue;
                            }
                            if (!nextStage) {
                                throw new Error("No next stage found");
                            }
                            pfs = getProcessflowsForStageId(nextStage.id).filter((pf) =>
                                doesProcessflowPass(pf as any, data),
                            );
                        }
                        console.log({ pfs });

                        if (nextStage && pfs) {
                            handlePositionChange({
                                ...currentPosition,
                                processflowId: pfs[0].id,
                                processflowStageId: nextStage.id,
                            });
                        } else {
                            throw new Error("No next stage or processflows found");
                        }
                    }
                    setSubmitCount(0);
                },
                nextStepAndAlert: () => {
                    const alertUsers = JSON.parse(localStorage.getItem("button") || "")?.alertUsers;

                    const replaceTemplatesInMessage = (message: string) => {
                        const firstNameTemplate = "{{firstName}}";
                        const firstName = user.firstName || "";
                        const lastNameTemplate = "{{lastName}}";
                        const lastName = user.lastName || "";
                        const emailTemplate = "{{email}}";
                        const email = user.email || "";
                        const companyNameTemplate = "{{companyName}}";
                        const companyName = company?.name || "";
                        const companyIdTemplate = "{{companyId}}";
                        const companyId = company?.id.toString() || "";

                        return message
                            .replace(firstNameTemplate, firstName)
                            .replace(lastNameTemplate, lastName)
                            .replace(emailTemplate, email)
                            .replace(companyIdTemplate, companyId)
                            .replace(companyNameTemplate, companyName);
                    };

                    alertUsers.map((au: AlertUser) => {
                        if (!au) {
                            return;
                        }

                        if (au?.alertUserType === "user") {
                            if (au?.alertTypes?.inApp) {
                                createAlert({
                                    authToken,
                                    data: {
                                        userId: au.userId,
                                        channel: "app",
                                        title: au.alertTitle,
                                        body: replaceTemplatesInMessage(au.alertMessage ?? ""),
                                        link: replaceTemplatesInMessage(au.alertLink ?? ""),
                                    },
                                });
                            }

                            if (au?.alertTypes?.email) {
                                createAlert({
                                    authToken,
                                    data: {
                                        userId: au.userId,
                                        channel: "email",
                                        title: au.alertTitle,
                                        body: replaceTemplatesInMessage(au.alertMessage ?? ""),
                                        link: replaceTemplatesInMessage(au.alertLink ?? ""),
                                    },
                                });
                            }

                            if (au?.alertTypes?.twilio) {
                                createAlert({
                                    authToken,
                                    data: {
                                        userId: au.userId,
                                        channel: "twilio",
                                        title: au.alertTitle,
                                        body: replaceTemplatesInMessage(au.alertMessage ?? ""),
                                        link: replaceTemplatesInMessage(au.alertLink ?? ""),
                                    },
                                });
                            }
                        } else if (au?.alertUserType === "roleGroup") {
                            if (au?.alertTypes?.inApp) {
                                createAlert({
                                    authToken,
                                    data: {
                                        roleGroupId: au.roleGroupId,
                                        channel: "app",
                                        title: au.alertTitle,
                                        body: replaceTemplatesInMessage(au.alertMessage ?? ""),
                                        link: replaceTemplatesInMessage(au.alertLink ?? ""),
                                    },
                                });
                            }

                            if (au?.alertTypes?.email) {
                                createAlert({
                                    authToken,
                                    data: {
                                        roleGroupId: au.roleGroupId,
                                        channel: "email",
                                        title: au.alertTitle,
                                        body: replaceTemplatesInMessage(au.alertMessage ?? ""),
                                        link: replaceTemplatesInMessage(au.alertLink ?? ""),
                                    },
                                });
                            }
                        }
                    });

                    const processflows = processflowsForCurrentStage.filter((pf) =>
                        doesProcessflowPass(pf as any, data),
                    );
                    const currentProcessflowIndex = processflows.findIndex(
                        (pf) => pf.id === currentPosition.processflowId,
                    );
                    const nextProcessflow = processflows[currentProcessflowIndex + 1];
                    if (nextProcessflow) {
                        handlePositionChange({
                            ...currentPosition,
                            processflowId: nextProcessflow.id,
                        });
                    } else {
                        const nextStage = processflowStages[currentProcessflowStageIndex + 1];
                        const pfs = getProcessflowsForStageId(nextStage.id).filter((pf) =>
                            doesProcessflowPass(pf as any, data),
                        );
                        handlePositionChange({
                            ...currentPosition,
                            processflowId: pfs[0].id,
                            processflowStageId: nextStage.id,
                        });
                    }
                    setSubmitCount(0);
                },
                nextStepAndSendSlackMessage: async () => {
                    const slackUrl = JSON.parse(localStorage.getItem("button") || "")?.slackUrl;
                    const slackHeader = JSON.parse(localStorage.getItem("button") || "")?.slackHeader;
                    const slackMessage = JSON.parse(localStorage.getItem("button") || "")?.slackMessage;

                    const replaceVariables = (message: string, variables: { [key: string]: string }) => {
                        for (const [variable, value] of Object.entries(variables)) {
                            const placeholder = `{{${variable}}}`;
                            message = message.replace(new RegExp(placeholder, "g"), value);
                        }
                        return message;
                    };

                    const variables: { [key: string]: string } = {
                        companyName: userCompany.data?.name || "Company name unknown",
                        email: loggedInUser.email,
                        firstName: loggedInUser.firstName || "-",
                        lastName: loggedInUser.lastName || "-",
                    };

                    // eslint-disable-next-line no-case-declarations
                    const message: string = replaceVariables(slackMessage, variables);

                    try {
                        sendSlackMessage(slackUrl, slackHeader, message);
                        console.log("Message pushed to Slack successfully", message, slackHeader);
                    } catch (error) {
                        console.error("Error pushing message to Slack:", error, message, slackHeader);
                    }

                    const processflows = processflowsForCurrentStage.filter((pf) =>
                        doesProcessflowPass(pf as any, data),
                    );
                    const currentProcessflowIndex = processflows.findIndex(
                        (pf) => pf.id === currentPosition.processflowId,
                    );
                    const nextProcessflow = processflows[currentProcessflowIndex + 1];
                    if (nextProcessflow) {
                        handlePositionChange({
                            ...currentPosition,
                            processflowId: nextProcessflow.id,
                        });
                    } else {
                        const nextStage = processflowStages[currentProcessflowStageIndex + 1];
                        const pfs = getProcessflowsForStageId(nextStage.id).filter((pf) =>
                            doesProcessflowPass(pf as any, data),
                        );
                        handlePositionChange({
                            ...currentPosition,
                            processflowId: pfs[0].id,
                            processflowStageId: nextStage.id,
                        });
                    }
                    setSubmitCount(0);
                },
                previousStep: () => {
                    console.log("nextStep");
                    const processflows = processflowsForCurrentStage.filter((pf) =>
                        doesProcessflowPass(pf as any, data),
                    );
                    console.log({ processflows });
                    const currentProcessflowIndex = processflows.findIndex(
                        (pf) => pf.id === currentPosition.processflowId,
                    );
                    const nextProcessflow = processflows[currentProcessflowIndex - 1];
                    if (nextProcessflow) {
                        handlePositionChange({
                            ...currentPosition,
                            processflowId: nextProcessflow.id,
                        });
                    } else {
                        console.log("No previous processflow found");
                        // Go through stages and make sure they pass
                        const passingStages = processflowStages.filter((stage) => doesStagePass(stage, data));
                        console.log({ passingStages });
                        let pfs;
                        let prevStage;
                        let offset = 1;
                        while (!pfs) {
                            prevStage = processflowStages[currentProcessflowStageIndex - offset];
                            if (!processflowStages[currentProcessflowStageIndex - offset]) {
                                console.error("No previous stage found");
                                throw new Error("No previous stage or processflows found");
                            }
                            if (!passingStages.includes(prevStage)) {
                                // Increases because you minus it
                                offset++;
                                console.log({ currentProcessflowStageIndex });
                                continue;
                            }
                            if (!prevStage) {
                                throw new Error("No previous stage found");
                            }
                            pfs = getProcessflowsForStageId(prevStage.id).filter((pf) =>
                                doesProcessflowPass(pf as any, data),
                            );
                        }
                        console.log({ pfs });

                        if (prevStage && pfs) {
                            handlePositionChange({
                                ...currentPosition,
                                processflowId: pfs[0].id,
                                processflowStageId: prevStage.id,
                            });
                        } else {
                            throw new Error("No next stage or processflows found");
                        }
                    }
                    setSubmitCount(0);
                },
                nextStage: () => {
                    const passingStages = processflowStages.filter((stage) => doesStagePass(stage, data));
                    console.log({ passingStages });
                    let index = currentProcessflowStageIndex;
                    index++;
                    let nextStage = processflowStages[index];
                    while (!doesStagePass(nextStage, data)) {
                        index++;
                        nextStage = processflowStages[index];
                        if (index > processflowStages.length) {
                            throw new Error("No next stage found");
                        }
                    }
                    console.log({ ds: doesStagePass(nextStage, data) });
                    const processflows = getProcessflowsForStageId(nextStage.id).filter((pf) =>
                        doesProcessflowPass(pf as any, data),
                    );
                    handlePositionChange({
                        processflowStageId: nextStage.id,
                        processflowId: processflows[0].id,
                    });
                    setSubmitCount(0);
                },
                previousStage: () => {
                    // find the index of the current item
                    const currentStage = processflowStages[currentProcessflowStageIndex];
                    const passingStages = processflowStages.filter((stage) => doesStagePass(stage, data));
                    console.log({ passingStages });
                    let index = passingStages.findIndex((stage) => stage.id === currentStage.id);
                    const currentIndex = index;
                    console.log({ index });
                    console.log({ c: passingStages[index] });
                    index--;
                    let previousStage = passingStages[index];
                    while (!doesStagePass(previousStage, data)) {
                        index--;
                        previousStage = passingStages[index];
                        if (index < 0) {
                            console.error({ currentIndex, index });
                            throw new Error("No previous stage found");
                        }
                    }
                    if (!previousStage) {
                        previousStage = passingStages[0];
                    }
                    const availableProcessflows = getProcessflowsForStageId(previousStage?.id);
                    console.log({ availableProcessflows });
                    handlePositionChange({
                        processflowStageId: previousStage.id,
                        processflowId: availableProcessflows[availableProcessflows.length - 1].id,
                    });
                    setSubmitCount(0);
                },
                openBillingModal: () => {
                    setShowNewInvoiceFlowModal(true);
                },
                showTaxPayerAdvocateModal: () => {
                    setShowTaxPayerAdvocateModal(true);
                },
                updateAggregateGrossReceipts: async () => {
                    const response = await companyService.getFilteredWhere({ id: companyId });
                    if (response) {
                        const group = response.data?.[0]?.group;
                        if (group) {
                            const responseAggregate = await retrieveQualificationForRevenueReductionForAggregate({
                                authToken,
                                data: {
                                    companyGroup: group,
                                },
                            });
                            const USDollar = new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD",
                            });
                            const queryResultNumbersMapping: Record<string, string | undefined> = {
                                "Aggregate Gross receipts Q1 2019": USDollar.format(responseAggregate?.Q1_2019?.value),
                                "Aggregate Gross receipts Q2 2019": USDollar.format(responseAggregate?.Q2_2019?.value),
                                "Aggregate Gross receipts Q3 2019": USDollar.format(responseAggregate?.Q3_2019?.value),
                                "Aggregate Gross receipts Q4 2019": USDollar.format(responseAggregate?.Q4_2019?.value),
                                "Aggregate Gross receipts Q1 2020": USDollar.format(responseAggregate?.Q1_2020?.value),
                                "Aggregate Gross receipts Q2 2020": USDollar.format(responseAggregate?.Q2_2020?.value),
                                "Aggregate Gross receipts Q3 2020": USDollar.format(responseAggregate?.Q3_2020?.value),
                                "Aggregate Gross receipts Q4 2020": USDollar.format(responseAggregate?.Q4_2020?.value),
                                "Aggregate Gross receipts Q1 2021": USDollar.format(responseAggregate?.Q1_2021?.value),
                                "Aggregate Gross receipts Q2 2021": USDollar.format(responseAggregate?.Q2_2021?.value),
                                "Aggregate Gross receipts Q3 2021": USDollar.format(responseAggregate?.Q3_2021?.value),
                                "Aggregate Gross receipts Q4 2021": USDollar.format(responseAggregate?.Q4_2021?.value),
                            };
                            const queryResultMapping: Record<string, string | undefined> = {
                                "Aggregate Q1 2020 Qualified": responseAggregate?.Q1_2020?.qualifies ? "Yes" : "No",
                                "Aggregate Q2 2020 Qualified": responseAggregate?.Q2_2020?.qualifies ? "Yes" : "No",
                                "Aggregate Q3 2020 Qualified": responseAggregate?.Q3_2020?.qualifies ? "Yes" : "No",
                                "Aggregate Q4 2020 Qualified": responseAggregate?.Q4_2020?.qualifies ? "Yes" : "No",
                                "Aggregate Q1 2021 Qualified": responseAggregate?.Q1_2021?.qualifies ? "Yes" : "No",
                                "Aggregate Q2 2021 Qualified": responseAggregate?.Q2_2021?.qualifies ? "Yes" : "No",
                                "Aggregate Q3 2021 Qualified": responseAggregate?.Q3_2021?.qualifies ? "Yes" : "No",
                                "Aggregate Q4 2021 Qualified": responseAggregate?.Q4_2021?.qualifies ? "Yes" : "No",
                                "Aggregate Gross receipts Q1 2020_chipLabel": responseAggregate?.Q1_2020?.qualifies
                                    ? "Qualified"
                                    : "Not qualified",
                                "Aggregate Gross receipts Q2 2020_chipLabel": responseAggregate?.Q2_2020?.qualifies
                                    ? "Qualified"
                                    : "Not qualified",
                                "Aggregate Gross receipts Q3 2020_chipLabel": responseAggregate?.Q3_2020?.qualifies
                                    ? "Qualified"
                                    : "Not qualified",
                                "Aggregate Gross receipts Q4 2020_chipLabel": responseAggregate?.Q4_2020?.qualifies
                                    ? "Qualified"
                                    : "Not qualified",
                                "Aggregate Gross receipts Q1 2021_chipLabel": responseAggregate?.Q1_2021?.qualifies
                                    ? "Qualified"
                                    : "Not qualified",
                                "Aggregate Gross receipts Q2 2021_chipLabel": responseAggregate?.Q2_2021?.qualifies
                                    ? "Qualified"
                                    : "Not qualified",
                                "Aggregate Gross receipts Q3 2021_chipLabel": responseAggregate?.Q3_2021?.qualifies
                                    ? "Qualified"
                                    : "Not qualified",
                                "Aggregate Gross receipts Q4 2021_chipLabel": responseAggregate?.Q4_2021?.qualifies
                                    ? "Qualified"
                                    : "Not qualified",
                            };

                            const queryReasonMapping: Record<string, string | undefined> = {
                                "Aggregate Reason Q1 2020 qualified system": responseAggregate?.Q1_2020?.reason,
                                "Aggregate Reason Q2 2020 qualified system": responseAggregate?.Q2_2020?.reason,
                                "Aggregate Reason Q3 2020 qualified system": responseAggregate?.Q3_2020?.reason,
                                "Aggregate Reason Q4 2020 qualified system": responseAggregate?.Q4_2020?.reason,
                                "Aggregate Reason Q1 2021 qualified system": responseAggregate?.Q1_2021?.reason,
                                "Aggregate Reason Q2 2021 qualified system": responseAggregate?.Q2_2021?.reason,
                                "Aggregate Reason Q3 2021 qualified system": responseAggregate?.Q3_2021?.reason,
                                "Aggregate Reason Q4 2021 qualified system": responseAggregate?.Q4_2021?.reason,
                            };
                            const updatedData = {
                                ...queryResultMapping,
                                ...queryReasonMapping,
                                ...queryResultNumbersMapping,
                            };

                            const updatedProgressData: ProcessFlowProgressData = {
                                group,
                                completedSteps: [],
                                ...progressData,
                                entriesByField: Object.keys(updatedData).reduce((acc, fieldKey: string) => {
                                    return {
                                        ...acc,
                                        [fieldKey]: {
                                            fieldName: fieldKey,
                                            // @ts-ignore
                                            fieldValue: updatedData[fieldKey],
                                            // Hide fields which will be reset based on current entry change.
                                            isHidden: false,
                                        },
                                    };
                                }, progressData?.entriesByField || {}),
                            };
                            console.error(JSON.stringify(updatedProgressData));
                            if (progressData) {
                                handleDataChange(getDataFromProgressData(updatedProgressData), updatedProgressData);
                            }
                        }
                    }
                },
                completeAffiliateTraining: async () => {
                    if (user.id) {
                        await roleGroupService.postURL("role-group-users", {
                            clearErcUserId: user.id,
                            roleGroupsId: 7, // set to affiliate role group
                        });

                        const r = await roleGroupService.getURL(
                            "role-group-users?filter=" +
                                JSON.stringify({
                                    where: {
                                        clearErcUserId: user.id,
                                        roleGroupsId: 12, // find affiliate in training entries
                                    },
                                }),
                        );

                        // Delete any affiliate in training entries for the user
                        if (r?.data && r.data?.length > 0) {
                            await Promise.all(
                                r.data.map((item: any) => roleGroupService.deleteURL(`role-group-users/${item.id}`)),
                            );
                        }

                        const response = await usersService.getOne(user.id as string);
                        const updatedUser = response?.data as ClearERCUser;
                        setUser(updatedUser as ClearERCUser);
                        authService.user = updatedUser;
                        localStorage.setItem("user", JSON.stringify({ ...updatedUser, token: authToken }));
                        const rbac_response = await authService.API.getURL(`users/${user.id}/roles`);
                        authService.roles = rbac_response?.data;
                        authService.roles.push({ roleCode: "URL_LOGOUT" }); // Everyone can log out
                        authService.roles.push({ roleCode: "URL_DASHBOARD" }); // Everyone can log out

                        window.location.href = "/dashboard";
                    }
                },
                redirect: async () => {
                    let url = JSON.parse(localStorage.getItem("button") || "")?.urlToRedirectTo;
                    if (url.includes("{{")) {
                        // replace the field with the value
                        const [usersProgress] = await getProcessflowProgresses({
                            authToken,
                            filters: { userId: user.id, group },
                        });
                        const data = usersProgress?.data;
                        const entriesByField = data?.entriesByField;
                        if (entriesByField) {
                            const entryKeys = Object.keys(entriesByField);
                            let newUrl = url;
                            entryKeys.forEach((key) => {
                                newUrl = newUrl.replace(`{{${key}}}`, entriesByField[key].fieldValue);
                            });
                            url = newUrl;
                        }
                    }
                    if (url) {
                        // navigate(url);
                        window.location.href = url;
                    }
                },
                createAccountSendConfirmation: async () => {
                    // This is where email should come from:
                    if (!user) {
                        console.error("User not defined");
                        throw new Error("User not defined");
                    }
                    if (!user.id) {
                        console.error("User id not defined");
                        throw new Error("User id not defined");
                    }
                    // TODO: these should not be hardcoded - they should come from the button object above
                    const email = data[emailField];
                    try {
                        z.string().email().parse(email);
                    } catch (e) {
                        setEntryErrors({
                            ...entryErrors,
                            [emailField]: ["Invalid email address"],
                        });
                        throw new Error("Please enter a valid email address.");
                    }

                    let affiliateUserId = "";
                    if (utm) {
                        try {
                            // Get the affiliate associated with the UTM code
                            const response = await utmLinkService.getFilteredWhere({ code: utm });
                            if (response) {
                                const affiliate = response.data[0].owner;
                                if (affiliate) {
                                    // Associate the affiliate with the user
                                    // await affiliateService.update(user.id, { affiliate });
                                    affiliateUserId = affiliate;
                                }
                            }
                        } catch (e) {
                            console.error(e);
                        }
                    }
                    // TODO: these should not be hardcoded
                    const newUserData = {
                        id: user.id,
                        email,
                        name: data["Company name"],
                        firstName: data["First name"],
                        lastName: data["Last name"],
                        company: data["Company name"],
                        phoneNumber: data["Phone number"],
                        utm: utm,
                        affiliateUserId,
                        isUnderAudit: data["Is the ERC claim under audit with IRS"],
                        totalRefund: data["Total ERC Refund"],
                    };

                    try {
                        setSubmitting(true);
                        const currentProcessflowIndex = processflowsForCurrentStage.findIndex(
                            (pf) => pf.id === currentPosition.processflowId,
                        );
                        let newUser;
                        if (group === 9) {
                            newUser = await triggerRegistration({
                                userId: newUserData.id,
                                email: newUserData.email,
                                name: newUserData.name,
                                firstName: newUserData.firstName,
                                lastName: newUserData.lastName,
                                phoneNumber: newUserData.phoneNumber,
                                utm: utm ?? "",
                                affiliateUserId: affiliateUserId ?? "",
                                defaultProcessflowId: 7,
                                site: "clear-erc",
                                isUnderAudit: "",
                                totalRefund: "",
                            });
                        } else if (group === 27) {
                            newUser = await triggerRegistration({
                                userId: newUserData.id,
                                email: newUserData.email,
                                name: newUserData.name,
                                firstName: newUserData.firstName,
                                lastName: newUserData.lastName,
                                phoneNumber: newUserData.phoneNumber,
                                utm: utm ?? "",
                                affiliateUserId: affiliateUserId ?? "",
                                defaultProcessflowId: 28,
                                site: "erc-repair",
                                isUnderAudit: newUserData.isUnderAudit,
                                totalRefund: newUserData.totalRefund,
                            });
                        } else if (group === 14) {
                            newUser = await triggerAffiliateRegistration({
                                userId: newUserData.id,
                                email: newUserData.email,
                                firstName: newUserData.firstName,
                                name: newUserData.name,
                                lastName: newUserData.lastName,
                                phoneNumber: newUserData.phoneNumber,
                                utm: utm ?? "",
                                affiliateUserId: affiliateUserId ?? "",
                                defaultProcessflowId: 11,
                            });
                        }
                        const updatedUser = { ...user, ...newUser };

                        const handleLogging = async () => {
                            let geoData: Record<string, any> = {};
                            try {
                                // This request is sometimes blocked by ad-blockers (AdBlock, uBlock etc)
                                geoData = await getGeoData();
                            } catch (error) {
                                console.error("Failed to get geolocation data.", error);
                            }
                            try {
                                if (currentProcessflowIndex === 14) {
                                    sendSlackDebug(
                                        `Affiliate UTM Registration: ${utm}\nCountry:  ${
                                            geoData?.country_name
                                        }\nCity: ${geoData?.city}\nState: ${geoData?.state}\nIP: ${
                                            geoData?.IPv4
                                        }\nURL: ${window.location}\nReferrer: ${
                                            document.referrer
                                        }\nnewUser: ${JSON.stringify(newUserData)}`,
                                    );
                                } else {
                                    sendSlackDebug(
                                        `UTM Registration: ${utm}\nCountry:  ${geoData?.country_name}\nCity: ${
                                            geoData?.city
                                        }\nState: ${geoData?.state}\nIP: ${geoData?.IPv4}\nURL: ${
                                            window.location
                                        }\nReferrer: ${document.referrer}\nnewUser: ${JSON.stringify(newUserData)}`,
                                    );
                                }
                                sendSlackSale(
                                    `UTM Registration: ${utm}\nCountry:  ${geoData?.country_name}\nCity: ${
                                        geoData?.city
                                    }\nState: ${geoData?.state}\nIP: ${geoData?.IPv4}\nURL: ${
                                        window.location
                                    }\nReferrer: ${document.referrer}\nnewUser: ${JSON.stringify(newUserData)}`,
                                );

                                const ip = geoData?.IPv4 ?? "Unknown";
                                const utmLog: UTMLog = {
                                    utm: utm ?? "Unknown",
                                    ipAddress: ip,
                                    data: JSON.stringify(newUserData),
                                    eventDatetime: new Date(),
                                    userId: updatedUser.id as string,
                                    site: "",
                                    notes: `UTM Registration: ${utm}\nCountry:  ${geoData?.country_name}\nCity: ${
                                        geoData?.city
                                    }\nState: ${geoData?.state}\nIP: ${geoData?.IPv4}\nURL: ${
                                        window.location
                                    }\nReferrer: ${document.referrer}\nnewUser: ${JSON.stringify(newUserData)}`,
                                };
                                utmLogService.create(utmLog);
                            } catch (e) {
                                console.error(e);
                            }
                        };

                        // Not awaited to prevent slowdowns
                        handleLogging();

                        const nextProcessflow = processflowsForCurrentStage[currentProcessflowIndex + 1];
                        const newPosition = {
                            ...currentPosition,
                            processflowId: nextProcessflow.id,
                        };
                        const nextProcessflowId = nextProcessflow.id;
                        const [usersProgress] = await getProcessflowProgresses({
                            authToken,
                            filters: { userId: user.id, group },
                        });
                        handlePositionChange({
                            ...currentPosition,
                            processflowId: nextProcessflowId,
                        });

                        if (usersProgress) {
                            const updatedProgressData = {
                                ...progressData,
                                currentStage: newPosition.processflowStageId,
                                currentStep: newPosition.processflowId,
                            };
                            await updateProgressAction.execute(updatedProgressData, updatedUser.id);
                        }

                        setUser(updatedUser as ClearERCUser);
                        localStorage.setItem("user", JSON.stringify(updatedUser));
                        setSubmitCount(0);
                    } catch (e) {
                        console.error(JSON.stringify((e as Error).message));
                        sendSlackDebug(
                            JSON.stringify({
                                error: (e as Error).toString(),
                                newUser: newUserData,
                                utm,
                            }),
                        );
                        throw e;
                    }
                    setSubmitting(false);
                },
            };

            const f = actionMap[action];

            if (!f) {
                throw new Error(`No button action defined for '${action}'`);
            }

            setError(undefined);
            try {
                await f();
            } catch (error) {
                console.error(error);
                if ((error as Error).message.includes("ER_DUP_ENTRY")) {
                    (error as Error).message =
                        "An account with this email address already exists. Please login or reset your password.";
                }
                setError(error);
                setEntryErrors({
                    ...entryErrors,
                    [emailField]: ["Invalid email address"],
                });
            }
            setSubmitting(false);
        },
        [
            authToken,
            businessRuleTypes,
            company?.id,
            company?.name,
            companyId,
            currentProcessflowStageIndex,
            data,
            doesProcessflowPass,
            doesStagePass,
            enableValidation,
            entryErrors,
            expectCurrentPosition,
            getEntriesForProcessFlowId,
            getProcessflowsForStageId,
            group,
            handleDataChange,
            handlePositionChange,
            loggedInUser.email,
            loggedInUser.firstName,
            loggedInUser.lastName,
            processflowId,
            processflowStages,
            progressData,
            setUser,
            submitCount,
            updateProgressAction,
            user,
            userCompany.data?.name,
            utm,
        ],
    );

    useEffect(() => {
        const timer = setTimeout(() => {
            setFakeWizardTimeout(true);
        }, 1);
        return () => clearTimeout(timer);
    }, []);

    const steps = position ? getStepsForStageId(position.processflowStageId) : undefined;

    const entries =
        position && businessRuleTypes
            ? getEntriesForProcessFlowId({
                  processflowId: position.processflowId,
                  businessRuleTypes: businessRuleTypes,
              })
            : undefined;

    const stages = processflowStages.length > 0 ? processflowStages : undefined;

    const allSteps = useMemo(() => {
        let allSteps: any[] = [];

        stages?.forEach((stage) => {
            const steps = getProcessflowsForStageId(stage.id);
            const filteredSteps = steps.filter((step) => doesProcessflowPass(step as any, data));
            allSteps = [...allSteps, ...filteredSteps];
        });

        return allSteps;
    }, [data, doesProcessflowPass, getProcessflowsForStageId, stages]);

    const isLoading = !steps || !entries || !stages;

    const isReady =
        hasLoaded && steps && steps.length > 0 && entries && entries.length > 0 && stages && stages.length > 0;
    if (!isReady) {
        console.log({ hasLoaded, steps, entries, stages });
        if (hasLoaded && steps?.length === 0) {
            const firstStage = stages?.[0];
            const steps = getStepsForStageId(firstStage?.id ?? -1);
            handlePositionChange({
                processflowStageId: firstStage?.id ?? -1,
                processflowId: steps[0].id,
            });
        }
    }
    function changeCompanyAndProcessflow(
        id: number,
        usersProgress: {
            id: number;
            group: number;
            userId: string;
            currentStep?: number | null | undefined;
            currentStage?: number | null | undefined;
            status?: string | null | undefined;
            data?:
                | {
                      entriesByField: Record<
                          string,
                          {
                              fieldName: string;
                              fieldValue?: any;
                              isHidden?: boolean | undefined;
                          }
                      >;
                      completedSteps?: any[] | undefined;
                      currentStage?: number | null | undefined;
                      currentStep?: number | null | undefined;
                      numberOfRelevantInteractiveEntries?: number | null | undefined;
                      numberOfCompletedEntries?: number | null | undefined;
                      percentageComplete?: number | null | undefined;
                      furthestStageId?: number | null | undefined;
                  }
                | null
                | undefined;
            client?: number | null | undefined;
            createdAt?: Date | null | undefined;
            updatedAt?: Date | null | undefined;
            city?: string | null | undefined;
            county?: string | null | undefined;
            state?: string | null | undefined;
        },
    ) {
        setCompanyId(id);
        localStorage.setItem("companyId", id.toString());

        setProcessflowProgressId(usersProgress.id);

        setProgressData(usersProgress.data as any);
        setData(getDataFromProgressData(usersProgress.data as any));

        setShowModal(false);
    }
    return !isReady || isLoading || !position || !fakeWizardTimeout ? (
        <>{hideStages && hideSteps ? <FakeWizard /> : <Loader2 />}</>
    ) : (
        <>
            <ModalDialog
                size="md"
                title="Tax Payer Advocate Details"
                show={showTaxPayerAdvocateModal}
                close={() => setShowTaxPayerAdvocateModal(false)}
            >
                <TaxPayerAdvocate />
            </ModalDialog>
            {!passedCompanyId && companies.length > 1 && (
                <>
                    {user.roleGroups?.find((rg) => rg.id === 10) && (
                        <div className="bg-white rounded-lg p-3 text-center dark:bg-slate-800 pl-24 mb-5">
                            Debug Stage: <strong>{position.processflowStageId}</strong> Step:{" "}
                            <strong>{position.processflowId}</strong>
                        </div>
                    )}
                    <div className="bg-white rounded-lg p-3 text-center dark:bg-slate-800 pl-24">
                        {company?.name?.split(" ").map((word, idx) => (
                            <span key={idx}>
                                <span className="each-word mx-auto text-3xl font-medium uppercase antialiased text-indigo-700 dark:text-green-500 tracking-widest">
                                    {word.substring(0, 1)}
                                </span>
                                <span className="each-word mx-auto text-2xl font-medium uppercase antialiased text-indigo-700 dark:text-green-500 tracking-widest mr-5">
                                    {word.substring(1)}
                                </span>
                            </span>
                        ))}
                        <ButtonNeoGen
                            className="align-top mt-2"
                            size="xxs"
                            type="outline"
                            onClick={() => {
                                setShowModal(true);
                            }}
                        >
                            Change Company
                        </ButtonNeoGen>
                    </div>
                    <div className="pb-4 mt-0 w-auto sm:w-64 md:w-96 overflow-none "></div>

                    <ModalDialog
                        show={showModal}
                        close={() => {
                            setShowModal(false);
                        }}
                        showOk={false}
                        title="Change Company"
                    >
                        <div className="mb-5">
                            <Select
                                isClearable={false}
                                value={company?.id || null}
                                onChange={async (id: any) => {
                                    setLastActive(new Date());
                                    const company = companies.find((c) => c.id === id);
                                    if (company?.ownedById) {
                                        const usersProgresses = await getProcessflowProgresses({
                                            authToken,
                                            filters: { userId: company.ownedById, group },
                                        });
                                        const usersProgress = usersProgresses[0];
                                        if (usersProgresses.length === 1 && usersProgress) {
                                            changeCompanyAndProcessflow(id, usersProgress);
                                        } else {
                                            const newProgress = await createProcessflowProgress({
                                                authToken,
                                                data: {
                                                    userId: company.ownedById,
                                                    group,
                                                    currentStage: processflowStages[0].id,
                                                    currentStep: processflows[0].id,
                                                    data: {
                                                        completedSteps: [],
                                                        currentStage: processflowStages[0].id,
                                                        currentStep: processflows[0].id,
                                                        entriesByField: {},
                                                    },
                                                },
                                            });
                                            const usersProgresses = await getProcessflowProgresses({
                                                authToken,
                                                filters: { userId: company.ownedById, group },
                                            });
                                            const usersProgress = usersProgresses[0];
                                            if (usersProgresses.length === 1 && usersProgress) {
                                                setCompanyId(id);
                                                localStorage.setItem("companyId", id);

                                                setProcessflowProgressId(usersProgress.id);

                                                setProgressData(usersProgress.data as any);
                                                setData(getDataFromProgressData(usersProgress.data as any));

                                                setShowModal(false);
                                            } else {
                                                alert("No progress data for this company and group: " + group);
                                                setShowModal(false);
                                            }

                                            setShowModal(false);
                                        }
                                    } else {
                                        alert("No company owned by this user");
                                        setShowModal(false);
                                    }
                                }}
                                options={companies.map((company) => ({
                                    value: company.id,
                                    label: company.name || "No name",
                                }))}
                            />
                        </div>
                    </ModalDialog>
                </>
            )}
            {error && (
                <>
                    <ErrorSection errors={[error.message]} />
                </>
            )}
            {showNewInvoiceFlowModal && company && lineItems && (
                <NewInvoiceFlowModal
                    lineItems={lineItems}
                    company={company}
                    onClose={() => {
                        setShowNewInvoiceFlowModal(false);
                    }}
                />
            )}
            {showRandDModal && (
                <RandDModal rAndDData={rAndDData} show={showRandDModal} close={() => setShowRandDModal(false)} />
            )}
            {showIssuesModal && (
                <IssuesModal
                    rAndDData={rAndDData}
                    show={showIssuesModal}
                    close={() => setShowIssuesModal(false)}
                    handleDataChange={handleDataChange}
                    progressData={progressData}
                />
            )}
            {submitting ? (
                <Submitting />
            ) : (
                <WizardContext.Provider
                    value={{
                        companyId: company?.id,
                        processflowProgressId: processflowId,
                    }}
                >
                    <Wizard
                        changeCompanyAndProcessflow={changeCompanyAndProcessflow}
                        allSteps={allSteps}
                        stages={stages.map((stage, index) => {
                            const furthestStageId = progressData?.furthestStageId;
                            const furthestStageIndex = stages.findIndex((stage) => stage.id === furthestStageId);
                            const isPastStage = index <= furthestStageIndex;
                            return {
                                ...stage,
                                onClick: isPastStage
                                    ? (id) => {
                                          const steps = getStepsForStageId(id);
                                          handlePositionChange({
                                              processflowStageId: id,
                                              processflowId: steps[0].id,
                                          });
                                      }
                                    : undefined,
                            };
                        })}
                        group={group}
                        stageId={position.processflowStageId}
                        onStageChange={
                            canSkipStages
                                ? (stage) => {
                                      const steps = getStepsForStageId(stage.id);
                                      handlePositionChange({
                                          processflowStageId: stage.id,
                                          processflowId: steps[0].id,
                                      });
                                  }
                                : undefined
                        }
                        steps={steps}
                        stepId={position.processflowId}
                        entries={
                            position.processflowId === 353 ? [...entries.slice(0, 1), ...entries.slice(1)] : entries
                        }
                        progressData={progressData}
                        isOptional={processflows.find((pf) => pf.id === position.processflowId)?.optional}
                        entryErrors={entryErrors}
                        onEntryChange={({ entry, value }) => {
                            handleEntryChange({ entry, value });
                        }}
                        data={data}
                        onButtonClick={({ action }) => {
                            handleButtonClick(action);
                        }}
                        hideStages={hideStages}
                        hideSteps={hideSteps}
                        getFileKey={(entry: Entry) =>
                            JSON.stringify({ userId: user.id, group, entryField: entry.field })
                        }
                        companyId={companyId}
                    />
                    {group === 9 && progressData?.currentStep === 79 && (
                        <div className="text-sm pt-3">
                            <Link to="/login" className="text-gray-700 hover:text-indigo-500">
                                Already have an account? Sign in
                            </Link>
                        </div>
                    )}
                </WizardContext.Provider>
            )}
        </>
    );
};
