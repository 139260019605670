import { z } from "zod";
import { makeRequest, parseSchema } from "../../../jason-proof-of-concept/shared/utils";
import { referrerSchema } from "../domain/referrer";

export const getReferrers = async ({ authToken, filters }: { authToken: string; filters?: any }) => {
    const response = await makeRequest({
        method: "get",
        path: "/referrers",
        authToken,
        queryParams: { filter: JSON.stringify(filters) },
    });

    const referrers = parseSchema(z.array(referrerSchema), response.data);

    return referrers;
};
