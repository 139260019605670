import React from "react";
import PageDescription from "../../layout/page-description";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import stagesService from "../../services/stages.service";
import PrintPre from "../../layout/print-pre";
import AddStatusModal from "./modals/add-status-modal";
import companyProductStagesService, { CompanyProductStagesType } from "../../services/company-product-stages.service";
import TableNeogen from "../../layout/table-neogen";
import SwalNeogenFire from "../../layout/swal-neogen";

export default function ProductStatuses({ company, user }: { company: any; user: any }) {
    const [category, setCategory] = React.useState<any>();
    const [companyStage, setCompanyStage] = React.useState<CompanyProductStagesType | undefined>(undefined);
    const [showAddStatusModal, setShowAddStatusModal] = React.useState(false);
    const [showAll, setShowAll] = React.useState(false);
    const queryCache = useQueryClient();
    const allStagesQuery = useQuery(["stages"], async () => {
        const response = await stagesService.getAll();
        return response?.data ?? [];
    });
    const thisCompanyStagesQuery = useQuery(["companyProductStages", company.id], async () => {
        const response = await companyProductStagesService.getFilteredWhere({
            companyId: company.id,
        });
        if (response) {
            return response.data;
        }
    });
    if (allStagesQuery.isLoading || thisCompanyStagesQuery.isLoading) return <div>Loading...</div>;
    return (
        <>
            <div className="mx-5">
                <PageDescription
                    title="Product Statuses"
                    description="This is the list of all statuses that a product can be in."
                    buttons={[
                        {
                            label: "Add Status",
                            onClick: async () => {
                                setShowAll(false);
                                setShowAddStatusModal(true);
                            },
                        },
                    ]}
                />
                <TableNeogen
                    formatters={[
                        { field: "lastUpdated", type: "DateTime" },
                        { field: "updatedByUser", type: "User" },
                        { field: "companyId", type: "Company" },
                        { field: "stageId", type: "Stages" },
                    ]}
                    actions={[
                        {
                            label: "Edit",
                            icon: "fas fa-edit",
                            onClick: (entry) => {
                                setShowAll(true);
                                setCategory(thisCompanyStagesQuery.data?.find((s) => s.id === entry)?.stageId);
                                setCompanyStage(thisCompanyStagesQuery.data?.find((s) => s.id === entry));
                                console.error("Edit", entry);
                                setShowAddStatusModal(true);
                            },
                        },
                        {
                            label: "Delete",
                            icon: "fas fa-trash",
                            type: "danger",
                            onClick: (entry) => {
                                SwalNeogenFire({
                                    title: "Are you sure?",
                                    text: "This action is irreversible",
                                    icon: "warning",
                                    showCancelButton: true,
                                    confirmButtonText: "Yes, delete it!",
                                    cancelButtonText: "No, cancel!",
                                }).then(async (result) => {
                                    if (result.isConfirmed) {
                                        await companyProductStagesService.deleteByID(entry);
                                        await queryCache.invalidateQueries(["companyProductStages", company.id]);
                                    }
                                });
                            },
                        },
                    ]}
                    entries={thisCompanyStagesQuery.data ?? []}
                />
            </div>
            <AddStatusModal
                show={showAddStatusModal}
                close={() => setShowAddStatusModal(false)}
                company={company}
                showAll={showAll}
                category={category}
                companyProductStage={companyStage}
            />
        </>
    );
}
