import { z } from "zod";
import ModalDialog from "../../../layout/modal-dialog";
import { useForm } from "../../../hooks/useForm";
import { Form } from "../../../layout/form/form";
import { TextField } from "../../../layout/form/text-field";
import { getAuthTokenNoThrow } from "../../../services/auth-header";
import ButtonNeoGen from "../../../layout/button-neogen";
import { Company } from "../../domain/company";
import { useMutation } from "@tanstack/react-query";
import { useUpdateCompany } from "../../hooks/use-update-company";
import { NumberField } from "../../../layout/form/number-field";

const schema = z.object({
    routingNumber: z.number().min(1),
    accountNumber: z.number().min(1),
    bankName: z.string().min(1),
    clientAddress: z.string().min(1),
});

type Data = z.infer<typeof schema>;

export const EditBankDetailsModal = ({
    onClose,
    company,
    onCompanyUpdated: onCompanyUpdated,
}: {
    onClose: () => any;
    company: Company;
    onCompanyUpdated: (company: Company) => void;
}) => {
    const authToken = getAuthTokenNoThrow() || "no-auth-token";
    const form = useForm({
        schema,
        defaultValues: {
            bankName: company.bankName || undefined,
            clientAddress: company.clientAddress || undefined,
            routingNumber: company.routingNumber || undefined,
            accountNumber: company.accountNumber || undefined,
        },
    });

    const updateCompanyMutation = useUpdateCompany();

    const submitMutation = useMutation({
        mutationFn: async (data: Data) => {
            const updatedCompany = await updateCompanyMutation.mutateAsync({
                authToken,
                id: company.id,
                data: {
                    bankName: data.bankName || undefined,
                    clientAddress: data.clientAddress || undefined,
                    routingNumber: data.routingNumber || undefined,
                    accountNumber: data.accountNumber || undefined,
                },
            });
            return updatedCompany;
        },
    });

    const handleSubmit = async (data: Data) => {
        const company = await submitMutation.mutateAsync(data);
        onCompanyUpdated(company);
    };

    return (
        <>
            <ModalDialog show title={"Edit Bank Details"} close={onClose} showOk={false} showCancel={false} size="sm">
                <Form onSubmit={form.handleSubmit(handleSubmit as any)} error={updateCompanyMutation.error as any}>
                    <TextField label="Bank Name" {...form.getFieldProps("bankName")} />
                    <NumberField label="Routing Number" {...form.getFieldProps("routingNumber")} />
                    <NumberField label="Account Number" {...form.getFieldProps("accountNumber")} />
                    <TextField label="Client Address" {...form.getFieldProps("clientAddress")} />
                    <div className="flex justify-end gap-4">
                        <ButtonNeoGen
                            type="outline"
                            disabled={updateCompanyMutation.isLoading}
                            onClick={() => onClose()}
                        >
                            Cancel
                        </ButtonNeoGen>
                        <ButtonNeoGen type="submit" disabled={updateCompanyMutation.isLoading}>
                            Update Bank Details
                        </ButtonNeoGen>
                    </div>
                </Form>
            </ModalDialog>
        </>
    );
};
