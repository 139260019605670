import { useQuery } from "@tanstack/react-query";
import DateChart from "../../layout/date-chart";
import PrintPre from "../../layout/print-pre";
import usersService from "../../services/users.service";
import utmLinkService from "../../services/utm-link.service";
import utmLogService from "../../services/utm-log.service";

export default function UtmHitsByDate(props: { darkMode: boolean; usersQuerySuccess: boolean }) {
    const utmLinksQuery = useQuery(["utmLinks"], async () => {
        const response = await utmLinkService.getAll();
        if (response) {
            return response.data;
        } else {
            return [];
        }
    });

    const utmQuery = useQuery(
        ["utmLogsByDate"],
        async () => {
            // const links = await utmLinkService.getAll();
            const response = await utmLogService.getLastSevenDays();
            // const users = await userService.getAll();
            // const utmCalcs:any = {};
            if (response) {
                // console.error("response", response.data);
                const results: any = [];
                response.data.forEach((item) => {
                    const date = new Date(item.eventDatetime).toLocaleDateString();
                    const existing = results.find((r: any) => r.Date === date);
                    if (existing) {
                        existing.Hits++;
                        if (item.data.length > 0) {
                            existing.Registrations++;
                        }
                    } else {
                        const newEntry = {
                            Date: date,
                            Hits: 1,
                            Registrations: 0,
                        };
                        results.push(newEntry);
                    }
                });
                // alert(JSON.stringify(results));
                // response.data.forEach((item) => {
                //     if (!item.utm || item.utm === "Unknown") {
                //         return;
                //     }
                //     const name = (usersQuery.data?.find((user) => user.id === (utmLinksQuery.data?.find((link) => link.id === item.utm)?.owner))?.name)+" ("+((utmLinksQuery.data?.find((link) => link.id === item.utm)?.name) ?? item.utm.substring(-5))+")";
                //     if (utmCalcs[name]) {
                //         utmCalcs[name].hits++;
                //         if (item.data.length > 0) {
                //             utmCalcs[name].registrations++;
                //         }
                //     } else {
                //         utmCalcs[name] = {
                //             hits: 1,
                //             registrations: 0
                //         };
                //     }
                // });

                // const recalcs = Object.keys(utmCalcs).map((key) => {
                //     return {
                //         id: key,
                //         data: [
                //             {
                //                 x: "Hits",
                //                 y: utmCalcs[key].hits
                //             },
                //             {
                //                 x: "Registrations",
                //                 y: utmCalcs[key].registrations
                //             }
                //         ]
                //     };
                // });

                // console.error("recalcs", recalcs);

                return results;
            } else {
                alert("no response");
            }
        },
        { enabled: props.usersQuerySuccess && utmLinksQuery.isSuccess },
    );

    return (
        <>
            <div className=" min-w-full w-96">
                <div className="bg-gradient-to-br to-white from-slate-100 dark:from-slate-900 dark:to-gray-800 border-2 border-white rounded-xl dark:bg-gray-800 dark:border-gray-700 dark:shadow-inner shadow">
                    <h2 className=" mx-auto mt-5 mb-3  px-0 text-lg leading-6 text-gray-700 dark:text-gray-400 sm:px-6 lg:px-8">
                        UTM Hits by date
                    </h2>
                    <div className="  rounded pb-7 w-full overflow-hidden">
                        {/* <PrintPre>
                            {utmQuery.data}
                        </PrintPre> */}
                        {utmQuery.data ? (
                            <DateChart
                                darkMode={props.darkMode}
                                data={utmQuery.data ?? []}
                                keys={["Hits", "Registrations"]}
                                indexBy="Date"
                                legend="Total"
                                height={400}
                            />
                        ) : (
                            <div className="lg:px-8 h-[400px]">No data currently available</div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}
