import { z } from "zod";
import { makeRequest, parseSchema } from "../../jason-proof-of-concept/shared/utils";
import { mandateIndustryGroupSchema } from "../domain/mandate-industry-group";

export const getMandateIndustryGroups = async ({ authToken, filters }: { authToken: string; filters?: any }) => {
    const response = await makeRequest({
        method: "get",
        path: "/mandate-industry-groups",
        authToken,
        queryParams: { filter: JSON.stringify(filters) },
    });

    const mandateIndustryGroups = parseSchema(z.array(mandateIndustryGroupSchema), response.data || []);

    return mandateIndustryGroups;
};
