import { makeRequest, parseSchema } from "../../jason-proof-of-concept/shared/utils";
import { MandateMandateEffect, mandateMandateEffectSchema } from "../domain/mandate-mandate-effect";

export type CreateMandateMandateEffectData = Partial<MandateMandateEffect>;

export const createMandateMandateEffect = async ({
    authToken,
    data,
}: {
    authToken: string;
    data: CreateMandateMandateEffectData;
}) => {
    const response = await makeRequest({
        method: "post",
        path: `/mandate-mandate-effects`,
        authToken,
        data,
    });

    const mandateMandateEffect = parseSchema(mandateMandateEffectSchema, response.data);

    return mandateMandateEffect;
};
