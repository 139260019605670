/* eslint-disable react/prop-types */
import { CheckIcon } from "@heroicons/react/24/outline";
import { ReactNode } from "react";
import { useRecoilState } from "recoil";
import userAtom from "../../atoms/userAtom";
import { useQuery } from "@tanstack/react-query";
import businessRulePerGroupService from "../../services/business-rule-per-group.service";
import { BusinessRuleGroup } from "../../typings/api/business-rule-group";

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
}

export type Stage = {
    id: number;
    name: string;
    description: string;
    status?: "complete";
    onClick?: (id: number) => any;
    businessRuleGroups?: BusinessRuleGroup[];
    ruleGroupsAreOr?: number;
};

export const Stages = ({
    stages,
    onStageChange,
    currentStageId,
    data,
}: {
    stages: Stage[];
    onStageChange?: (stage: Stage) => void;
    currentStageId?: number;
    data: any;
}) => {
    const [user] = useRecoilState(userAtom);
    // console.log(data);
    const rolesQuery = useQuery(["roles"], async () => {
        const response = await businessRulePerGroupService.getAll();
        if (response) {
            return response.data;
        }
    });
    const ButtonOrDiv = ({ stage, children }: { stage: Stage; children: ReactNode }) => {
        return stage.onClick || onStageChange ? (
            <button
                onClick={() => {
                    if (stage.onClick) {
                        stage.onClick(stage.id);
                    }
                    if (onStageChange) {
                        onStageChange(stage);
                    }
                }}
                className="relative flex items-start group"
            >
                {children}
            </button>
        ) : (
            <div className="flex relative">{children}</div>
        );
    };
    /**
     * Determines whether a stage should be shown based on the provided stage and data.
     * @param stage - The stage object.
     * @param data - The data object.
     * @returns A boolean indicating whether the stage should be shown.
     */
    function stageShouldShow(stage: Stage, data: any) {
        // console.log({ stage });
        // const myRoles = rolesQuery?.data?.filter((r) => user?.roleGroups?.some((ur) => ur.id === r.businessRuleGroup));
        if (stage.businessRuleGroups === undefined || stage.businessRuleGroups.length === 0) {
            return true;
        }
        const ruleGroupsAreOr = stage.ruleGroupsAreOr === 1;
        for (const group of stage.businessRuleGroups ?? []) {
            for (const rule of group.businessRules ?? []) {
                if (rule.ruleType === 14) {
                    // 14 is the rule type for role group
                    // console.log(rule);
                    let hasRole;
                    if (rule.fieldName === "Role Group") {
                        hasRole = user?.roleGroups?.some((r) => r.id === Number(rule.valueTriggerStart));
                    } else {
                        // console.log(window.localStorage.getItem("tempRoleGroup"), rule.valueTriggerStart);
                        hasRole = window.localStorage.getItem("tempRoleGroup") === rule.valueTriggerStart;
                    }
                    if (hasRole) {
                        // Have the role group
                        if (group.isQualify) {
                            // If it's a qualifier and the role group is present, show it
                            return true;
                        } else if (!ruleGroupsAreOr) {
                            // If it's a disqualifier and the role group is present, don't show it
                            return false;
                        }
                    } else {
                        // Don't have the role group
                        if (group.isQualify && !ruleGroupsAreOr) {
                            // If it's a qualifier and the role group is missing, don't show it
                            return false;
                        } else if (ruleGroupsAreOr) {
                            continue;
                        } else {
                            // If it's a disqualifier and the role group is missing, show it
                            return true;
                        }
                    }
                }
                if (rule.ruleType === 8) {
                    // console.log(data, rule);
                    if (data[rule.fieldName] === rule.valueTriggerStart) {
                        if (group.isQualify) {
                            return true;
                        } else {
                            return false;
                        }
                    }
                }
                // console.error({ rule });
            }
        }
        return false;
    }

    return (
        <nav aria-label="Progress">
            <ol role="list" className="overflow-hidden">
                {stages
                    .filter((s) => stageShouldShow(s, data))
                    ?.map((stage, stepIdx) => (
                        <li
                            key={stage.name}
                            className={classNames(stepIdx !== stages.length - 1 ? "pb-10" : "", "relative")}
                        >
                            {stage.status === "complete" ? (
                                <>
                                    {stepIdx !== stages.length - 1 ? (
                                        <div
                                            className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-indigo-600"
                                            aria-hidden="true"
                                        />
                                    ) : null}
                                    <ButtonOrDiv stage={stage}>
                                        <span className="h-9 flex items-center">
                                            <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-indigo-600 rounded-full group-hover:bg-indigo-800">
                                                <CheckIcon className="w-5 h-5 text-white" aria-hidden="true" />
                                            </span>
                                        </span>
                                        <span className="ml-4 min-w-0 flex flex-col">
                                            <span className="text-xs text-left pb-1 font-medium tracking-wider uppercase dark:text-gray-300">
                                                {stage.name}
                                            </span>
                                            <span className="text-xs text-gray-500 tracking-wider dark:text-gray-400">
                                                {stage.description}
                                            </span>
                                        </span>
                                    </ButtonOrDiv>
                                </>
                            ) : stage.id === currentStageId ? (
                                <>
                                    {stepIdx !== stages.length - 1 ? (
                                        <div
                                            className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-gray-300 dark:bg-gray-600"
                                            aria-hidden="true"
                                        />
                                    ) : null}
                                    <ButtonOrDiv stage={stage}>
                                        <span className="h-9 flex items-left" aria-hidden="true">
                                            <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-white dark:bg-gray-900 border-2 border-indigo-600 rounded-full">
                                                <span className="h-2.5 w-2.5 bg-indigo-600 rounded-full" />
                                            </span>
                                        </span>
                                        <span className="ml-4 min-w-0 flex flex-col">
                                            <span className="text-xs text-left pb-1  font-medium tracking-wider uppercase text-indigo-600 dark:text-green-400">
                                                {stage.name}
                                            </span>
                                            <span className="text-xs text-left font-normal tracking-wider text-gray-500 dark:text-gray-400">
                                                {stage.description}
                                            </span>
                                        </span>
                                    </ButtonOrDiv>
                                </>
                            ) : (
                                <>
                                    {stepIdx !== stages.length - 1 ? (
                                        <div
                                            className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-gray-300 dark:bg-gray-600"
                                            aria-hidden="true"
                                        />
                                    ) : null}
                                    <ButtonOrDiv stage={stage}>
                                        <span className="h-9 flex items-left" aria-hidden="true">
                                            <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-white dark:bg-gray-900 dark:border-gray-700 border-2 border-gray-300 rounded-full group-hover:border-gray-400">
                                                <span className="h-2.5 w-2.5 bg-transparent rounded-full group-hover:bg-gray-300" />
                                            </span>
                                        </span>
                                        <span className="ml-4 min-w-0 flex flex-col">
                                            <span className="text-xs text-left font-medium pb-1 tracking-wider uppercase text-gray-500 dark:text-gray-300">
                                                {stage.name}
                                            </span>
                                            <span className="text-xs text-left font-normal tracking-wider text-gray-500 dark:text-gray-400">
                                                {stage.description}
                                            </span>
                                        </span>
                                    </ButtonOrDiv>
                                </>
                            )}
                        </li>
                    ))}
            </ol>
        </nav>
    );
};
