import React from "react";
import ModalDialog from "../../../layout/modal-dialog";
import TableNeogen from "../../../layout/table-neogen";
import PrintPre from "../../../layout/print-pre";
import { duration } from "moment";
import secToHMS from "../../utilities/formatters";

function Calls({ show, close, calls }: { show: boolean; close: () => void; calls: any[] }) {
    return (
        <ModalDialog close={() => close()} show={show} title={""} size="md" showCancel={false} okAction={() => close()}>
            <div className="my-5">
                <TableNeogen
                    formatters={[
                        { field: "status", type: "CallStatus" },
                        { field: "calldate", type: "DateTime" },
                        { field: "from", type: "Phone" },
                        { field: "to", type: "Phone" },
                    ]}
                    ignoreFields={["sid"]}
                    entries={
                        calls.map((c) => {
                            return {
                                id: c.id,
                                calldate: c.calldate,
                                from: c.from,
                                to: c.to,
                                duration: secToHMS(c.duration),
                                status: c.status,
                            };
                        }) ?? []
                    }
                />
            </div>
        </ModalDialog>
    );
}

export default Calls;
