import React from "react";
import PageDescription from "../../layout/page-description";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import companyService from "../../services/company.service";
import PrintPre from "../../layout/print-pre";
import companyGroupService from "../../services/company-group.service";
import UserCompanyPicker from "../../layout/user-company-picker";
import TableNeogen from "../../layout/table-neogen";
import InputControlled from "../../layout/input-controlled";
import ButtonNeoGen from "../../layout/button-neogen";
import { Company } from "../domain/company";
import userCompanyService from "../../services/user-company.service";

function CompanyGroups({ company, user }: { company: any; user: any }) {
    const queryCache = useQueryClient();
    const [internalCompany, setInternalCompany] = React.useState<Company>(company);
    const [showPicker, setShowPicker] = React.useState(false);
    const [showCompanyName, setShowCompanyName] = React.useState(false);
    const [companyName, setCompanyName] = React.useState("");
    const companyQuery = useQuery(["companies"], async () => {
        const response = await companyService.getAll();
        if (response) {
            return response.data;
        }
    });
    if (companyQuery.isLoading) return <div>Loading...</div>;
    return (
        <div className="mx-5">
            <PageDescription
                title="Company Groups"
                description="This is the list of all other companies that belong in the same agregated group as this company."
                buttons={
                    internalCompany.group === null
                        ? [
                              {
                                  label: "Create Group",
                                  onClick: async () => {
                                      const newGroup = await companyGroupService.create({ name: company.name });

                                      queryCache.invalidateQueries(["company"]);

                                      if (newGroup) {
                                          console.error(newGroup.data?.id);
                                          internalCompany.group = newGroup.data?.id;
                                          await companyService.update(internalCompany.id, { group: newGroup.data?.id });
                                          //   window.location.reload();
                                      }
                                  },
                              },
                          ]
                        : [
                              {
                                  label: "Add Existing Company",
                                  onClick: () => setShowPicker(true),
                              },
                              {
                                  label: "Add New Company",
                                  onClick: () => setShowCompanyName(true),
                              },
                          ]
                }
            />
            {showCompanyName ? (
                <>
                    <InputControlled
                        label={"New Company Name"}
                        value={companyName}
                        onChange={(v) => setCompanyName(v)}
                    />
                    <ButtonNeoGen
                        onClick={async () => {
                            const newCompany = await companyService.create({
                                name: companyName,
                                // affiliate: internalCompany.affiliate,
                                ownedById: internalCompany.ownedById,
                                group: internalCompany.group,
                            });
                            if (newCompany) {
                                // Now find any users on the company with a type of 7 (affiliate)
                                // and duplicate them for the new company
                                const userCompanyEntries = await userCompanyService.getFilteredWhere({
                                    and: [{ companyId: internalCompany.id }, { roleGroupId: 7 }],
                                });
                                if (userCompanyEntries) {
                                    for (const userCompanyEntry of userCompanyEntries.data) {
                                        if (userCompanyEntry.id) {
                                            delete userCompanyEntry.id;
                                        }
                                        await userCompanyService.create({
                                            ...userCompanyEntry,
                                            companyId: newCompany.data?.id,
                                            roleGroupId: 7,
                                        });
                                    }
                                }

                                // await companyService.update(newCompany.data?.id, { group: internalCompany.group });
                                queryCache.invalidateQueries(["companies"]);
                                queryCache.invalidateQueries(["company"]);
                                setCompanyName("");
                                setShowCompanyName(false);
                            }
                        }}
                    >
                        Create Company
                    </ButtonNeoGen>
                </>
            ) : (
                <>
                    {internalCompany.group !== null && (
                        <TableNeogen
                            entries={
                                companyQuery.data
                                    ?.filter((c: any) => c.group === internalCompany.group)
                                    .map((c: any) => ({ id: c.id, Name: c.name })) ?? []
                            }
                        />
                    )}
                </>
            )}
            {/* <PrintPre>{companyQuery.data}</PrintPre> */}
            <UserCompanyPicker
                open={showPicker}
                setOpen={setShowPicker}
                showUsers={false}
                companyList={companyQuery.data}
                setCompany={async (company: any) => {
                    await companyService.update(company.id, { group: internalCompany.group });
                    queryCache.invalidateQueries(["companies"]);
                    setShowPicker(false);
                }}
            />
        </div>
    );
}

export default CompanyGroups;
