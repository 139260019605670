import { TBody, THead, Table, Td, Th, Tr } from "../../layout/table";
import { useFiles } from "../../files/hooks/use-files";
import { useMemo, useState } from "react";
import { getAuthToken } from "../../services/auth-header";
import ButtonNeoGen from "../../layout/button-neogen";
import fileService from "../../services/file.service";
import TableNeogen from "../../layout/table-neogen";
import { useQuery } from "@tanstack/react-query";
import Loader3 from "../../sections/utilities/Loader3";
import PrintPre from "../../layout/print-pre";
import { neq } from "semver";
import { id } from "date-fns/locale";
import SwalNeogenFire from "../../layout/swal-neogen";
import { Document, Page } from "react-pdf";
import ModalDialog from "../../layout/modal-dialog";

export const Files = ({ companyId }: { companyId: number }) => {
    const [showFilePreview, setShowFilePreview] = useState(false);
    const [numPages, setNumPages] = useState<number>();
    const [file, setFile] = useState("");
    const [pageNumber, setPageNumber] = useState<number>(1);

    function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
        setNumPages(numPages);
        setPageNumber(1);
    }
    const filesQuery = useQuery(["files", companyId], async () => {
        const response = await fileService.getFilteredWhere({
            companyId,
        });
        if (response) {
            return (response.data as any).pageItems;
        }
    });
    if (filesQuery.isLoading) {
        return <Loader3 />;
    }
    return (
        <div className="px-5">
            {filesQuery.data.filter((f: any) => f.notes === "cpa-transcript").length > 0 && (
                <>
                    <h1 className="text-2xl mb-5">Transcripts</h1>
                    <TableNeogen
                        ignoreFields={[
                            "filename",
                            "key",
                            "companyId",
                            "notes",
                            "mimetype",
                            "encoding",
                            "documentTypeId",
                            "processflowProgressId",
                            "isArchived",
                            "lastUpdated",
                            "uploadedByUser",
                            "documentType",
                        ]}
                        formatters={[
                            { field: "dateUploaded", type: "DateTime" },
                            { field: "size", type: "FileSize" },
                            { field: "uploaded_by", type: "User" },
                        ]}
                        entries={filesQuery.data.filter((f: any) => f.notes === "cpa-transcript") ?? []}
                        actions={[
                            {
                                label: "Preview",
                                icon: "fas fa-eye",
                                onClick: (entry) => {
                                    const file = filesQuery.data.find((file: any) => file.id === entry);
                                    if (file) {
                                        setShowFilePreview(true);
                                        setFile("https://api.clearerc.com/files/" + file.filename);
                                    } else {
                                        console.error({ entry, files: filesQuery.data });
                                    }
                                },
                            },
                            {
                                label: "Download",
                                icon: "fas fa-download",
                                onClick: (entry) => {
                                    const file = filesQuery.data.find((file: any) => file.id === entry);
                                    if (file) {
                                        fileService
                                            .downloadFile(file.filename, file.originalFilename)
                                            .catch((error) => {
                                                console.error(error);
                                                SwalNeogenFire({
                                                    title: "Error",
                                                    text:
                                                        "There was an error downloading the file: " +
                                                        error.response.statusText,
                                                    icon: "error",
                                                    showConfirmButton: false,
                                                    timer: 3500,
                                                });
                                            });
                                    } else {
                                        // alert("Not found");
                                        console.error({ entry, files: filesQuery.data });
                                    }
                                },
                            },
                        ]}
                    />
                </>
            )}
            {filesQuery.data.filter((f: any) => f.notes === "cpa-tax-advocate-upload").length > 0 && (
                <>
                    <h1 className="text-2xl my-5">Tax Advocate Updates</h1>
                    <TableNeogen
                        ignoreFields={[
                            "filename",
                            "key",
                            "companyId",
                            "notes",
                            "mimetype",
                            "encoding",
                            "documentTypeId",
                            "processflowProgressId",
                            "isArchived",
                            "lastUpdated",
                            "uploadedByUser",
                            "documentType",
                        ]}
                        formatters={[
                            { field: "dateUploaded", type: "DateTime" },
                            { field: "size", type: "FileSize" },
                            { field: "uploaded_by", type: "User" },
                        ]}
                        entries={filesQuery.data.filter((f: any) => f.notes === "cpa-tax-advocate-upload") ?? []}
                        actions={[
                            {
                                label: "Preview",
                                icon: "fas fa-eye",
                                onClick: (entry) => {
                                    const file = filesQuery.data.find((file: any) => file.id === entry);
                                    if (file) {
                                        setShowFilePreview(true);
                                        setFile("https://api.clearerc.com/files/" + file.filename);
                                    } else {
                                        console.error({ entry, files: filesQuery.data });
                                    }
                                },
                            },
                            {
                                label: "Download",
                                icon: "fas fa-download",
                                onClick: (entry) => {
                                    const file = filesQuery.data.find((file: any) => file.id === entry);
                                    if (file) {
                                        fileService
                                            .downloadFile(file.filename, file.originalFilename)
                                            .catch((error) => {
                                                console.error(error);
                                                SwalNeogenFire({
                                                    title: "Error",
                                                    text:
                                                        "There was an error downloading the file: " +
                                                        error.response.statusText,
                                                    icon: "error",
                                                    showConfirmButton: false,
                                                    timer: 3500,
                                                });
                                            });
                                    } else {
                                        // alert("Not found");
                                        console.error({ entry, files: filesQuery.data });
                                    }
                                },
                            },
                        ]}
                    />
                </>
            )}
            {filesQuery.data.filter((f: any) => f.notes === "").length > 0 && (
                <>
                    <h1 className="text-2xl my-5">Other Files</h1>
                    <TableNeogen
                        ignoreFields={[
                            "filename",
                            "key",
                            "companyId",
                            "notes",
                            "mimetype",
                            "encoding",
                            "documentTypeId",
                            "processflowProgressId",
                            "isArchived",
                            "lastUpdated",
                            "uploadedByUser",
                            "documentType",
                        ]}
                        formatters={[
                            { field: "dateUploaded", type: "DateTime" },
                            { field: "size", type: "FileSize" },
                            { field: "uploaded_by", type: "User" },
                        ]}
                        entries={filesQuery.data.filter((f: any) => f.notes === "") ?? []}
                        actions={[
                            {
                                label: "Download",
                                icon: "fas fa-download",
                                onClick: (entry) => {
                                    const file = filesQuery.data.find((file: any) => file.id === entry);
                                    if (file) {
                                        fileService
                                            .downloadFile(file.filename, file.originalFilename)
                                            .catch((error) => {
                                                console.error(error);
                                                SwalNeogenFire({
                                                    title: "Error",
                                                    text:
                                                        "There was an error downloading the file: " +
                                                        error.response.statusText,
                                                    icon: "error",
                                                    showConfirmButton: false,
                                                    timer: 3500,
                                                });
                                            });
                                    } else {
                                        // alert("Not found");
                                        console.error({ entry, files: filesQuery.data });
                                    }
                                },
                            },
                        ]}
                    />
                </>
            )}
            {showFilePreview && (
                <ModalDialog
                    show={true}
                    close={() => setShowFilePreview(false)}
                    title="Preview"
                    size="lg"
                    okAction={() => setShowFilePreview(false)}
                >
                    <div className="mt-5 text-center">
                        <Document
                            file={file}
                            onLoadSuccess={onDocumentLoadSuccess}
                            className={"text-center flex flex-col items-center"}
                        >
                            <Page pageNumber={pageNumber} />
                        </Document>
                    </div>
                </ModalDialog>
            )}
        </div>
    );
};
