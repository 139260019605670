import { useCallback, useEffect, useRef, useState } from "react";
import { debounce } from "lodash";
import { useNavigate } from "react-router";
import { TBody, THead, Table, Td, Th, Tr } from "../../layout/table";
import SearchField from "../../layout/search-field";
import { ViewportList } from "react-viewport-list";
import { useInfiniteMandates } from "../hooks/use-mandates";
import { useAuth } from "../../auth/use-auth";
import qs from "qs";
import { formateDateNew } from "../utils";
import { getMandateDetails } from "../domain/mandate";

function MandatesPage({ darkMode }: { darkMode?: boolean }) {
    const auth = useAuth();
    const authToken = auth.expectAuthToken();
    const navigate = useNavigate();
    const viewportRef = useRef<HTMLDivElement | null>(null);
    const listRef = useRef<any>(null);

    const params = new URLSearchParams(window.location.search);
    const searchParam = params.get("search");
    const statesParam = params.get("states");

    const [states, setStates] = useState<string[] | undefined>(statesParam ? statesParam.split(",") : undefined);
    const [search, setSearch] = useState(searchParam || "");
    const [filters, setFilters] = useState<{ search?: string; states?: string[] } | undefined>(() => {
        return {
            search: searchParam || undefined,
            states: statesParam ? statesParam.split(",") : undefined,
        };
    });

    const mandatesQuery = useInfiniteMandates({
        authToken,
        filters: {
            where: {
                ...(filters?.search && filters.search?.length > 0
                    ? {
                          or: [
                              { name: { like: `%${filters.search}%` } },
                              { aiName: { like: `%${filters.search}%` } },
                              { humanName: { like: `%${filters.search}%` } },
                              { googleSheetName: { like: `%${filters.search}%` } },
                          ],
                      }
                    : {}),
                ...(filters?.states && filters.states.length > 0 ? { state: { inq: filters.states } } : {}),
            },
        },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const updateDebouncedSearch = useCallback(
        debounce(
            (params: { search?: string; states?: string[] }) => {
                setFilters({ ...filters, ...params });
                const queryParams = qs.stringify(params, { skipNulls: true, arrayFormat: "comma" });
                navigate(`/mandates?${queryParams}`);
            },
            500,
            { trailing: true, maxWait: 5000 },
        ),
        [],
    );

    useEffect(() => {
        updateDebouncedSearch({ search, states });
    }, [search, states, updateDebouncedSearch]);

    const loadMoreItems = useCallback(
        debounce(
            () => {
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                mandatesQuery.fetchNextPage();
            },
            500,
            { leading: true },
        ),
        [],
    );

    const mandates = mandatesQuery.mandates || [];

    return (
        <div style={{ height: "calc(100vh - 100px)", display: "flex", flexDirection: "column", gap: 12 }}>
            <div
                style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}
            >
                <div>
                    <h1>Mandates</h1>
                </div>
                <div className="flex flex-1 flex-row gap-3 justify-end">
                    <SearchField search={search} setSearch={setSearch} placeholder="Search mandates..." />
                </div>
            </div>
            <div style={{ flex: 1, overflow: "auto" }} ref={viewportRef}>
                <div style={{ paddingTop: 0 }}>
                    <Table>
                        <THead sticky>
                            <Tr>
                                <Th>Mandate</Th>
                                <Th>Effective</Th>
                                <Th>State</Th>
                                <Th>County</Th>
                                <Th>City</Th>
                            </Tr>
                        </THead>
                        <TBody>
                            {mandatesQuery.isLoading && (
                                <Tr>
                                    <Td colSpan={5} style={{ textAlign: "center" }}>
                                        No mandates
                                    </Td>
                                </Tr>
                            )}
                            {(mandates || []).length > 0 && (
                                <ViewportList
                                    items={mandates}
                                    renderSpacer={({ ref, style }) => <Tr ref={ref} style={style} />}
                                    ref={listRef}
                                    viewportRef={viewportRef}
                                    scrollThreshold={100}
                                    initialPrerender={25}
                                    overscan={10}
                                >
                                    {(mandate, index) => {
                                        if (mandates.length > 10 && mandates.length - index < 5) {
                                            loadMoreItems();
                                        }

                                        const { name, effectiveDate } = getMandateDetails(mandate);

                                        return (
                                            <Tr
                                                key={mandate.id}
                                                onClick={() => {
                                                    navigate(`/mandates/${mandate.id}`);
                                                }}
                                                style={{ cursor: "pointer" }}
                                            >
                                                <Td>
                                                    {name?.value ? (
                                                        <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                                                            <div>{name.value}</div>
                                                            <div style={{ color: "gray", fontSize: 13 }}>
                                                                ({name.source})
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        "-"
                                                    )}
                                                </Td>
                                                <Td>
                                                    {effectiveDate?.value ? (
                                                        <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                                                            <div>{formateDateNew({ date: effectiveDate.value })}</div>
                                                            <div style={{ color: "gray", fontSize: 13 }}>
                                                                ({effectiveDate.source})
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        "-"
                                                    )}
                                                </Td>
                                                <Td>{mandate.state || "-"}</Td>
                                                <Td>{mandate.counties?.join(", ") || "-"}</Td>
                                                <Td>{mandate.cities?.join(", ") || "-"}</Td>
                                            </Tr>
                                        );
                                    }}
                                </ViewportList>
                            )}
                        </TBody>
                    </Table>
                </div>
            </div>
        </div>
    );
}

export default MandatesPage;
