import APIService from "./api.service";

export type City = {
    id?: number;
    city: string;
    county: string;
    state: string;
};

class CityService extends APIService<City> {
    constructor() {
        super("/cities");
    }
}

export default new CityService();
