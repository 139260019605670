import React, { useEffect } from "react";
import ModalDialog from "../../../../layout/modal-dialog";
import stagesService, { Stages } from "../../../../services/stages.service";
import PrintPre from "../../../../layout/print-pre";
import { Select } from "../../../../layout/form/select-input";
import InputControlled from "../../../../layout/input-controlled";
import { useQueryClient } from "@tanstack/react-query";
import SwalNeogenFire from "../../../../layout/swal-neogen";
import Swal from "sweetalert2";
import RadioButtonNeoGenControlled from "../../../../layout/radiobutton-neogen-controlled";
import Toggle from "../../../../layout/toggle";
import { set } from "lodash";
import ButtonNeoGen from "../../../../layout/button-neogen";

export default function AddEditStageModal({
    show,
    close,
    stages,
    stage,
    setStage,
}: {
    show: boolean;
    close: () => void;
    stages: Stages[];
    stage?: Stages;
    setStage: React.Dispatch<React.SetStateAction<Stages | undefined>>;
}) {
    const queryCache = useQueryClient();
    const [isPublic, setIsPublic] = React.useState(false);
    useEffect(() => {
        if (stage) {
            setIsPublic(stage.isPublic === 1);
        }
    }, [stage]);
    async function saveChanges() {
        const duplicate = { ...stage };
        if (!duplicate?.description) {
            duplicate.description = "";
        }
        if (!duplicate?.isAccountant) {
            duplicate.isAccountant = 0;
        }
        if (!duplicate?.isPublic) {
            duplicate.isPublic = 0;
        }
        if (!duplicate?.parent) {
            duplicate.parent = 0;
        }
        if (!duplicate?.requiredRoleGroup) {
            duplicate.requiredRoleGroup = -1;
        }
        if (stage?.id) {
            // update
            SwalNeogenFire({
                title: "Updating Stage",
                text: "Please wait...",
                icon: "info",
                showConfirmButton: false,
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
            });

            await stagesService.update(duplicate.id, duplicate);
            await queryCache.invalidateQueries(["stages"]);
        } else {
            // create
            SwalNeogenFire({
                title: "Creating Stage",
                text: "Please wait...",
                icon: "info",
                showConfirmButton: false,
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
            });
            const newStage = {
                name: duplicate.name,
                description: duplicate.description,
                requiredRoleGroup: duplicate.requiredRoleGroup,
                parent: duplicate.parent ?? 0,
                isPublic: duplicate.isPublic ?? 0,
                isAccountant: duplicate.isAccountant ?? 0,
            };
            delete duplicate.id;
            await stagesService.create(newStage);
            await queryCache.invalidateQueries(["stages"]);
        }

        Swal.close();
        close();
    }
    return (
        <ModalDialog close={close} show={show} title={stage?.id ? "Edit Stage" : "Add Stage"} okAction={saveChanges}>
            <div>
                <InputControlled
                    label="Stage Name"
                    value={stage?.name ?? ""}
                    onChange={(value) => {
                        const duplicate = { ...stage };
                        duplicate.name = value;
                        if (typeof duplicate.isPublic === undefined) {
                            duplicate.isPublic = 0;
                        }
                        setStage(duplicate);
                    }}
                />
                <InputControlled
                    label="Stage Description"
                    value={stage?.description ?? ""}
                    onChange={(value) => {
                        const duplicate = { ...stage };
                        duplicate.description = value;
                        if (typeof duplicate.isPublic === undefined) {
                            duplicate.isPublic = 0;
                        }
                        setStage(duplicate);
                    }}
                />
                <InputControlled
                    label="Required Role Group"
                    value={stage?.requiredRoleGroup?.toString() ?? ""}
                    onChange={(value) => {
                        const duplicate = { ...stage };
                        duplicate.requiredRoleGroup = Number(value);
                        if (typeof duplicate.isPublic === undefined) {
                            duplicate.isPublic = 0;
                        }
                        setStage(duplicate);
                    }}
                />
                <br />
                <Toggle
                    options={["Public", "Not Public"]}
                    selectedIndex={isPublic ? 0 : 1}
                    setSelectedIndex={function (index: number): void {
                        setIsPublic(index === 0);
                    }}
                />
                <br />

                <Select
                    label="Parent Stage"
                    options={stages.map((stage) => ({
                        label: stage.name ?? "",
                        value: stage.id ?? -1,
                    }))}
                    value={stage?.parent ?? ""}
                    onChange={function (value?: string | number | string[] | null | undefined) {
                        const duplicate = { ...stage };
                        duplicate.parent = (value as number) ?? 0;
                        if (typeof duplicate.isPublic === undefined) {
                            duplicate.isPublic = 0;
                        }
                        setStage(duplicate);
                    }}
                />
                {stage?.id && (
                    <div className="mt-5">
                        <ButtonNeoGen
                            type="danger"
                            icon="fas fa-trash"
                            onClick={async () => {
                                await SwalNeogenFire({
                                    title: "Delete Stage",
                                    text: "Are you sure you want to delete this stage?",
                                    icon: "warning",
                                    showCancelButton: true,
                                    confirmButtonText: "Yes",
                                    cancelButtonText: "No",
                                }).then(async (result) => {
                                    if (result.isConfirmed) {
                                        SwalNeogenFire({
                                            title: "Deleting Stage",
                                            text: "Please wait...",
                                            icon: "info",
                                            showConfirmButton: false,
                                            allowOutsideClick: false,
                                            allowEscapeKey: false,
                                            allowEnterKey: false,
                                            timer: 1500,
                                        });
                                        await stagesService.deleteByID(Number(stage?.id ?? -1));
                                        await queryCache.invalidateQueries(["stages"]);
                                        close();
                                    }
                                });
                            }}
                        >
                            Delete Stage
                        </ButtonNeoGen>
                    </div>
                )}

                <br />
            </div>
        </ModalDialog>
    );
}
