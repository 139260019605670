/* eslint-disable react/display-name */
import { ExclamationCircleIcon } from "@heroicons/react/24/solid";
import { forwardRef, InputHTMLAttributes, useState } from "react";

export type TextInputProps = Omit<InputHTMLAttributes<HTMLInputElement>, "form">;

export const TextInput = forwardRef((props: TextInputProps & { hasError?: boolean }, ref: any) => {
    const [value, setValue] = useState(props.value || "");
    const isControlled = props.value !== undefined;

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (props.onChange) {
            props.onChange(e);
        }

        if (!isControlled) {
            setValue(e.target.value);
        }
    };

    return (
        <div style={{ position: "relative" }}>
            {props.hasError && (
                <div className={"absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none "}>
                    <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                </div>
            )}
            <input
                type="text"
                value={isControlled ? props.value : value}
                onChange={handleChange}
                {...props}
                style={{ cursor: props.disabled ? "not-allowed" : undefined, ...props.style }}
                className={
                    ` dark:bg-gray-800 ${props.disabled ? ` bg-gray-100 ` : ``}   ` +
                    (props.hasError
                        ? "block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                        : " placeholder-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md dark:bg-gray-900 dark:placeholder-slate-700 dark:border-gray-900 dark:text-gray-300")
                }
                ref={ref}
            />
        </div>
    );
});
