import { useQuery } from "@tanstack/react-query";
import ModalDialog from "../../../layout/modal-dialog";
import PrintPre from "../../../layout/print-pre";
import industryService from "../../../services/industry.service";
import { useEffect, useState } from "react";
import API from "../../../services/api.service";
import randdService from "../../../services/randd.service";
import Markdown from "react-markdown";
import markdownToDelta from "markdown-to-quill-delta";
import { ProcessFlowProgressData } from "../../../typings/api/processflow-progress-data";
import Loader3 from "../../../sections/utilities/Loader3";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { use } from "i18next";
function IssuesModal({
    show,
    close,
    rAndDData,
    handleDataChange,
    progressData,
}: {
    show: boolean;
    close: () => void;
    rAndDData: { industry: number[]; industryAlternate: string };
    handleDataChange: (newData: any, progressData: ProcessFlowProgressData) => void;
    progressData?: ProcessFlowProgressData;
}): JSX.Element {
    const [value, setValue] = useState("");
    const [saving, setSaving] = useState(false);
    const [industryNames, setIndustryNames] = useState<string[]>([]);
    const [quillData, setQuillData] = useState<any>();
    const industryQuery = useQuery(["industry"], async () => {
        const response = await industryService.getAll();
        if (response) {
            return response.data;
        }
    });
    const aiQuery = useQuery(
        ["rAndDAI", show],
        async () => {
            console.log(industryNames);
            const response = await randdService.postURL("get-issues", {
                businesstype: industryNames,
            });
            // const response = await fetch("http://localhost:4000/get-r-and-d", {
            //     method: "POST",
            //     headers: {
            //         "Content-Type": "application/json",
            //     },
            //     body: JSON.stringify({ businesstype: industryNames.join(" or ") }),
            // });
            console.log(response);

            if (response) {
                return response.data;
            }
        },
        { enabled: industryNames.length > 0 },
    );

    async function saveDetails() {
        console.log("Save Details");
        console.log({ progressData });
        setSaving(true);
        if (progressData) {
            const newData = {
                ...progressData,
            };
            newData.entriesByField.generatedIssues = {
                fieldName: "generatedIssues",
                fieldValue: JSON.stringify(value),
            };
            progressData.entriesByField.generatedIssues = {
                fieldName: "generatedIssues",
                fieldValue: JSON.stringify(value),
            };
            // alert(JSON.stringify(newData.entriesByField.generatedIssues));
            await handleDataChange(newData, progressData);
        }

        // close();
        setTimeout(() => {
            window.location.reload();
        }, 5000);
    }

    useEffect(() => {
        if (aiQuery.isSuccess) {
            console.info(aiQuery.data);
            const ops = markdownToDelta(aiQuery.data);
            setQuillData(ops);
            setValue(aiQuery.data);
        }
        // setSaving(false);
    }, [aiQuery.data, aiQuery.isSuccess]);

    useEffect(() => {
        if (show) {
            setSaving(false);
        }
    }, [show]);

    useEffect(() => {
        let industryNamesTemp;
        if (rAndDData.industry?.length > 0 && industryQuery.data) {
            industryNamesTemp = rAndDData.industry.map((industry) => {
                const industryName = industryQuery.data?.find((industryData) => industryData.id === industry);
                return industryName ? industryName.name : "";
            });
            setIndustryNames(industryNamesTemp);
        } else if (rAndDData.industryAlternate) {
            industryNamesTemp = [rAndDData.industryAlternate];
            setIndustryNames(industryNamesTemp);
        } else {
            console.log({ rAndDData });
        }
        console.log(industryNamesTemp);
        // Now go to the API and get the R&D data
    }, [industryQuery.data, rAndDData, rAndDData.industry, rAndDData.industryAlternate]);
    if (industryQuery.isLoading || aiQuery.isLoading || saving) {
        return (
            <ModalDialog
                close={() => close()}
                show={show}
                title={saving ? "Please Wait, Saving" : "Generating AI Response"}
            >
                <Loader3 />
            </ModalDialog>
        );
    }
    return (
        <ModalDialog
            close={() => close()}
            show={show}
            title={"How Might I Qualify"}
            size="xl"
            okAction={() => saveDetails()}
        >
            {/* <ReactQuill theme="snow" value={value} onChange={setValue} /> */}
            <Markdown>{aiQuery.data}</Markdown>
        </ModalDialog>
    );
}

export default IssuesModal;
