import { z } from "zod";

export const companyLineItemSchema = z.object({
    id: z.number(),
    companyId: z.number(),
    lineItemId: z.number(),
    amount: z.number().nullish(),
    notes: z.string().nullish(),
});

export type CompanyLineItem = z.infer<typeof companyLineItemSchema>;
