import { useMutation, useQueryClient } from "@tanstack/react-query";
import Swal from "sweetalert2";
import processflowService from "../../../services/processflow.service";
import { BusinessRuleGroup } from "../../../typings/api/business-rule-group";
import { Entry } from "../../../layout/add-to-list";
import Badge from "../../../layout/badge";
import ButtonNeoGen from "../../../layout/button-neogen";
import { useRecoilState } from "recoil";
import userAtom from "../../../atoms/userAtom";
import { ClearERCUser } from "../../../typings/api/clear-erc-user";
import { getAuthTokenNoThrow } from "../../../services/auth-header";
import { createStep } from "../actions/create-step";

export function StepEntry({
    entry,
    entries,
    setEntries,
    setIsStep,
    setStepIsEdit,
    setId,
    setShow,
    setShowEditRules,
}: {
    entry: any;
    entries: any[];
    setEntries: any;
    setIsStep: (isStep: boolean) => void;
    setStepIsEdit: (isEdit: boolean) => void;
    setId: (id: number) => void;
    setShow: (show: boolean) => void;
    key: any;
    setShowEditRules: (show: boolean) => void;
}): JSX.Element {
    const cache = useQueryClient();
    const [user, setUser] = useRecoilState<ClearERCUser>(userAtom);

    const duplicateStepMutation = useMutation({
        mutationFn: async ({ authToken, data }: { authToken: string; data: any }) => {
            return await createStep({ authToken, data });
        },
    });

    const handleDuplicateStep = async (data: any) => {
        const step = await duplicateStepMutation.mutateAsync({
            authToken: getAuthTokenNoThrow() as string,
            data,
        });
        if (step) window.location.reload();
    };

    return (
        <div
            className={
                "flex-grow break-inside-avoid mb-1 relative rounded-lg border-2 border-white " +
                "bg-slate-100 dark:bg-slate-800 dark:border-gray-700 px-6 py-1 shadow-md space-x-3" +
                "hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500 " +
                "grid grid-cols-7"
            }
        >
            <></>
            <div className="col-span-3 my-auto">
                <span className="absolute inset-0" aria-hidden="true" />
                <p className="">
                    <span className=" text-sm font-medium text-gray-900 dark:text-green-500 my-auto py-auto">
                        {entry.title}
                    </span>
                    <span className="ml-3 text-xs text-blue-400 ">(ID: {entry.id})</span>
                </p>
                {entry.businessRuleGroups?.length > 0 && (
                    <div className="my-0">
                        {entry.businessRuleGroups.map((bg: BusinessRuleGroup) => {
                            return (
                                <div
                                    key={bg.id}
                                    className="text-sm my-1  dark:border-gray-600  dark:text-gray-400 inline-block mr-3"
                                >
                                    <Badge color={bg.isQualify ? "green" : "red"} label={bg.name} />
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
            <div className="col-span-2 my-auto text-sm text-gray-500 dark:text-gray-400">
                Stage: {entry.stageName}
                <Badge
                    color={entry.ruleGroupsAreOr ? "purple" : "green"}
                    label={entry.ruleGroupsAreOr ? "OR" : "AND"}
                />
            </div>
            <div className="col-span-2 grid grid-cols-4 gap-1">
                <ButtonNeoGen
                    text={"Edit"}
                    onClick={() => {
                        setStepIsEdit(true);
                        setId(entry.id);
                        setShow(true);
                    }}
                    icon={"fas fa-edit"}
                    size={"xxs"}
                    className={
                        "bg-indigo-500 hover:bg-indigo-700 text-white font-bold rounded z-10 mx-auto my-auto px-auto py-auto"
                    }
                />
                <ButtonNeoGen
                    text={"Rules"}
                    onClick={() => {
                        setIsStep(true);
                        setId(entry.id);
                        setShowEditRules(true);
                    }}
                    icon={"fas fa-shield-alt"}
                    size={"xxs"}
                    type={"info"}
                    className={"z-10 mx-auto my-auto px-auto py-auto"}
                />

                <ButtonNeoGen
                    text={"Delete"}
                    onClick={() => {
                        Swal.fire({
                            title: "Are you sure you want to delete this step?",
                            text: "You won't be able to revert this!",
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#3085d6",
                            cancelButtonColor: "#d33",
                            confirmButtonText: "Yes, delete it!",
                        }).then((result) => {
                            if (result.isConfirmed) {
                                // TODO - delete associated business rules
                                processflowService.deleteByID(entry.id).then(() => {
                                    cache.invalidateQueries(["processflow-entries"]);
                                    setEntries(
                                        entries?.filter((_entry: Entry, _index: number) => {
                                            return entry.id !== _entry.id;
                                        }) ?? [],
                                    );
                                });
                            }
                        });
                    }}
                    type={"danger"}
                    size={"xxs"}
                    icon={"fas fa-trash-alt"}
                    className={
                        "bg-red-500 hover:bg-red-700 text-white font-bold rounded z-10  mx-auto my-auto px-auto py-auto"
                    }
                />

                <ButtonNeoGen
                    text="Duplicate"
                    onClick={() =>
                        handleDuplicateStep({
                            ...entry,
                            id: undefined,
                            stageName: undefined,
                            checkboxEntries: entry.checkboxEntries || "[]",
                            fileUploadEntries: entry.fileUploadEntries || "[]",
                            stateEntries: entry.stateEntries || "[]",
                            selectBoxEntries: entry.selectBoxEntries || "[]",
                            textareaEntries: entry.textareaEntries || "[]",
                            textfieldEntries: entry.textfieldEntries || "[]",
                            yesNoEntries: entry.yesNoEntries || "[]",
                            datePickerEntries: entry.datePickerEntries || "[]",
                            title: `${entry.title} (Duplicated)`,
                            businessRuleGroups: undefined,
                        })
                    }
                    type="primary"
                    size={"xxs"}
                    // icon={"fal fa-clone"}
                    className={
                        "bg-indigo-500 hover:bg-indigo-700 text-white font-bold rounded z-10  mx-auto my-auto px-auto py-auto"
                    }
                />
            </div>
        </div>
    );
}
