import { Path } from "react-hook-form";
import { ExclamationCircleIcon } from "@heroicons/react/24/solid";
import { useState, useEffect } from "react";
import useDebounce from "../../hooks/useDebounce";
import { Label } from "../label";

type EinNumberInputProps = {
    label?: Path<any>;
    required?: boolean;
    value: string;
    setValue?: (e: string) => void;
    onChange?: (e: string) => void;
    name?: string;
    fieldState?: any;
    rules?: any;
    errors?: string[];
    type?: string;
    className?: string;
    autoComplete?: string;
    description?: string;
    placeholder?: string;
    darkBackground?: boolean;
    noDebounce?: boolean;
    password?: boolean;
    noMargin?: boolean;
    disabled?: boolean;
};

export default function EinNumberInput({
    darkBackground,
    placeholder,
    value,
    setValue,
    onChange,
    type,
    className,
    name,
    label,
    required,
    rules,
    description,
    errors,
    noDebounce,
    password,
    noMargin,
    disabled,
    ...rest
}: EinNumberInputProps) {
    const [text, setText] = useState(value);
    const debounced = useDebounce(text, 500);
    const [isDirty, setIsDirty] = useState(false);

    useEffect(() => {
        if (typeof setValue === "function") {
            if (isDirty) {
                setValue(formatEIN(debounced));
            }
        }
        if (typeof onChange === "function") {
            onChange(formatEIN(debounced));
        }
        setText(debounced);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debounced]);

    useEffect(() => {
        setText(value);
    }, [value]);

    const formatEIN = (input: string) => {
        const digits = input.replace(/\D/g, "");
        if (digits.length > 9) return digits.slice(0, 9); // Limit to 9 digits
        return digits.slice(0, 2) + (digits.length > 2 ? "-" : "") + digits.slice(2);
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value.replace(/\D/g, "");
        if (newValue.length > 9) return; // Limit input to 9 digits
        setIsDirty(true);
        if (noDebounce) {
            if (typeof setValue === "function") {
                setValue(formatEIN(newValue));
            }
            if (typeof onChange === "function") {
                onChange(formatEIN(newValue));
            }
            setText(formatEIN(newValue));
        } else {
            setText(formatEIN(newValue));
        }
    };

    return (
        <>
            <div className="">
                <div className={noMargin ? "m-0" : "mb-5"}>
                    {label === "" || !label ? (
                        ""
                    ) : (
                        <div className="flex justify-between items-center">
                            <Label
                                text={label || ""}
                                htmlFor="email"
                                isDarkBackground={!!darkBackground}
                                isRequired={!!(required && !errors)}
                            />
                        </div>
                    )}
                    <div className={(noMargin ? "m-0" : "mt-1 ") + "relative rounded-md"}>
                        <input
                            id={label}
                            type="text" // Changed type to text for EIN
                            disabled={disabled}
                            {...rest}
                            value={text}
                            onChange={handleChange}
                            className={
                                className +
                                " dark:bg-gray-800  " +
                                (errors
                                    ? "block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                                    : "shadow-sm focus:ring-indigo-500 placeholder-gray-300 dark:placeholder-gray-700 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md dark:bg-gray-900  dark:border-gray-900 dark:text-gray-300")
                            }
                            placeholder={placeholder ?? "XX-XXXXXXX"}
                        />
                        {(errors ?? []).length > 0 && (
                            <div
                                className={
                                    "absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none " +
                                    (description ? "-mt-6 " : "")
                                }
                            >
                                <ExclamationCircleIcon
                                    id={`exclamationCircle${
                                        label ? "-" + label.replace(/\s/g, "").trim().split("?")[0] : ""
                                    }`}
                                    className="h-5 w-5 text-red-500"
                                    aria-hidden="true"
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}
