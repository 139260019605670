import { useNavigate } from "react-router";
import { formatCurrency } from "../../billing/utils";
import {
    RepairDeal,
    affiliateCommissionPercentage,
    commissionOnSubAffiliatePercentage,
} from "../affiliate-repair-deals-page";

export const RepairDealsCard = ({ repairDeal }: { repairDeal: RepairDeal }) => {
    const navigate = useNavigate();
    const commissionPercentage = affiliateCommissionPercentage;
    return (
        <div
            className={`shadow-md rounded-lg p-3  cursor-pointer mb-4 ${"bg-indigo-50 dark:bg-indigo-900"}`}
            onClick={() => navigate(`/companies/${repairDeal.company?.id}`)}
        >
            <div className="mb-2">
                <p className="text-xs font-bold">{repairDeal.company?.name}</p>
                <div className={`${"bg-indigo-200 dark:bg-indigo-800"} rounded-md text-xxxs px-2 py-1 mt-1 w-fit`}>
                    {repairDeal.repairStage?.title || "Unknown"}
                </div>
            </div>
            <p className="text-xs ">
                Total commissionable products:{" "}
                <span className="font-bold">{formatCurrency(repairDeal.totalCommissionableLineItems || 0)}</span>
            </p>
            <div className="border-b-[1px] border-gray-400 my-2" />
            <p className={`text-xs text-indigo-400" font-bold`}>Commission</p>
            {repairDeal.referrer ? (
                <>
                    <p className="text-xs ">
                        Total commission:{" "}
                        <span className="font-bold">
                            {formatCurrency((repairDeal?.totalCommissionableLineItems || 0) * commissionPercentage)}{" "}
                            (15%)
                        </span>
                    </p>
                    <p className="text-xs ">
                        Referrer commission:{" "}
                        <span className="font-bold">
                            {formatCurrency(
                                ((repairDeal?.totalCommissionableLineItems || 0) *
                                    commissionPercentage *
                                    repairDeal.referrer.commissionPercentage) /
                                    100,
                            )}{" "}
                            ({repairDeal.referrer.commissionPercentage}%)
                        </span>
                    </p>
                    <p className="text-xs ">
                        Due: <span className="font-bold">{formatCurrency(repairDeal.totalCommissionDue)}</span>
                    </p>
                    <p className="text-xs ">
                        Paid: <span className="font-bold">{formatCurrency(repairDeal.totalCommissionPaid)}</span>
                    </p>
                </>
            ) : (
                <>
                    <p className="text-xs ">
                        Total:{" "}
                        <span className="font-bold">
                            {formatCurrency((repairDeal?.totalCommissionableLineItems || 0) * commissionPercentage)}{" "}
                            (15%)
                        </span>
                    </p>
                    <p className="text-xs ">
                        Due: <span className="font-bold">{formatCurrency(repairDeal.totalCommissionDue)}</span>
                    </p>
                    <p className="text-xs ">
                        Paid: <span className="font-bold">{formatCurrency(repairDeal.totalCommissionPaid)}</span>
                    </p>
                </>
            )}
            {/* <div className="border-b-[1px] border-gray-400 my-2" />
            <p className="text-xs ">
                {isMainAffiliate ? "Affiliate" : "Sub-Affiliate"}:{" "}
                <span className="font-bold">
                    {repairDeal.affiliate?.firstName} {repairDeal.affiliate?.lastName}
                </span>
            </p> */}
        </div>
    );
};
