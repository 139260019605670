import Swal from "sweetalert2";
import { downloadFile } from "../sections/utilities/download-file";
import fileService from "../services/file.service";
import { FileUpload, FileUploadWithMultiple } from "../typings/api/file-upload";
import ButtonNeoGen from "./button-neogen";
import * as Sentry from "@sentry/react";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import ModalDialog from "./modal-dialog";
import { pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
import { Document, Page } from "react-pdf";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";

export default function FileUploadWidgetEntry({
    // deleteFile,
    onFileChange,
    setIsReplacing,
    file,
    newFileDetails,
    setNewFileDetails,
    passUpFileDetails,
}: FileUploadWidgetEntryProps) {
    const queryCache = useQueryClient();
    const [showFileUpload, setShowFileUpload] = useState(false);
    const [numPages, setNumPages] = useState<number>();
    const [pageNumber, setPageNumber] = useState<number>(1);

    useEffect(() => {
        const page = parseInt(localStorage.getItem(`${file.id}-pageNumber`) || "1");
        setPageNumber(page);
    }, []);

    async function deleteFile(file: FileUpload): Promise<void> {
        try {
            // Delete By ID will set the file to archived
            fileService.deleteByID(file.id).then(() => {
                // Update the processflow progress to reflect the deletion
                const existingArray = [...newFileDetails.multipleFiles];
                const index = existingArray.findIndex((x) => x.id === file.id);
                existingArray.splice(index, 1);
                setNewFileDetails({
                    multipleFiles: existingArray,
                    hasMultiple: true,
                });
                // file.isArchived = true;

                passUpFileDetails({
                    multipleFiles: existingArray,
                    hasMultiple: true,
                });
                setIsReplacing(false);
                queryCache.invalidateQueries(["files"]);
                Swal.fire({
                    text: "File deleted successfully",
                    icon: "success",
                    showCancelButton: false,
                    // showOkButton: true,
                    // cancelButtonColor: "#3085d6",
                    // cancelButtonText: "No, cancel!",
                    showConfirmButton: false,
                    timer: 2000,
                });
            });
        } catch (error) {
            console.error(error);
            Sentry.captureException(error);
        }
    }

    if (file.isArchived) {
        return <></>;
    }

    function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
        setNumPages(numPages);
    }

    return (
        <>
            {showFileUpload && (
                <ModalDialog
                    size="lg"
                    title="File preview"
                    close={() => setShowFileUpload(false)}
                    show={true}
                    showOk={false}
                    showCancel={false}
                >
                    <div>
                        <div className="flex justify-between">
                            <div className="flex align-middle gap-2">
                                <ButtonNeoGen
                                    disabled={pageNumber === 1}
                                    onClick={() => {
                                        const pn = numPages && pageNumber > 1 ? pageNumber - 1 : pageNumber;
                                        setPageNumber(pn);
                                        localStorage.setItem(`${file.id}-pageNumber`, pn.toString());
                                    }}
                                >
                                    Back
                                </ButtonNeoGen>
                                <ButtonNeoGen
                                    disabled={pageNumber === numPages}
                                    onClick={() => {
                                        const pn = numPages && pageNumber < numPages ? pageNumber + 1 : pageNumber;
                                        setPageNumber(pn);
                                        localStorage.setItem(`${file.id}-pageNumber`, pn.toString());
                                    }}
                                >
                                    Next
                                </ButtonNeoGen>
                            </div>
                            <p>
                                Page {pageNumber} of {numPages}
                            </p>
                        </div>
                        <Document
                            file={`${process.env.REACT_APP_API_URL}/files/${file.filename}`}
                            onLoadSuccess={onDocumentLoadSuccess}
                        >
                            <Page pageNumber={pageNumber} scale={1.5} className="m-auto text-center" />
                        </Document>
                    </div>
                </ModalDialog>
            )}
            <div className="my-auto border dark:border-slate-600 mx-auto p-2 rounded-lg bg-white dark:bg-slate-800 shadow overflow-none">
                <div className="grid gap-5">
                    <div className="grid-cols-1 flex flex-auto gap-5">
                        <div className="grow my-auto break-all"> {file.originalFilename}</div>
                        <div className="flex flex-col">
                            <ButtonNeoGen
                                type="primary"
                                size="xs"
                                className="shrink my-auto w-full"
                                iconAlign="left"
                                text="View"
                                onClick={() => setShowFileUpload(true)}
                            />
                            <ButtonNeoGen
                                size="xs"
                                type="primary"
                                className="shrink my-auto"
                                iconAlign="left"
                                text="Download"
                                onClick={() => {
                                    return downloadFile(file?.filename ?? "", file?.originalFilename ?? "");
                                }}
                            />
                        </div>
                        <ButtonNeoGen
                            type="danger"
                            icon="fas fa-times"
                            iconAlign="center"
                            className="-mr-1 -mt-1 h-5 w-5 text-center ml-auto mb-auto relative top-0 right-0 bg-red-500"
                            onClick={() => {
                                Swal.fire({
                                    text: "Are you sure you want to delete this file?",
                                    icon: "warning",
                                    showCancelButton: true,
                                    confirmButtonColor: "#d33",
                                    confirmButtonText: "Yes, delete it!",
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        deleteFile(file).then(() => {
                                            Swal.fire({
                                                title: "Success",
                                                text: "File deleted",
                                                icon: "success",
                                                showConfirmButton: false,
                                                timer: 1500,
                                            });
                                        });
                                    }
                                });
                            }}
                            size="xxs"
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

type FileUploadWidgetEntryProps = {
    file: FileUpload;
    setIsReplacing: (isReplacing: boolean) => void;
    newFileDetails: {
        multipleFiles: FileUpload[];
        hasMultiple: true;
    };
    passUpFileDetails: (fileUploadWithMultiple: FileUploadWithMultiple) => void;
    setNewFileDetails: (newFileDetails: { multipleFiles: FileUpload[]; hasMultiple: true }) => void;

    // deleteFile: (file: FileUpload) => Promise<boolean>;
    onFileChange: (file: File) => void;
};
