import { format, formatRelative } from "date-fns";
import { differenceInDays, getYear } from "date-fns";

export function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const formatDate = (date: Date, formatStr?: string) => {
    return format(date, formatStr || "d MMMM yyy HH:mm:ss");
};

export const formateDateNew = ({
    date,
    format: passedFormat,
    withTime,
    relative,
}: {
    date: Date;
    format?: string;
    withTime?: boolean;
    relative?: boolean;
}) => {
    const now = new Date();

    const isSameYear = getYear(date) === getYear(now);

    const diff = differenceInDays(now, date);
    const useRelative = relative && !passedFormat && diff < 2;

    const defaultFormat = `d LLLL yyyy ${withTime ? `p` : ""}`;

    const dateString = formatDate(date, passedFormat || defaultFormat);

    const relativeDateString = capitalizeFirstLetter(formatRelative(date, now));

    return useRelative
        ? `${relativeDateString.replace("Last ", "").split(" ")[0]} ${withTime ? formatDate(date, "p") : ""}`
        : dateString;
};
