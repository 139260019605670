import { z } from "zod";
import { getAuthTokenNoThrow } from "../../../services/auth-header";
import { useForm } from "../../../hooks/useForm";
import { useMutation } from "@tanstack/react-query";
import ModalDialog from "../../../layout/modal-dialog";
import { Form } from "../../../layout/form/form";
import ButtonNeoGen from "../../../layout/button-neogen";
import { createReferrer } from "../actions/create-referrer";
import { SelectField } from "../../../layout/form/select-field";
import { NumberField } from "../../../layout/form/number-field";
import { User } from "../../../jason-proof-of-concept/users/domain/user";
import { useMemo, useState } from "react";
import Toggle from "../../../layout/toggle";
import { TextField } from "../../../layout/form/text-field";
import { EmailField } from "../../../layout/form/email-field";
import { createUser } from "../../../jason-proof-of-concept/users/actions/create-user";
import roleGroupService from "../../../services/role-group.service";
import { PhoneNumberField } from "../../../layout/form/phone-number-field";

const schema = z.object({
    referrerId: z.string().uuid(),
    affiliateId: z.string().uuid().nullish(),
    commissionPercentage: z.number().max(100).min(0),
    affiliateFirstName: z.string().min(1).nullish(),
    affiliateLastName: z.string().min(1).nullish(),
    affiliateEmail: z.string().email().nullish(),
    affiliatePhone: z.string().nullish(),
});

type Data = z.infer<typeof schema>;

export const AddAffiliateToReferrerModal = ({
    onClose,
    onCreated,
    users,
    referrer,
    hideCommission,
}: {
    onClose: () => any;
    onCreated: () => void;
    users: User[];
    referrer?: User;
    hideCommission?: boolean;
}) => {
    const authToken = getAuthTokenNoThrow() || "no-auth-token";
    const form = useForm({ schema, defaultValues: { referrerId: referrer?.id as string } });
    const [showExistingUsers, setShowExistingUsers] = useState(0);

    const mutation = useMutation({
        mutationFn: async (data: Data) => {
            if (!data.affiliateId) {
                const createdAffiliateUser = await createUser({
                    authToken,
                    data: {
                        email: data.affiliateEmail || "",
                        firstName: data.affiliateFirstName,
                        lastName: data.affiliateLastName,
                        phone: data.affiliatePhone,
                        emailVerified: true,
                        isTempPassword: true,
                    },
                });

                const createdRoleGroup = await roleGroupService.postURL("role-group-users", {
                    clearErcUserId: createdAffiliateUser.id,
                    roleGroupsId: 7,
                });

                const result = await createReferrer({
                    authToken,
                    data: {
                        referrerId: data.referrerId,
                        affiliateId: createdAffiliateUser.id,
                        commissionPercentage: data.commissionPercentage,
                    },
                });

                return result;
            } else {
                const result = await createReferrer({
                    authToken,
                    data: {
                        referrerId: data.referrerId,
                        affiliateId: data.affiliateId,
                        commissionPercentage: data.commissionPercentage,
                    },
                });
                return result;
            }
        },
    });

    const handleSubmit = async (data: Data) => {
        const result = await mutation.mutateAsync(data);
        onCreated();
    };

    const referrerUsers = useMemo(
        () => users.filter((u) => u.roleGroups?.some((rg) => rg.name === "Referrer")),
        [users],
    );

    return (
        <ModalDialog
            size="md"
            show
            title={
                referrer ? `Add Affiliate to ${referrer.firstName} ${referrer.lastName}` : "Add Affiliate to Referrer"
            }
            close={onClose}
            showOk={false}
            showCancel={false}
        >
            <Form onSubmit={form.handleSubmit(handleSubmit)} error={mutation.error as any}>
                <div className="grid grid-cols-1 gap-3">
                    {!referrer && (
                        <>
                            <SelectField
                                label="Referrer"
                                {...form.getFieldProps("referrerId")}
                                options={referrerUsers.map((u) => ({
                                    label: u.firstName + " " + u.lastName + " (" + u.email + ")",
                                    value: u.id,
                                }))}
                            />
                            <div className="border-b-2 border-gray-200 dark:border-gray-900 my-2" />
                        </>
                    )}
                    <p className="text-gray-400font-normal tracking-wider leading-normal text-gray-400 dark:text-gray-400">
                        Affiliate
                    </p>
                    <Toggle
                        options={["Set existing user", "Set new user"]}
                        setSelectedIndex={setShowExistingUsers}
                        selectedIndex={showExistingUsers}
                        className="min-w-[200px]"
                    />
                    {showExistingUsers === 0 ? (
                        <SelectField
                            label="Affiliate"
                            {...form.getFieldProps("affiliateId")}
                            options={users.map((u) => ({
                                label: u.firstName + " " + u.lastName + " (" + u.email + ")",
                                value: u.id,
                            }))}
                        />
                    ) : (
                        <div className="grid grid-cols-1 gap-3">
                            <TextField
                                label="First name"
                                {...form.getFieldProps("affiliateFirstName")}
                                isRequired={true}
                            />
                            <TextField
                                label="Last name"
                                {...form.getFieldProps("affiliateLastName")}
                                isRequired={true}
                            />
                            <EmailField
                                autoComplete="username"
                                label="Email Address"
                                {...form.getFieldProps("affiliateEmail")}
                            />
                            <PhoneNumberField label="Phone" {...form.getFieldProps("affiliatePhone")} />
                        </div>
                    )}
                    <div className="border-b-2 border-gray-200 dark:border-gray-900 my-2" />
                    {!hideCommission && (
                        <NumberField
                            label="Override commission % (default 10%)"
                            {...form.getFieldProps("commissionPercentage")}
                            defaultValue={10}
                        />
                    )}
                </div>
                <ButtonNeoGen block type="submit" disabled={mutation.isLoading}>
                    Add affiliate to referrer
                </ButtonNeoGen>
            </Form>
        </ModalDialog>
    );
};
