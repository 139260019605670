import ModalDialog from "../../layout/modal-dialog";
import { MandateMandateEffect } from "../../mandate-mandate-effects/domain/mandate-mandate-effect";

export const MandateEffectModal = ({
    onClose,
    mandateEffect,
    mandateMandateEffect,
}: {
    onClose: () => void;
    mandateEffect: string;
    mandateMandateEffect: MandateMandateEffect;
}) => {
    return (
        <ModalDialog title={`Mandate Effect - ${mandateEffect}`} show close={onClose} size="md">
            {mandateMandateEffect.impact}
        </ModalDialog>
    );
};
