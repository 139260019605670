import { useEffect, useState } from "react";
import { Select } from "../../layout/form/select-input";
import ModalDialog from "../../layout/modal-dialog";
import { LineItemsType } from "../../services/line-items.service";
import { Currency } from "../../billing/components";
import ButtonNeoGen from "../../layout/button-neogen";
import { CurrencyInput } from "../../layout/form/currency-input";
import { NumberInput } from "../../layout/form/number-input";
import { Form } from "../../layout/form/form";
import { TextField } from "../../layout/form/text-field";
import { z } from "zod";
import { useForm } from "../../hooks/useForm";
import { Company } from "../domain/company";
import { useMutation, useQuery } from "@tanstack/react-query";
import { updateCompany } from "../actions/update-company";
import { createInvoice } from "../../invoices/actions/create-invoice";
import { useAuth } from "../../auth/use-auth";
import { SelectField } from "../../layout/form/select-field";
import { TBody, Table, Td, Tr } from "../../layout/table";
import { addDays } from "date-fns";
import { EmailField } from "../../layout/form/email-field";
import { getUser } from "../../jason-proof-of-concept/users/actions/get-user";
import { updateUser } from "../../jason-proof-of-concept/users/actions/update-user";
import { Label } from "../../layout/label";
import { last } from "lodash";
import { PhoneNumberField } from "../../layout/form/phone-number-field";

const billingDetailsFormSchema = z.object({
    lineItemId: z.number(),
    businessName: z.string(),
    state: z.string().nullish(),
    city: z.string().nullish(),
    ownerFirstName: z.string().nullish(),
    ownerLastName: z.string().nullish(),
    ownerEmail: z.string().email(),
    ownerPhone: z.string().nullish(),
});

type Data = z.infer<typeof billingDetailsFormSchema>;

export const NewInvoiceFlowModal = ({
    onClose,
    lineItems,
    company,
}: {
    onClose: () => void;
    lineItems: LineItemsType[];
    company: Company;
}) => {
    const auth = useAuth();
    const authToken = auth.expectAuthToken();
    const [discountAmount, setDiscountAmount] = useState<number>(0);
    const [discountPercent, setDiscountPercent] = useState<number>(0);
    const [discountType, setDiscountType] = useState<"amount" | "percentage">("amount");
    const [paymentTerms, setPaymentTerms] = useState<"upfront" | "30days" | "60days" | "90days" | "120days">("upfront");

    const form = useForm({
        schema: billingDetailsFormSchema,
        defaultValues: {
            businessName: company.name || undefined,
            state: company.state,
            city: company.city,
            ownerFirstName: company.ownedBy?.firstName,
            ownerLastName: company.ownedBy?.lastName,
            ownerEmail: company.ownedBy?.email,
            ownerPhone: company.ownedBy?.phone,
        },
    });
    const formValues = form.watch();

    const paymentTermOptions = [
        {
            value: "upfront",
            label: "Upfront",
            days: 0,
        },
        {
            value: "30days",
            label: "30 Days",
            days: 30,
        },
        {
            value: "60days",
            label: "60 Days",
            days: 60,
        },
        {
            value: "90days",
            label: "90 Days",
            days: 90,
        },
        {
            value: "120days",
            label: "120 Days",
            days: 120,
        },
    ];

    const packageOptions = lineItems
        .filter((li) => li.name.startsWith("Tax Advocate"))
        .map((li) => ({ label: li.name, value: li.id as number }));

    const selectedLineItem = lineItems.find((li) => li.id === formValues.lineItemId);
    const basePrice = selectedLineItem?.price || 0;
    const finalPrice = basePrice - discountAmount;

    const getOwnerQuery = useQuery({
        queryKey: ["companyOwner", company.ownedById],
        queryFn: async () => {
            if (!company.ownedById) {
                throw new Error("No owner for company");
            }
            const user = await getUser({ authToken, id: company.ownedById });
            form.setValue("ownerFirstName", user.firstName);
            form.setValue("ownerLastName", user.lastName);
            form.setValue("ownerEmail", user.email);
            form.setValue("ownerPhone", user.phone);
            return user;
        },
        enabled: !!company.ownedById,
    });
    const owner = getOwnerQuery.data;

    useEffect(() => {
        if (discountType === "amount") {
            setDiscountPercent((discountAmount / basePrice) * 100);
        }
        if (discountType === "percentage") {
            setDiscountAmount((discountPercent / 100) * basePrice);
        }
    }, [basePrice]);

    const mutation = useMutation({
        mutationFn: async (data: Data) => {
            if (data.businessName !== company.name) {
                await updateCompany({ authToken, id: company.id, data: { name: data.businessName } });
            }

            const dueInDays = paymentTermOptions.find((pt) => pt.value === paymentTerms)?.days;
            const dueDate = dueInDays ? addDays(new Date(), dueInDays) : undefined;

            const invoice = await createInvoice({
                authToken,
                data: {
                    companyId: company.id,
                    ...(dueDate ? { dueDate } : {}),
                    lineItems: [{ lineItemId: data.lineItemId, price: basePrice, discount: discountAmount || 0 }],
                    contactFirstName: formValues.ownerFirstName,
                    contactLastName: formValues.ownerLastName,
                    contactEmail: formValues.ownerEmail,
                    contactPhone: formValues.ownerPhone,
                },
            });
            return { invoice };
        },
    });

    const handleSubmit = async (data: Data) => {
        await mutation.mutateAsync(data);
    };

    const invoice = mutation.data?.invoice;

    const user = window.localStorage.getItem("user") ? JSON.parse(window.localStorage.getItem("user") ?? "") : {};
    const isAdmin = (user?.user?.roleGroups || []).find((r: any) => r.id === 4);

    const stripeUrl = invoice?.stripeInvoiceUrl;

    return (
        <ModalDialog
            show
            close={onClose}
            title={invoice ? "Invoice Issued To Customer" : "Issue Invoice To Customer"}
            showOk={false}
            showCancel={false}
            size="sm"
        >
            {invoice ? (
                <div className="flex flex-col gap-4">
                    <div style={{ textAlign: "center" }}>
                        The invoice has been issued to the customer and they should receive it in their email inbox at{" "}
                        {invoice.contactEmail || owner?.email || "No email"}
                    </div>
                    <div style={{ textAlign: "center" }}>Open the invoice below to see when it gets paid:</div>
                    <div style={{ textAlign: "center" }}>
                        {invoice.qbInvoiceId && (
                            <a
                                href={`https://qbo.intuit.com/app/invoice?txnId=${invoice.qbInvoiceId}`}
                                target="_blank"
                                rel="noreferrer"
                                style={{ textDecoration: "underline" }}
                            >
                                View invoice {invoice.invoiceNumber} on QuickBooks
                            </a>
                        )}
                        {stripeUrl && (
                            <a
                                href={stripeUrl}
                                target="_blank"
                                rel="noreferrer"
                                style={{ textDecoration: "underline" }}
                            >
                                View invoice {invoice.invoiceNumber} on Stripe
                            </a>
                        )}
                    </div>
                </div>
            ) : (
                <Form onSubmit={form.handleSubmit(handleSubmit as any)} error={mutation.error as any}>
                    <div className="flex flex-col gap-2">
                        <div className="flex-1">
                            <SelectField
                                label="Select Package"
                                options={packageOptions}
                                {...form.getFieldProps("lineItemId")}
                            />
                        </div>
                        <div className="flex-1">
                            <div>
                                <div className="flex justify-between gap-4 items-center">
                                    <div>
                                        <Label text="Discount Amount" />
                                        <CurrencyInput
                                            value={discountAmount}
                                            onChange={(e) => {
                                                setDiscountType("amount");
                                                const amount = Number.parseFloat(e.target.value);
                                                setDiscountAmount(amount);
                                                const percent = (amount / basePrice) * 100;
                                                setDiscountPercent(percent);
                                            }}
                                        />
                                    </div>
                                    <div>Or</div>
                                    <div>
                                        <Label text="Discount Percent" />
                                        <NumberInput
                                            value={discountPercent}
                                            onChange={(e) => {
                                                setDiscountType("percentage");
                                                const percent = Number.parseFloat(e.target.value);
                                                setDiscountPercent(percent);
                                                setDiscountAmount(basePrice * (percent / 100));
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <Label text="Set Payment Terms" />
                            <div>
                                <Select
                                    options={paymentTermOptions}
                                    value={paymentTerms}
                                    onChange={(e) => {
                                        setPaymentTerms(e as any);
                                    }}
                                />
                            </div>
                        </div>
                        <hr />
                        <div>
                            <TextField label="Business Name" {...form.getFieldProps("businessName")} />
                            <div className="flex flex-row gap-5 ">
                                <div className="flex-1">
                                    <TextField label="State" {...form.getFieldProps("state")} />
                                </div>
                                <div className="flex-1">
                                    <TextField label="City" {...form.getFieldProps("city")} />
                                </div>
                            </div>
                            <div className="flex flex-row gap-5">
                                <div className="flex-1">
                                    <TextField label="First Name" {...form.getFieldProps("ownerFirstName")} />
                                </div>
                                <div className="flex-1">
                                    <TextField label="Last Name" {...form.getFieldProps("ownerLastName")} />
                                </div>
                            </div>
                            <div className="flex flex-row gap-5">
                                <div className="flex-1">
                                    <EmailField label="Email" {...form.getFieldProps("ownerEmail")} />
                                </div>
                                <div className="flex-1">
                                    <PhoneNumberField label="Phone" {...form.getFieldProps("ownerPhone")} />
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div>
                            <div>
                                <table style={{ tableLayout: "fixed", width: "100%", borderCollapse: "collapse" }}>
                                    <tbody>
                                        <tr style={{ borderBottom: "1px solid lightgray" }}>
                                            <td>Base Price</td>
                                            <td style={{ textAlign: "right" }}>
                                                <div>
                                                    <Currency amount={basePrice} />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ borderBottom: "1px solid lightgray" }}>
                                            <td>Discount</td>
                                            <td style={{ textAlign: "right", color: "gray" }}>
                                                {discountAmount ? (
                                                    <>
                                                        -<Currency amount={discountAmount} />
                                                    </>
                                                ) : (
                                                    <Currency amount={discountAmount} />
                                                )}
                                            </td>
                                        </tr>
                                        <tr style={{ borderBottom: "1px solid lightgray" }}>
                                            <td>Final Price</td>
                                            <td style={{ textAlign: "right", fontWeight: "bold" }}>
                                                <Currency amount={finalPrice} />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="flex justify-end gap-4 mt-2">
                            <ButtonNeoGen type="submit" isLoading={mutation.isLoading}>
                                Issue Invoice
                            </ButtonNeoGen>
                        </div>
                    </div>
                </Form>
            )}
        </ModalDialog>
    );
};
