export type CompanyGroup = {
    id?: number;
    name: string;
};

import APIService from "./api.service";

class CompanyGroupService extends APIService<CompanyGroup> {
    constructor() {
        super("company-groups");
    }
}

export default new CompanyGroupService();
