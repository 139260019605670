import APIService from "./api.service";

export type BulletPoint = {
    id?: number;
    name: string;
    description: string;
    bulletPointGroup: number;
};

class BulletPointService extends APIService<BulletPoint> {
    constructor() {
        super("/bullet-points");
    }
}

export default new BulletPointService();
