import { format } from "date-fns";
import { formateDateNew } from "../mandates/utils";

export const DateDisplay = ({ date }: { date: Date }) => {
    return <span>{format(date, "dd MMM yyyy")}</span>;
};

export const DateDisplay2 = ({ date }: { date?: Date | null }) => {
    return <span>{date ? formateDateNew({ date }) : "-"}</span>;
};
