import { RepairDeal } from "../affiliate-repair-deals-page";
import { RepairDealsCard } from "./repair-deals-card";

export const RepairDealsColumn = ({ title, repairDeals }: { title: string; repairDeals: RepairDeal[] }) => {
    return (
        <div className="bg-white dark:bg-gray-700 w-[250px] max-h-[calc(100vh-460px)] rounded-xl flex flex-col">
            <div className="bg-gray-300 dark:bg-gray-800 rounded-t-xl p-4 !h-[70px] w-[250px] flex items-center text-center justify-center">
                <div className="flex items-center gap-2 justify-center">
                    <h3 className="text-sm">{title}</h3>
                    {repairDeals.length > 0 && (
                        <div className="bg-white dark:bg-gray-900 rounded-full py-1 px-2.5 text-xs">
                            {repairDeals.length}
                        </div>
                    )}
                </div>
            </div>
            <div className="py-4 px-2 w-[250px] overflow-y-auto">
                {repairDeals.map((deal) => (
                    <RepairDealsCard key={deal.company?.id} repairDeal={deal} />
                ))}
            </div>
        </div>
    );
};
