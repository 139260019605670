import { Modal } from "react-bootstrap";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, Modifier } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Component, useEffect, useState } from "react";
import { convertToHTML } from "draft-convert";
import { ContentState, convertToRaw, convertFromRaw } from "draft-js";
import DOMPurify from "dompurify";
import authService from "../../../services/auth.service";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import helpDocsService from "../../../services/help-docs.service";
import { NeoGenHelpDocs } from "../../../typings/api";
import "./add-faq.css";
// import { faTags } from "@fortawesome/pro-regular-svg-icons";
import { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/outline";
import ModalDialog from "../../../layout/modal-dialog";
import InputNeoGen from "../../../layout/input-neogen";
import { useForm } from "react-hook-form";
import { waitForAll } from "recoil";
import SelectNeoGen from "../../../layout/select-neogen";
import PrintPre from "../../../layout/print-pre";
import { useNavigate, useParams } from "react-router-dom";
import ButtonNeoGen from "../../../layout/button-neogen";
import ResponseVariable from "../../../layout/modals/response-variable";
import ResponseVariableModal from "./response-variable-modal";

import React, { MouseEventHandler } from "react";
import PropTypes from "prop-types";
import CustomOption from "./add-star";
import InputControlled from "../../../layout/input-controlled";
import helpDocsTemplateItemsService from "../../../services/help-docs-template-items.service";
// import { EditorState, Modifier } from "draft-js";

const defaultValues = {
    select: "",
    input: "",
};

export default function AddFaq(props: AddFaqProps) {
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm();
    const watchAllFields = watch();
    const cancelButtonRef = useRef(null);
    const queryCache = useQueryClient();
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [previousState, setPreviousState] = useState(EditorState.createEmpty());
    const [convertedContent, setConvertedContent] = useState(null);
    const [canAddFaqs, setCanAddFaqs] = useState(false);
    const [showAddFaq, setShowAddFaq] = useState(false);
    const [showResponseVariable, setShowResponseVariable] = useState(false);
    const [title, setTitle] = useState("");
    const [tags, setTags] = useState("");
    const [active, setActive] = useState(0);
    const [isPublic, setIsPublic] = useState(0);
    const [order, setOrder] = useState(0);
    const [existingEntry, setExistingEntry] = useState<NeoGenHelpDocs>();
    const { id } = useParams();
    const navigate = useNavigate();
    // const helpDocsQuery = useQuery(["HelpDocs"], async () => {
    //     let response = await helpDocsService.getAll();
    //     if (response) {
    //         return response.data;
    //     }
    // });
    useEffect(() => {
        if (id) {
            //
            helpDocsService.getOne(Number(id)).then((r) => {
                if (r) {
                    setExistingEntry(r.data);
                }
            });
        }
    }, [id]);
    useEffect(() => {
        authService.canIAccess("ADD_FAQ").then((r) => {
            setCanAddFaqs(r);
        });
        // console.error("x");
        if (existingEntry) {
            setTitle(existingEntry.title);
            setTags(existingEntry.tags);
            setIsPublic(existingEntry.public);
            setActive(existingEntry.active);
            setOrder(existingEntry.order ?? 0);
            setEditorState(EditorState.createWithContent(convertFromRaw(JSON.parse(existingEntry.body))));
        } else {
            setEditorState(EditorState.createEmpty());
        }
    }, [existingEntry]);

    const convertContentToHTML = () => {
        const currentContentAsHTML = convertToHTML({
            styleToHTML: (style: any) => {
                console.log(style);
                if (style === "BOLD") {
                    return <span style={{ fontWeight: "bold" }} />;
                }
                if (style.substring(0, 9) === "fontsize-") {
                    return <span style={{ fontSize: style.substring(9) + "px" }} />;
                }
            },
            blockToHTML: (block: any) => {
                console.log(block);
                if (block.type === "PARAGRAPH") {
                    return <p />;
                } else if (block.type === "unstyled") {
                    return <div style={{ minHeight: "2rem" }} />;
                }
            },
            entityToHTML: (entity: any, originalText: any) => {
                console.log(entity, originalText);
                if (entity.type === "LINK") {
                    return <a href={entity.data.url}>{originalText}</a>;
                }
                return originalText;
            },
        })(editorState.getCurrentContent(), { flat: true });
        console.log(convertToRaw(editorState.getCurrentContent()));
        setConvertedContent(currentContentAsHTML);
    };

    const onEditorStateChange = (editorState: any) => {
        // if (JSON.stringify(editorState) !== JSON.stringify(previousState)) {
        //     console.log(JSON.stringify(editorState));
        setEditorState(editorState);
        //     // convertContentToHTML();
        // }
        // setPreviousState(editorState);
    };

    function save() {
        console.log("Saving");
        const body = JSON.stringify(convertToRaw(editorState.getCurrentContent()));

        if (existingEntry) {
            existingEntry.body = body;
            existingEntry.tags = tags ?? "";
            existingEntry.title = title;
            (existingEntry.updated = new Date()),
                (existingEntry.created = new Date(existingEntry.created ?? new Date()));

            existingEntry.public = Number(isPublic);
            existingEntry.active = Number(active);
            existingEntry.order = Number(order);
            existingEntry.preview = existingEntry.preview ?? "";
            helpDocsService
                .patchURL(helpDocsService.endpoint + "/" + existingEntry.id, existingEntry)
                .then((r) => {
                    console.error(r);
                    queryCache.invalidateQueries(["HelpDocs"]).then((r: any) => {
                        queryCache.invalidateQueries(["help-docs"]).then((r: any) => {
                            console.log(r);
                            console.log("Invalidated");
                            // props.hide();
                            navigate("/faq");
                        });
                    });
                })
                .catch((e) => {
                    console.error("xxxx");
                    console.error(e.response);
                });
        } else {
            const newEntry: NeoGenHelpDocs = {
                title: title,
                body: body,
                tags: tags ?? "",
                updated: new Date(),
                created: new Date(),
                active: 0,
                public: 0,
                order: 0,
                preview: "",
            };
            helpDocsService.create(newEntry).then((r) => {
                console.log(r);
                queryCache.invalidateQueries(["HelpDocs"]).then((r2: any) => {
                    console.log("Invalidated");
                    // props.hide();
                    if (r) {
                        const helpDocsTemplateItem = {
                            helpDocId: r.data?.id,
                            templateId: Number(window.localStorage.getItem("selectedTemplate")),
                            order: 1000,
                        };
                        console.log(helpDocsTemplateItem);
                        helpDocsTemplateItemsService.create(helpDocsTemplateItem).then((r) => {
                            console.log(r);

                            queryCache.invalidateQueries(["help-docs-template-items"]).then((r: any) => {
                                navigate("/faq");
                            });
                        });
                    }
                });
            });
            Number(window.localStorage.getItem("selectedTemplate"));
        }

        console.log(body);
    }

    useEffect(() => {
        console.log(watchAllFields);
        if (watchAllFields.title) {
            setTitle(watchAllFields.title);
        }
        if (watchAllFields.tags) {
            setTags(watchAllFields.tags);
        }
    }, [watch, watchAllFields]);

    function addResponseVariable(val: string) {
        // if (quillRef.current) {
        //     quillRef.current.focus();
        //     const range = quillRef.current.editor?.getSelection();
        //     if (range) {
        //         quillRef.current.editor?.insertText(range.index, "{{" + val + "}}");
        //     }
        // }
    }

    const uploadCallback = (file: any) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader(); // eslint-disable-line no-undef
            reader.onload = (e) => resolve({ data: { link: e.target?.result } });
            reader.onerror = (e) => reject(e);
            reader.readAsDataURL(file);
        });
    };

    return (
        <>
            {/* <ModalNeoGen title="Add FAQ" show={props.show} close={() => props.hide()} size="lg" okAction={() => save()} okText="Save"> */}
            <div className="bg-white p-5 border rounded-xl h-full dark:bg-gray-900 dark:border-gray-700">
                <div className="mt-3  mb-5">
                    <div className="mt-2">
                        <form>
                            <div className="grid grid-cols-4 gap-5">
                                <div className="col-span-1">
                                    <InputNeoGen
                                        label="FAQ Title:"
                                        name="title"
                                        register={register}
                                        defaultValue={existingEntry?.title}
                                    />
                                </div>
                                <div className="col-span-1">
                                    <InputNeoGen label="Comma Separated Tags:" name="tags" register={register} />
                                </div>
                                <div className="col-span-1">
                                    <SelectNeoGen
                                        className="mb-5"
                                        label="Active:"
                                        value={active}
                                        options={[
                                            { id: 0, name: "No" },
                                            { id: 1, name: "Yes" },
                                        ]}
                                        onChange={(e) => setActive(Number(e))}
                                    />
                                </div>
                                <div className="col-span-1 -mt-1">
                                    {/* <SelectNeoGen
                                        className="mb-5"
                                        label="Public:"
                                        value={isPublic}
                                        options={[
                                            { id: 0, name: "No" },
                                            { id: 1, name: "Yes" },
                                        ]}
                                        onChange={(e) => setIsPublic(Number(e))}
                                    /> */}
                                    <InputControlled
                                        label="Order:"
                                        name="order"
                                        onChange={(e) => setOrder(Number(e))}
                                        value={String(existingEntry?.order ?? 0)}
                                    />
                                </div>
                            </div>
                        </form>

                        <div className="h-96 m-0 p-0 z-0">
                            <Editor
                                toolbar={{
                                    image: {
                                        previewImage: true,
                                        uploadEnabled: true,
                                        uploadCallback: uploadCallback,
                                    },
                                }}
                                // toolbarStyle={{
                                //     border: "1px solid #ff0000"
                                // }}
                                editorState={editorState}
                                toolbarClassName="toolbarClassName mb-5  dark:bg-gray-900 border dark:border-gray-900 rounded pb-5"
                                wrapperClassName="wrapperClassName h-80  dark:!bg-gray-900 "
                                editorClassName="editorClassName2  dark:bg-gray-900  dark:border-gray-600"
                                onEditorStateChange={onEditorStateChange}
                                // eslint-disable-next-line react/jsx-key
                                toolbarCustomButtons={[<CustomOption />]}
                            />
                        </div>
                    </div>
                </div>
                <ButtonNeoGen
                    onClick={() => {
                        navigate("/faq");
                    }}
                    text="Cancel"
                    className="mr-5 mt-5"
                    type="info"
                />
                <ButtonNeoGen onClick={save} text="Save" />
            </div>
            {/*  */}
            {/* </ModalNeoGen> */}
        </>
    );
}

type AddFaqProps = {
    // show: boolean,
    // hide: () => void,
    // existingEntry?: (NeoGenHelpDocs|null)
};
// AddFaq.whyDidYouRender = true;
