import { InvoiceLineItem } from "../../invoice-line-items/domain/invoice-line-item";
import { makeRequest, parseSchema } from "../../jason-proof-of-concept/shared/utils";
import { Invoice, invoiceSchema } from "../domain/invoice";

export type CreateInvoiceData = Partial<Invoice> & { lineItems?: Omit<InvoiceLineItem, "invoiceId" | "id">[] };

export const createInvoice = async ({ authToken, data }: { authToken: string; data: CreateInvoiceData }) => {
    const response = await makeRequest({
        method: "post",
        path: `/invoices`,
        authToken,
        data,
    });

    const invoice = parseSchema(invoiceSchema, response.data);

    return invoice;
};
