import { makeRequest, parseSchema } from "../../jason-proof-of-concept/shared/utils";
import { Alert, AlertSchema } from "../domain/alert";

export const createAlert = async ({ authToken, data }: { authToken?: string; data: Partial<Alert> }) => {
    const response = await makeRequest({
        method: "post",
        path: "/alerts",
        authToken,
        data,
    });

    return response.data;
};
