import PageDescription from "../../layout/page-description";
import { useQuery } from "@tanstack/react-query";
import invoiceTemplateService from "../../services/invoice-template.service";
import PrintPre from "../../layout/print-pre";
import invoiceTemplateLineItemService from "../../services/invoice-template-line-item.service";
import { useState } from "react";
import AddEditInvoiceTemplate from "./tools/modals/add-edit-invoice-template";

function InvoiceTemplates() {
    const [showModal, setShowModal] = useState(false);
    const invoiceTemplateQuery = useQuery(["invoice-templates"], async () => {
        // Fetch invoice templates
        const response = await invoiceTemplateService.getAll();
        if (response) {
            return response.data;
        }
    });
    const invoiceTemplateLineItemQuery = useQuery(["invoice-template-line-itemss"], async () => {
        // Fetch invoice templates
        const response = await invoiceTemplateLineItemService.getAll();
        if (response) {
            return response.data;
        }
    });
    if (invoiceTemplateQuery.isLoading || invoiceTemplateLineItemQuery.isLoading) {
        return <div>Loading...</div>;
    }
    return (
        <>
            <PageDescription
                title="Invoice Templates"
                description="Create and manage invoice templates."
                buttons={[
                    {
                        label: "Create Invoice Template",
                        onClick: () => {
                            // Create invoice template
                            setShowModal(true);
                        },
                    },
                ]}
            />
            <PrintPre>{invoiceTemplateQuery.data}</PrintPre>
            <PrintPre>{invoiceTemplateLineItemQuery.data}</PrintPre>
            <AddEditInvoiceTemplate show={showModal} close={() => setShowModal(false)} />
        </>
    );
}

export default InvoiceTemplates;
