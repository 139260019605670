import { useMutation } from "@tanstack/react-query";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { z } from "zod";
import userAtom from "../../atoms/userAtom";
import { EmailField } from "../../layout/form/email-field";
import { Form } from "../../layout/form/form";
import { PasswordField } from "../../layout/form/password-field";
import { TextField } from "../../layout/form/text-field";
import { useForm } from "../../hooks/useForm";
import ButtonNeoGen from "../../layout/button-neogen";
import ErrorSection from "../../layout/error-section";
import Loader2 from "../../sections/utilities/Loader2";
import { getAuthTokenNoThrow } from "../../services/auth-header";
import authService from "../../services/auth.service";
import { actionMagicLink } from "../actions/action-magic-link";
import logo from "../../images/ERCRepair.png";
import { MagicLinkDataType } from "../pages/magic-link-page";
import { ClearERCUser } from "../../typings/api/clear-erc-user";
import { PhoneNumberField } from "../../layout/form/phone-number-field";

const schema = z.object({
    firstName: z.string(),
    lastName: z.string(),
    email: z.string(),
    phone: z.string(),
    password: z.string().min(8),
});

type Data = z.infer<typeof schema>;

export const AffiliateMagicLinkSignUpForm = ({ magicLink, code }: { magicLink: MagicLinkDataType; code: string }) => {
    const [loggedInUser, setUser] = useRecoilState(userAtom);
    const authToken = getAuthTokenNoThrow() || "no-token";
    const form = useForm({ schema });
    const navigate = useNavigate();

    useEffect(() => {
        if (magicLink) {
            Object.keys(magicLink.data).forEach((k) => {
                form.resetField(k as any, { defaultValue: magicLink.data[k] });
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [magicLink]);

    const mutation = useMutation({
        mutationFn: async (data: Record<string, any>) => {
            if (!code) {
                throw new Error("MagicLink code must be defined.");
            }
            const result = await actionMagicLink({ authToken, code, data: data });
            return result;
        },
    });

    const handleSubmit = async (data: Data) => {
        const result = await mutation.mutateAsync(data);
        if (result.user) {
            setUser(result.user as ClearERCUser);
        }
        await authService.login(data.email, data.password);
    };

    const hasLoaded = !!magicLink;
    const hasSuccess = !!mutation.data?.magicLink;

    const handleContinue = () => {
        if (window.location.hostname === "login.ercrepair.com") {
            navigate("/processflow-wizard/28");
            return;
        }
        navigate("/processflow-wizard/11");
    };

    return (
        <div className="flex justify-center align-middle">
            <div>
                {!hasLoaded ? (
                    <Loader2 />
                ) : (
                    <>
                        <div className="p-8 bg-white dark:bg-slate-800 rounded-xl m-10 w-md lg:w-[550px]">
                            <div className="flex justify-center mb-3">
                                <img className="h-14" src={logo} alt="ERC Repair logo" />
                            </div>
                            {hasSuccess ? (
                                <>
                                    <h1 className="text-center text-xl">Your account has been created</h1>
                                    <p className="text-center text-gray-400 text-sm mt-4">
                                        Congratulations, and welcome! Your ERC Repair account has now been created.
                                        Click continue to start your affiliate training.
                                    </p>
                                    <div className="mt-2">
                                        <ButtonNeoGen type="submit" className="w-full mt-6" onClick={handleContinue}>
                                            Continue to affiliate training
                                        </ButtonNeoGen>
                                    </div>
                                </>
                            ) : (
                                <>
                                    {magicLink.usedAt ? (
                                        <>
                                            <h1 className="text-center text-xl">
                                                This magic link has already been used.
                                            </h1>
                                            <div className="flex justify-center mt-1">
                                                <p className="text-gray-400 text-sm w-[75%] text-center">
                                                    Please try again or contact us for help.
                                                </p>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <h1 className="text-center text-xl">Set up your ERC Repair account</h1>
                                            <div className="flex justify-center mt-1">
                                                <p className="text-gray-400 text-sm w-[75%] text-center">
                                                    Please enter and confirm your details below to set up your ERC
                                                    Repair account
                                                </p>
                                            </div>
                                            <div className="mt-5">
                                                <>
                                                    {mutation.error && (
                                                        <ErrorSection
                                                            errors={[
                                                                (mutation.error as any)?.message ||
                                                                    "Something went wrong",
                                                            ]}
                                                        />
                                                    )}
                                                    <Form onSubmit={form.handleSubmit(handleSubmit)}>
                                                        <TextField
                                                            label="First Name"
                                                            {...form.getFieldProps("firstName")}
                                                            isRequired
                                                        />
                                                        <TextField
                                                            label="Last Name"
                                                            {...form.getFieldProps("lastName")}
                                                            isRequired
                                                        />
                                                        <EmailField
                                                            label="Email Address"
                                                            autoComplete="new-username"
                                                            {...form.getFieldProps("email")}
                                                            isRequired
                                                        />
                                                        <PhoneNumberField
                                                            label="Phone Number"
                                                            {...form.getFieldProps("phone")}
                                                            isRequired
                                                        />
                                                        <PasswordField
                                                            autoComplete="new-password"
                                                            label="Password"
                                                            {...form.getFieldProps("password")}
                                                            isRequired
                                                        />
                                                        <ButtonNeoGen
                                                            type="submit"
                                                            className="w-full mt-6"
                                                            disabled={mutation.isLoading}
                                                        >
                                                            {mutation.isLoading ? "Loading..." : "Continue"}
                                                        </ButtonNeoGen>
                                                    </Form>
                                                </>
                                            </div>
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};
