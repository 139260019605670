import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Page } from "react-pdf";
import PageDescription from "../../../layout/page-description";
import { NewCompanyModal } from "../../../companies/components/modals/new-company-modal";
import { useRoleGroups } from "../../../role-groups/hooks/use-role-groups";
import { getAuthTokenNoThrow } from "../../../services/auth-header";
import { CompanyFilters } from "../../../companies/components/modals/company-filters-modal";
import { useInfiniteCompanies } from "../../../companies/hooks/use-companies";
import { debounce } from "lodash";
import { useNavigate } from "react-router";
import { User } from "@sentry/react";
import { useUsers } from "../../../jason-proof-of-concept/users/hooks/use-users";
import { search } from "ss-search";
import { useQuery } from "@tanstack/react-query";
import PrintPre from "../../../layout/print-pre";
import roleGroupUsersService from "../../../services/role-group-users.service";
import Loader3 from "../../utilities/Loader3";
import companyService from "../../../services/company.service";

export default function Leads() {
    const [showAddLead, setShowAddLead] = React.useState(false);
    const authToken = getAuthTokenNoThrow() || "no-auth";
    const roleGroupsQuery = useRoleGroups({ authToken });
    const [debouncedSearch, setDebouncedSearch] = useState("");
    const navigate = useNavigate();
    const [companyFilters, setCompanyFilters] = useState<CompanyFilters>({});
    const usersQuery = useUsers({ authToken });

    const affiliates = useMemo(
        () =>
            usersQuery.data ? usersQuery.data.filter((u) => u.roleGroups?.some((rg) => rg.name === "Affiliate")) : [],
        [usersQuery.data],
    );
    const companiesQuery = useQuery(["companies"], async () => {
        const response = await companyService.getAll();
        return response?.data ?? [];
    });
    // const companies = useMemo(() => companiesQuery.companies || [], [companiesQuery.companies]);

    // const companyOwners = companies.map((c) => c.ownedBy).filter((u) => u) as User[];

    const users = useMemo(() => usersQuery.data || [], [usersQuery.data]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const updateDebouncedSearch = useCallback(
        debounce(
            (term) => {
                setDebouncedSearch(term);
            },
            700,
            { trailing: true, maxWait: 1000 },
        ),
        [],
    );
    useEffect(() => {
        updateDebouncedSearch(search);
    }, [search, updateDebouncedSearch]);

    const roleGroupUsersQuery = useQuery(["roleGroupUsers"], async () => {
        const response = await roleGroupUsersService.getAll();
        return response?.data ?? [];
    });

    const roleGroups = useMemo(() => roleGroupsQuery.data || [], [roleGroupsQuery.data]);
    if (roleGroupsQuery.isLoading || usersQuery.isLoading || companiesQuery.isLoading) {
        return <Loader3 />;
    }
    function getRolesForCompany(companyId: number) {
        const owner = companiesQuery.data?.find((c) => c.id === companyId)?.ownedById;
        return roleGroupUsersQuery.data?.filter((r) => r.clearErcUserId === owner).map((rg) => rg.roleGroupsId);
    }
    function checkIfRolesAreLead(roles?: number[]) {
        if (!roles) return false;
        return roles.some((r) => [53, 54, 55].includes(r));
    }
    return (
        <>
            <PageDescription
                title="Leads"
                description="Leads are the steps in the process that a customer goes through. This is a visual representation of the stages."
                buttons={[
                    {
                        label: "Add Lead",
                        icon: "fas fa-plus",
                        onClick: () => {
                            setShowAddLead(true);
                        },
                    },
                ]}
            />

            {companiesQuery.data
                ?.filter((c) => checkIfRolesAreLead(getRolesForCompany(c.id)))
                .map((company) => (
                    <div key={company.id}>
                        <div>Name: {company.name}</div>
                        <div>
                            Roles:{" "}
                            {getRolesForCompany(company.id)?.map((r) => roleGroups.find((rg) => rg.id === r)?.name)}
                        </div>
                    </div>
                ))}

            {/* { //map((company) => (
                <div key={company.id}>
                    <div>Name: {company.name}</div>
                    <div>
                        Owner:{" "}
                        {}
                    </div>
                </div>
            ))} */}
            {/* <PrintPre>{roleGroupUsersQuery}</PrintPre> */}
            {showAddLead && (
                <NewCompanyModal
                    onClose={() => setShowAddLead(false)}
                    onCompanyCreated={({ company }) => {
                        companiesQuery.refetch();
                        navigate(`/companies/${company.id}`);
                    }}
                    affiliates={users}
                    roleGroups={roleGroups}
                    roleId={53}
                />
            )}
        </>
    );
}
