import { z } from "zod";
import { getAuthTokenNoThrow } from "../../services/auth-header";
import { useForm } from "../../hooks/useForm";
import { useUpdateCompany } from "../hooks/use-update-company";
import { useMutation } from "@tanstack/react-query";
import { Form } from "../../layout/form/form";
import { CurrencyField } from "../../layout/form/currency-field";
import ButtonNeoGen from "../../layout/button-neogen";
import { TBody, THead, Table, Td, Th, Tr } from "../../layout/table";
import { Payment } from "../../payments/domain/payment";
import { Currency } from "../../billing/components";
import { DateDisplay } from "../../layout/date-display";
import { DateTimeField } from "../../layout/form/date-field";
import { SelectField } from "../../layout/form/select-field";
import { QuartersData } from "./billing";
import { updateProcessflowProgress } from "../../jason-proof-of-concept/processflow-progresses/actions/update-processflow-progress";
import { useMemo } from "react";

const schema = z.object({
    q2_2020: z.number().min(0),
    q3_2020: z.number().min(0),
    q4_2020: z.number().min(0),
    q1_2021: z.number().min(0),
    q2_2021: z.number().min(0),
    q3_2021: z.number().min(0),
    q4_2021: z.number().min(0),
    q2_2020_filedAt: z.coerce.date().nullish(),
    q3_2020_filedAt: z.coerce.date().nullish(),
    q4_2020_filedAt: z.coerce.date().nullish(),
    q1_2021_filedAt: z.coerce.date().nullish(),
    q2_2021_filedAt: z.coerce.date().nullish(),
    q3_2021_filedAt: z.coerce.date().nullish(),
    q4_2021_filedAt: z.coerce.date().nullish(),
    q2_2020_irsTranscriptStatus: z.string().nullish(),
    q3_2020_irsTranscriptStatus: z.string().nullish(),
    q4_2020_irsTranscriptStatus: z.string().nullish(),
    q1_2021_irsTranscriptStatus: z.string().nullish(),
    q2_2021_irsTranscriptStatus: z.string().nullish(),
    q3_2021_irsTranscriptStatus: z.string().nullish(),
    q4_2021_irsTranscriptStatus: z.string().nullish(),
    q2_2020_irsTranscriptDate: z.coerce.date().nullish(),
    q3_2020_irsTranscriptDate: z.coerce.date().nullish(),
    q4_2020_irsTranscriptDate: z.coerce.date().nullish(),
    q1_2021_irsTranscriptDate: z.coerce.date().nullish(),
    q2_2021_irsTranscriptDate: z.coerce.date().nullish(),
    q3_2021_irsTranscriptDate: z.coerce.date().nullish(),
    q4_2021_irsTranscriptDate: z.coerce.date().nullish(),
    q2_2020_qualificationReason: z.string().nullish(),
    q3_2020_qualificationReason: z.string().nullish(),
    q4_2020_qualificationReason: z.string().nullish(),
    q1_2021_qualificationReason: z.string().nullish(),
    q2_2021_qualificationReason: z.string().nullish(),
    q3_2021_qualificationReason: z.string().nullish(),
    q4_2021_qualificationReason: z.string().nullish(),
});

type Data = z.infer<typeof schema>;

export const EditQuartersTable = ({
    processflowProgressId,
    data,
    onUpdated,
    irsPayments,
}: {
    processflowProgressId: number | undefined;
    data: QuartersData;
    onUpdated: () => void;
    irsPayments?: Payment[];
}) => {
    const authToken = getAuthTokenNoThrow() || "no-auth-token";
    const form = useForm({
        schema,
        defaultValues: {
            q2_2020: data.q2_2020 || undefined,
            q3_2020: data.q3_2020 || undefined,
            q4_2020: data.q4_2020 || undefined,
            q1_2021: data.q1_2021 || undefined,
            q2_2021: data.q2_2021 || undefined,
            q3_2021: data.q3_2021 || undefined,
            q4_2021: data.q4_2021 || undefined,
            q2_2020_filedAt: data.q2_2020_filedAt ? new Date(data.q2_2020_filedAt) : undefined,
            q3_2020_filedAt: data.q3_2020_filedAt ? new Date(data.q3_2020_filedAt) : undefined,
            q4_2020_filedAt: data.q4_2020_filedAt ? new Date(data.q4_2020_filedAt) : undefined,
            q1_2021_filedAt: data.q1_2021_filedAt ? new Date(data.q1_2021_filedAt) : undefined,
            q2_2021_filedAt: data.q2_2021_filedAt ? new Date(data.q2_2021_filedAt) : undefined,
            q3_2021_filedAt: data.q3_2021_filedAt ? new Date(data.q3_2021_filedAt) : undefined,
            q4_2021_filedAt: data.q4_2021_filedAt ? new Date(data.q4_2021_filedAt) : undefined,
            q2_2020_irsTranscriptStatus: data.q2_2020_irsTranscriptStatus || "Not pulled",
            q3_2020_irsTranscriptStatus: data.q3_2020_irsTranscriptStatus || "Not pulled",
            q4_2020_irsTranscriptStatus: data.q4_2020_irsTranscriptStatus || "Not pulled",
            q1_2021_irsTranscriptStatus: data.q1_2021_irsTranscriptStatus || "Not pulled",
            q2_2021_irsTranscriptStatus: data.q2_2021_irsTranscriptStatus || "Not pulled",
            q3_2021_irsTranscriptStatus: data.q3_2021_irsTranscriptStatus || "Not pulled",
            q4_2021_irsTranscriptStatus: data.q4_2021_irsTranscriptStatus || "Not pulled",

            q2_2020_irsTranscriptDate: data.q2_2020_irsTranscriptDate
                ? new Date(data.q2_2020_irsTranscriptDate)
                : undefined,
            q3_2020_irsTranscriptDate: data.q3_2020_irsTranscriptDate
                ? new Date(data.q3_2020_irsTranscriptDate)
                : undefined,
            q4_2020_irsTranscriptDate: data.q4_2020_irsTranscriptDate
                ? new Date(data.q4_2020_irsTranscriptDate)
                : undefined,
            q1_2021_irsTranscriptDate: data.q1_2021_irsTranscriptDate
                ? new Date(data.q1_2021_irsTranscriptDate)
                : undefined,
            q2_2021_irsTranscriptDate: data.q2_2021_irsTranscriptDate
                ? new Date(data.q2_2021_irsTranscriptDate)
                : undefined,
            q3_2021_irsTranscriptDate: data.q3_2021_irsTranscriptDate
                ? new Date(data.q3_2021_irsTranscriptDate)
                : undefined,
            q4_2021_irsTranscriptDate: data.q4_2021_irsTranscriptDate
                ? new Date(data.q4_2021_irsTranscriptDate)
                : undefined,
            q2_2020_qualificationReason: data.q2_2020_qualificationReason,
            q3_2020_qualificationReason: data.q3_2020_qualificationReason,
            q4_2020_qualificationReason: data.q4_2020_qualificationReason,
            q1_2021_qualificationReason: data.q1_2021_qualificationReason,
            q2_2021_qualificationReason: data.q2_2021_qualificationReason,
            q3_2021_qualificationReason: data.q3_2021_qualificationReason,
            q4_2021_qualificationReason: data.q4_2021_qualificationReason,
        },
    });

    const q2_2020_payment = irsPayments?.find((p) => p.quarter === "Q2 2020");
    const q3_2020_payment = irsPayments?.find((p) => p.quarter === "Q3 2020");
    const q4_2020_payment = irsPayments?.find((p) => p.quarter === "Q4 2020");
    const q1_2021_payment = irsPayments?.find((p) => p.quarter === "Q1 2021");
    const q2_2021_payment = irsPayments?.find((p) => p.quarter === "Q2 2021");
    const q3_2021_payment = irsPayments?.find((p) => p.quarter === "Q3 2021");
    const q4_2021_payment = irsPayments?.find((p) => p.quarter === "Q4 2021");

    const updateCompanyMutation = useUpdateCompany();

    const submitMutation = useMutation({
        mutationFn: async (data: Data) => {
            if (!processflowProgressId) return;
            const updatedCompany = await updateProcessflowProgress({
                authToken,
                id: processflowProgressId,
                data: {
                    data: {
                        entriesByField: {
                            "Q2 2020": {
                                fieldName: "Q2 2020",
                                fieldValue: true,
                                isHidden: false,
                            },
                            "Amount qualified Q2 2020": {
                                fieldName: "Amount qualified Q2 2020",
                                fieldValue: data.q2_2020 || null,
                                isHidden: false,
                            },
                            "Reason why qualified for Q2 2020": {
                                fieldName: "Reason why qualified for Q2 2020",
                                fieldValue: data.q2_2020_qualificationReason || null,
                                isHidden: false,
                            },
                            "Filed date for Q2 2020": {
                                fieldName: "Filed date for Q2 2020",
                                fieldValue: data.q2_2020_filedAt?.toISOString() || null,
                                isHidden: false,
                            },
                            "IRS Transcript Status for Q2 2020": {
                                fieldName: "IRS Transcript Status for Q2 2020",
                                fieldValue: data.q2_2020_irsTranscriptStatus || null,
                                isHidden: false,
                            },
                            "IRS Transcript Date for Q2 2020": {
                                fieldName: "IRS Transcript Date for Q2 2020",
                                fieldValue: data.q2_2020_irsTranscriptDate?.toISOString() || null,
                                isHidden: false,
                            },
                            "Q3 2020": {
                                fieldName: "Q3 2020",
                                fieldValue: true,
                                isHidden: false,
                            },
                            "Amount qualified Q3 2020": {
                                fieldName: "Amount qualified Q3 2020",
                                fieldValue: data.q3_2020 || null,
                                isHidden: false,
                            },
                            "Reason why qualified for Q3 2020": {
                                fieldName: "Reason why qualified for Q3 2020",
                                fieldValue: data.q3_2020_qualificationReason || null,
                                isHidden: false,
                            },
                            "Filed date for Q3 2020": {
                                fieldName: "Filed date for Q3 2020",
                                fieldValue: data.q3_2020_filedAt?.toISOString() || null,
                                isHidden: false,
                            },
                            "IRS Transcript Status for Q3 2020": {
                                fieldName: "IRS Transcript Status for Q3 2020",
                                fieldValue: data.q3_2020_irsTranscriptStatus || null,
                                isHidden: false,
                            },
                            "IRS Transcript Date for Q3 2020": {
                                fieldName: "IRS Transcript Date for Q3 2020",
                                fieldValue: data.q3_2020_irsTranscriptDate?.toISOString() || null,
                                isHidden: false,
                            },
                            "Q4 2020": {
                                fieldName: "Q4 2020",
                                fieldValue: true,
                                isHidden: false,
                            },
                            "Amount qualified Q4 2020": {
                                fieldName: "Amount qualified Q4 2020",
                                fieldValue: data.q4_2020 || null,
                                isHidden: false,
                            },
                            "Reason why qualified for Q4 2020": {
                                fieldName: "Reason why qualified for Q4 2020",
                                fieldValue: data.q4_2020_qualificationReason || null,
                                isHidden: false,
                            },
                            "Filed date for Q4 2020": {
                                fieldName: "Filed date for Q4 2020",
                                fieldValue: data.q4_2020_filedAt?.toISOString() || null,
                                isHidden: false,
                            },
                            "IRS Transcript Status for Q4 2020": {
                                fieldName: "IRS Transcript Status for Q4 2020",
                                fieldValue: data.q4_2020_irsTranscriptStatus || null,
                                isHidden: false,
                            },
                            "IRS Transcript Date for Q4 2020": {
                                fieldName: "IRS Transcript Date for Q4 2020",
                                fieldValue: data.q4_2020_irsTranscriptDate?.toISOString() || null,
                                isHidden: false,
                            },
                            "Q1 2021": {
                                fieldName: "Q1 2021",
                                fieldValue: true,
                                isHidden: false,
                            },
                            "Amount qualified Q1 2021": {
                                fieldName: "Amount qualified Q1 2021",
                                fieldValue: data.q1_2021 || null,
                                isHidden: false,
                            },
                            "Reason why qualified for Q1 2021": {
                                fieldName: "Reason why qualified for Q1 2021",
                                fieldValue: data.q1_2021_qualificationReason || null,
                                isHidden: false,
                            },
                            "Filed date for Q1 2021": {
                                fieldName: "Filed date for Q1 2021",
                                fieldValue: data.q1_2021_filedAt?.toISOString() || null,
                                isHidden: false,
                            },
                            "IRS Transcript Status for Q1 2021": {
                                fieldName: "IRS Transcript Status for Q1 2021",
                                fieldValue: data.q1_2021_irsTranscriptStatus || null,
                                isHidden: false,
                            },
                            "IRS Transcript Date for Q1 2021": {
                                fieldName: "IRS Transcript Date for Q1 2021",
                                fieldValue: data.q1_2021_irsTranscriptDate?.toISOString() || null,
                                isHidden: false,
                            },
                            "Q2 2021": {
                                fieldName: "Q2 2021",
                                fieldValue: true,
                                isHidden: false,
                            },
                            "Amount qualified Q2 2021": {
                                fieldName: "Amount qualified Q2 2021",
                                fieldValue: data.q2_2021 || null,
                                isHidden: false,
                            },
                            "Reason why qualified for Q2 2021": {
                                fieldName: "Reason why qualified for Q2 2021",
                                fieldValue: data.q2_2021_qualificationReason || null,
                                isHidden: false,
                            },
                            "Filed date for Q2 2021": {
                                fieldName: "Filed date for Q2 2021",
                                fieldValue: data.q2_2021_filedAt?.toISOString() || null,
                                isHidden: false,
                            },
                            "IRS Transcript Status for Q2 2021": {
                                fieldName: "IRS Transcript Status for Q2 2021",
                                fieldValue: data.q2_2021_irsTranscriptStatus || null,
                                isHidden: false,
                            },
                            "IRS Transcript Date for Q2 2021": {
                                fieldName: "IRS Transcript Date for Q2 2021",
                                fieldValue: data.q2_2021_irsTranscriptDate?.toISOString() || null,
                                isHidden: false,
                            },
                            "Q3 2021": {
                                fieldName: "Q3 2021",
                                fieldValue: true,
                                isHidden: false,
                            },
                            "Amount qualified Q3 2021": {
                                fieldName: "Amount qualified Q3 2021",
                                fieldValue: data.q3_2021 || null,
                                isHidden: false,
                            },
                            "Reason why qualified for Q3 2021": {
                                fieldName: "Reason why qualified for Q3 2021",
                                fieldValue: data.q3_2021_qualificationReason || null,
                                isHidden: false,
                            },
                            "Filed date for Q3 2021": {
                                fieldName: "Filed date for Q3 2021",
                                fieldValue: data.q3_2021_filedAt?.toISOString() || null,
                                isHidden: false,
                            },
                            "IRS Transcript Status for Q3 2021": {
                                fieldName: "IRS Transcript Status for Q3 2021",
                                fieldValue: data.q3_2021_irsTranscriptStatus || null,
                                isHidden: false,
                            },
                            "IRS Transcript Date for Q3 2021": {
                                fieldName: "IRS Transcript Date for Q3 2021",
                                fieldValue: data.q3_2021_irsTranscriptDate?.toISOString() || null,
                                isHidden: false,
                            },
                            "Q4 2021": {
                                fieldName: "Q4 2021",
                                fieldValue: true,
                                isHidden: false,
                            },
                            "Amount qualified Q4 2021": {
                                fieldName: "Amount qualified Q4 2021",
                                fieldValue: data.q4_2021 || null,
                                isHidden: false,
                            },
                            "Reason why qualified for Q4 2021": {
                                fieldName: "Reason why qualified for Q4 2021",
                                fieldValue: data.q4_2021_qualificationReason || null,
                                isHidden: false,
                            },
                            "Filed date for Q4 2021": {
                                fieldName: "Filed date for Q4 2021",
                                fieldValue: data.q4_2021_filedAt?.toISOString() || null,
                                isHidden: false,
                            },
                            "IRS Transcript Status for Q4 2021": {
                                fieldName: "IRS Transcript Status for Q4 2021",
                                fieldValue: data.q4_2021_irsTranscriptStatus || null,
                                isHidden: false,
                            },
                            "IRS Transcript Date for Q4 2021": {
                                fieldName: "IRS Transcript Date for Q4 2021",
                                fieldValue: data.q4_2021_irsTranscriptDate?.toISOString() || null,
                                isHidden: false,
                            },
                        },
                    },
                },
            });
            return updatedCompany;
        },
    });

    const handleSubmit = async (data: Data) => {
        await submitMutation.mutateAsync(data);
        onUpdated();
    };

    const irsTranscriptStatusOptions = [
        { value: "Not pulled", label: "Not pulled" },
        { value: "Not on Transcript", label: "Not on Transcript" },
        { value: "On Transcript", label: "On Transcript" },
    ];

    const qualificationReasonOptions = [
        { value: "Revenue Reduction", label: "Revenue Reduction" },
        { value: "Full or Partial Shutdown", label: "Full or Partial Shutdown" },
        { value: "Not Sure", label: "Not Sure" },
    ];

    const rows = useMemo(
        () => [
            {
                quarter: "Q2 2020",
                reason: form.getFieldProps("q2_2020_qualificationReason"),
                amount: form.getFieldProps("q2_2020"),
                date: form.getFieldProps("q2_2020_filedAt"),
                irsStatus: form.getFieldProps("q2_2020_irsTranscriptStatus"),
                irsDate: form.getFieldProps("q2_2020_irsTranscriptDate"),
                payout: q2_2020_payment ? q2_2020_payment.amount : null,
                payoutDate: q2_2020_payment ? q2_2020_payment.date : null,
            },
            {
                quarter: "Q3 2020",
                reason: form.getFieldProps("q3_2020_qualificationReason"),
                amount: form.getFieldProps("q3_2020"),
                date: form.getFieldProps("q3_2020_filedAt"),
                irsStatus: form.getFieldProps("q3_2020_irsTranscriptStatus"),
                irsDate: form.getFieldProps("q3_2020_irsTranscriptDate"),
                payout: q3_2020_payment ? q3_2020_payment.amount : null,
                payoutDate: q3_2020_payment ? q3_2020_payment.date : null,
            },
            {
                quarter: "Q4 2020",
                reason: form.getFieldProps("q4_2020_qualificationReason"),
                amount: form.getFieldProps("q4_2020"),
                date: form.getFieldProps("q4_2020_filedAt"),
                irsStatus: form.getFieldProps("q4_2020_irsTranscriptStatus"),
                irsDate: form.getFieldProps("q4_2020_irsTranscriptDate"),
                payout: q4_2020_payment ? q4_2020_payment.amount : null,
                payoutDate: q4_2020_payment ? q4_2020_payment.date : null,
            },
            {
                quarter: "Q1 2021",
                reason: form.getFieldProps("q1_2021_qualificationReason"),
                amount: form.getFieldProps("q1_2021"),
                date: form.getFieldProps("q1_2021_filedAt"),
                irsStatus: form.getFieldProps("q1_2021_irsTranscriptStatus"),
                irsDate: form.getFieldProps("q1_2021_irsTranscriptDate"),
                payout: q1_2021_payment ? q1_2021_payment.amount : null,
                payoutDate: q1_2021_payment ? q1_2021_payment.date : null,
            },
            {
                quarter: "Q2 2021",
                reason: form.getFieldProps("q2_2021_qualificationReason"),
                amount: form.getFieldProps("q2_2021"),
                date: form.getFieldProps("q2_2021_filedAt"),
                irsStatus: form.getFieldProps("q2_2021_irsTranscriptStatus"),
                irsDate: form.getFieldProps("q2_2021_irsTranscriptDate"),
                payout: q2_2021_payment ? q2_2021_payment.amount : null,
                payoutDate: q2_2021_payment ? q2_2021_payment.date : null,
            },
            {
                quarter: "Q3 2021",
                reason: form.getFieldProps("q3_2021_qualificationReason"),
                amount: form.getFieldProps("q3_2021"),
                date: form.getFieldProps("q3_2021_filedAt"),
                irsStatus: form.getFieldProps("q3_2021_irsTranscriptStatus"),
                irsDate: form.getFieldProps("q3_2021_irsTranscriptDate"),
                payout: q3_2021_payment ? q3_2021_payment.amount : null,
                payoutDate: q3_2021_payment ? q3_2021_payment.date : null,
            },
            {
                quarter: "Q4 2021",
                reason: form.getFieldProps("q4_2021_qualificationReason"),
                amount: form.getFieldProps("q4_2021"),
                date: form.getFieldProps("q4_2021_filedAt"),
                irsStatus: form.getFieldProps("q4_2021_irsTranscriptStatus"),
                irsDate: form.getFieldProps("q4_2021_irsTranscriptDate"),
                payout: q4_2021_payment ? q4_2021_payment.amount : null,
                payoutDate: q4_2021_payment ? q4_2021_payment.date : null,
            },
        ],
        [],
    );

    const formData = form.getValues();

    return (
        <Form onSubmit={form.handleSubmit(handleSubmit as any)} error={updateCompanyMutation.error as any}>
            <div style={{ marginBottom: 12, display: "flex", justifyContent: "space-between" }}>
                <p style={{ fontSize: 14, fontWeight: "bold" }}>Quarters Submitted to IRS</p>
                <ButtonNeoGen type="submit">Save</ButtonNeoGen>
            </div>
            <Table>
                <THead>
                    <Tr>
                        <Th>Quarter</Th>
                        <Th>Qualification Reason</Th>
                        <Th>Filed Amount</Th>
                        <Th>Filed Date</Th>
                        <Th>IRS Transcript Status</Th>
                        <Th>IRS Transcript Date</Th>
                        <Th>Received from IRS</Th>
                        <Th>Received from IRS Date</Th>
                    </Tr>
                </THead>
                <TBody>
                    {rows.map((row) => (
                        <Tr key={row.quarter}>
                            <Td>{row.quarter}</Td>
                            <Td>
                                <SelectField noMargin {...row.reason} options={qualificationReasonOptions} />
                            </Td>
                            <Td>
                                <CurrencyField {...row.amount} />
                            </Td>
                            <Td>
                                <DateTimeField {...row.date} />
                            </Td>
                            <Td>
                                <SelectField noMargin {...row.irsStatus} options={irsTranscriptStatusOptions} />
                            </Td>
                            <Td>
                                <DateTimeField {...row.irsDate} />
                            </Td>
                            <Td>{row.payout ? <Currency amount={row.payout} /> : "-"}</Td>
                            <Td>{row.payoutDate ? <DateDisplay date={row.payoutDate} /> : "-"}</Td>
                        </Tr>
                    ))}
                </TBody>
            </Table>
        </Form>
    );
};
