import { useQuery } from "@tanstack/react-query";
import { getInvoices } from "../actions/get-invoices";

type Params = {
    authToken: string;
    filters?: any;
};

const getInvoicesQueryKey = ({ authToken, filters }: Params) => {
    return ["invoices", { authToken, filters }];
};

export const useInvoices = ({ authToken, filters }: Params, options?: { enabled?: boolean }) => {
    const query = useQuery({
        queryKey: getInvoicesQueryKey({ authToken, filters }),
        queryFn: async () => {
            const invoices = await getInvoices({ authToken, filters });
            return invoices;
        },
        ...options,
    });
    return query;
};
