import { z } from "zod";
import ModalDialog from "../../../layout/modal-dialog";
import { Form } from "../../../layout/form/form";
import { EmailField } from "../../../layout/form/email-field";
import { useForm } from "../../../hooks/useForm";
import { useMutation } from "@tanstack/react-query";
import { createUserCompany } from "../../../user-companies/actions/create-user-company";
import { getAuthTokenNoThrow } from "../../../services/auth-header";
import ButtonNeoGen from "../../../layout/button-neogen";
import { useRecoilState } from "recoil";
import userAtom from "../../../atoms/userAtom";
import { User } from "../../../jason-proof-of-concept/users/domain/user";
import { SelectField } from "../../../layout/form/select-field";
import { inviteUserToCompany } from "../../actions/invite-user-to-company";
import authService from "../../../services/auth.service";
import { roleAssignments, roleGroups } from "../../../services/role-group.service";
import { useUsers } from "../../../jason-proof-of-concept/users/hooks/use-users";
import { orderBy } from "lodash";
import { useEffect, useMemo, useState } from "react";
import Toggle from "../../../layout/toggle";
import { createUser } from "../../../jason-proof-of-concept/users/actions/create-user";
import { createMagicLink } from "../../../magic-links/actions/create-magic-link";
import { MagicLinkTypes } from "../../../magic-links/domain/magic-link";
import { TextField } from "../../../layout/form/text-field";
import { CheckboxField } from "../../../layout/form/checkbox-field";
import { PasswordField } from "../../../layout/form/password-field";
import { PhoneNumberField } from "../../../layout/form/phone-number-field";

const defaultCommissions = {
    [roleGroups.DocCollector]: 2,
    [roleGroups.SalesRep]: 1,
    [roleGroups.SubstantiationTeam]: 2,
    [roleGroups.DocCollectionManager]: 1,
    [roleGroups.SubstantiationManager]: 1,
    [roleGroups.SalesManager]: 1,
    [roleGroups.AffiliateManager]: 2,
    [roleGroups.DirectorOfSales]: 2,
    [roleGroups.Affiliate]: 15,
};

const addUserSchema = z.object({
    email: z.string().email(),
    roleId: z.number().optional(),
    firstName: z.string(),
    lastName: z.string(),
    phone: z.string(),
    inviteToSystem: z.boolean().optional(),
    preSetPassword: z.boolean().optional(),
    password: z.string().min(8).optional(),
    confirmPassword: z.string().min(8).optional(),
});

type AddUserData = z.infer<typeof addUserSchema>;

const inviteUserSchema = z.object({
    userId: z.string().uuid().optional(),
    email: z.string().email().optional(),
    roleId: z.number().optional(),
    commissionPercentage: z.number().optional(),
});

type InviteUserData = z.infer<typeof inviteUserSchema>;

export const InviteExistingUserModal = ({
    onClose,
    companyId,
    onUserAdded,
    companyRoles,
}: {
    onClose: () => any;
    companyId: number;
    onUserAdded: (user: User) => any;
    companyRoles: NonNullable<User["companyRoleGroups"]>;
}) => {
    const [option, setOption] = useState(0);
    const [user] = useRecoilState(userAtom);
    if (!user.id) {
        throw new Error("User not defined.");
    }
    const authToken = getAuthTokenNoThrow() || "no-token";

    const inviteUserForm = useForm({ schema: inviteUserSchema });
    const addUserForm = useForm({ schema: addUserSchema });
    const addUserFormValues = addUserForm.watch();

    const inviteUserMutation = useMutation({
        mutationFn: async (data: InviteUserData) => {
            await inviteUserToCompany({
                authToken,
                id: companyId,
                data: {
                    userId: data.userId,
                    roleId: data.roleId ?? -1,
                    commissionPercentage: data.commissionPercentage,
                },
            });
            return { user };
        },
    });

    const addUserMutation = useMutation({
        mutationFn: async (data: AddUserData) => {
            const now = new Date();
            if (data.password !== data.confirmPassword) {
                throw new Error("Passwords do not match");
            }
            const user = await createUser({
                authToken,
                data: {
                    email: data.email,
                    firstName: data.firstName,
                    lastName: data.lastName,
                    phone: data.phone,
                    userStatus: data.inviteToSystem ? "invited" : undefined,
                    invitedAt: data.inviteToSystem ? now : undefined,
                    ...(data.preSetPassword && data.password
                        ? { password: data.password, userStatus: "active", accountSetupAt: now }
                        : {}),
                },
            });
            const userCompany = await createUserCompany({
                authToken,
                data: { userId: user.id, companyId, roleId: data.roleId },
            });
            if (data.inviteToSystem && !data.preSetPassword) {
                await createMagicLink({
                    authToken,
                    data: {
                        type: MagicLinkTypes.userInvite,
                        createdById: user.id,
                        data: {
                            firstName: data.firstName,
                            lastName: data.lastName,
                            email: data.email,
                            phone: data.phone,
                        },
                    },
                });
            }
            return { user, userCompany };
        },
    });

    const handleSubmit = async (data: InviteUserData | AddUserData) => {
        if (option === 0) {
            const { user } = await inviteUserMutation.mutateAsync(data as InviteUserData);
            if (user) {
                onUserAdded(user as any);
            }
        } else {
            const { user } = await addUserMutation.mutateAsync(data as AddUserData);
            if (user) {
                onUserAdded(user as any);
            }
        }
    };

    const usersQuery = useUsers({ authToken });
    const users = usersQuery.data || [];

    const roleId = inviteUserForm.watch("roleId");

    useEffect(() => {
        let commissionToUse = 0;
        if (roleId) {
            commissionToUse = defaultCommissions[roleId] || 0;
        }
        inviteUserForm.setValue("commissionPercentage", commissionToUse);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [roleId]);

    const filteredUsers = users.filter((user) => {
        if (roleId && roleId !== -1) {
            return (user.roleGroups || []).find((rg) => rg.id === roleId);
        }
        return true;
    });

    const usersRoleGroups = (authService.getCurrentUser()?.user?.roleGroups || []) as any[];
    const roleGroupsIds = usersRoleGroups.reduce<number[]>((acc, roleGroup: any) => {
        return [...acc, ...(roleAssignments?.[roleGroup.id] || [])];
    }, []);
    const finalRoleGroups = companyRoles.filter((rg) => roleGroupsIds.includes(rg.id || 9999));
    finalRoleGroups.push({ id: -1, name: "No role" });

    const isSuperUser = !!usersRoleGroups.find((rg) => rg.id === roleGroups.SuperUser);

    const roleOptions = useMemo(
        () =>
            orderBy(
                finalRoleGroups.map((role) => ({ label: role.name || "", value: role.id as number })),
                (o) => o.label,
            ),
        [finalRoleGroups],
    );
    const userOptions = useMemo(
        () =>
            orderBy(
                filteredUsers.map((user) => ({
                    label: `${user.firstName} ${user.lastName} (${user.email})` || "",
                    value: user.id,
                })),
                (o) => o.label,
            ),
        [filteredUsers],
    );

    const options = finalRoleGroups.map((role) => ({ label: role.name || "", value: role.id as number }));

    return (
        <ModalDialog title="Add user to company" close={onClose} show showOk={false} showCancel={false} size="sm">
            <Toggle
                options={["Invite existing user", "Add new user"]}
                setSelectedIndex={setOption}
                selectedIndex={option}
            />
            <p className="px-4 pt-2 text-gray-400 text-center">
                {option === 0
                    ? "Invite an existing user on the system to this company."
                    : "Add a new user to this company."}
            </p>
            <div className="pt-6">
                {option === 0 ? (
                    <Form onSubmit={inviteUserForm.handleSubmit(handleSubmit)} error={inviteUserMutation.error as any}>
                        {isSuperUser ? (
                            <>
                                <SelectField
                                    label="Select role"
                                    {...inviteUserForm.getFieldProps("roleId")}
                                    options={roleOptions}
                                />
                                <SelectField
                                    label="Select user"
                                    {...inviteUserForm.getFieldProps("userId")}
                                    options={userOptions}
                                />
                            </>
                        ) : (
                            <>
                                <EmailField label="Email" {...inviteUserForm.getFieldProps("email")} />
                                <SelectField
                                    label="Select role"
                                    {...inviteUserForm.getFieldProps("roleId")}
                                    options={roleOptions}
                                />
                            </>
                        )}
                        <div className="flex flex-row justify-end">
                            <div className="flex flex-row gap-2">
                                <ButtonNeoGen type="outline" onClick={onClose}>
                                    Cancel
                                </ButtonNeoGen>
                                <ButtonNeoGen type="submit" disabled={inviteUserMutation.isLoading}>
                                    Invite user
                                </ButtonNeoGen>
                            </div>
                        </div>
                    </Form>
                ) : (
                    <div>
                        <Form onSubmit={addUserForm.handleSubmit(handleSubmit)} error={addUserMutation.error as any}>
                            <SelectField
                                label="Select role"
                                {...addUserForm.getFieldProps("roleId")}
                                options={options}
                            />
                            <TextField label="First name" {...addUserForm.getFieldProps("firstName")} />
                            <TextField label="Last name" {...addUserForm.getFieldProps("lastName")} />
                            <EmailField
                                autoComplete="off"
                                label="Email address"
                                {...addUserForm.getFieldProps("email")}
                            />
                            <PhoneNumberField label="Phone number" {...addUserForm.getFieldProps("phone")} />
                            <CheckboxField label="Invite to system" {...addUserForm.getFieldProps("inviteToSystem")} />
                            {addUserFormValues.inviteToSystem && (
                                <>
                                    <CheckboxField
                                        label="Pre-set password"
                                        {...addUserForm.getFieldProps("preSetPassword")}
                                    />
                                    {addUserFormValues.preSetPassword && (
                                        <>
                                            <PasswordField
                                                label="Password"
                                                {...addUserForm.getFieldProps("password")}
                                            />
                                            <PasswordField
                                                label="Confirm password"
                                                {...addUserForm.getFieldProps("confirmPassword")}
                                            />
                                        </>
                                    )}
                                </>
                            )}
                            <div className="flex flex-row justify-end">
                                <div className="flex flex-row gap-2">
                                    <ButtonNeoGen type="outline" onClick={onClose}>
                                        Cancel
                                    </ButtonNeoGen>
                                    <ButtonNeoGen type="submit" disabled={addUserMutation.isLoading}>
                                        Add user
                                    </ButtonNeoGen>
                                </div>
                            </div>
                        </Form>
                    </div>
                )}
            </div>
        </ModalDialog>
    );
};
