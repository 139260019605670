import { Currency } from "../../../billing/components";
import ModalDialog from "../../../layout/modal-dialog";
import { TBody, THead, Table, Td, Tr } from "../../../layout/table";

export const TranscriptModal = ({
    onClose,
    paymasterName,
    companyName,
}: {
    onClose: () => void;
    paymasterName: string;
    companyName: string;
}) => {
    return (
        <ModalDialog
            show
            title={`Transcript for Paymaster ${paymasterName} (${companyName})`}
            close={onClose}
            showOk={false}
            showCancel={false}
            size="sm"
        >
            <Table>
                <THead>
                    <Tr>
                        <Td>Date</Td>
                        <Td>Event</Td>
                        <Td>Amount</Td>
                    </Tr>
                </THead>
                <TBody>
                    <Tr>
                        <Td>{new Date().toLocaleDateString()}</Td>
                        <Td>IRS to Lockbox Payout For Q2 2020</Td>
                        <Td>
                            <Currency amount={1000} />
                        </Td>
                    </Tr>
                    <Tr>
                        <Td>{new Date().toLocaleDateString()}</Td>
                        <Td>Lockbox to Paymaster Payout</Td>
                        <Td>
                            <Currency amount={1000} />
                        </Td>
                    </Tr>
                    <Tr>
                        <Td>{new Date().toLocaleDateString()}</Td>
                        <Td>Paymaster Received Payment</Td>
                        <Td>
                            <Currency amount={1000} />
                        </Td>
                    </Tr>
                </TBody>
            </Table>
        </ModalDialog>
    );
};
