import { z } from "zod";
import { User } from "../../../jason-proof-of-concept/users/domain/user";
import { getAuthTokenNoThrow } from "../../../services/auth-header";
import { useForm } from "../../../hooks/useForm";
import { useMutation } from "@tanstack/react-query";
import { createUser } from "../../../jason-proof-of-concept/users/actions/create-user";
import roleGroupService from "../../../services/role-group.service";
import ModalDialog from "../../../layout/modal-dialog";
import { Form } from "../../../layout/form/form";
import { TextField } from "../../../layout/form/text-field";
import { EmailField } from "../../../layout/form/email-field";
import { PasswordField } from "../../../layout/form/password-field";
import ButtonNeoGen from "../../../layout/button-neogen";
import { PhoneNumberField } from "../../../layout/form/phone-number-field";

const schema = z.object({
    firstName: z.string().min(1),
    lastName: z.string().min(1),
    email: z.string().email(),
    phone: z.string().nullish(),
    password: z.string().nullish(),
    passwordAgain: z.string().nullish(),
});

type Data = z.infer<typeof schema>;

export const CreateReferrerModal = ({
    onClose,
    onReferrerCreated,
}: {
    onClose: () => any;
    onReferrerCreated: (user: User) => void;
}) => {
    const authToken = getAuthTokenNoThrow() || "no-auth-token";

    const form = useForm({ schema });

    const mutation = useMutation({
        mutationFn: async (data: Data) => {
            const { passwordAgain, ...filteredData } = data;
            const createdUser = await createUser({
                authToken,
                data: {
                    ...filteredData,
                    emailVerified: true,
                    isTempPassword: true,
                },
            });

            const createdRoleGroup = await roleGroupService.postURL("role-group-users", {
                clearErcUserId: createdUser.id,
                roleGroupsId: 46,
            });

            return createdUser;
        },
    });

    const handleSubmit = async (data: Data) => {
        const createdUser = await mutation.mutateAsync(data);
        onReferrerCreated(createdUser);
    };

    return (
        <ModalDialog show title={"Add Referrer"} close={onClose} showOk={false} showCancel={false}>
            <Form onSubmit={form.handleSubmit(handleSubmit)} error={mutation.error as any}>
                <div className="grid grid-cols-1 gap-3">
                    <TextField label="First name" {...form.getFieldProps("firstName")} isRequired={true} />
                    <TextField label="Last name" {...form.getFieldProps("lastName")} isRequired={true} />
                    <EmailField autoComplete="username" label="Email Address" {...form.getFieldProps("email")} />
                    <PhoneNumberField label="Phone" {...form.getFieldProps("phone")} />
                    <PasswordField autoComplete="new-password" label="Password" {...form.getFieldProps("password")} />
                    <PasswordField autoComplete="off" label="Password Again" {...form.getFieldProps("passwordAgain")} />
                </div>
                <ButtonNeoGen block type="submit" disabled={mutation.isLoading}>
                    Create referrer
                </ButtonNeoGen>
            </Form>
        </ModalDialog>
    );
};
