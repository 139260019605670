import { FileUploadUtil } from "../../../utilities/file-upload";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import Swal from "sweetalert2";
import fileUploadService from "../../../../services/file.service";
import UserContext from "../../../../services/user-context";
import ModalDialog from "../../../../layout/modal-dialog";
import { assumedUserAtom } from "../../../../atoms/userAtom";
import { useRecoilState } from "recoil";
import InputControlled from "../../../../layout/input-controlled";
import TextAreaNeoGenControlled from "../../../../layout/text-area-controlled";
import CheckBoxNeoGenControlled from "../../../../layout/checkbox-controlled";
import industryService, { Industry } from "../../../../services/industry.service";
import SelectNeoGenSearchable from "../../../../layout/select-neogen-searchable";
import { Select } from "../../../../layout/form/select-input";
import mandateService, { Mandate } from "../../../../services/mandate.service";
import mandateIndustriesService from "../../../../services/mandate-industries.service";
import mandateIndustryGroupsService from "../../../../services/mandate-industry-groups.service";
import _ from "lodash";
import industryGroupsService from "../../../../services/industry-groups.service";
import mandateTypeService from "../../../../services/mandate-type.service";
import mandateBindsService from "../../../../services/mandate-binds.service";

const states = [
    {
        name: "Alabama",
        id: "AL",
    },
    {
        name: "Alaska",
        id: "AK",
    },
    {
        name: "American Samoa",
        id: "AS",
    },
    {
        name: "Arizona",
        id: "AZ",
    },
    {
        name: "Arkansas",
        id: "AR",
    },
    {
        name: "California",
        id: "CA",
    },
    {
        name: "Colorado",
        id: "CO",
    },
    {
        name: "Connecticut",
        id: "CT",
    },
    {
        name: "Delaware",
        id: "DE",
    },
    {
        name: "District Of Columbia",
        id: "DC",
    },
    {
        name: "Federated States Of Micronesia",
        id: "FM",
    },
    {
        name: "Florida",
        id: "FL",
    },
    {
        name: "Georgia",
        id: "GA",
    },
    {
        name: "Guam",
        id: "GU",
    },
    {
        name: "Hawaii",
        id: "HI",
    },
    {
        name: "Idaho",
        id: "ID",
    },
    {
        name: "Illinois",
        id: "IL",
    },
    {
        name: "Indiana",
        id: "IN",
    },
    {
        name: "Iowa",
        id: "IA",
    },
    {
        name: "Kansas",
        id: "KS",
    },
    {
        name: "Kentucky",
        id: "KY",
    },
    {
        name: "Louisiana",
        id: "LA",
    },
    {
        name: "Maine",
        id: "ME",
    },
    {
        name: "Marshall Islands",
        id: "MH",
    },
    {
        name: "Maryland",
        id: "MD",
    },
    {
        name: "Massachusetts",
        id: "MA",
    },
    {
        name: "Michigan",
        id: "MI",
    },
    {
        name: "Minnesota",
        id: "MN",
    },
    {
        name: "Mississippi",
        id: "MS",
    },
    {
        name: "Missouri",
        id: "MO",
    },
    {
        name: "Montana",
        id: "MT",
    },
    {
        name: "Nebraska",
        id: "NE",
    },
    {
        name: "Nevada",
        id: "NV",
    },
    {
        name: "New Hampshire",
        id: "NH",
    },
    {
        name: "New Jersey",
        id: "NJ",
    },
    {
        name: "New Mexico",
        id: "NM",
    },
    {
        name: "New York",
        id: "NY",
    },
    {
        name: "North Carolina",
        id: "NC",
    },
    {
        name: "North Dakota",
        id: "ND",
    },
    {
        name: "Northern Mariana Islands",
        id: "MP",
    },
    {
        name: "Ohio",
        id: "OH",
    },
    {
        name: "Oklahoma",
        id: "OK",
    },
    {
        name: "Oregon",
        id: "OR",
    },
    {
        name: "Palau",
        id: "PW",
    },
    {
        name: "Pennsylvania",
        id: "PA",
    },
    {
        name: "Puerto Rico",
        id: "PR",
    },
    {
        name: "Rhode Island",
        id: "RI",
    },
    {
        name: "South Carolina",
        id: "SC",
    },
    {
        name: "South Dakota",
        id: "SD",
    },
    {
        name: "Tennessee",
        id: "TN",
    },
    {
        name: "Texas",
        id: "TX",
    },
    {
        name: "Utah",
        id: "UT",
    },
    {
        name: "Vermont",
        id: "VT",
    },
    {
        name: "Virgin Islands",
        id: "VI",
    },
    {
        name: "Virginia",
        id: "VA",
    },
    {
        name: "Washington",
        id: "WA",
    },
    {
        name: "West Virginia",
        id: "WV",
    },
    {
        name: "Wisconsin",
        id: "WI",
    },
    {
        name: "Wyoming",
        id: "WY",
    },
];

export default function AddMandate(props: AddMandateProps) {
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [user] = useContext(UserContext);
    const [assumedUser, setAssumedUser] = useRecoilState(assumedUserAtom);
    const [name, setName] = useState("");
    const [mandateType, setMandateType] = useState<number | null>(null);
    const [binds, setBinds] = useState<number[]>([]);
    const [state, setState] = useState<any>(null); // states[0
    const [notes, setNotes] = useState("");
    const [q2_2020, setQ2_2020] = useState(false);
    const [q3_2020, setQ3_2020] = useState(false);
    const [q4_2020, setQ4_2020] = useState(false);
    const [q1_2021, setQ1_2021] = useState(false);
    const [q2_2021, setQ2_2021] = useState(false);
    const [q3_2021, setQ3_2021] = useState(false);
    // const [q8, setQ8] = useState(false);
    const [orderText, setOrderText] = useState("");
    const queryCache = useQueryClient();
    const [industries, setIndustries] = useState<number[] | null | undefined>(null);
    const [industryGroups, setIndustryGroups] = useState<number[] | null | undefined>(null);
    const industriesQuery = useQuery(["industries"], async () => {
        const result = await industryService.getAll();
        return result?.data ?? [];
    });

    const mandateIndustryQuery = useQuery(["mandateIndustry"], async () => {
        const result = await mandateIndustriesService.getAll();
        return result?.data ?? [];
    });

    const mandateTypesQuery = useQuery(["mandateTypes"], async () => {
        const result = await mandateTypeService.getAll();
        return result?.data ?? [];
    });

    const mandateIndustryGroupQuery = useQuery(["mandateIndustryGroup"], async () => {
        const result = await mandateIndustryGroupsService.getAll();
        return result?.data ?? [];
    });

    const industryGroupsQuery = useQuery(["industryGroups"], async () => {
        const result = await industryGroupsService.getAll();
        return result?.data ?? [];
    });
    const mandataBindsQuery = useQuery(["mandateBinds"], async () => {
        const result = await mandateBindsService.getAll();
        return result?.data ?? [];
    });
    useEffect(() => {
        setTimeout(() => {
            if (props.mandate?.id) {
                setName(props.mandate.name);
                setNotes(props.mandate.notes);
                setState(states.find((i) => i.name === props.mandate?.state)?.id ?? null);
                // setIndustries(props.mandate.industries);
                setIndustries(
                    mandateIndustryQuery.data?.filter((i) => i.mandate === props.mandate?.id).map((i) => i.industry) ??
                        [],
                );
                setIndustryGroups(
                    mandateIndustryGroupQuery.data
                        ?.filter((i) => i.mandate === props.mandate?.id)
                        .map((i) => i.industryGroup) ?? [],
                );

                setQ2_2020(props.mandate.q2 === 1);
                setQ3_2020(props.mandate.q3 === 1);
                setQ4_2020(props.mandate.q4 === 1);
                setQ1_2021(props.mandate.q5 === 1);
                setQ2_2021(props.mandate.q6 === 1);
                setQ3_2021(props.mandate.q7 === 1);
                // setQ8(props.mandate.q8 === 1);

                setOrderText(props.mandate.orderText ?? "");
                setBinds(
                    mandataBindsQuery.data?.filter((i) => i.boundBy === props.mandate?.id)?.map((i) => i.id ?? -1) ??
                        [],
                );
            } else {
                setName("");
                setIndustries(null);
                setIndustryGroups(null);
                setNotes("");
                setState("");
                setOrderText("");
                setQ2_2020(false);
                setQ3_2020(false);
                setQ4_2020(false);
                setQ1_2021(false);
                setQ2_2021(false);
                setQ3_2021(false);
                setBinds([]);
            }
        }, 1);
    }, [mandataBindsQuery.data, mandateIndustryGroupQuery.data, mandateIndustryQuery.data, props]);
    function onFileChange(event: any) {
        // Update the state
        setSelectedFile(event.target.files[0]);
    }

    function addRemoveIndustries(mandateId: number, industries: number[]) {
        // The entry has rule groups because they are included in the query
        // They actually come from the ProcessflowRuleGroups table
        const dbRules = mandateIndustryQuery.data?.filter((i) => i.industry === props.mandate?.id) ?? [];
        // const same = _.isEqual(dbRules[0], enabled);

        // entriets.
        const removals = _.differenceWith(dbRules, industries, _.isEqual);
        const additions = _.differenceWith(industries, dbRules, _.isEqual);
        console.log({ removals, additions });

        // Find the industryGroupMembers that are in the db but not in the enabled list
        // and delete them
        const entriesToDelete = mandateIndustryQuery.data?.filter((i) => removals.find((r) => r.id === i.id)) ?? [];
        // Find the industryGroupMembers that are in the enabled list but not in the db
        // and add them
        const entriesToAdd = industries.filter((i) => additions.find((a) => a === i)) ?? [];
        for (const entry of entriesToAdd) {
            mandateIndustriesService.create({ mandate: mandateId, industry: entry });
        }
        for (const entry of entriesToDelete) {
            mandateIndustriesService.deleteByID(entry.id ?? -1);
        }
        queryCache.invalidateQueries(["mandateIndustry"]);
    }
    function addRemoveIndustryGroups(mandateId: number, industryGroups: number[]) {
        // The entry has rule groups because they are included in the query
        // They actually come from the ProcessflowRuleGroups table
        const dbRules = mandateIndustryGroupQuery.data?.filter((i) => i.industryGroup === props.mandate?.id) ?? [];
        // const same = _.isEqual(dbRules[0], enabled);

        // entriets.
        const removals = _.differenceWith(dbRules, industryGroups, _.isEqual);
        const additions = _.differenceWith(industryGroups, dbRules, _.isEqual);
        console.log({ removals, additions });

        // Find the industryGroupMembers that are in the db but not in the enabled list
        // and delete them
        const entriesToDelete =
            mandateIndustryGroupQuery.data?.filter((i) => removals.find((r) => r.id === i.id)) ?? [];
        // Find the industryGroupMembers that are in the enabled list but not in the db
        // and add them
        const entriesToAdd = industryGroups.filter((i) => additions.find((a) => a === i)) ?? [];
        for (const entry of entriesToAdd) {
            mandateIndustryGroupsService.create({ mandate: mandateId, industryGroup: entry });
        }
        for (const entry of entriesToDelete) {
            mandateIndustryGroupsService.deleteByID(entry.id ?? -1);
        }
        queryCache.invalidateQueries(["mandateIndustryGroup"]);
    }
    // function addRemoveBinds(mandateId: number, binds: number[]) {}

    async function onFileUpload() {
        // If the mandate is new we're going to need to save it first so we can get the id
        if (!props.mandate?.id) {
            const newMandate = await mandateService.create({
                name,
                state: states.find((i) => i.id === state)?.name ?? null,
                notes,
                q1: 0,
                q2: q2_2020 ? 1 : 0,
                q3: q3_2020 ? 1 : 0,
                q4: q4_2020 ? 1 : 0,
                q5: q1_2021 ? 1 : 0,
                q6: q2_2021 ? 1 : 0,
                q7: q3_2021 ? 1 : 0,
                q8: 0,
                orderText,
            });
            if (newMandate) {
                // we now have our id
                console.error({ newMandate });
                addRemoveIndustries(newMandate.data?.id ?? -1, industries ?? []);
                addRemoveIndustryGroups(newMandate.data?.id ?? -1, industryGroups ?? []);
            }
        } else {
            // we already have an id
            await mandateService.update(props.mandate.id, {
                name,
                state: states.find((i) => i.id === state)?.name ?? null,
                notes,
                q1: 0,
                q2: q2_2020 ? 1 : 0,
                q3: q3_2020 ? 1 : 0,
                q4: q4_2020 ? 1 : 0,
                q5: q1_2021 ? 1 : 0,
                q6: q2_2021 ? 1 : 0,
                q7: q3_2021 ? 1 : 0,
                q8: 0,
                orderText,
            });
            addRemoveIndustries(props.mandate.id, industries ?? []);
            addRemoveIndustryGroups(props.mandate.id, industryGroups ?? []);
        }
        queryCache.invalidateQueries(["mandates"]);

        // Create an object of formData
        // if (!selectedFile) {
        //     Swal.fire({
        //         title: "Error",
        //         text: "You need to select a file",
        //         icon: "error",
        //     });
        //     return;
        // } else {

        // If they're uploading a file
        if (selectedFile) {
            alert(selectedFile);
            console.error({ assumedUser });
            const res = await fileUploadService.uploadFile({
                file: selectedFile,
                userId: assumedUser?.id ?? user.id ?? "",
            });
            if (res && res.status === 200) {
                props.close();
                queryCache.invalidateQueries(["files"]);
                Swal.fire({
                    title: "Success",
                    text: "File uploaded successfully",
                    icon: "success",
                    showConfirmButton: false,
                    timer: 1500,
                });
                return;
            } else {
                Swal.fire({
                    title: "Error",
                    text: "File upload failed",
                    icon: "error",
                    showConfirmButton: false,
                    timer: 1500,
                });
                return;
            }
        }

        props.close();
        // Details of the uploaded file

        // Request made to the backend api
        // Send formData object
        // axios.post("api/uploadfile", formData);
    }
    return (
        <ModalDialog
            close={props.close}
            show={props.show}
            size="md"
            title={props.mandate ? "Edit Mandate" : "Add Mandate"}
            okAction={onFileUpload}
            okText={props.mandate ? "Save Changes" : "Add Mandate"}
        >
            <div className="mb-5">
                <Select
                    label="State"
                    value={state ?? ""}
                    options={states.map((i) => ({ label: i.name ?? "", value: i.id ?? -1 })) ?? []}
                    onChange={function (value?: any): void {
                        // console.error({ value });
                        setState(states.find((i) => i.id === value)?.id ?? null);
                        // console.error({ state: states.find((i) => i.id === value)?.name ?? null });
                    }}
                />
            </div>

            <div className="mb-5">
                <Select
                    label="Mandate Type"
                    value={mandateType ?? ""}
                    options={mandateTypesQuery.data?.map((i) => ({ label: i.name ?? "", value: i.id ?? -1 })) ?? []}
                    onChange={function (value?: any): void {
                        // console.error({ value });
                        setMandateType(mandateTypesQuery.data?.find((i) => i.id === value)?.id ?? null);
                        // console.error({ state: states.find((i) => i.id === value)?.name ?? null });
                    }}
                />
            </div>

            <div className="mb-5">
                <Select
                    label="Industry"
                    value={industries ?? null}
                    isMultiple={true}
                    options={
                        (industriesQuery.data ?? []).map((i) => ({ label: i.name ?? "", value: i.id ?? -1 })) ?? []
                    }
                    onChange={function (value?: any): void {
                        // console.error({ value });
                        // value is an array of ids
                        setIndustries(value);
                        // setIndustries(industriesQuery.data?.find((i) => i.id === value)?.id ?? null);
                    }}
                />
            </div>
            <div className="mb-5">
                <Select
                    label="Industry Groups"
                    value={industryGroups ?? null}
                    isMultiple={true}
                    options={
                        (industryGroupsQuery.data ?? []).map((i) => ({ label: i.name ?? "", value: i.id ?? -1 })) ?? []
                    }
                    onChange={function (value?: any): void {
                        // console.error({ value });
                        // value is an array of ids
                        setIndustryGroups(value);
                        // setIndustries(industriesQuery.data?.find((i) => i.id === value)?.id ?? null);
                    }}
                />
            </div>

            <InputControlled
                label="Mandate Name"
                value={name}
                onChange={(value) => {
                    setName(value);
                }}
            />
            <TextAreaNeoGenControlled
                label={"Notes"}
                value={notes}
                setValue={function (e: string): void {
                    setNotes(e);
                }}
            />
            <TextAreaNeoGenControlled
                label={"Order Text"}
                value={orderText}
                setValue={function (e: string): void {
                    setOrderText(e);
                }}
            />
            <div className="grid grid-cols-3">
                <CheckBoxNeoGenControlled
                    label="Covers Quarter 2, 2020"
                    setValue={function (event: ChangeEvent<HTMLInputElement>): void {
                        setQ2_2020(event.target.checked);
                    }}
                    value={q2_2020}
                    name={"q2"}
                />
                <CheckBoxNeoGenControlled
                    label="Covers Quarter 3, 2020"
                    setValue={function (event: ChangeEvent<HTMLInputElement>): void {
                        setQ3_2020(event.target.checked);
                    }}
                    value={q3_2020}
                    name={"q3"}
                />
                <CheckBoxNeoGenControlled
                    label="Covers Quarter 4, 2020"
                    setValue={function (event: ChangeEvent<HTMLInputElement>): void {
                        setQ4_2020(event.target.checked);
                    }}
                    value={q4_2020}
                    name={"q4"}
                />
                <CheckBoxNeoGenControlled
                    label="Covers Quarter 1, 2021"
                    setValue={function (event: ChangeEvent<HTMLInputElement>): void {
                        setQ1_2021(event.target.checked);
                    }}
                    value={q1_2021}
                    name={"q5"}
                />
                <CheckBoxNeoGenControlled
                    label="Covers Quarter 2, 2021"
                    setValue={function (event: ChangeEvent<HTMLInputElement>): void {
                        setQ2_2021(event.target.checked);
                    }}
                    value={q2_2021}
                    name={"q6"}
                />
                <CheckBoxNeoGenControlled
                    label="Covers Quarter 3, 2021"
                    setValue={function (event: ChangeEvent<HTMLInputElement>): void {
                        setQ3_2021(event.target.checked);
                    }}
                    value={q3_2021}
                    name={"q7"}
                />
            </div>
            <FileUploadUtil onFileChange={onFileChange} />
            {/* <PrintPre> */}
            {selectedFile && (
                <div className="flex mx-auto items-center justify-center bg-gray-200 dark:bg-gray-700 dark:border dark:border-green-600 my-10 p-3 rounded-xl">
                    {selectedFile?.name}
                    <button
                        className="ml-2 bg-transparent hover:text-red-200 text-red-500 font-bold"
                        onClick={() => setSelectedFile(null)}
                    >
                        <span className="fal fa-delete-left" />
                    </button>
                </div>
            )}

            {/* </PrintPre> */}
        </ModalDialog>
    );
}

type AddMandateProps = {
    show: boolean;
    close: () => void;
    mandate?: Mandate | null;
};
