import React from "react";
import PrintPre from "../../layout/print-pre";
import repairStageHistoryService, { RepairStageHistoryType } from "../../services/repair-stage-history.service";
import { useQuery } from "@tanstack/react-query";
import repairStagesService from "../../services/repair-stages.service";
import repairStageCategoriesService from "../../services/repair-stage-categories.service";
import { get } from "lodash";
import PageDescription from "../../layout/page-description";
import authService from "../../services/auth.service";
import { roleGroups } from "../../services/role-group.service";

function History({ companyId, owner, progressQuery }: { companyId: any; owner: any; progressQuery: any }) {
    const user = authService.getCurrentUser()?.user;
    const usersRoleGroups = (user?.roleGroups || []) as any[];
    const isSuperUser = !!usersRoleGroups.find((rg) => rg?.id === roleGroups.SuperUser);
    const historyQuery = useQuery(["history", "reversed", progressQuery.data?.usersProgress?.id], async () => {
        const response = await repairStageHistoryService.getFilteredWhere({
            processflow: progressQuery.data.usersProgress?.id,
        });
        if (response) {
            return response.data.sort(
                (a: RepairStageHistoryType, b: RepairStageHistoryType) =>
                    new Date(b.changeWhen).getTime() - new Date(a.changeWhen).getTime(),
            );
        } else {
            return [];
        }
    });
    const repairStagesQuery = useQuery(["repairStages"], async () => {
        const response = await repairStagesService.getAll();
        if (response) {
            return response.data;
        }
    });
    const repairStageCategoriesQuery = useQuery(["repairStageCategories"], async () => {
        const response = await repairStageCategoriesService.getAll();
        if (response) {
            return response.data;
        }
    });
    if (historyQuery.isLoading || repairStagesQuery.isLoading || repairStageCategoriesQuery.isLoading) {
        return <div>Loading...</div>;
    }
    function getStageTitle(stageId: number) {
        return repairStagesQuery.data?.find((h) => h?.id === stageId)?.title;
    }
    function getStageCategory(stageId: number) {
        return repairStageCategoriesQuery.data?.find(
            (c) => c?.id === repairStagesQuery.data?.find((h) => h?.id === stageId)?.parent,
        )?.name;
    }
    function getStageCategoryId(stageId: number) {
        return repairStageCategoriesQuery.data?.find(
            (c) => c?.id === repairStagesQuery.data?.find((h) => h?.id === stageId)?.parent,
        )?.id;
    }
    function getCategoryColor(stageId?: number) {
        switch (stageId) {
            case 51:
                return "bg-blue-500 dark:bg-blue-800";
            case 54:
                return "bg-orange-500 dark:bg-orange-800";
            case 66:
                return "bg-yellow-500 dark:bg-yellow-800";
            case 71:
                return "bg-red-500 dark:bg-red-800";
            case 79:
                return "bg-purple-500 dark:bg-purple-800";
            case 84:
                return "bg-indigo-500 dark:bg-indigo-800";
            case 86:
                return "bg-pink-500 dark:bg-pink-800";
            case 92:
                return "bg-green-500 dark:bg-green-800";
            default:
                return "bg-gray-500 dark:bg-gray-800";
        }
    }

    return (
        <div className="">
            {/* <PageDescription
                title={"History"}
                description="History"
                buttons={[
                    {
                        label: "Kanban",
                        onClick: () => {
                            window.location.href = `/kanban`;
                        },
                    },
                ]}
            /> */}
            {/* <div className="border border-gray-200 shadow rounded-xl p-3 dark:border-gray-600 dark:bg-gray-900"> */}
            {historyQuery.data?.map((history: RepairStageHistoryType, count: number) => (
                <div key={history?.id} className="  mr-5 mb-5 p-2 relative dark:text-gray-500">
                    <div className="text-sm">{new Date(history.changeWhen).toLocaleString()} </div>
                    <div className="text-left w-full">
                        {/* {count === 0 && (
                            <>
                                <span
                                    className={
                                        "mr-1 rounded-full " +
                                        getCategoryColor(getStageCategoryId(history.stageFrom)) +
                                        " text-white px-2"
                                    }
                                >
                                    {getStageCategory(history.stageFrom)}
                                </span>
                                <br />
                                <span className="mr-1 rounded-full bg-green-500 text-white px-2">
                                    {getStageTitle(history.stageFrom)}
                                </span>
                                <br />
                            </>
                        )} */}

                        {/* <i className="fas fa-arrow-down mr-2"></i> */}
                        {/* <br /> */}
                        <span
                            className={
                                "mr-1 rounded-full text-sm " +
                                getCategoryColor(getStageCategoryId(history.stageTo)) +
                                " text-white px-2 dark:text-gray-300"
                            }
                        >
                            {getStageCategory(history.stageTo)}
                        </span>
                        <br />
                        <span className="mr-1 rounded-full bg-green-500 text-white px-2 dark:text-gray-900 text-sm">
                            {isSuperUser ? getStageTitle(history.stageTo) : "File Updated"}
                        </span>
                    </div>
                    {count < (historyQuery.data?.length ?? -1) - 1 && (
                        <div
                            className="absolute left-4 top-22 -ml-px h-9 w-0.5 bg-gray-200 dark:bg-gray-500"
                            aria-hidden="true"
                        />
                    )}
                </div>
            ))}
            {/* </div> */}
        </div>
    );
}

export default History;
