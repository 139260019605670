import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router";
import { getAuthTokenNoThrow } from "../../services/auth-header";
import { getUser } from "../../jason-proof-of-concept/users/actions/get-user";
import BreadCrumbs from "../../layout/breadcrumbs";
import { WizardContainer } from "../../jason-proof-of-concept/wizard-v2/wizard-container";
import Loader2 from "../../sections/utilities/Loader2";
import companyService from "../../services/company.service";
import { Company } from "../../typings/api";

export const CompanyApplicationPage = () => {
    const authToken = getAuthTokenNoThrow();
    const { companyId } = useParams();
    if (!companyId) {
        throw new Error("companyId is required");
    }
    const properCompanyId = Number.parseInt(companyId, 10);

    const query = useQuery(["user-company", { authToken, companyId }], async () => {
        const company = (await companyService.getOne(companyId))?.data as Company;
        const user = company.ownedById && authToken ? await getUser({ authToken, id: company.ownedById }) : undefined;
        return { user, company };
    });
    const user = query.data?.user;
    const company = query.data?.company;
    const name = company?.name;

    return (
        <div>
            <BreadCrumbs
                pages={[
                    {
                        name: "Companies",
                        href: "/companies",
                    },
                    {
                        name: name || "",
                        href: `/companies/${companyId || ""}`,
                    },
                    {
                        name: `Application`,
                        href: `/companies/${companyId || ""}/application`,
                        current: true,
                    },
                ]}
            />
            {!user && !company && query.isLoading && <Loader2 />}
            {user && <WizardContainer processflowGroupId={28} user={user} companyId={properCompanyId} />}
            {!query.isLoading && company && !user && (
                <div className="flex justify-center items-center h-20">
                    <div>Application not found. Company does not have an owner set.</div>
                </div>
            )}
        </div>
    );
};
