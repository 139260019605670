import DatePicker from "react-datepicker";

import "./css/react-datepicker.css";
import "./css/datepicker-dark.css";
import { useEffect, useState } from "react";
// import PrintPre from "../../layout/print-pre";

export default function CalendarWidget(props: CalendarWidgetProps) {
    const [selected, setSelected] = useState<Date | null>(new Date());
    useEffect(() => {
        setSelected(new Date(props.value));
    }, [props.value]);
    return (
        <div className="mb-5 mt-1">
            <div className="flex">
                <p className="block text-sm font-normal tracking-wider text-gray-500 dark:text-gray-400">
                    {props.label}
                </p>
                {props.isRequired && (
                    <span className="inline-block text-lg leading-none text-red-500 font-extralight pl-[1px] mr-[1px] mt-[2px]">
                        *
                    </span>
                )}
            </div>

            <div className="w-full">
                <DatePicker
                    className="text-xs border rounded-md border-gray-300 dark:border-gray-900 w-full h-[38px] dark:bg-gray-900 "
                    selected={props.value}
                    // setSelected={setSelected}
                    onChange={(date: Date) => {
                        setSelected(date);
                        props.onChange(date);
                    }}
                />
            </div>
            <p className="col-span-2 text-sm text-gray-500">{props.description}</p>
        </div>
    );
}

type CalendarWidgetProps = {
    value: Date;
    onChange: (to: Date) => void;
    description: string;
    label: string;
    isRequired?: boolean;
};
