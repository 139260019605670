import { useQuery, useQueryClient } from "@tanstack/react-query";
import ModalDialog from "../../../layout/modal-dialog";
import PrintPre from "../../../layout/print-pre";
import stagesService, { Stages } from "../../../services/stages.service";
import SelectNeoGen from "../../../layout/select-neogen";
import { useCallback, useEffect, useMemo, useState } from "react";
import companyProductStagesService, {
    CompanyProductStagesType,
} from "../../../services/company-product-stages.service";
import { useRecoilState } from "recoil";
import userAtom from "../../../atoms/userAtom";
import SwalNeogenFire from "../../../layout/swal-neogen";

export default function AddStatusModal({
    show,
    close,
    company,
    showAll,
    category,
    companyProductStage,
}: // user,
{
    show: boolean;
    close: () => void;
    company: any;
    showAll: boolean;
    category: any;
    companyProductStage: CompanyProductStagesType | undefined;
    // user: any;
}) {
    const [user] = useRecoilState(userAtom);
    const [status, setStatus] = useState<any>();
    const [entries, setEntries] = useState<any>();
    const queryCache = useQueryClient();
    const allStagesQuery = useQuery(["stages"], async () => {
        const response = await stagesService.getAll();
        return response?.data ?? [];
    });
    const sortAlpha = (a: any, b: any) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
    };
    async function saveStatus() {
        if (!status) {
            SwalNeogenFire({
                title: "Error",
                text: "You need to select a status",
                icon: "error",
                showConfirmButton: false,
                timer: 3500,
            });
            return;
        } else {
            // alert("Status: " + showAll);
        }
        // console.error("Save Status", status);
        if (!showAll) {
            const companyProductStatus: CompanyProductStagesType = {
                companyId: company.id,
                stageId: status,
                lastUpdated: new Date(),
                updatedByUser: user.id,
            };
            await companyProductStagesService.create(companyProductStatus);
        } else if (companyProductStage) {
            companyProductStage.stageId = status;
            companyProductStage.lastUpdated = new Date();
            companyProductStage.updatedByUser = user.id;
            // console.error("Update", companyProductStage);
            // return;
            await companyProductStagesService.update(companyProductStage.id, companyProductStage);
        }
        await queryCache.invalidateQueries(["companyProductStages", company.id]);
        close();
        // console.log(companyProductStatus);
    }
    const getParentName = useCallback(
        (stage: Stages): string => {
            // const stage
            console.log({ stage });
            if (stage.parent === 0) return stage.name?.trim() ?? "";
            const parent = allStagesQuery.data?.find((s) => s.id === stage.parent);
            if (!parent) return stage.name?.trim() ?? "";
            return getParentName(parent) + " -> " + stage.name?.trim();
        },
        [allStagesQuery.data],
    );
    useEffect(() => {
        const entriesInternal = allStagesQuery.data
            ?.filter((i) => {
                const myParent = allStagesQuery.data?.find((s) => s.id === category)?.parent;
                return (
                    (!showAll && i.parent === 0) ||
                    (showAll && i.parent === category) ||
                    (showAll && i.parent === myParent) ||
                    (showAll && i.id === category) ||
                    (showAll && i.parent === 0)
                );
            })
            ?.map((stage: any) => {
                return { id: stage.id, name: getParentName(stage) };
            })
            .sort(sortAlpha);
        setEntries(entriesInternal);
        // for (const entry of entries ?? []) {
        //     console.log({ entry });
        //     console.log({ parent: getParentName(entry) });
        // }
        console.log({ entriesInternal });
    }, [allStagesQuery.data, category, getParentName, showAll]);
    if (allStagesQuery.isLoading) return <div>Loading...</div>;
    return (
        <ModalDialog
            size="md"
            close={() => close()}
            show={show}
            title={
                showAll
                    ? "Change Status For " + allStagesQuery.data?.find((i) => i.id === category)?.name
                    : "Add Status"
            }
            okAction={() => saveStatus()}
        >
            <div className="mb-5">
                <SelectNeoGen
                    label="Please Select A New Status"
                    options={entries}
                    value={status}
                    onChange={(e: any) => {
                        setStatus(e);
                    }}
                />
                {/* <PrintPre>{companyProductStage}</PrintPre> */}
            </div>
        </ModalDialog>
    );
}
