import React from "react";
import PageDescription from "../../../layout/page-description";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import bulletPointsService, { BulletPoint } from "../../../services/bullet-points.service";
import bulletPointGroupService, { BulletPointGroup } from "../../../services/bullet-point-group.service";
import TableNeogen from "../../../layout/table-neogen";
import AddBulletPointGroup from "./modals/add-bullet-point-group";
import PrintPre from "../../../layout/print-pre";
import { Button } from "react-bootstrap";
import ButtonNeoGen from "../../../layout/button-neogen";
import SwalNeogenFire from "../../../layout/swal-neogen";
import AddBulletPoint from "./modals/add-edit-bullet-point";
import { set } from "lodash";

function BulletPoints() {
    const [showAddBulletPoint, setShowAddBulletPoint] = React.useState(false);
    const [showAddBulletPointGroup, setShowAddBulletPointGroup] = React.useState(false);
    const [bulletPointGroup, setBulletPointGroup] = React.useState<BulletPointGroup | null>(null); // TODO: map the group onto bulletPoint.group
    const [bulletPoint, setBulletPoint] = React.useState<BulletPoint | null>(null);
    const queryCache = useQueryClient();
    const bulletPointsQuery = useQuery(["bulletPoints"], async () => {
        const response = await bulletPointsService.getAll();
        if (response) {
            return response.data;
        }
    });
    const bulletPointsGroupQuery = useQuery(["bulletPointsGroup"], async () => {
        const response = await bulletPointGroupService.getAll();
        if (response) {
            return response.data;
        }
    });
    if (bulletPointsQuery.isLoading || bulletPointsGroupQuery.isLoading) return <div>Loading...</div>;
    return (
        <>
            <PageDescription
                title="Bullet Points"
                description="Manage bullet points"
                buttons={[
                    {
                        label: "Add Bullet Point Group",
                        icon: "fas fa-list-ul",
                        onClick: () => {
                            setBulletPointGroup(null);
                            setShowAddBulletPointGroup(true);
                        },
                    },
                ]}
            />
            {bulletPointsGroupQuery.data?.map((group: BulletPointGroup) => (
                <div
                    key={group.id}
                    className="border border-white shadow-md rounded-xl p-5 bg-gray-100 mb-5 dark:bg-gray-900 dark:border-gray-700"
                >
                    <h1 className="text-xl mb-5">{group.name}</h1>
                    <div className="flex gap-3 mb-5">
                        <ButtonNeoGen
                            text="Add Bullet Point"
                            type="info"
                            iconAlign="left"
                            icon="fas fa-plus"
                            onClick={() => {
                                setBulletPoint(null);
                                setBulletPointGroup(group);
                                setShowAddBulletPoint(true);
                            }}
                        />
                        <ButtonNeoGen
                            text="Edit Group"
                            type="secondary"
                            iconAlign="left"
                            icon="fas fa-edit"
                            onClick={() => {
                                setBulletPointGroup(group);
                                setShowAddBulletPointGroup(true);
                            }}
                        />
                        <ButtonNeoGen
                            text="Delete Group"
                            type="danger"
                            iconAlign="left"
                            icon="fas fa-trash"
                            onClick={() => {
                                SwalNeogenFire({
                                    title: "Are you sure?",
                                    text: "You will not be able to recover this group!",
                                    icon: "warning",
                                    showCancelButton: true,
                                    confirmButtonText: "Yes, delete it!",
                                    confirmButtonColor: "#d33",
                                    cancelButtonText: "No, cancel!",
                                }).then(async (result) => {
                                    if (result.isConfirmed) {
                                        for (const bulletPoint of bulletPointsQuery.data ?? []) {
                                            if (bulletPoint.bulletPointGroup === group.id) {
                                                await bulletPointsService.deleteByID(bulletPoint.id ?? -1);
                                            }
                                        }
                                        await bulletPointGroupService.deleteByID(group.id ?? -1);
                                        await queryCache.invalidateQueries(["bulletPointsGroup"]);
                                        await bulletPointsGroupQuery.refetch();
                                    }
                                });
                            }}
                        />
                    </div>
                    <TableNeogen
                        entries={bulletPointsQuery.data?.filter((bp) => bp.bulletPointGroup === group.id) ?? []}
                        ignoreFields={["bulletPointGroup"]}
                        actions={[
                            // Edit
                            {
                                label: "Edit",
                                icon: "fas fa-edit",
                                onClick: (id: number) => {
                                    setBulletPoint(bulletPointsQuery.data?.find((bp) => bp.id === id) ?? null);
                                    setBulletPointGroup(group);
                                    setShowAddBulletPoint(true);
                                },
                            },
                            // Delete
                            {
                                label: "Delete",
                                type: "danger",
                                icon: "fas fa-trash",
                                onClick: async (id: number) => {
                                    SwalNeogenFire({
                                        title: "Are you sure?",
                                        text: "You will not be able to recover this bullet point!",
                                        icon: "warning",
                                        showCancelButton: true,
                                        confirmButtonText: "Yes, delete it!",
                                        confirmButtonColor: "#d33",
                                        cancelButtonText: "No, cancel!",
                                    }).then(async (result) => {
                                        if (result.isConfirmed) {
                                            await bulletPointsService.deleteByID(id);
                                            await queryCache.invalidateQueries(["bulletPoints"]);
                                            await bulletPointsQuery.refetch();
                                        }
                                    });
                                },
                            },
                        ]}
                    />
                </div>
            ))}

            <AddBulletPointGroup
                show={showAddBulletPointGroup}
                close={() => setShowAddBulletPointGroup(false)}
                bulletPointGroup={bulletPointGroup}
            />
            <AddBulletPoint
                show={showAddBulletPoint}
                close={() => setShowAddBulletPoint(false)}
                bulletPoint={bulletPoint}
                bulletPointGroup={bulletPointGroup?.id ?? -1}
            />
        </>
    );
}

export default BulletPoints;
