/**
 *
 *
 * @author Matthew Riddell <matt@neogen.ai>
 * @date 8/24/20, 10:39 AM
 *
 */

import axios, { AxiosError } from "axios";
import { NeogenRoles, User } from "../typings/api";
import API from "./api.service";
import * as Sentry from "@sentry/react";
import axiosRetry from "axios-retry";
import { ErcUser } from "../typings/api/erc-user";
import { ClearERCUser } from "../typings/api/clear-erc-user";

// Exponential back-off retry delay between requests
axiosRetry(axios, { retries: 200, retryDelay: axiosRetry.exponentialDelay });
// import { useQuery, useMutation, useQueryClient, QueryCache, ReactQueryCacheProvider } from ""react-query";";

export const API_URL = process.env.REACT_APP_API_URL ?? "https://api.clearerc.com/";

class AuthService {
    rolesVal: NeogenRoles[] = [];
    userVal: ClearERCUser | null = null;
    get roles() {
        if (this.rolesVal.length === 0) {
            this.rolesVal = JSON.parse(localStorage.getItem("roles") ?? "[]");
        }
        return this.rolesVal;
    }
    set roles(roles: NeogenRoles[]) {
        console.trace("Setting roles", roles);
        localStorage.setItem("roles", JSON.stringify(roles));
        this.rolesVal = roles;
    }
    get user() {
        console.trace("Getting user", this.userVal);
        return this.userVal;
    }
    set user(user: ClearERCUser | null) {
        console.trace("Setting user", user);
        this.userVal = user;
    }

    API: API<User>;
    // cache = useQueryClient();

    constructor() {
        this.API = new API("/users");
    }

    async login(email: string, password: string) {
        localStorage.clear();
        try {
            const response = await this.API.postURL("/users/login", {
                email,
                password,
            });

            if (!response.data.roles) {
                response.data.roles = [];
            }
            response.data.roles.push({ roleCode: "URL_LOGOUT" }); // Everyone can log out
            this.roles = response.data.roles;

            if (response.data.token) {
                localStorage.setItem("user", JSON.stringify(response.data));
                localStorage.setItem("refreshToken", response.data.refreshToken);
                const user = response.data;
                this.user = user;
                return user;
            } else {
                throw { message: "Invalid Username/Password" };
            }
        } catch (e) {
            if ((e as AxiosError).response?.status === 401) {
                throw { message: "Invalid Username/Password" };
            } else if ((e as AxiosError).code === "ERR_NETWORK") {
                // Throw error to sentry
                Sentry.captureException(e);
                throw { message: "Network Error" };
            } else {
                throw e;
            }
        }
    }

    logout() {
        localStorage.clear();
        window.location.href = "/";
    }

    register(email: string, password: string) {
        return axios.post(API_URL + "users/signup", {
            email: email,
            password: password,
        });
    }
    convert(email: string, password: string, id: string) {
        return axios.post(API_URL + "user-conversion", {
            email: email,
            password: password,
            id: id,
        });
    }

    whoAmiI() {
        return this.API.getURL("whoAmI");
    }

    async refresh() {
        const refreshToken = localStorage.getItem("refreshToken");
        const refresh = await this.API.postURL("/refresh", {
            refreshToken: refreshToken,
        }).catch((e: AxiosError) => {
            if (e?.response?.status === 401 || e?.response?.status === 403) {
                localStorage.clear();
                window.location.href = "/";
            }
        });
        if (refresh?.data?.accessToken) {
            if (localStorage.getItem("user") !== null) {
                const user = JSON.parse(localStorage.getItem("user") ?? "{}");
                user.token = refresh.data.accessToken;
                localStorage.setItem("user", JSON.stringify(user));
                localStorage.setItem("refreshToken", refresh.data.accessToken);
            }
        }
    }

    getCurrentUser() {
        try {
            if (localStorage.getItem("user") !== null) {
                return JSON.parse(localStorage.getItem("user") ?? "{}") ?? null;
            } else {
                const publicUserId = localStorage.getItem("publicUserId");
                if (publicUserId) {
                    return { id: publicUserId, publicUser: true };
                }
                console.error("No User in local storage");
                return null;
            }
        } catch (e) {
            localStorage.clear();
            window.location.href = "/";
            return {};
        }
    }

    hasAllRoles(roles: string[]) {
        const user = this.getCurrentUser();
        if (user !== null) {
            for (const role of roles) {
                if (!user.roles.includes(role)) {
                    return false;
                }
            }
            return true;
        } else {
            return false;
        }
    }

    setCurrentUser(user: any) {
        localStorage.setItem("user", JSON.stringify(user));
    }

    async canIAccess(roleCode: string) {
        const roles = this.roles;
        if (!Array.isArray(roles)) {
            return false;
        }
        return roles?.find((role: NeogenRoles) => role?.roleCode === roleCode) !== undefined;
    }
}

export default new AuthService();
