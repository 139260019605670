import { z } from "zod";
import ModalDialog from "../../../layout/modal-dialog";
import { useForm } from "../../../hooks/useForm";
import { Form } from "../../../layout/form/form";
import { useMutation } from "@tanstack/react-query";
import { useAuth } from "../../../auth/use-auth";
import { NumberField } from "../../../layout/form/number-field";
import { createInvoice } from "../../../invoices/actions/create-invoice";
import { Invoice } from "../../../invoices/domain/invoice";
import { useFieldArray } from "react-hook-form";
import { LineItemsType } from "../../../services/line-items.service";
import { TBody, THead, Table, Td, Th, Tr } from "../../../layout/table";
import { Select } from "../../../layout/form/select-input";
import { CurrencyField } from "../../../layout/form/currency-field";
import { TextField } from "../../../layout/form/text-field";
import ButtonNeoGen from "../../../layout/button-neogen";
import ErrorSection from "../../../layout/error-section";

const schema = z.object({
    description: z.string().optional(),
    lineItems: z.array(z.object({ lineItemId: z.number(), price: z.number() })),
});

type Data = z.infer<typeof schema>;

export const NewInvoiceModal = ({
    onClose,
    onCreate,
    defaultValues,
    companyId,
    lineItems,
}: {
    onClose: () => void;
    onCreate: (invoice: Invoice) => void;
    defaultValues: Partial<Data>;
    companyId: number;
    lineItems: LineItemsType[];
}) => {
    const auth = useAuth();
    const authToken = auth.expectAuthToken();

    const form = useForm({
        schema,
        defaultValues: { description: "Invoice for ERCRepair", ...(defaultValues as any) },
    });

    const { fields, append, prepend, remove, swap, move, insert, update } = useFieldArray({
        control: form.control,
        name: "lineItems",
    });

    const mutation = useMutation({
        mutationFn: async (data: Data) => {
            if (!data.lineItems || data.lineItems.length === 0) {
                throw new Error("No line items provided");
            }
            const invoice = await createInvoice({ authToken, data: { ...data, companyId } });
            return invoice;
        },
    });

    const handleSubmit = form.handleSubmit(async (data) => {
        const invoice = await mutation.mutateAsync(data);
        onCreate(invoice);
    });

    return (
        <Form onSubmit={handleSubmit}>
            <ModalDialog
                title="New Invoice"
                show
                close={onClose}
                okAction={handleSubmit}
                okText="Issue Invoice"
                size="md"
                isLoading={mutation.isLoading}
                disabled={mutation.isLoading || fields.length === 0}
            >
                {mutation.error && <ErrorSection errors={[(mutation.error as any)?.message as any]} />}
                <TextField label="Description" {...form.getFieldProps("description")} />
                <div className="flex flex-row justify-between p-4">
                    <div></div>
                    <div>
                        <ButtonNeoGen
                            onClick={() => {
                                append({});
                            }}
                            disabled={mutation.isLoading}
                        >
                            Add Line Item
                        </ButtonNeoGen>
                    </div>
                </div>
                <Table>
                    <THead>
                        <Tr>
                            <Th style={{ width: 300 }}>Line Items</Th>
                            <Th>Price</Th>
                            <Td></Td>
                        </Tr>
                    </THead>
                    <TBody>
                        {!fields ||
                            (fields.length === 0 && (
                                <Tr>
                                    <Td colSpan={3} style={{ textAlign: "center" }}>
                                        No line items
                                    </Td>
                                </Tr>
                            ))}
                        {fields &&
                            fields.length > 0 &&
                            fields.map((field, index) => {
                                const invoiceLineItem = field as Data["lineItems"][0] & { id: string };
                                return (
                                    <Tr key={invoiceLineItem.id}>
                                        <Td>
                                            <Select
                                                options={lineItems.map((li) => ({
                                                    label: li.name as string,
                                                    value: li.id as number,
                                                }))}
                                                value={invoiceLineItem.lineItemId}
                                                disabled={mutation.isLoading}
                                                onChange={(lineItemId) => {
                                                    const lineItem = lineItems.find((li) => li.id === lineItemId);
                                                    update(index, {
                                                        ...field,
                                                        lineItemId,
                                                        price: lineItem?.price || 0,
                                                    });
                                                }}
                                            />
                                        </Td>
                                        <Td>
                                            <CurrencyField
                                                {...form.getFieldProps(`lineItems.${index}.price`)}
                                                disabled={mutation.isLoading}
                                            />
                                        </Td>
                                        <Td style={{ textAlign: "right" }}>
                                            <ButtonNeoGen
                                                type="outline-primary"
                                                onClick={() => {
                                                    remove(index);
                                                }}
                                                disabled={mutation.isLoading}
                                            >
                                                Remove
                                            </ButtonNeoGen>
                                        </Td>
                                    </Tr>
                                );
                            })}
                    </TBody>
                </Table>
            </ModalDialog>
        </Form>
    );
};
