import { makeRequest } from "../../../jason-proof-of-concept/shared/utils";
import { Referrer } from "../domain/referrer";

export type UpdateReferrerData = Partial<Referrer>;

export const updateReferrer = async ({
    authToken,
    data,
    id,
}: {
    authToken: string;
    data: UpdateReferrerData;
    id: number;
}) => {
    const response = await makeRequest({
        method: "patch",
        path: `/referrers/${id}`,
        authToken,
        data,
    });

    return response.data;
};
