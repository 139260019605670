import { SortableContext, useSortable } from "@dnd-kit/sortable";
import TrashIcon from "../icons/TrashIcon";
import { Column, Id, Task } from "../types";
import { CSS } from "@dnd-kit/utilities";
import { useMemo, useState } from "react";
import PlusIcon from "../icons/PlusIcon";
import TaskCard from "./TaskCard";

interface Props {
    column: string;
    tasks: Task[];
}

function ColumnContainer({ column, tasks }: Props) {
    const [editMode, setEditMode] = useState(false);

    const tasksIds = useMemo(() => {
        return tasks.map((task) => task.id);
    }, [tasks]);

    const { setNodeRef, attributes, listeners, transform, transition, isDragging } = useSortable({
        id: column,
        data: {
            type: "Column",
            column,
        },
        disabled: editMode,
    });

    const style = {
        transition,
        transform: CSS.Transform.toString(transform),
    };

    return (
        <div
            ref={setNodeRef}
            style={style}
            className="bg-gray-300 dark:bg-gray-800 w-[250px] h-screen rounded-xl flex flex-col"
        >
            {/* Column title */}
            <div
                {...attributes}
                {...listeners}
                onClick={() => {
                    // setEditMode(true);
                    // alert("Edit mode not implemented yet");
                    // alert(JSON.stringify(column));
                    // changeLevel(column);
                }}
                className="

      text-md
      h-[60px]
      cursor-grab
      rounded-xl
      rounded-b-none
      p-3
      font-bold
      border-gray-300
        dark:border-gray-700
      border-4
      flex
      items-center
      justify-between
      "
            >
                <div className="flex gap-2">
                    <div
                        className="
        flex
        justify-center
        items-center
        bg-blue-500
        px-2
        py-1
        text-sm
        text-white
        dark:bg-blue-900
        dark:text-green-300
        rounded-full
        "
                    >
                        {tasks.length}
                    </div>
                    {column.replace(/[0-9]/g, "")}
                </div>
            </div>

            {/* Column task container */}
            <div className="flex flex-grow flex-col gap-4 p-2 overflow-x-hidden overflow-y-auto bg-gray-100 dark:bg-gray-700">
                <SortableContext items={tasksIds}>
                    {tasks.map((task) => (
                        <TaskCard key={task.id} task={task} />
                    ))}
                </SortableContext>
            </div>
            {/* Column footer */}
        </div>
    );
}

export default ColumnContainer;
