import React from "react";
import PageDescription from "../../layout/page-description";
import TableNeogen from "../../layout/table-neogen";
import ButtonNeoGen from "../../layout/button-neogen";

const currentYear = new Date().getFullYear();

const transactions = [
    { id: 1, name: "Tech Titans", type: "Pending", amount: 5000, date: "2023-10-10" },
    { id: 2, name: "Code Crafters", type: "In Review", amount: 10000, date: "2023-10-11" },
    { id: 3, name: "Digital Dynamics", type: "In Process", amount: 15000, date: "2023-10-12" },
    { id: 4, name: "Binary Bakers", type: "Paid Out", amount: 20000, date: "2023-10-13" },
    { id: 5, name: "Pixel Pioneers", type: "Pending", amount: 25000, date: "2023-10-14" },
    { id: 6, name: "Logic Loomers", type: "In Review", amount: 30000, date: "2023-10-15" },
    { id: 7, name: "Syntax Spartans", type: "In Process", amount: 35000, date: "2023-10-16" },
    { id: 8, name: "Data Driven", type: "Paid Out", amount: 40000, date: "2023-10-17" },
    { id: 9, name: "Algorithm Architects", type: "Pending", amount: 45000, date: "2023-10-18" },
    { id: 10, name: "Function Founders", type: "In Review", amount: 50000, date: "2023-10-19" },
    { id: 11, name: "Quantum Quirks", type: "In Process", amount: 55000, date: "2023-10-20" },
    { id: 12, name: "Bit Builders", type: "Paid Out", amount: 60000, date: "2023-10-21" },
    { id: 13, name: "Network Navigators", type: "Pending", amount: 65000, date: "2023-10-22" },
    { id: 14, name: "Protocol Pioneers", type: "In Review", amount: 70000, date: "2023-10-23" },
    { id: 15, name: "Database Dynamos", type: "In Process", amount: 75000, date: "2023-10-24" },
    { id: 16, name: "Interface Innovators", type: "Paid Out", amount: 80000, date: "2023-10-25" },
    { id: 17, name: "Cloud Chasers", type: "Pending", amount: 55000, date: "2023-10-26" },
    { id: 18, name: "Stack Stalwarts", type: "In Review", amount: 60000, date: "2023-10-27" },
    { id: 19, name: "Runtime Rangers", type: "In Process", amount: 65000, date: "2023-10-28" },
    { id: 20, name: "Kernel Keepers", type: "Paid Out", amount: 70000, date: "2023-10-29" },
];

function Paymaster() {
    const [type, setType] = React.useState("All");
    return (
        <>
            <PageDescription title="Paymaster" description="This is the paymaster information page for affiliates" />
            {type === "All" && (
                <>
                    <div className="grid grid-cols-4 gap-5">
                        <div
                            className="bg-red-800 cursor-pointer bg-gradient-to-br from-red-600 border-2 border-white dark:border-gray-700 p-3 dark:text-gray-300 text-gray-50 text-center rounded-lg shadow"
                            onClick={() => {
                                setType("Pending");
                            }}
                        >
                            Pending Commissions
                            <div className="text-xl">
                                {" "}
                                {Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(
                                    Math.random() * 100000,
                                )}
                            </div>
                        </div>
                        <div
                            className="bg-blue-800 cursor-pointer bg-gradient-to-br from-blue-600 border-2 border-white  dark:border-gray-700 p-3 dark:text-gray-300 text-gray-50 text-center rounded-lg shadow"
                            onClick={() => {
                                setType("In Review");
                            }}
                        >
                            In Review
                            <div className="text-xl">
                                {" "}
                                {Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(
                                    Math.random() * 100000,
                                )}
                            </div>
                        </div>
                        <div
                            className="bg-green-800 cursor-pointer bg-gradient-to-br from-green-600 border-2 border-white  dark:border-gray-700 p-3 dark:text-gray-300 text-gray-50 text-center rounded-lg shadow"
                            onClick={() => {
                                setType("In Process");
                            }}
                        >
                            In process
                            <div className="text-xl">
                                {" "}
                                {Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(
                                    Math.random() * 100000,
                                )}
                            </div>
                        </div>
                        <div
                            className="bg-indigo-800 cursor-pointer bg-gradient-to-br from-indigo-600 border-2 border-white  dark:border-gray-700 p-3 dark:text-gray-300 text-gray-50 text-center rounded-lg shadow"
                            onClick={() => {
                                setType("Paid Out");
                            }}
                        >
                            Paid Out
                            <div className="text-xl">
                                {" "}
                                {Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(
                                    Math.random() * 100000,
                                )}
                            </div>
                        </div>
                    </div>
                </>
            )}
            {type === "Pending" && (
                <>
                    <ButtonNeoGen onClick={() => setType("All")}>
                        <i className="fal fa-angle-left mr-3"></i>Reset To All Commissions
                    </ButtonNeoGen>
                    <h1 className="text-lg mt-3">Pending Commissions</h1>
                </>
            )}
            {type === "In Review" && (
                <>
                    <ButtonNeoGen onClick={() => setType("All")}>
                        <i className="fal fa-angle-left mr-3"></i>Reset To All Commissions
                    </ButtonNeoGen>
                    <h1 className="text-lg mt-3">In Review Commissions</h1>
                </>
            )}
            {type === "In Process" && (
                <>
                    <ButtonNeoGen onClick={() => setType("All")}>
                        <i className="fal fa-angle-left mr-3"></i>Reset To All Commissions
                    </ButtonNeoGen>
                    <h1 className="text-lg mt-3">In Process Commissions</h1>
                </>
            )}
            {type === "Paid Out" && (
                <>
                    <ButtonNeoGen onClick={() => setType("All")}>
                        <i className="fal fa-angle-left mr-3"></i>Reset To All Commissions
                    </ButtonNeoGen>
                    <h1 className="text-lg mt-3">Paid Out Commissions</h1>
                </>
            )}
            <div className="mt-5">
                <TableNeogen
                    entries={
                        type === "All" ? transactions : transactions.filter((transaction) => transaction.type === type)
                    }
                    actions={[
                        {
                            label: "Open Company",
                            onClick: (transaction) => {
                                window.location.href = `/companies/2230`;
                            },
                        },
                    ]}
                    formatters={[
                        { field: "amount", type: "Currency" },
                        { field: "date", type: "Date" },
                    ]}
                />
            </div>
        </>
    );
}

export default Paymaster;
