/**
 * Renders a modal dialog for adding or editing a bullet point.
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.show - Indicates whether the modal should be shown.
 * @param {Function} props.close - The function to close the modal.
 * @param {BulletPoint | null} props.bulletPoint - The bullet point to edit, or null if adding a new bullet point.
 * @param {number} props.bulletPointGroup - The ID of the bullet point group.
 * @returns {JSX.Element} The rendered modal dialog component.
 */
import React, { useEffect } from "react";
import ModalDialog from "../../../../layout/modal-dialog";
import bulletPointService, { BulletPoint } from "../../../../services/bullet-points.service";
import PrintPre from "../../../../layout/print-pre";
import InputControlled from "../../../../layout/input-controlled";
import { useQueryClient } from "@tanstack/react-query";
import TextAreaNeoGenControlled from "../../../../layout/text-area-controlled";

function AddBulletPoint({
    show,
    close,
    bulletPoint,
    bulletPointGroup,
}: {
    show: boolean;
    close: () => void;
    bulletPoint: null | BulletPoint;
    bulletPointGroup: number;
}): JSX.Element {
    const [name, setName] = React.useState<string>("");
    const [description, setDescription] = React.useState<string>("");
    const queryCache = useQueryClient();

    useEffect(() => {
        if (bulletPoint) {
            setName(bulletPoint.name);
            setDescription(bulletPoint.description);
        } else {
            setName("");
            setDescription("");
        }
    }, [bulletPoint, show]);

    /**
     * Saves the bullet point.
     * If editing an existing bullet point, updates the bullet point.
     * If adding a new bullet point, creates a new bullet point.
     * Invalidates the "bulletPoints" query cache and closes the modal.
     */
    async function save() {
        if (bulletPoint) {
            await bulletPointService.update(bulletPoint.id, { name, description });
            queryCache.invalidateQueries(["bulletPoints"]);
            close();
        } else {
            const newGroup: BulletPoint = {
                name,
                description,
                bulletPointGroup,
            };
            await bulletPointService.create(newGroup);
            queryCache.invalidateQueries(["bulletPoints"]);
            close();
        }
    }

    return (
        <ModalDialog
            close={() => close()}
            show={show}
            title={bulletPoint ? "Edit Bulletpoint" : "Add Bulletpoint"}
            okAction={() => save()}
        >
            <>
                <InputControlled value={name} label="Name" onChange={(v) => setName(v)} />
                <TextAreaNeoGenControlled label={"Text"} value={description} setValue={(v) => setDescription(v)} />
            </>
        </ModalDialog>
    );
}

export default AddBulletPoint;
