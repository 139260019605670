import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { Sunburst } from "../../layout/sunburst";
import utmLinkService from "../../services/utm-link.service";
import utmLogService from "../../services/utm-log.service";
import Loader2 from "../utilities/Loader2";
import ColorHash from "color-hash";
import { ClearERCUser } from "../../typings/api/clear-erc-user";

type UTMEntries = {
    id: string;
    data: [
        {
            x: string;
            y: number;
        },
    ];
};

export default function UtmStats(props: {
    darkMode: boolean;
    users: ClearERCUser[] | undefined;
    usersQuerySuccess: boolean;
    usersQueryLoading: boolean;
}) {
    const [utms, setUtms] = useState<any>([]);

    const utmLinksQuery = useQuery(["utmLinks"], async () => {
        const response = await utmLinkService.getAll();
        if (response) {
            return response.data;
        } else {
            return [];
        }
    });

    function componentToHex(c: { toString: (arg0: number) => any }) {
        const hex = c.toString(16);
        return hex.length == 1 ? "0" + hex : hex;
    }

    function rgbToHex(
        r: { toString: (arg0: number) => any },
        g: { toString: (arg0: number) => any },
        b: { toString: (arg0: number) => any },
    ) {
        return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
    }

    const utmQuery = useQuery(
        ["utmLogs", "forPie"],
        async () => {
            // const links = await utmLinkService.getAll();
            const response = await utmLogService.getLastSevenDays();
            // const users = await userService.getAll();
            const utmCalcs: any = {};
            if (response) {
                // console.error("response", response.data);
                response.data.forEach((item) => {
                    if (!item.utm || item.utm === "Unknown") {
                        return;
                    }
                    const name =
                        props.users?.find(
                            (user) =>
                                user.id === utmLinksQuery.data?.find((link) => link.code === item.utm)?.owner ??
                                "No Owner Found",
                        )?.name +
                        " (" +
                        (utmLinksQuery.data?.find((link) => link.id === item.utm)?.name ??
                            "..." + item.utm.substring(item.utm.length - 5)) +
                        ")";
                    if (utmCalcs[name]) {
                        utmCalcs[name].hits++;
                        if (item.data.length > 0) {
                            utmCalcs[name].registrations++;
                        }
                    } else {
                        utmCalcs[name] = {
                            hits: 1,
                            registrations: 0,
                        };
                    }
                });

                const recalcs = Object.keys(utmCalcs)
                    .map((key) => {
                        const colorHash = new ColorHash({
                            hue: [
                                { min: 30, max: 285 },
                                { min: 8, max: 10 },
                                { min: 270, max: 285 },
                            ],
                            lightness: [0.7, 0.7, 0.7],
                        });
                        const color = colorHash.rgb(key);
                        const colorHashDark = new ColorHash({
                            hue: [
                                { min: 30, max: 205 },
                                { min: 8, max: 10 },
                                { min: 270, max: 285 },
                            ],
                            lightness: [0.5, 0.5, 0.5],
                        });
                        const darkColor = colorHashDark.rgb(key);

                        return {
                            id: key,
                            // data: [
                            // {
                            // id: "Hits",
                            label: (key + " (" + utmCalcs[key].hits + ")").substring(0, 38),
                            value: utmCalcs[key].hits,
                            color: rgbToHex(color[0], color[1], color[2]),
                            darkColor: rgbToHex(darkColor[0], darkColor[1], darkColor[2]),
                            // },
                            // {
                            // x: "Registrations",
                            // y: utmCalcs[key].registrations,
                            // },
                            // ],
                        };
                    })
                    .sort((a, b) => b?.value - a?.value);

                // console.error(
                //     "recalcs",
                //     recalcs.filter((i) => i.value > 0),
                // );

                setUtms(recalcs.filter((i) => i.value > 0));
                return recalcs.filter((i) => i.value > 0);
            }
        },
        {
            enabled:
                props.usersQuerySuccess &&
                utmLinksQuery.isSuccess &&
                (props.darkMode === true || props.darkMode === false),
        },
    );

    return (
        <>
            <div className=" min-w-full w-96">
                <div className="bg-gradient-to-br to-white from-slate-100 dark:from-slate-900 dark:to-gray-800 border-2 border-white rounded-xl dark:bg-gray-800 dark:border-gray-700 dark:shadow-inner shadow">
                    <h2 className=" mx-auto mt-5 mb-3  px-0 text-lg leading-6 text-gray-700 dark:text-gray-400 sm:px-6 lg:px-8">
                        UTM Hits
                    </h2>
                    <div className="  rounded pb-7 w-full ">
                        {/* <Stages stages={[]} onStageChange={() => null} /> */}

                        {props.usersQueryLoading || utmQuery.isLoading || utmLinksQuery.isLoading ? (
                            <Loader2 />
                        ) : (
                            <>
                                {/* <PrintPre>
                                    {usersQuery.data}
                                </PrintPre>
                                <PrintPre>
                                    {utmLinksQuery.data}
                                </PrintPre>
                                <PrintPre>
                                    {utmQuery.data}
                                </PrintPre> */}

                                <div style={{ height: "400px" }} className="z-50 overflow-visible">
                                    {utmQuery.data ? (
                                        // <RadialBarInternal data={utmQuery.data} />
                                        <Sunburst data={utmQuery.data} darkMode={props.darkMode} />
                                    ) : (
                                        // <PrintPre>{utmQuery.data}</PrintPre>
                                        <div className="lg:px-8">No data currently available</div>
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}
