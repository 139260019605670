import { ReactNode } from "react";
import { Label } from "../label";
import { ExclamationCircleIcon } from "@heroicons/react/24/solid";

export type FieldProps = {
    label?: string;
    isRequired?: boolean;
    children: ReactNode;
    helperText?: string;
    errors?: { message?: string }[];
    placeholder?: string;
    isFocused?: boolean;
};

export type FormFieldProps<V> = Omit<FieldProps, "children"> & {
    defaultValue?: V;
};

export const Field = ({
    children,
    helpText,
    label,
    isRequired,
    error,
    noMargin,
}: {
    children?: ReactNode;
    helpText?: ReactNode;
    label: ReactNode;
    isRequired?: boolean;
    error?: string;
    noMargin?: boolean;
}) => {
    return (
        <div className={`flex flex-col ${noMargin ? "" : " mb-2 "} gap-1 relative`}>
            {label && <Label isRequired={!!isRequired} text={label} />}
            {children}
            {error && <p className="text-red-400 text-sm">{error}</p>}
            {helpText && <p className="text-gray-500 text-sm font-extralight">{helpText}</p>}
        </div>
    );
};
