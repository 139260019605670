import React, { useEffect } from "react";
import PrintPre from "../../layout/print-pre";
import invoicesService, { InvoiceType } from "../../services/invoices.service";
import { useQuery } from "@tanstack/react-query";
import TableNeogen from "../../layout/table-neogen";
import warning from "./warning.png";

function Invoices({ companyId, data, onChange }: { companyId?: number; data: any; onChange: any }) {
    const [checking, setChecking] = React.useState(false);
    const invoicesQuery = useQuery(["invoices", { companyId }], async () => {
        if (!companyId) {
            return [];
        }
        const response = await invoicesService.getFilteredWhere({ companyId });
        if (response) {
            return response.data as InvoiceType[];
        }
        return [];
    });
    useEffect(() => {
        if (invoicesQuery.isSuccess && invoicesQuery.data?.length === 0) {
            console.log("No invoices found for this company.;");
            // Set a timeout to check for invoices every 5 seconds
            const interval = setInterval(async () => {
                setChecking(true);
                await invoicesQuery.refetch();
                setChecking(false);
            }, 5000);
            return () => clearInterval(interval);
        }
    }, [invoicesQuery, invoicesQuery.data?.length, invoicesQuery.isSuccess]);
    if (invoicesQuery.isLoading || invoicesQuery?.data === undefined) {
        return <div>Loading...</div>;
    }
    return (
        <div>
            {/* <PrintPre>Company Id: {companyId}</PrintPre> */}
            <div className="my-5">
                {invoicesQuery.data.length === 0 ? (
                    <div className="border border-gray-300 shadow rounded-lg dark:border-gray-900 p-5 bg-gray-50 dark:bg-gray-900">
                        <img src={warning} alt="Warning" className="mx-auto mb-5" width="100" />
                        <h1 className="text-xl text-center">There are currently no invoices on your account</h1>
                        <p className="my-5 text-center">
                            Please let your case manager know that you don&apos;t have any invoices available.{" "}
                            {checking && <>...</>}
                        </p>
                    </div>
                ) : (
                    <></>
                )}
            </div>
            <TableNeogen
                ignoreFields={[
                    "updatedAt",
                    "issuedAt",
                    "paidAt",
                    "stripeInvoiceId",
                    "companyId",
                    "dueDate",
                    "stripeInvoiceUrl",
                    "qbInvoiceId",
                    // "qbInvoicePaymentUrl",
                    "invoiceNumber",
                ]}
                formatters={[
                    {
                        field: "amount",
                        type: "Currency",
                    },
                    {
                        field: "amountPaid",
                        type: "Currency",
                    },
                    {
                        field: "createdAt",
                        type: "Date",
                    },
                    {
                        field: "qbInvoicePaymentUrl",
                        type: "PayLink",
                    },
                ]}
                entries={(invoicesQuery?.data ?? []).map((invoice) => ({
                    ...invoice,
                    qbInvoicePaymentUrl: invoice.status === "issued" ? "" : invoice.qbInvoicePaymentUrl,
                }))}
            />
        </div>
    );
}

export default Invoices;
