import { z } from "zod";

export const mandateIndustryGroupSchema = z.object({
    id: z.number(),
    mandateId: z.number(),
    mandateMandateEffectId: z.number(),
    industryGroupId: z.number(),
});

export type MandateIndustryGroup = z.infer<typeof mandateIndustryGroupSchema>;
