import { z } from "zod";
import { User } from "../../../jason-proof-of-concept/users/domain/user";
import { getAuthTokenNoThrow } from "../../../services/auth-header";
import { useForm } from "../../../hooks/useForm";
import { useMutation } from "@tanstack/react-query";
import { createUser } from "../../../jason-proof-of-concept/users/actions/create-user";
import roleGroupService from "../../../services/role-group.service";
import ModalDialog from "../../../layout/modal-dialog";
import { Form } from "../../../layout/form/form";
import { TextField } from "../../../layout/form/text-field";
import { EmailField } from "../../../layout/form/email-field";
import { PasswordField } from "../../../layout/form/password-field";
import ButtonNeoGen from "../../../layout/button-neogen";
import { updateUser } from "../../../jason-proof-of-concept/users/actions/update-user";
import { PhoneNumberField } from "../../../layout/form/phone-number-field";

const schema = z.object({
    firstName: z.string().min(1),
    lastName: z.string().min(1),
    email: z.string().email(),
    phone: z.string().nullish(),
});

type Data = z.infer<typeof schema>;

export const EditReferrerModal = ({
    onClose,
    onReferrerUpdated,
    referrer,
}: {
    onClose: () => any;
    onReferrerUpdated: (user: User) => void;
    referrer: User;
}) => {
    const authToken = getAuthTokenNoThrow() || "no-auth-token";

    const form = useForm({
        schema,
        defaultValues: {
            firstName: referrer.firstName as string,
            lastName: referrer.lastName as string,
            email: referrer.email as string,
            phone: referrer.phone as string,
        },
    });

    const mutation = useMutation({
        mutationFn: async (data: Data) => {
            const updatedUser = await updateUser({
                id: referrer.id,
                authToken,
                data,
            });

            return updatedUser;
        },
    });

    const handleSubmit = async (data: Data) => {
        const updatedUser = await mutation.mutateAsync(data);
        onReferrerUpdated(updatedUser);
    };

    return (
        <ModalDialog show title={"Edit Referrer"} close={onClose} showOk={false} showCancel={false}>
            <Form onSubmit={form.handleSubmit(handleSubmit)} error={mutation.error as any}>
                <div className="grid grid-cols-1 gap-3">
                    <TextField label="First name" {...form.getFieldProps("firstName")} isRequired={true} />
                    <TextField label="Last name" {...form.getFieldProps("lastName")} isRequired={true} />
                    <EmailField autoComplete="username" label="Email Address" {...form.getFieldProps("email")} />
                    <PhoneNumberField label="Phone" {...form.getFieldProps("phone")} />
                </div>
                <ButtonNeoGen block type="submit" disabled={mutation.isLoading}>
                    Edit referrer
                </ButtonNeoGen>
            </Form>
        </ModalDialog>
    );
};
