import APIService from "./api.service";

export type ZipCode = {
    id?: number;
    zipCode: string;
    lat: string;
    long: string;
    city: string;
    state: string;
    county: string;
};

class ZipCodeService extends APIService<ZipCode> {
    constructor() {
        super("/zip-codes");
    }
}

export default new ZipCodeService();
