import APIService from "./api.service";

export type InvoiceType = {
    id?: number;
    createdAt: Date;
    updatedAt: Date;
    issuedAt: Date;
    companyId: 1;
    invoiceNumber: number;
    description: string;
    status: string;
    amount: number;
    stripeInvoiceId: string;
    dueDate: Date;
    qbInvoicePaymentUrl: string;
};

class InvoicesService extends APIService<InvoiceType> {
    constructor() {
        super("/invoices");
    }
}

export default new InvoicesService();
