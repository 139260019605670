import { useQuery } from "@tanstack/react-query";
import { getReferrers } from "../actions/get-referrers";

type Params = {
    authToken: string;
    filters?: any;
};

const getReferrersQueryKey = ({ authToken, filters }: Params) => {
    return ["referrers", { authToken, filters }];
};

export const useReferrers = ({ authToken, filters }: Params) => {
    const query = useQuery({
        queryKey: getReferrersQueryKey({ authToken, filters }),
        queryFn: async () => {
            const referrers = await getReferrers({ authToken, filters });
            return referrers;
        },
    });
    return query;
};
