import { startCase } from "lodash";
import { Invoice } from "../../../invoices/domain/invoice";
import { DateDisplay } from "../../../layout/date-display";
import ModalDialog from "../../../layout/modal-dialog";
import { TBody, Table, Td, Tr } from "../../../layout/table";
import { Currency } from "../../../billing/components";
import { InvoiceLineItem } from "../../../invoice-line-items/domain/invoice-line-item";

export const InvoiceModal = ({
    invoice,
    onClose,
    invoiceLineItems,
}: {
    invoice: Invoice;
    onClose: () => void;
    invoiceLineItems?: InvoiceLineItem[];
}) => {
    return (
        <ModalDialog title={`Invoice ${invoice.invoiceNumber}`} show close={onClose} size="md">
            <div className="flex flex-row gap-4">
                <div>
                    <Table>
                        <TBody>
                            <Tr>
                                <Td>Invoice Number</Td>
                                <Td>{invoice.invoiceNumber}</Td>
                            </Tr>
                            <Tr>
                                <Td>Status</Td>
                                <Td>{invoice.status && startCase(invoice.status)}</Td>
                            </Tr>
                            <Tr>
                                <Td>Date Issued</Td>
                                <Td>{invoice.issuedAt && <DateDisplay date={invoice.issuedAt} />}</Td>
                            </Tr>
                        </TBody>
                    </Table>
                </div>
                <div>
                    <Table>
                        <TBody>
                            <Tr>
                                <Td>Amount</Td>
                                <Td>
                                    <Currency amount={invoice.amount || 0} />
                                </Td>
                            </Tr>
                            <Tr>
                                <Td>Date Paid</Td>
                                <Td>{invoice.paidAt && <DateDisplay date={invoice.paidAt} />}</Td>
                            </Tr>
                            <Tr>
                                <Td>Amount Paid</Td>
                                <Td>
                                    <Currency amount={invoice.amountPaid || 0} />
                                </Td>
                            </Tr>
                        </TBody>
                    </Table>
                </div>
            </div>
        </ModalDialog>
    );
};
