import ModalDialog from "../../../layout/modal-dialog";
import { TBody, THead, Table, Td, Tr } from "../../../layout/table";
import { Payment } from "../../../payments/domain/payment";

export const PaymentsHistoryModal = ({
    onClose,
    name,
    payments,
}: {
    onClose: () => void;
    name: string;
    payments?: Payment[];
}) => {
    return (
        <ModalDialog
            show
            title={`Payments History for ${name}`}
            close={onClose}
            showOk={false}
            showCancel={false}
            size="sm"
        >
            {payments?.length === 0 || !payments ? (
                <p>No payments found</p>
            ) : (
                <Table>
                    <THead>
                        <Tr>
                            <Td>Date</Td>
                            <Td>From</Td>
                            <Td>To</Td>
                            <Td>Amount</Td>
                            <Td>Notes</Td>
                        </Tr>
                    </THead>
                    <TBody>
                        {payments?.map((payment) => (
                            <Tr key={payment.id}>
                                <Td>{payment.date && new Date(payment.date).toLocaleDateString()}</Td>
                                <Td>{payment.fromCompany?.name}</Td>
                                <Td>{payment.toCompany?.name}</Td>
                                <Td>{payment.amount}</Td>
                                <Td>{payment.notes}</Td>
                            </Tr>
                        ))}
                    </TBody>
                </Table>
            )}
        </ModalDialog>
    );
};
