import { ChangeEvent, ReactNode, useMemo, useState } from "react";
import ButtonNeoGen from "../../layout/button-neogen";
import fileUploadService from "../../services/file.service";
import { ClearERCUser } from "../../typings/api/clear-erc-user";
import { useQuery } from "@tanstack/react-query";
import { TBody, THead, Table, Td, Th, Tr } from "../../layout/table";
import { format } from "date-fns";
import fileService from "../../services/file.service";
import ModalDialog from "../../layout/modal-dialog";
import { Document, Page } from "react-pdf";
import { Notes } from "./notes";

// ✅ Need a section that only the CPA can see and they can do the following.
// ✅ Upload the taxpayer advocate updates
// ✅ upload transcripts
// ability to add notes and client can see updates on notes.
// Basically what is going to happen is that we will have to assign a CPA a client and they will have to get alerted we assigned them a file.
// They will call in the TAxPayer Advocate will have to put notes.
// They will also need to upload transcripts to add to IRS

const Divider = () => <div className="border-b-[1px] border-gray-300 my-4" />;

const SectionHeader = ({ title, description }: { title: string; description?: string }) => {
    return (
        <div>
            <h2 className="text-base">{title}</h2>
            {description && <p className="text-xs text-gray-400">{description}</p>}
        </div>
    );
};

const HeaderWrapper = ({ children }: { children: any }) => (
    <div className="flex justify-between items-center">{children}</div>
);

export const CPA = ({
    companyId,
    user,
    cpaUser,
    taxAdvocateUser,
    notesSection,
}: {
    companyId: number;
    user: ClearERCUser;
    cpaUser: any;
    taxAdvocateUser: any;
    notesSection: ReactNode;
}) => {
    const [selectedTAFile, setSelectedTAFile] = useState<File | null>(null);
    const [selectedTranscriptFile, setSelectedTranscriptFile] = useState<File | null>(null);
    const [fileToShow, setFileToShow] = useState<any | null>(null);
    const [numPages, setNumPages] = useState<number>();
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [fileIdToDelete, setFileIdToDelete] = useState<number | null>(null);
    function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
        setNumPages(numPages);
    }

    const taFilesQuery = useQuery({
        queryFn: async () => {
            const response = await fileUploadService.getFiles({
                where: { companyId, notes: "cpa-tax-advocate-upload" },
            });

            return response;
        },
        queryKey: ["cpa-tax-advocate-upload"],
    });

    const taFiles = taFilesQuery.data;

    const handleTAFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files && event.target.files[0];
        if (file) {
            setSelectedTAFile(file);
            const res = await fileUploadService.uploadFile({
                file,
                userId: user.id ?? "",
                companyId,
                notes: "cpa-tax-advocate-upload",
            });
            taFilesQuery.refetch();
        }
    };

    const transcriptFilesQuery = useQuery({
        queryFn: async () => {
            const response = await fileUploadService.getFiles({
                where: { companyId, notes: "cpa-transcript" },
            });

            return response;
        },
        queryKey: ["cpa-transcript"],
    });

    const transcriptFiles = transcriptFilesQuery.data;

    const handleTranscriptFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files && event.target.files[0];
        if (file) {
            setSelectedTranscriptFile(file);
            const res = await fileUploadService.uploadFile({
                file,
                userId: user.id ?? "",
                companyId,
                notes: "cpa-transcript",
            });
            transcriptFilesQuery.refetch();
        }
    };

    return (
        <>
            {fileIdToDelete && (
                <ModalDialog
                    size="sm"
                    title="Delete file"
                    close={() => setFileIdToDelete(null)}
                    show={true}
                    showOk={false}
                    showCancel={false}
                >
                    <p className="text-center mb-4">Are you sure you want to delete this file?</p>
                    <div className="flex items-center justify-center gap-4">
                        <ButtonNeoGen
                            onClick={async () => {
                                await fileService.deleteByID(fileIdToDelete);
                                await taFilesQuery.refetch();
                                await transcriptFilesQuery.refetch();
                                setFileIdToDelete(null);
                            }}
                            type="danger"
                        >
                            Yes
                        </ButtonNeoGen>
                        <ButtonNeoGen onClick={() => setFileIdToDelete(null)} type="outline">
                            No
                        </ButtonNeoGen>
                    </div>
                </ModalDialog>
            )}
            {fileToShow && (
                <ModalDialog
                    size="lg"
                    title="File preview"
                    close={() => setFileToShow(null)}
                    show={true}
                    showOk={false}
                    showCancel={false}
                >
                    <div>
                        <div className="flex justify-between">
                            <div className="flex align-middle gap-2">
                                <ButtonNeoGen
                                    disabled={pageNumber === 1}
                                    onClick={() => {
                                        const pn = numPages && pageNumber > 1 ? pageNumber - 1 : pageNumber;
                                        setPageNumber(pn);
                                    }}
                                >
                                    Back
                                </ButtonNeoGen>
                                <ButtonNeoGen
                                    disabled={pageNumber === numPages}
                                    onClick={() => {
                                        const pn = numPages && pageNumber < numPages ? pageNumber + 1 : pageNumber;
                                        setPageNumber(pn);
                                    }}
                                >
                                    Next
                                </ButtonNeoGen>
                            </div>
                            <p>
                                Page {pageNumber} of {numPages}
                            </p>
                        </div>
                        <Document
                            file={`${process.env.REACT_APP_API_URL}/files/${fileToShow.filename}`}
                            onLoadSuccess={onDocumentLoadSuccess}
                        >
                            <Page pageNumber={pageNumber} scale={1.5} className="m-auto text-center" />
                        </Document>
                    </div>
                </ModalDialog>
            )}
            <div className="p-3">
                <HeaderWrapper>
                    <h1 className="text-2xl font-medium mb-3">CPA Management</h1>
                </HeaderWrapper>
                <div className="flex gap-2">
                    <div className="text-sm">CPA:</div>
                    <div className="text-sm font-semibold">
                        {cpaUser ? `${cpaUser.firstName} ${cpaUser.lastName}` : "No CPA assigned to this client"}
                    </div>
                </div>
                <div className="flex gap-2">
                    <div className="text-sm">Tax Advocate:</div>
                    <div className="text-sm font-semibold">
                        {taxAdvocateUser
                            ? `${taxAdvocateUser.firstName} ${taxAdvocateUser.lastName}`
                            : "No tax advocate assigned to this client"}
                    </div>
                </div>

                <Divider />
                <HeaderWrapper>
                    <SectionHeader
                        title="Taxpayer Advocate Updates"
                        description="Upload the taxpayer advocate updates"
                    />
                    <label className="bg-indigo-800 hover:bg-indigo-700 text-white dark:text-gray-200 dark:border-indigo-800 dark:bg-indigo-700 inline-flex items-center px-4 py-2 border text-sm font-medium rounded-md shadow-sm cursor-pointer">
                        Upload File
                        <input type="file" className="hidden" onChange={handleTAFileChange} />
                    </label>
                </HeaderWrapper>
                <div>
                    {taFiles && taFiles?.total > 0 && (
                        <Table className="border-gray-100 border-[1px] my-2">
                            <THead>
                                <Tr>
                                    <Th>File Name</Th>
                                    <Th>Date Uploaded</Th>
                                    <Th>Actions</Th>
                                </Tr>
                            </THead>
                            <TBody>
                                {taFiles.pageItems.map((file) => {
                                    return (
                                        <Tr key={file.id}>
                                            <Td>{file.originalFilename}</Td>
                                            <Td>{format(new Date(file.dateUploaded), "dd MMM yyyy")}</Td>
                                            <Td className="flex gap-4 p-4">
                                                <ButtonNeoGen type="outline" onClick={() => setFileToShow(file)}>
                                                    View
                                                </ButtonNeoGen>
                                                <ButtonNeoGen
                                                    type="outline"
                                                    onClick={() =>
                                                        fileService.downloadFile(file.filename, file.originalFilename)
                                                    }
                                                >
                                                    Download
                                                </ButtonNeoGen>
                                                <ButtonNeoGen
                                                    type="outline-danger"
                                                    onClick={() => setFileIdToDelete(file.id)}
                                                >
                                                    Delete
                                                </ButtonNeoGen>
                                            </Td>
                                        </Tr>
                                    );
                                })}
                            </TBody>
                        </Table>
                    )}
                </div>

                <Divider />
                <HeaderWrapper>
                    <SectionHeader title="Transcripts" description="Upload transcripts" />
                    <label className="bg-indigo-800 hover:bg-indigo-700 text-white dark:text-gray-200 dark:border-indigo-800 dark:bg-indigo-700 inline-flex items-center px-4 py-2 border text-sm font-medium rounded-md shadow-sm cursor-pointer">
                        Upload File
                        <input type="file" className="hidden" onChange={handleTranscriptFileChange} />
                    </label>
                </HeaderWrapper>
                <div>
                    {transcriptFiles && transcriptFiles?.total > 0 && (
                        <Table className="border-gray-100 border-[1px] my-2">
                            <THead>
                                <Tr>
                                    <Th>File Name</Th>
                                    <Th>Date Uploaded</Th>
                                    <Th>Actions</Th>
                                </Tr>
                            </THead>
                            <TBody>
                                {transcriptFiles.pageItems.map((file) => {
                                    return (
                                        <Tr key={file.id}>
                                            <Td>{file.originalFilename}</Td>
                                            <Td>{format(new Date(file.dateUploaded), "dd MMM yyyy")}</Td>
                                            <Td className="flex gap-4 p-4">
                                                <ButtonNeoGen type="outline" onClick={() => setFileToShow(file)}>
                                                    View
                                                </ButtonNeoGen>
                                                <ButtonNeoGen
                                                    type="outline"
                                                    onClick={() =>
                                                        fileService.downloadFile(file.filename, file.originalFilename)
                                                    }
                                                >
                                                    Download
                                                </ButtonNeoGen>
                                                <ButtonNeoGen
                                                    type="outline-danger"
                                                    onClick={() => setFileIdToDelete(file.id)}
                                                >
                                                    Delete
                                                </ButtonNeoGen>
                                            </Td>
                                        </Tr>
                                    );
                                })}
                            </TBody>
                        </Table>
                    )}
                </div>

                <Divider />
                <HeaderWrapper>
                    <SectionHeader title="Notes" />
                </HeaderWrapper>
                {notesSection}
            </div>
        </>
    );
};
