import APIService from "./api.service";

class InvoiceTemplateService extends APIService<InvoiceTemplate> {
    constructor() {
        super("/invoice-templates");
    }
}

export type InvoiceTemplate = {
    id?: number;
    name: string;
    description: string;
    companyId: number;
};
export default new InvoiceTemplateService();
