import APIService from "./api.service";
import { UserCompany } from "../user-companies/domain/user-company";

class UserCompanyService extends APIService<UserCompany> {
    constructor() {
        super("user-companies");
    }
}

export default new UserCompanyService();
