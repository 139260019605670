import { useCallback, useEffect, useMemo, useState } from "react";
import PageDescription from "../../layout/page-description";
import Loader2 from "../utilities/Loader2";
import { useUsers } from "../../jason-proof-of-concept/users/hooks/use-users";
import { useAuth } from "../../auth/use-auth";
import { TBody, THead, Table, Td, Tr } from "../../layout/table";
import OptionsDropDown from "../../layout/options-dropdown";
import { useReferrers } from "./hooks/use-referrers";
import { CreateReferrerModal } from "./components/create-referrer-modal";
import { AddAffiliateToReferrerModal } from "./components/add-affiliate-to-referrer-modal";
import { EditReferrerModal } from "./components/edit-referrer-modal";
import { User } from "../../jason-proof-of-concept/users/domain/user";
import { EditCommissionModal } from "./components/edit-commission-modal";
import { search as ssSearch } from "ss-search";
import SearchField from "../../layout/search-field";
import { debounce } from "lodash";

export default function Referrers() {
    const auth = useAuth();
    const authToken = auth.expectAuthToken();
    const [showCreateReferrerModal, setShowCreateReferrerModal] = useState(false);
    const [showReferrerToEditModal, setShowReferrerToEditModal] = useState<User | undefined>(undefined);
    const [showAddAffiliateToReferrerModal, setShowAddAffiliateToReferrerModal] = useState(false);
    const [showAddAffiliateToReferrerWithAffiliates, setShowAddAffiliateToReferrerWithAffiliates] = useState<
        User | undefined
    >(undefined);
    const [showEditCommissionModal, setShowEditCommissionModal] = useState<any>(undefined);
    const referrerRelationsQuery = useReferrers({ authToken });
    const referrerRelations = useMemo(() => referrerRelationsQuery.data || [], [referrerRelationsQuery.data]);
    const usersQuery = useUsers({ authToken });

    const users = useMemo(() => usersQuery.data || [], [usersQuery.data]);

    const referrerUsers = useMemo(
        () => (users ? users.filter((u) => u.roleGroups?.some((rg) => rg.id === 46)) : []),
        [usersQuery.data],
    );

    const rows = useMemo(() => {
        return referrerUsers.map((user) => {
            return {
                referrer: user,
                affiliates: [
                    ...referrerRelations
                        .filter((r) => r.referrerId === user.id)
                        .map((r) => ({
                            id: r.id,
                            affiliate: r.affiliateUser,
                            commissionPercentage: r.commissionPercentage,
                            createdAt: r.createdAt,
                        })),
                ],
            };
        });
    }, [usersQuery.data, referrerRelationsQuery.data]);

    const isLoading = referrerRelationsQuery.isLoading || usersQuery.isLoading;

    return (
        <>
            {showCreateReferrerModal && (
                <CreateReferrerModal
                    onClose={() => setShowCreateReferrerModal(false)}
                    onReferrerCreated={() => {
                        referrerRelationsQuery.refetch();
                        usersQuery.refetch();
                        setShowCreateReferrerModal(false);
                    }}
                />
            )}
            {showAddAffiliateToReferrerModal && (
                <AddAffiliateToReferrerModal
                    onClose={() => setShowAddAffiliateToReferrerModal(false)}
                    users={users}
                    onCreated={() => {
                        referrerRelationsQuery.refetch();
                        usersQuery.refetch();
                        setShowAddAffiliateToReferrerModal(false);
                    }}
                />
            )}
            {showEditCommissionModal && (
                <EditCommissionModal
                    onClose={() => setShowEditCommissionModal(undefined)}
                    item={showEditCommissionModal}
                    onUpdated={() => {
                        referrerRelationsQuery.refetch();
                        usersQuery.refetch();
                        setShowEditCommissionModal(undefined);
                    }}
                />
            )}
            {showReferrerToEditModal && (
                <EditReferrerModal
                    referrer={showReferrerToEditModal}
                    onClose={() => setShowReferrerToEditModal(undefined)}
                    onReferrerUpdated={() => {
                        referrerRelationsQuery.refetch();
                        usersQuery.refetch();
                        setShowReferrerToEditModal(undefined);
                    }}
                />
            )}
            {showAddAffiliateToReferrerWithAffiliates && (
                <AddAffiliateToReferrerModal
                    onClose={() => setShowAddAffiliateToReferrerWithAffiliates(undefined)}
                    referrer={showAddAffiliateToReferrerWithAffiliates}
                    users={users}
                    onCreated={() => {
                        referrerRelationsQuery.refetch();
                        usersQuery.refetch();
                        setShowAddAffiliateToReferrerWithAffiliates(undefined);
                    }}
                />
            )}
            {isLoading ? (
                <Loader2 />
            ) : (
                <>
                    <PageDescription
                        title="Referrers"
                        description="Manage referrers"
                        buttons={[
                            {
                                label: "New Referrer",
                                icon: "fas fa-plus",
                                onClick: () => {
                                    setShowCreateReferrerModal(true);
                                },
                            },
                            {
                                label: "Add Affiliate to Referrer",
                                icon: "fas fa-plus",
                                onClick: () => {
                                    setShowAddAffiliateToReferrerModal(true);
                                },
                            },
                        ]}
                    ></PageDescription>
                    <Table>
                        <THead>
                            <Tr>
                                <Td style={{ width: "20%", verticalAlign: "top" }}>Referrer</Td>
                                <Td style={{ width: "60%" }}>Affiliates (Name, Created At, Commission %)</Td>
                                <Td style={{ textAlign: "right", width: "20%" }}></Td>
                            </Tr>
                        </THead>
                        <TBody>
                            {rows.map((referrer) => {
                                return (
                                    <Tr key={referrer.referrer.id}>
                                        <Td>{referrer.referrer.firstName + " " + referrer.referrer.lastName}</Td>
                                        <Td>
                                            {referrer.affiliates.length > 0 ? (
                                                <Table style={{ width: "100%" }}>
                                                    <TBody>
                                                        {referrer.affiliates.map((affiliate) => {
                                                            return (
                                                                <Tr key={affiliate.affiliate?.id}>
                                                                    <Td>
                                                                        {affiliate.affiliate?.firstName}{" "}
                                                                        {affiliate.affiliate?.lastName}
                                                                    </Td>
                                                                    <Td>
                                                                        {" "}
                                                                        <div className="flex flex-col">
                                                                            {affiliate.createdAt?.toDateString()}
                                                                        </div>
                                                                    </Td>
                                                                    <Td>{affiliate.commissionPercentage}%</Td>
                                                                    <Td style={{ width: "10%" }}>
                                                                        <button
                                                                            onClick={() =>
                                                                                setShowEditCommissionModal({
                                                                                    affiliate: affiliate.affiliate,
                                                                                    referrer: referrer.referrer,
                                                                                    commissionPercentage:
                                                                                        affiliate.commissionPercentage,
                                                                                    id: affiliate.id,
                                                                                })
                                                                            }
                                                                            className="fal fa-pencil bg-indigo-700 dark:bg-gray-800  p-2 rounded-full text-gray-100 dark:text-gray-400"
                                                                        />
                                                                    </Td>
                                                                </Tr>
                                                            );
                                                        })}
                                                    </TBody>
                                                </Table>
                                            ) : (
                                                "-"
                                            )}
                                        </Td>
                                        <Td style={{ paddingTop: 0, paddingBottom: 0 }}>
                                            <div className="flex flex-1 justify-end">
                                                <OptionsDropDown
                                                    options={[
                                                        {
                                                            label: "Edit Referrer",
                                                            action: () => setShowReferrerToEditModal(referrer.referrer),
                                                        },
                                                        {
                                                            label: "Add Affiliate",
                                                            action: () =>
                                                                setShowAddAffiliateToReferrerWithAffiliates(
                                                                    referrer.referrer,
                                                                ),
                                                        },
                                                    ]}
                                                />
                                            </div>
                                        </Td>
                                    </Tr>
                                );
                            })}
                        </TBody>
                    </Table>
                </>
            )}
        </>
    );
}
