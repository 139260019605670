import { useState, useMemo } from "react";
import { useAuth } from "../../auth/use-auth";
import { useUsers } from "../../jason-proof-of-concept/users/hooks/use-users";
import OptionsDropDown from "../../layout/options-dropdown";
import PageDescription from "../../layout/page-description";
import { useReferrers } from "../referrers/hooks/use-referrers";
import Loader2 from "../utilities/Loader2";
import { TBody, THead, Table, Td, Tr } from "../../layout/table";
import Toggle from "../../layout/toggle";
import { Section, Stat } from "../../affiliate-billing/affiliate-deals-page";
import { Currency } from "../../billing/components";
import { useRecoilState } from "recoil";
import userAtom from "../../atoms/userAtom";
import { BankingDetailsModal } from "./components/banking-details-modal";
import { Payment } from "../../payments/domain/payment";
import { PaymentsHistoryModal } from "./components/payments-history-modal";
import { TranscriptModal } from "./components/transcript-modal";

type BankDetails = {
    name: string;
    bankDetails: any;
};

type PaymentHistory = {
    name: string;
    payments?: Payment[];
};

type TranscriptUser = {
    paymasterName: string;
    companyName: string;
};

export default function PaymasterPayouts() {
    const auth = useAuth();
    const authToken = auth.expectAuthToken();
    const usersQuery = useUsers({ authToken });
    const [isPaymasterDashboard, setIsPaymasterDashboard] = useState(0);
    const [currentUser] = useRecoilState(userAtom);
    const users = useMemo(() => usersQuery.data || [], [usersQuery.data]);
    const [showBankDetailsForCompany, setShowBankDetailsForCompany] = useState<BankDetails | undefined>();
    const [showBankDetailsForUser, setShowBankDetailsForUser] = useState<BankDetails | undefined>();
    const [showPaymentsHistoryForUser, setShowPaymentsHistoryForUser] = useState<PaymentHistory | undefined>();
    const [showTranscriptFor, setShowTranscriptFor] = useState<TranscriptUser | undefined>();

    const isLoading = false;

    const paymasterRows = useMemo(() => {
        return [
            {
                paymasterName: "Jay B",
                companyName: "Paymaster Co",
                companyId: 1,
                dueToName: "ClearERC",
                dueToRole: "Self",
                status: "Outstanding",
                totalDue: 10000,
                totalPaid: 2000,
                bankingDetails: "TODO",
                paymentDetails: { amount: 10, date: new Date() },
            },
            {
                paymasterName: "Marc P",
                companyName: "Paymaster Co",
                companyId: 1,
                dueToName: "ClearERC",
                dueToRole: "Self",
                status: "Outstanding",
                totalDue: 10000,
                totalPaid: 2000,
                bankingDetails: "TODO",
                paymentDetails: { amount: 10, date: new Date() },
            },
            {
                paymasterName: "Marc P",
                companyName: "Paymaster Co",
                companyId: 1,
                dueToName: "Marc P",
                status: "Outstanding",
                dueToRole: "Paymaster",
                totalDue: 1000,
                totalPaid: 0,
                bankingDetails: "TODO",
                paymentDetails: { amount: 10, date: new Date() },
            },
        ];
    }, []);

    const commissionRows = useMemo(() => {
        return [
            {
                userName: "Jason Russell",
                role: "Referrer",
                companyName: "ClearERC",
                companyId: 1,
                commissionPercentage: 2,
                status: "Outstanding",
                totalDue: 1000,
                totalPaid: 200,
                bankingDetails: "TODO",
                paymentDetails: { amount: 10, date: new Date() },
            },
            {
                userName: "Nicol Russell",
                role: "Affiliate",
                companyName: "ClearERC",
                companyId: 1,
                commissionPercentage: 2,
                status: "Outstanding",
                totalDue: 1000,
                totalPaid: 200,
                bankingDetails: "TODO",
                paymentDetails: { amount: 0, date: new Date() },
            },
        ];
    }, []);

    return (
        <>
            {isLoading ? (
                <Loader2 />
            ) : (
                <>
                    {showPaymentsHistoryForUser && (
                        <PaymentsHistoryModal
                            onClose={() => setShowPaymentsHistoryForUser(undefined)}
                            name={showPaymentsHistoryForUser?.name}
                            payments={showPaymentsHistoryForUser?.payments}
                        />
                    )}
                    {showBankDetailsForUser && (
                        <BankingDetailsModal
                            currentUser={currentUser}
                            onClose={() => setShowBankDetailsForUser(undefined)}
                            name={showBankDetailsForUser?.name}
                            bankName={showBankDetailsForUser?.bankDetails?.bankName}
                            accountNumber={showBankDetailsForUser?.bankDetails?.accountNumber}
                            routingNumber={showBankDetailsForUser?.bankDetails?.routingNumber}
                            clientAddress={showBankDetailsForUser?.bankDetails?.clientAddress}
                        />
                    )}
                    {showTranscriptFor && (
                        <TranscriptModal
                            onClose={() => setShowTranscriptFor(undefined)}
                            paymasterName={showTranscriptFor?.paymasterName}
                            companyName={showTranscriptFor?.companyName}
                        />
                    )}
                    {showBankDetailsForCompany && (
                        <BankingDetailsModal
                            currentUser={currentUser}
                            onClose={() => setShowBankDetailsForCompany(undefined)}
                            name={showBankDetailsForCompany?.name}
                            bankName={showBankDetailsForCompany?.bankDetails?.bankName}
                            accountNumber={showBankDetailsForCompany?.bankDetails?.accountNumber}
                            routingNumber={showBankDetailsForCompany?.bankDetails?.routingNumber}
                            clientAddress={showBankDetailsForCompany?.bankDetails?.clientAddress}
                        />
                    )}
                    <PageDescription
                        title="Paymaster Payouts"
                        description="View payouts by paymasters and commissions due to internal roles"
                    ></PageDescription>
                    <div className="w-full flex gap-8 mb-5 items-center">
                        <div className="flex-1">
                            <Section
                                style={{
                                    display: "flex",
                                    justifyContent: "space-evenly",
                                    alignItems: "center",
                                }}
                                className="w-full"
                            >
                                {isPaymasterDashboard ? (
                                    <>
                                        <Stat title="Amount In Lockbox" value={<Currency amount={0} />} />
                                        <Stat title="Amount Sent To Paymasters" value={<Currency amount={0} />} />
                                        <Stat title="Amount Received By Paymasters" value={<Currency amount={0} />} />
                                        <Stat title="Total Paid Out By Paymasters" value={<Currency amount={0} />} />
                                    </>
                                ) : (
                                    <>
                                        <Stat
                                            title="Total Payouts Received By ClearERC"
                                            value={<Currency amount={0} />}
                                        />
                                        <Stat
                                            title="Total Outstanding Commissions Due To Members"
                                            value={<Currency amount={0} />}
                                        />
                                    </>
                                )}
                            </Section>
                        </div>
                        <Toggle
                            options={["Commissions Due", "Paymaster Dashboard"]}
                            setSelectedIndex={setIsPaymasterDashboard}
                            selectedIndex={isPaymasterDashboard}
                        />
                    </div>
                    {isPaymasterDashboard ? (
                        <Table>
                            <THead>
                                <Tr>
                                    <Td>Paymaster Name</Td>
                                    <Td>Company</Td>
                                    <Td>Payout Due To</Td>
                                    <Td>Role</Td>
                                    <Td>Status</Td>
                                    <Td>Total Due</Td>
                                    <Td>Total Paid</Td>
                                    <Td>Banking Details</Td>
                                    <Td></Td>
                                </Tr>
                            </THead>
                            <TBody>
                                {paymasterRows.map((row) => (
                                    <Tr key={`${row.companyId}-${row.paymasterName}-${row.dueToName}`}>
                                        <Td>{row.paymasterName}</Td>
                                        <Td>{row.companyName}</Td>
                                        <Td>{row.dueToName}</Td>
                                        <Td>{row.dueToRole}</Td>
                                        <Td>
                                            <div
                                                style={{ width: "fit-content" }}
                                                className="text-orange-500 border-orange-500 border-[1px] bg-orange-100 px-2 width-fit-content rounded-full text-center text-xs"
                                            >
                                                {row.status}
                                            </div>
                                        </Td>
                                        <Td>
                                            <Currency amount={row.totalDue} />
                                        </Td>
                                        <Td>
                                            <div
                                                className="underline text-blue-500 cursor-pointer"
                                                onClick={() =>
                                                    setShowPaymentsHistoryForUser({
                                                        name: row.dueToName,
                                                        payments: undefined,
                                                    })
                                                }
                                            >
                                                <Currency amount={row.totalPaid} />
                                            </div>
                                        </Td>
                                        <Td>
                                            <div
                                                className="underline text-blue-500 cursor-pointer"
                                                onClick={() =>
                                                    setShowBankDetailsForCompany({
                                                        name: row.dueToName,
                                                        bankDetails: row.bankingDetails,
                                                    })
                                                }
                                            >
                                                Banking Details
                                            </div>
                                        </Td>
                                        <Td>
                                            <div>
                                                <OptionsDropDown
                                                    options={[
                                                        {
                                                            label: "View Transcript",
                                                            action: () =>
                                                                setShowTranscriptFor({
                                                                    paymasterName: row.paymasterName,
                                                                    companyName: row.companyName,
                                                                }),
                                                        },
                                                    ]}
                                                />
                                            </div>
                                        </Td>
                                    </Tr>
                                ))}
                            </TBody>
                        </Table>
                    ) : (
                        <Table>
                            <THead>
                                <Tr>
                                    <Td>User Name</Td>
                                    <Td>Role</Td>
                                    <Td>Company</Td>
                                    <Td>Commission %</Td>
                                    <Td>Status</Td>
                                    <Td>Total Due</Td>
                                    <Td>Total Paid</Td>
                                    <Td>Banking Details</Td>
                                    <Td></Td>
                                </Tr>
                            </THead>
                            <TBody>
                                {commissionRows.map((row) => (
                                    <Tr key={`${row.companyId}-${row.userName}`}>
                                        <Td>{row.userName}</Td>
                                        <Td>{row.role}</Td>
                                        <Td>{row.companyName}</Td>
                                        <Td>{row.commissionPercentage}%</Td>
                                        <Td>
                                            <div
                                                style={{ width: "fit-content" }}
                                                className="text-orange-500 border-orange-500 border-[1px] bg-orange-100 px-2 width-fit-content rounded-full text-center text-xs"
                                            >
                                                {row.status}
                                            </div>
                                        </Td>
                                        <Td>
                                            <Currency amount={row.totalDue} />
                                        </Td>
                                        <Td>
                                            <div
                                                className="underline text-blue-500 cursor-pointer"
                                                onClick={() =>
                                                    setShowPaymentsHistoryForUser({
                                                        name: row.userName,
                                                        payments: undefined,
                                                    })
                                                }
                                            >
                                                <Currency amount={row.totalPaid} />
                                            </div>
                                        </Td>
                                        <Td>
                                            <div
                                                className="underline text-blue-500 cursor-pointer"
                                                onClick={() =>
                                                    setShowBankDetailsForUser({
                                                        name: row.userName,
                                                        bankDetails: row.bankingDetails,
                                                    })
                                                }
                                            >
                                                Banking Details
                                            </div>
                                        </Td>
                                        {/* <Td>
                                            {row.paymentDetails.amount > 0 ? (
                                                <div>
                                                    <Currency amount={row.paymentDetails.amount} />
                                                    <br />
                                                    <div className="text-xxs text-gray-400">
                                                        Paid on {row.paymentDetails.date.toDateString()}
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="text-orange-500 border-orange-500 border-[1px] bg-orange-100 px-2 width-fit-content rounded-full text-center text-xs">
                                                    Outstanding
                                                </div>
                                            )}
                                        </Td> */}
                                        <Td>
                                            <div>
                                                <OptionsDropDown
                                                    options={[
                                                        {
                                                            label: "Record Payment",
                                                            action: () => console.log("TODO"),
                                                        },
                                                    ]}
                                                />
                                            </div>
                                        </Td>
                                    </Tr>
                                ))}
                            </TBody>
                        </Table>
                    )}
                </>
            )}
        </>
    );
}
