import { z } from "zod";

export const mandateEffectSchema = z.object({
    id: z.number(),
    name: z.string(),
    alternativeTerms: z.string().nullish(),
    description: z.string().nullish(),
    notes: z.string().nullish(),
});

export type MandateEffect = z.infer<typeof mandateEffectSchema>;
