import { useState, useEffect, Fragment } from "react";
import * as React from "react";
import PrintPre from "./print-pre";
import { Select } from "./form/select-input";

export default function SelectNeoGenMultiple(props: SelectNeoGenMultipleProps) {
    const [selected, setSelected] = useState<any[]>(props.value ?? [0]);
    useEffect(() => {
        if (typeof props.value !== "undefined") {
            setSelected([props.value[0]]);
        } else {
            setSelected([{ id: 0, name: "Select" }]);
        }
    }, [props.value]);
    if (!Array.isArray(props.value)) {
        return <PrintPre>{props.value}</PrintPre>;
    }
    return (
        <div className={props.className}>
            <Select
                label={props.label}
                value={props.value ?? null}
                isMultiple={true}
                options={(props.options ?? []).map((i) => ({ label: i.name ?? "", value: i.id ?? -1 })) ?? []}
                onChange={function (value?: any): void {
                    setSelected(value);
                    props.onChange(value);
                }}
            />
            {props.description && (
                <p id="comments-description" className="text-gray-400 text-sm mt-1">
                    {props.description}
                </p>
            )}
        </div>
    );
}

export type Entry = {
    id?: number | string | null;
    name?: string | null;
};

type SelectNeoGenMultipleProps = {
    options?: (unknown & Entry)[];
    label?: string;
    placeholder?: string;
    value?: any[];
    onChange: (idx: number[] | string[], text?: string[]) => void;
    className?: string;
    description?: string;
    ref?: React.Ref<any>;
    required?: boolean;
};
