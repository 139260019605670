import APIService from "./api.service";

export type Response = {
    options: any[];
};

class RAndDService extends APIService<Response> {
    constructor() {
        super("/get-r-and-d");
    }
}

export default new RAndDService();
