import React, { useEffect } from "react";
import ModalDialog from "../../../layout/modal-dialog";
import PrintPre from "../../../layout/print-pre";
import CheckBoxNeoGenControlled from "../../../layout/checkbox-controlled";
import { Entry } from "../../../layout/select-neogen";
function capitalize(str: string) {
    return str?.toLowerCase()?.replace(/(?<= )[^\s]|^./g, (a: string) => a.toUpperCase());
}
const fieldNames = [
    "Source or service 1 company 1",
    "Source or service 2 company 1",
    "Source or service 3 company 1",
    "Source or service 4 company 1",
    "Source or service 5 company 1",
    "Source or service 6 company 1",
    "Source or service 7 company 1",
    "Source or service 8 company 1",
    "Source or service 9 company 1",
    "Source or service 10 company 1",
];
function ApplyToSources({
    sources,
    show,
    close,
    data,
    allEntries,
    onChangeWithEntry,
}: {
    sources: any;
    show: any;
    close: any;
    data: any;
    allEntries: any;
    onChangeWithEntry: (entry: Entry, value: any) => void;
}) {
    const [selectedSources, setSelectedSources] = React.useState<any>([]);
    const [inputData, setInputData] = React.useState<any>({});
    const [bulletPointForSourcePicker, setBulletPointForSourcePicker] = React.useState<any>([]);
    function getSources() {
        const sourcesArray = [];
        for (let i = 0; i < sources["count"]; i++) {
            sourcesArray.push(sources["Source " + (i + 1)]);
        }
        return sourcesArray;
    }
    useEffect(() => {
        setBulletPointForSourcePicker(
            allEntries
                .filter((e: any) => e.type === "bulletPointForSourcePicker")
                .sort((a: any, b: any) => a.id - b.id),
        );
        for (const entry of allEntries) {
            if (entry.type === "bulletPointPicker") {
                setInputData(data[entry.id]);
            }
        }
    }, [allEntries, data]);
    async function saveData() {
        // onChangeWithEntry
        // Go through each of the selected sources and if they are ticked, add the bullet point and group to the source
        for (let i = 0; i < sources["count"]; i++) {
            const enabled = selectedSources.includes(sources["Source " + (i + 1)]);
            const entry = allEntries.find((entry: Entry) => entry.id === bulletPointForSourcePicker[i]?.id);
            if (enabled) {
                await onChangeWithEntry(entry, JSON.stringify(inputData));
                close();
            }
        }
    }

    return (
        <ModalDialog close={() => close()} show={show} title={"Apply To Sources"} okAction={() => saveData()}>
            {/* <PrintPre>{bulletPointForSourcePicker}</PrintPre> */}
            {/* <PrintPre>{allEntries.filter((e: any) => e.type === "bulletPointForSourcePicker")}</PrintPre> */}
            {getSources().map((source: any, index: number) => (
                <div key={index} className="flex items-center gap-3">
                    <CheckBoxNeoGenControlled
                        value={selectedSources.includes(source)}
                        setValue={(e: React.ChangeEvent<HTMLInputElement>) => {
                            if (e.target.checked) {
                                setSelectedSources([...selectedSources, source]);
                            } else {
                                setSelectedSources(selectedSources.filter((item: any) => item !== source));
                            }
                        }}
                        name={source}
                        label={capitalize(source)}
                    />
                </div>
            ))}
        </ModalDialog>
    );
}

export default ApplyToSources;
