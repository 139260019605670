export type PaymentType = object;

import APIService from "./api.service";

class PaymentService extends APIService<PaymentType> {
    constructor() {
        super("make-payment");
    }
    makePayment(paymentAmount: number) {
        return this.postURL("make-payment", { paymentAmount });
    }
}

export default new PaymentService();
