import { makeRequest, parseSchema } from "../../jason-proof-of-concept/shared/utils";
import { MandateMandateEffect, mandateMandateEffectSchema } from "../domain/mandate-mandate-effect";

export const updateMandateMandateEffect = async ({
    authToken,
    id,
    data,
}: {
    authToken: string;
    id: number;
    data: Partial<MandateMandateEffect>;
}) => {
    const response = await makeRequest({
        method: "patch",
        path: `/mandate-mandate-effects/${id}`,
        authToken,
        data,
    });

    const mandateMandateEffect = parseSchema(mandateMandateEffectSchema, response.data);

    return mandateMandateEffect;
};
