import React from "react";
import ModalDialog from "../../../../layout/modal-dialog";
import InputControlled from "../../../../layout/input-controlled";
import TextAreaNeoGenControlled from "../../../../layout/text-area-controlled";
import { set } from "lodash";
import ErrorSection from "../../../../layout/error-section";
import UserCompanyPicker from "../../../../layout/user-company-picker";
import { useQuery } from "@tanstack/react-query";
import companyService from "../../../../services/company.service";
import ButtonNeoGen from "../../../../layout/button-neogen";
import { Company } from "../../../../companies/domain/company";
import PrintPre from "../../../../layout/print-pre";

function AddEditInvoiceTemplate({ show, close, entry }: { show: boolean; close: () => void; entry?: any }) {
    const [name, setName] = React.useState("");
    const [company, setCompany] = React.useState<Company | null>(null);
    const [showCompanyPicker, setShowCompanyPicker] = React.useState(false);
    const [errors, setErrors] = React.useState<string[]>([]);
    const [description, setDescription] = React.useState("");
    const [companyId, setCompanyId] = React.useState(0);
    const companyQuery = useQuery(["company"], async () => {
        const response = await companyService.getAll();
        if (response) {
            return response.data;
        }
    });
    async function saveEntry() {
        const probs = [];
        if (name === "") {
            probs.push("Name is required");
        }
        if (probs.length > 0) {
            setErrors(probs);
            return;
        } else {
            setErrors([]);
        }

        console.log("Save entry");
        if (entry) {
            console.log("Update entry");
        } else {
            console.log("Create entry");
            const data = {
                name,
                description,
                companyId,
            };
            console.log(data);
            close();
        }
        //
    }
    if (companyQuery.isLoading) {
        return <div>Loading...</div>;
    }
    return (
        <ModalDialog close={() => close()} show={show} title={""} okAction={() => saveEntry()}>
            <>
                {errors.length > 0 && <ErrorSection errors={errors} />}
                {company && company.name}
                <ButtonNeoGen
                    label={"Select Company"}
                    onClick={() => {
                        setShowCompanyPicker(true);
                    }}
                >
                    Select Company
                </ButtonNeoGen>

                <UserCompanyPicker
                    showUsers={false}
                    showCompanies={true}
                    companyList={companyQuery.data}
                    open={showCompanyPicker}
                    setOpen={() => setShowCompanyPicker(true)}
                    setCompany={(c) => {
                        setCompany(c);
                        setShowCompanyPicker(false);
                    }}
                />
                <InputControlled label="Name" value={name} onChange={(v) => setName(v)} />
                <TextAreaNeoGenControlled
                    label={"Description"}
                    value={description}
                    setValue={(v) => setDescription(v)}
                />
            </>
        </ModalDialog>
    );
}

export default AddEditInvoiceTemplate;
