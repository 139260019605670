import { z } from "zod";
import { makeRequest, parseSchema } from "../../jason-proof-of-concept/shared/utils";
import { invoiceSchema } from "../domain/invoice";

export const getInvoices = async ({ authToken, filters }: { authToken: string; filters?: any }) => {
    const response = await makeRequest({
        method: "get",
        path: "/invoices",
        authToken,
        queryParams: { filter: JSON.stringify(filters) },
    });

    const invoices = parseSchema(z.array(invoiceSchema), response.data);

    return invoices;
};
