import { RoleGroup } from "../typings/api/role-group";
import APIService from "./api.service";

// ID values copied from database.
export const roleGroups = {
    SuperUser: 4,
    PublicAccess: 5,
    ERCClient: 6,
    Affiliate: 7,
    Accountant: 8,
    AccountManager: 9,
    Debug: 10,
    Closer: 11,
    AffiliateInTraining: 12,
    InternalCpa: 13,
    DocCollector: 14,
    TaxAttorney: 15,
    InternalDocCollector: 16,
    SalesManager: 17,
    Paymaster: 18,
    Owner: 19,
    Bookkeeper: 20,
    Agent: 21,
    CFO: 22,
    HedgeFund: 23,
    SalesRep: 24,
    SubstantiationTeam: 25,
    DocCollectionManager: 26,
    SubstantiationManager: 27,
    AffiliateManager: 28,
    DirectorOfSales: 29,
    ERCRepairHedgeFund: 30,
    ERCRepairClient: 31,
    ERCRepairAccountant: 32,
    TaxAdvocate: 38,
    ERCProvider: 39,
    TaxPayerAdvocateClient: 40,
    TaxpayerAdvocateAffiliate: 41,
    TaxpayerAdvocateAgent: 42,
    RAndDClient: 43,
    RAndDClient2: 47,
    SubstantiationClient: 44,
    TaxAdvocateAccountant: 45,
    Referrer: 46,
    WOTC: 48,
    EnergyClient: 49,
    Wellness: 50,
    CostSegregation: 51,
    TranscriptClient: 52,
};

// Keep in sync with API.
export const roleAssignments = {
    [roleGroups.SuperUser]: [...Object.values(roleGroups)],
    [roleGroups.PublicAccess]: [],
    [roleGroups.ERCClient]: [],
    [roleGroups.Affiliate]: [
        roleGroups.ERCClient,
        roleGroups.Affiliate,
        roleGroups.PublicAccess,
        roleGroups.AffiliateInTraining,
        roleGroups.TaxPayerAdvocateClient,
    ],
    [roleGroups.Accountant]: [],
    [roleGroups.AccountManager]: [],
    [roleGroups.AffiliateInTraining]: [roleGroups.Affiliate],
    [roleGroups.Owner]: [roleGroups.Bookkeeper, roleGroups.DocCollector, roleGroups.TaxAttorney],
    [roleGroups.Agent]: [],
    [roleGroups.CFO]: [],
    [roleGroups.HedgeFund]: [],
    [roleGroups.SalesRep]: [],
    [roleGroups.SubstantiationTeam]: [],
    [roleGroups.DocCollectionManager]: [],
    [roleGroups.SubstantiationManager]: [],
    [roleGroups.AffiliateManager]: [],
    [roleGroups.DirectorOfSales]: [],
    [roleGroups.ERCRepairHedgeFund]: [],
    [roleGroups.ERCRepairClient]: [],
    [roleGroups.ERCRepairAccountant]: [],
    [roleGroups.TaxAdvocate]: [],
    [roleGroups.ERCProvider]: [],
    [roleGroups.TaxPayerAdvocateClient]: [],
    [roleGroups.TaxpayerAdvocateAffiliate]: [],
    [roleGroups.Referrer]: [roleGroups.Affiliate, roleGroups.AffiliateInTraining],
    [roleGroups.TaxpayerAdvocateAgent]: [],
    [roleGroups.RAndDClient]: [],
    [roleGroups.RAndDClient2]: [],
    [roleGroups.SubstantiationClient]: [],
    [roleGroups.TaxAdvocateAccountant]: [],
    [roleGroups.Referrer]: [],
    [roleGroups.WOTC]: [],
    [roleGroups.EnergyClient]: [],
    [roleGroups.Wellness]: [],
    [roleGroups.CostSegregation]: [],
    [roleGroups.TranscriptClient]: [],
};

class RoleGroupService extends APIService<RoleGroup> {
    constructor() {
        super("role-groups");
    }

    async getAllRoleGroups() {
        const filter = {
            include: ["roles"],
        };
        const response = await this.getFiltered(filter);
        if (response && response.data) {
            response.data = response.data.map((entry) => {
                if (!entry.roles) {
                    entry.roles = [];
                }
                return entry;
            });
        }
        return response;
    }
}

export default new RoleGroupService();
