import { useMutation } from "@tanstack/react-query";
import ModalDialog from "../../layout/modal-dialog";
import { useAuth } from "../../auth/use-auth";
import { Mandate } from "../domain/mandate";
import { updateMandate } from "../actions/update-mandate";
import { z } from "zod";
import { Form } from "../../layout/form/form";
import { useForm } from "../../hooks/useForm";
import { Table, TBody, Td, Tr } from "../../layout/table";
import { TextAreaField } from "../../layout/form/text-area-field";
import { RadioField } from "../../layout/form/radio-field";
import { DateTimeField } from "../../layout/form/date-field";
import { DateDisplay2 } from "../../layout/date-display";

const schema = z.object({
    aiExtendedUntilDate: z.coerce.date().nullish(),
    isAiExtendedUntilDateCorrect: z.coerce.boolean().nullish(),
    humanExtendedUntilDate: z.coerce.date().nullish(),
    extendedUntilDateNotes: z.string().nullish(),
});

type Data = z.infer<typeof schema>;

export const EditMandateExtendedUntilDateModal = ({
    onClose,
    mandate,
    onUpdate,
    userId,
}: {
    onClose: () => void;
    mandate: Mandate;
    onUpdate: (updatedMandate: Mandate) => void;
    userId?: string;
}) => {
    const auth = useAuth();
    const authToken = auth.expectAuthToken();

    const form = useForm({
        schema,
        defaultValues: { ...mandate },
    });

    const triggerAiMutation = useMutation({
        mutationFn: async (data: Partial<Mandate>) => {
            const updatedMandate = await updateMandate({ authToken, id: mandate.id, data });
            onUpdate(updatedMandate);
            onClose();
        },
    });

    const handleSubmit = async (data: Partial<Mandate>) => {
        const isSubmittingAiFeedback = data.isAiExtendedUntilDateCorrect !== mandate.isAiExtendedUntilDateCorrect;

        const d: Partial<Mandate> =
            isSubmittingAiFeedback === true
                ? {
                      isAiExtendedUntilDateCorrect: data.isAiExtendedUntilDateCorrect,
                      aiExtendedUntilDateAtFeedbackTime: data.aiExtendedUntilDate,
                      aiExtendedUntilDateFeedbackSubmittedAt: new Date(),
                      extendedUntilDate:
                          data.isAiExtendedUntilDateCorrect === true
                              ? data.aiExtendedUntilDate
                              : data.humanExtendedUntilDate,
                  }
                : {
                      extendedUntilDate:
                          data.isAiExtendedUntilDateCorrect !== true ? data.humanExtendedUntilDate : undefined,
                  };

        const updatedData: Partial<Mandate> = {
            ...d,
            humanExtendedUntilDate: data.humanExtendedUntilDate,
            extendedUntilDateNotes: data.extendedUntilDateNotes,
            feedbackOnExtendedUntilDateUserId: userId,
        };

        await triggerAiMutation.mutateAsync(updatedData);
    };

    const values = form.watch();

    const useEditableField = values.isAiExtendedUntilDateCorrect !== true || !mandate.aiExtendedUntilDate;

    return (
        <ModalDialog
            title="Edit Mandate Extended Until Date"
            show
            close={onClose}
            okAction={form.handleSubmit(handleSubmit)}
            okText="Update Extended Until Date"
            isLoading={triggerAiMutation.isLoading}
            size="md"
        >
            <Form onSubmit={form.handleSubmit(handleSubmit)}>
                <Table>
                    <TBody>
                        <Tr>
                            <Td style={{ color: "gray", width: 220 }}>Confirmed Date</Td>
                            <Td style={{ fontWeight: 800, paddingTop: 0, paddingBottom: 0 }}>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                    <div className="flex-1">
                                        {useEditableField ? (
                                            <DateTimeField
                                                isRequired={values.isAiExtendedUntilDateCorrect === false}
                                                {...form.getFieldProps("humanExtendedUntilDate")}
                                            />
                                        ) : (
                                            <>
                                                <DateDisplay2
                                                    date={
                                                        values.isAiExtendedUntilDateCorrect === true
                                                            ? mandate.aiExtendedUntilDateAtFeedbackTime ||
                                                              mandate.aiExtendedUntilDate
                                                            : mandate.extendedUntilDate
                                                    }
                                                />
                                            </>
                                        )}
                                    </div>
                                </div>
                            </Td>
                        </Tr>
                        <Tr>
                            <Td style={{ color: "gray", width: 185 }}>AI Suggested Date</Td>
                            <Td style={{ fontWeight: 500, paddingTop: 0, paddingBottom: 0 }}>
                                <div style={{ display: "flex", flexDirection: "row", gap: 6, alignItems: "center" }}>
                                    <div>
                                        <DateDisplay2
                                            date={
                                                mandate.isAiExtendedUntilDateCorrect === true
                                                    ? mandate.aiExtendedUntilDateAtFeedbackTime
                                                    : mandate.aiExtendedUntilDate
                                            }
                                        />
                                    </div>
                                </div>
                            </Td>
                        </Tr>
                        <Tr>
                            <Td style={{ color: "gray", width: 180 }}>Is the AI suggestion correct?</Td>
                            <Td style={{ fontWeight: 500, paddingTop: 0, paddingBottom: 0 }}>
                                <div style={{ display: "flex", flexDirection: "row", gap: 6, alignItems: "center" }}>
                                    <RadioField
                                        disabled={!mandate.aiExtendedUntilDate}
                                        {...form.getFieldProps("isAiExtendedUntilDateCorrect")}
                                        options={[
                                            { label: "Yes", value: true },
                                            { label: "No", value: false },
                                        ]}
                                    />
                                </div>
                            </Td>
                        </Tr>
                    </TBody>
                </Table>
                <TextAreaField label="Notes" {...form.getFieldProps("extendedUntilDateNotes")} />
            </Form>
        </ModalDialog>
    );
};
