import React, { useState } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import PrintPre from "../../layout/print-pre";
import { useQuery } from "@tanstack/react-query";
import helpDocsService from "../../services/help-docs.service";
import helpDocsTemplateItemsService from "../../services/help-docs-template-items.service";
import ButtonNeoGen from "../../layout/button-neogen";
import { useNavigate } from "react-router";
import { NeoGenHelpDocs } from "../../typings/api";
import DeleteFaq from "./modals/delete-faq";
import packageSectionRulesService from "../../services/package-section-rules.service";

export function SortableItem(props: { item: any }) {
    const navigate = useNavigate();

    const [showDeleteFaq, setShowDeleteFaq] = useState(false);
    const [existing, setExisting] = useState<null | NeoGenHelpDocs>(null);
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: props.item.id });
    const [selectedTemplate, setSelectedTemplate] = useState<number | null>(
        Number(window.localStorage.getItem("selectedTemplate")) ?? null,
    );
    const helpDocsTemplateItemsQuery = useQuery(["help-docs-template-items", selectedTemplate], async () => {
        const response = await helpDocsTemplateItemsService.getFilteredWhere({
            templateId: selectedTemplate,
        });
        return (
            response?.data.sort((a, b) => {
                if ((a?.order ?? 0) > (b?.order ?? 0)) return 1;
                if ((a?.order ?? 0) < (b?.order ?? 0)) return -1;

                return 0;
            }) ?? []
        );
    });
    const helpDocsQuery = useQuery(
        ["help-docs", "sorted"],
        async () => {
            const response = await helpDocsService.getAll();
            if (response) {
                return response.data.sort((a, b) => {
                    const templateItemA = helpDocsTemplateItemsQuery.data?.find((e) => e.helpDocId === a.id);
                    const templateItemB = helpDocsTemplateItemsQuery.data?.find((e) => e.helpDocId === b.id);
                    if ((templateItemA?.order ?? 0) > (templateItemB?.order ?? 0)) return 1;
                    if ((templateItemA?.order ?? 0) < (templateItemB?.order ?? 0)) return -1;

                    return 0;
                });
            }
        },
        { enabled: helpDocsTemplateItemsQuery.isSuccess },
    );
    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    const helpDoc = helpDocsQuery.data?.find((hd) => hd.id === props.item.helpDocId);

    const rulesQuery = useQuery(["package-section-rules"], async () => {
        const response = await packageSectionRulesService.getAll();
        return response?.data ?? [];
    });

    if (!helpDoc) {
        return null;
    }

    return (
        <div
            style={style}
            ref={setNodeRef}
            className="rounded-lg bg-white my-3 p-2 pt-4 pl-4 border-gray-300 shadow-lg dark:bg-gray-800"
        >
            <div className="grid grid-cols-3">
                <div className="col-span-1">
                    <i {...listeners} {...attributes} className="fas fa-grip-vertical mr-5 text-gray-300"></i>
                    <span className="text-sm">{helpDoc?.title}</span>
                </div>
                <div className="col-span-1 flex flex-row flex-wrap">
                    {rulesQuery.data
                        ?.filter((r) => r.helpDoc === helpDoc?.id)
                        .map((r) => (
                            <div
                                className="inline-block border mr-3 border-gray-500 p-2 rounded mb-2 bg-gray-100 dark:bg-gray-900 shadow text-xs"
                                key={r.id}
                            >
                                <span className="text-blue-700">{r.fieldName}</span>: <b>{r.fieldValue}</b>{" "}
                                {r.isEnabler ? "Enabler" : "Disabler"}
                                {/* <br /> */}
                                {/* <PrintPre key={r.id}>{r}</PrintPre> */}
                            </div>
                        ))}
                    {helpDoc?.active ? (
                        <span className="border border-green-500 p-2 rounded mb-2 bg-green-100 dark:bg-green-900 shadow text-xs">
                            Active
                        </span>
                    ) : (
                        <span className="border border-red-500 p-2 rounded mb-2 bg-red-100 dark:bg-red-900 shadow text-xs">
                            Inactive
                        </span>
                    )}
                </div>
                <div className="col-span-1">
                    <ButtonNeoGen
                        onClick={() => {
                            const entry = helpDocsQuery.data?.find((h) => h.id === props.item.helpDocId);
                            if (entry) {
                                setExisting(entry);

                                setShowDeleteFaq(true);
                            }
                        }}
                        className="float-right mb-1"
                        size="xs"
                        type="danger"
                    >
                        <i className="fal fa-trash mr-1"></i>
                        Delete
                    </ButtonNeoGen>
                    <ButtonNeoGen
                        onClick={() => {
                            navigate("/faq/edit/" + props.item.helpDocId);
                        }}
                        className="float-right mb-1"
                        size="xs"
                    >
                        <i className="fal fa-pencil mr-1"></i>
                        Edit
                    </ButtonNeoGen>
                    <ButtonNeoGen
                        onClick={() => {
                            navigate("/package-section-rules/" + props.item.helpDocId);
                        }}
                        className="float-right"
                        size="xs"
                    >
                        <i className="fal fa-cog mr-1"></i>
                        Package Section Rules ({rulesQuery.data?.filter((r) => r.helpDoc === helpDoc?.id).length})
                    </ButtonNeoGen>
                </div>
            </div>
            <DeleteFaq
                show={showDeleteFaq}
                hide={() => setShowDeleteFaq(false)}
                existingEntry={existing}
                helpDocTemplateId={props.item.id}
            />
        </div>
    );
}
