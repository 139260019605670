import { z } from "zod";
import ModalDialog from "../../../layout/modal-dialog";
import { Form } from "../../../layout/form/form";
import { useForm } from "../../../hooks/useForm";
import { getAuthTokenNoThrow } from "../../../services/auth-header";
import ButtonNeoGen from "../../../layout/button-neogen";
import { User } from "../../../jason-proof-of-concept/users/domain/user";
import { SelectField } from "../../../layout/form/select-field";
import { useUsers } from "../../../jason-proof-of-concept/users/hooks/use-users";
import { orderBy } from "lodash";
import { useMemo } from "react";
import { useMutation } from "@tanstack/react-query";
import { updateUserCompany } from "../../../user-companies/actions/update-user-company";
import { getUserCompanies } from "../../../user-companies/actions/get-user-companies";

const initialFormSchema = z.object({
    userId: z.string().uuid(),
});

type InitialFormData = z.infer<typeof initialFormSchema>;

export const EditCompanyRoleUserModal = ({
    onClose,
    companyId,
    onUserUpdated,
    user,
    roleName,
    roleId,
}: {
    onClose: () => any;
    companyId: number;
    onUserUpdated: (user: User) => any;
    user: User;
    roleName: string;
    roleId: number;
}) => {
    if (!user.id) {
        throw new Error("User not defined.");
    }
    const initialForm = useForm({ schema: initialFormSchema });
    const authToken = getAuthTokenNoThrow() || "no-token";

    const initialMutation = useMutation({
        mutationFn: async (data: InitialFormData) => {
            const [userCompany] = await getUserCompanies({
                authToken,
                filters: { where: { companyId, ercUserId: user.id, roleId } },
            });
            await updateUserCompany({
                authToken,
                id: userCompany.id ?? -1,
                data: { roleGroupId: roleId, ercUserId: data.userId },
            });
            return { user };
        },
    });

    const handleSubmit = async (data: InitialFormData) => {
        const { user } = await initialMutation.mutateAsync(data);
        if (user) {
            onUserUpdated(user as any);
        }
    };

    const usersQuery = useUsers({ authToken });
    const users = usersQuery.data || [];

    const filteredUsers = users.filter((user) => {
        return (user.roleGroups || []).find((rg) => rg.name === roleName);
    });

    const userOptions = useMemo(
        () =>
            orderBy(
                filteredUsers.map((user) => ({
                    label: `${user.firstName} ${user.lastName} (${user.email})` || "",
                    value: user.id,
                })),
                (o) => o.label,
            ),
        [filteredUsers],
    );

    return (
        <ModalDialog title={`Edit ${roleName}`} close={onClose} show showOk={false} showCancel={false}>
            <p className="px-4 text-gray-400 text-center">Update {roleName} for this company.</p>
            <div className="pt-6">
                <Form onSubmit={initialForm.handleSubmit(handleSubmit as any)} error={initialMutation.error as any}>
                    <SelectField label="Select user" {...initialForm.getFieldProps("userId")} options={userOptions} />

                    <div className="flex flex-row justify-end">
                        <div className="flex flex-row gap-2">
                            <ButtonNeoGen type="outline" onClick={onClose}>
                                Cancel
                            </ButtonNeoGen>
                            <ButtonNeoGen type="submit" disabled={initialMutation.isLoading}>
                                Update {roleName}
                            </ButtonNeoGen>
                        </div>
                    </div>
                </Form>
            </div>
        </ModalDialog>
    );
};
