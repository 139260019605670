import APIService from "./api.service";

class InvoiceTemplateLineItemService extends APIService<InvoiceTemplateLineItem> {
    constructor() {
        super("/invoice-template-line-items");
    }
}

export type InvoiceTemplateLineItem = {
    id?: number;
    invoiceTemplateId: number;
    lineItemId: number;
};
export default new InvoiceTemplateLineItemService();
