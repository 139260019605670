import { useMutation } from "@tanstack/react-query";
import ModalDialog from "../../layout/modal-dialog";
import { useAuth } from "../../auth/use-auth";
import { Mandate } from "../domain/mandate";
import { updateMandate } from "../actions/update-mandate";
import { z } from "zod";
import { Form } from "../../layout/form/form";
import { TextField } from "../../layout/form/text-field";
import { useForm } from "../../hooks/useForm";
import { Table, TBody, Td, Tr } from "../../layout/table";
import { TextAreaField } from "../../layout/form/text-area-field";
import { RadioField } from "../../layout/form/radio-field";
import { SelectField } from "../../layout/form/select-field";
import { cities } from "../../cities";

const schema = z.object({
    aiCities: z.array(z.string()).nullish(),
    isAiCitiesCorrect: z.coerce.boolean().nullish(),
    humanCities: z.array(z.string()).nullish(),
    citiesNotes: z.string().nullish(),
});

type Data = z.infer<typeof schema>;

export const EditMandateCitiesModal = ({
    onClose,
    mandate,
    onUpdate,
    userId,
}: {
    onClose: () => void;
    mandate: Mandate;
    onUpdate: (updatedMandate: Mandate) => void;
    userId?: string;
}) => {
    const auth = useAuth();
    const authToken = auth.expectAuthToken();

    const form = useForm({
        schema,
        defaultValues: { ...mandate },
    });

    const triggerAiMutation = useMutation({
        mutationFn: async (data: Partial<Mandate>) => {
            const updatedMandate = await updateMandate({ authToken, id: mandate.id, data });
            onUpdate(updatedMandate);
            onClose();
        },
    });

    const handleSubmit = async (data: Partial<Mandate>) => {
        const isSubmittingAiFeedback = data.isAiCitiesCorrect !== mandate.isAiCitiesCorrect;

        const humanCities = (data.humanCities || []).map((c) => c.replace(/__\d+$/, ""));

        const d: Partial<Mandate> =
            isSubmittingAiFeedback === true
                ? {
                      isAiCitiesCorrect: data.isAiCitiesCorrect,
                      aiCitiesAtFeedbackTime: data.aiCities || [],
                      aiCitiesFeedbackSubmittedAt: new Date(),
                      cities: data.isAiCitiesCorrect === true ? data.aiCities || [] : humanCities,
                  }
                : {
                      cities: data.isAiCitiesCorrect !== true ? humanCities || [] : undefined,
                  };

        const updatedData: Partial<Mandate> = {
            ...d,
            humanCities: humanCities,
            citiesNotes: data.citiesNotes,
            feedbackOnCitiesUserId: userId,
        };

        await triggerAiMutation.mutateAsync(updatedData);
    };

    const values = form.watch();

    const useEditableField = values.isAiCitiesCorrect !== true || !mandate.aiCities;

    return (
        <ModalDialog
            title="Edit Mandate Cities"
            show
            size="md"
            close={onClose}
            okAction={form.handleSubmit(handleSubmit)}
            okText="Update Cities"
            isLoading={triggerAiMutation.isLoading}
        >
            <Form onSubmit={form.handleSubmit(handleSubmit)}>
                <Table>
                    <TBody>
                        <Tr>
                            <Td style={{ color: "gray", width: 220 }}>Confirmed Cities</Td>
                            <Td style={{ fontWeight: 800, paddingTop: 0, paddingBottom: 0 }}>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        width: "100%",
                                    }}
                                >
                                    <div className="w-full">
                                        {useEditableField ? (
                                            <SelectField
                                                isMultiple
                                                options={cities.map((c) => ({
                                                    value: `${c.city}__${c.id}`,
                                                    label: `${c.city} (${c.county}, ${c.state})`,
                                                }))}
                                                isRequired={values.isAiCitiesCorrect === false}
                                                {...form.getFieldProps("humanCities")}
                                            />
                                        ) : (
                                            <>
                                                {values.isAiCitiesCorrect === true
                                                    ? mandate.aiCitiesAtFeedbackTime || mandate.aiCities
                                                    : mandate.cities || "-"}
                                            </>
                                        )}
                                    </div>
                                </div>
                            </Td>
                        </Tr>
                        <Tr>
                            <Td style={{ color: "gray", width: 185 }}>AI Suggested Cities</Td>
                            <Td style={{ fontWeight: 500, paddingTop: 0, paddingBottom: 0 }}>
                                <div style={{ display: "flex", flexDirection: "row", gap: 6, alignItems: "center" }}>
                                    <div>
                                        {mandate.isAiCitiesCorrect === true
                                            ? mandate.aiCitiesAtFeedbackTime
                                            : mandate.aiCities || "-"}
                                    </div>
                                </div>
                            </Td>
                        </Tr>
                        <Tr>
                            <Td style={{ color: "gray", width: 180 }}>Is the AI suggestion correct?</Td>
                            <Td style={{ fontWeight: 500, paddingTop: 0, paddingBottom: 0 }}>
                                <div style={{ display: "flex", flexDirection: "row", gap: 6, alignItems: "center" }}>
                                    <RadioField
                                        disabled={!mandate.aiCities}
                                        {...form.getFieldProps("isAiCitiesCorrect")}
                                        options={[
                                            { label: "Yes", value: true },
                                            { label: "No", value: false },
                                        ]}
                                    />
                                </div>
                            </Td>
                        </Tr>
                        <Tr>
                            <Td style={{ color: "gray", width: 185 }}>Google Sheet Cities</Td>
                            <Td style={{ fontWeight: 500, paddingTop: 0, paddingBottom: 0 }}>
                                <div style={{ display: "flex", flexDirection: "row", gap: 6, alignItems: "center" }}>
                                    <div>{mandate.googleSheetCities?.join(", ") || "-"}</div>
                                </div>
                            </Td>
                        </Tr>
                    </TBody>
                </Table>
                <TextAreaField label="Notes" {...form.getFieldProps("citiesNotes")} />
            </Form>
        </ModalDialog>
    );
};
