import React, { useState } from "react";
import PageDescription from "../../../layout/page-description";
import UserCompanyPicker from "../../../layout/user-company-picker";
import { useQuery } from "@tanstack/react-query";
import companyService from "../../../services/company.service";
import { set } from "lodash";
import StageStatusPie from "./components/stage-status-pie";
import usersService from "../../../services/users.service";

function Providers() {
    const [showCompanyPicker, setShowCompanyPicker] = useState(false);
    const [companies, setCompanies] = useState<boolean>(false);
    const companiesQuery = useQuery(["companies"], async () => {
        const response = await companyService.getAll();
        if (response) {
            return response.data;
        }
    });
    const usersQuery = useQuery(["users"], async () => {
        const response = await usersService.getAll();
        if (response) {
            return response.data;
        }
    });
    if (companiesQuery.isLoading || usersQuery.isLoading) {
        return <div>Loading...</div>;
    }
    return (
        <div>
            <PageDescription
                title="Providers"
                description="Providers are used to assign roles to users."
                buttons={[
                    {
                        label: "Add Tax Payer Advocate Customer",
                        onClick: () => {
                            console.log("Add Tax Payer Advocate Customer");
                        },
                        icon: "fas fa-plus",
                    },
                    {
                        label: "Add Agent",
                        type: "secondary",
                        onClick: () => {
                            console.log("Add Customer");
                        },
                        icon: "fas fa-user-plus",
                    },
                    // {
                    //     label: "Upload Customers",
                    //     type: "secondary",
                    //     onClick: () => {
                    //         console.log("Upload Customer");
                    //     },
                    //     icon: "fas fa-upload",
                    // },

                    {
                        label: "Search For Customer",
                        type: "info",
                        onClick: () => {
                            setCompanies(true);
                            setShowCompanyPicker(true);
                        },
                        icon: "fas fa-magnifying-glass",
                    },
                    {
                        label: "Search For Agent",
                        type: "info",
                        onClick: () => {
                            setCompanies(false);
                            setShowCompanyPicker(true);
                        },
                        icon: "fas fa-magnifying-glass",
                    },
                ]}
            />
            {/* <div>Stuff</div> */}
            <UserCompanyPicker
                showUsers={!companies}
                showCompanies={companies}
                open={showCompanyPicker}
                setOpen={setShowCompanyPicker}
                companyList={companiesQuery.data ?? []}
                userList={usersQuery.data ?? []}
                setCompany={(company) => {
                    if (companies) {
                        console.log("Selected company: ", company);
                        setShowCompanyPicker(false);
                        window.location.href = "/companies/" + company.id;
                    }
                }}
                setUser={(user) => {
                    if (!companies) {
                        console.log("Selected user: ", user);
                        setShowCompanyPicker(false);
                        window.location.href = "/agent/" + user.id;
                    }
                }}
            />
            <div className="columns-2 gap-5">
                <div className="bg-gray-50 dark:bg-gray-900 border-2 dark:border-gray-800 border-white p-5 rounded-lg shadow">
                    <StageStatusPie />
                </div>
                <div className="bg-gray-50 dark:bg-gray-900 border-2 dark:border-gray-800 border-white p-5 rounded-lg shadow">
                    <StageStatusPie />
                </div>
            </div>
        </div>
    );
}

export default Providers;
