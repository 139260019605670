import React, { useEffect } from "react";
import { useRecoilState } from "recoil";
import userAtom from "../../../atoms/userAtom";
import PrintPre from "../../../layout/print-pre";
import { useQuery } from "@tanstack/react-query";
import appointmentsService, { Appointment } from "../../../services/appointments.service";
import ButtonNeoGen from "../../../layout/button-neogen";

type AppointmentStatuses = {
    fullStatus?: string[];
    lastStatus?: string;
};

function MyAppointments() {
    const [email, setEmail] = React.useState("");
    const [user] = useRecoilState(userAtom);
    useEffect(() => {
        setEmail(user.email);
    }, [user]);
    const convertEventNames = (eventTypeId: string) => {
        switch (eventTypeId) {
            case "BOOKING_CREATED":
                return "Booking Created";
            case "BOOKING_CANCELLED":
                return "Booking Cancelled";
            case "BOOKING_CONFIRMED":
                return "Booking Confirmed";
            case "BOOKING_RESCHEDULED":
                return "Booking Rescheduled";
            case "BOOKING_REMINDER":
                return "Booking Reminder";
            default:
                return eventTypeId;
        }
    };
    // This function combines multiple status updates for each appointment into a single status update
    const createFilteredEventsList = (events: (Appointment & AppointmentStatuses)[]) => {
        const newEvents: (Appointment & AppointmentStatuses)[] = [];
        for (const event of events) {
            const existingEvent = newEvents.find((e) => e.id === event.id);
            if (existingEvent) {
                if (!existingEvent.fullStatus) {
                    existingEvent.fullStatus = [];
                }
                existingEvent.fullStatus?.push(event.triggerEvent);
                event.lastStatus = convertEventNames(event.triggerEvent);
            } else {
                event.fullStatus = [event.triggerEvent];
                event.lastStatus = convertEventNames(event.triggerEvent);
                newEvents.push(event);
            }
        }
        return events;
    };
    const myAppointments = useQuery(
        ["appointments", email],
        async () => {
            const response = await appointmentsService.getFilteredWhere({
                or: [{ email: email }, { destinationCalendarEmail: email }],
            });
            if (response) {
                return response.data;
            }
            return [];
        },
        { enabled: !!email },
    );
    if (!myAppointments.isSuccess) {
        return <div>Loading...</div>;
    }
    return (
        <div>
            {/* <p className="mt-0 text-left text-xl font-light tracking-wider text-gray-600 sm:text-2xl dark:text-green-400 ">
                Appointments
            </p> */}
            {myAppointments.data.length === 0 ? (
                <div className=" text-left text-gray-600 dark:text-green-400 ">You currently have no appointments.</div>
            ) : (
                <div className="grid grid-cols-4 gap-5">
                    {createFilteredEventsList(myAppointments.data)?.map((appointment) => (
                        <React.Fragment key={appointment.id}>
                            <div className="overflow-hidden bg-white dark:bg-gray-800 rounded-xl shadow">
                                <div className="px-5 py-3">
                                    <p className="mb-5 text-left  text-gray-600 sm:text-2xl dark:text-green-400 ">
                                        {appointment.payload.eventTitle}
                                    </p>
                                    <p className="text-left text-sm font-light tracking-wider text-gray-600 dark:text-green-400 ">
                                        Status: {appointment.lastStatus}
                                    </p>
                                    {appointment.lastStatus !== "Booking Cancelled" && (
                                        <>
                                            <p className="text-left text-sm font-light tracking-wider text-gray-600 dark:text-green-400 ">
                                                Start Time: {new Date(appointment.startsAt).toLocaleString()}
                                            </p>
                                            <p className="text-left text-sm font-light tracking-wider text-gray-600 dark:text-green-400 ">
                                                End Time: {new Date(appointment.endsAt).toLocaleString()}
                                            </p>
                                            {appointment.payload.metadata && (
                                                <div className="mt-5">
                                                    <ButtonNeoGen
                                                        icon="fal fa-video"
                                                        onClick={() => {
                                                            window.open(
                                                                appointment.payload.metadata?.videoCallUrl,
                                                                "_blank", // <- This is what makes it open in a new window.
                                                            );
                                                        }}
                                                    >
                                                        Google Meet Link
                                                    </ButtonNeoGen>
                                                </div>
                                            )}
                                        </>
                                    )}
                                    <div className="mt-2">
                                        <ButtonNeoGen
                                            type="info"
                                            icon="fal fa-calendar"
                                            onClick={() => {
                                                window.open(
                                                    "https://cal.com/booking/" + appointment.payload.uid,
                                                    "_blank", // <- This is what makes it open in a new window.
                                                );
                                            }}
                                        >
                                            Manage Appointment
                                        </ButtonNeoGen>
                                    </div>
                                </div>
                            </div>
                            {/* <PrintPre key={appointment.id}>{appointment}</PrintPre> */}
                        </React.Fragment>
                    ))}
                </div>
            )}
        </div>
    );
}

export default MyAppointments;
