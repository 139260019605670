/* eslint-disable prettier/prettier */
import { ResponsiveBar, ResponsiveBarCanvas } from "@nivo/bar";


export default function DateChart(props: DateChartProps) {
    return (
        <>
            <div style={{ width: "100%", height: props.height ? props.height + "px" : "400px" }}>
                <ResponsiveBar
                    data={props.data ?? []}
                    keys={props.keys}
                    indexBy={props.indexBy}
                    // keys={dests}
                    // indexBy="date"
                    // margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                    // padding={0.3}
                    // valueScale={{ type: "linear" }}
                    // indexScale={{ type: "band", round: true }}
                    // colors={{ scheme: props.darkMode?"purpleRed_green":"pastel2" }}
                    margin={{ top: 30, right: 120, bottom: 52, left: 90 }}
                    padding={0.3}
                    valueScale={{ type: "linear" }}
                    indexScale={{ type: "band", round: true }}
                    groupMode="grouped"
                    colors={
                        props.darkMode
                            ? [
                                "#4038C2",
                                "#38bdf8",
                                "#4624a3",
                                "#053f0b",
                                "#007f21",
                                "#0c5476",
                                "#5310a5",
                                "#955fff",
                                "#0f68d6",
                                "#d6830f",
                            ]
                            : ["#4038C2", "#38bdf8", "#d4ff65", "#fffc62", "#ffb349", "#ffc1e6", "#e894f7", "#bb8dff"]
                    }
                    borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}

                    // axisTop={null}
                    // axisRight={null}
                    borderRadius={2}
                    // borderColor={{ from: "color", modifiers: [ [ "darker", 1.6 ] ] }}

                    // axisTop={null}

                    // axisRight={null}
                    // xFormat="time:%Y-%m-%d"
                    axisBottom={{
                        tickSize: 5,
                        // format: (v:any) => vals.find((vts: string) => {
                        //     // console.log(vts+" === "+v);
                        //     return vts === v;}) ? "" : v,
                        tickPadding: 5,
                        tickRotation: 90,
                        legend: "",
                        legendPosition: "middle",
                        legendOffset: 52,

                        // type: "time",
                    }}
                    axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: props.legend,
                        legendPosition: "middle",
                        legendOffset: -60,
                    }}
                    theme={{
                        textColor: props.darkMode ? "#aaa" : "#000",
                        axis: {
                            ticks: {
                                line: {
                                    stroke: "green",
                                },
                                text: {
                                    fill: props.darkMode ? "#777" : "#444",
                                },
                            },
                        },
                        grid: {
                            line: {
                                stroke: props.darkMode ? "#223" : "#eee",
                                strokeWidth: 2,
                                strokeDasharray: "3 3",
                            },
                        },
                        background: "transparent",
                    }}
                    labelSkipWidth={2}
                    labelSkipHeight={22}
                    legends={[
                        {
                            dataFrom: "keys",
                            anchor: "bottom-right",
                            direction: "column",
                            justify: false,
                            translateX: 120,
                            translateY: 0,
                            itemsSpacing: 2,
                            itemWidth: 100,
                            itemHeight: 20,
                            itemDirection: "left-to-right",
                            itemOpacity: 0.85,
                            symbolSize: 20,

                            effects: [
                                {
                                    on: "hover",
                                    style: {
                                        itemOpacity: 1,
                                    },
                                },
                            ],
                        },
                    ]}
                    enableLabel={false}
                    labelTextColor={{ from: "color", modifiers: [["brighter", 1.6]] }}
                    // legends={[
                    //     {
                    //         dataFrom: "keys",
                    //         anchor: "bottom-right",
                    //         direction: "column",
                    //         justify: false,
                    //         translateX: 120,
                    //         translateY: 0,
                    //         itemsSpacing: 2,
                    //         itemWidth: 100,
                    //         itemHeight: 20,
                    //         itemDirection: "left-to-right",
                    //         itemOpacity: 0.85,
                    //         symbolSize: 20,
                    //         effects: [
                    //             {
                    //                 on: "hover",
                    //                 style: {
                    //                     itemOpacity: 1
                    //                 }
                    //             }
                    //         ]
                    //     }
                    // ]}
                    isInteractive={true}
                // animate={true}
                // motionStiffness={90}
                // motionDamping={15}
                />
                {/* <PrintPre>
                        {secondsQuery.data}
                    </PrintPre> */}
            </div>
        </>
    );
}

type DateChartProps = {
    darkMode: boolean;
    data: any[];
    keys: string[];
    indexBy: string;
    legend: string;
    height?: number;
};
