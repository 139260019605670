import APIService from "./api.service";

export type CompanyProductStagesType = {
    id?: number;
    companyId?: number;
    stageId?: number;
    lastUpdated: Date;
    updatedByUser?: string;
};

class CompanyProductStages extends APIService<CompanyProductStagesType> {
    constructor() {
        super("/company-product-stages");
    }
}

export default new CompanyProductStages();
