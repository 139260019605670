import APIService from "./api.service";

export type BulletPointGroup = {
    id?: number;
    name: string;
};

class BulletPointGroupService extends APIService<BulletPointGroup> {
    constructor() {
        super("/bulletpoint-groups");
    }
}

export default new BulletPointGroupService();
