import { z } from "zod";

export const userCompanySchema = z.object({
    id: z.number().optional(),
    ercUserId: z.string().uuid(),
    companyId: z.number(),
    roleGroupId: z.number(),
    commissionPercentage: z.number().nullish(),
    totalFeeAmount: z.number().nullish(),
    totalFeePercent: z.number().nullish(),
    totalFeeType: z.enum(["amount", "percentage"]).nullish(),
    upfrontFeeAmount: z.number().nullish(),
    upfrontFeePercent: z.number().nullish(),
    upfrontFeeType: z.enum(["amount", "percentage"]).nullish(),
    upfrontFeeDate: z.coerce.date().nullish(),
    depositFeeAmount: z.number().nullish(),
    depositFeePercent: z.number().nullish(),
    depositFeeType: z.enum(["amount", "percentage"]).nullish(),
    depositFeeDate: z.coerce.date().nullish(),
    laterFeeAmount: z.number().nullish(),
    laterFeePercent: z.number().nullish(),
    laterFeeType: z.enum(["amount", "percentage"]).nullish(),
    laterFeeDate: z.coerce.date().nullish(),
});

export type UserCompany = z.infer<typeof userCompanySchema>;
