export type Calls = {
    id?: number;
    calldate: Date;
    from: string;
    sid: string;
    status: "completed" | "no-answer" | "busy" | "failed";
    to: string;
    duration: number;
};

import APIService from "./api.service";

class CallsService extends APIService<Calls> {
    constructor() {
        super("cdrs");
    }
}

export default new CallsService();
