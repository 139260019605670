import APIService from "./api.service";

export type MandateEffectGroup = {
    id?: number;
    name?: string;
    description?: string;
};

class MandateEffectGroupService extends APIService<MandateEffectGroup> {
    constructor() {
        super("/mandate-effect-groups");
    }
}

export default new MandateEffectGroupService();
