import { useMutation } from "@tanstack/react-query";
import ModalDialog from "../../layout/modal-dialog";
import { useAuth } from "../../auth/use-auth";
import { Mandate } from "../domain/mandate";
import { updateMandate } from "../actions/update-mandate";
import { z } from "zod";
import { Form } from "../../layout/form/form";
import { TextField } from "../../layout/form/text-field";
import { useForm } from "../../hooks/useForm";
import { Table, TBody, Td, Tr } from "../../layout/table";
import { TextAreaField } from "../../layout/form/text-area-field";
import { RadioField } from "../../layout/form/radio-field";
import { SelectField } from "../../layout/form/select-field";
import { states } from "../../states";

const schema = z.object({
    aiState: z.string().nullish(),
    isAiStateCorrect: z.coerce.boolean().nullish(),
    humanState: z.string().nullish(),
    stateNotes: z.string().nullish(),
});

type Data = z.infer<typeof schema>;

export const EditMandateStateModal = ({
    onClose,
    mandate,
    onUpdate,
    userId,
}: {
    onClose: () => void;
    mandate: Mandate;
    onUpdate: (updatedMandate: Mandate) => void;
    userId?: string;
}) => {
    const auth = useAuth();
    const authToken = auth.expectAuthToken();

    const form = useForm({
        schema,
        defaultValues: { ...mandate },
    });

    const triggerAiMutation = useMutation({
        mutationFn: async (data: Partial<Mandate>) => {
            const updatedMandate = await updateMandate({ authToken, id: mandate.id, data });
            onUpdate(updatedMandate);
            onClose();
        },
    });

    const handleSubmit = async (data: Partial<Mandate>) => {
        const isSubmittingAiFeedback = data.isAiStateCorrect !== mandate.isAiStateCorrect;

        const d: Partial<Mandate> =
            isSubmittingAiFeedback === true
                ? {
                      isAiStateCorrect: data.isAiStateCorrect,
                      aiStateAtFeedbackTime: data.aiState,
                      aiStateFeedbackSubmittedAt: new Date(),
                      state: data.isAiStateCorrect === true ? data.aiState : data.humanState,
                  }
                : { state: data.isAiStateCorrect !== true ? data.humanState : undefined };

        const updatedData: Partial<Mandate> = {
            ...d,
            humanState: data.humanState,
            stateNotes: data.stateNotes,
            feedbackOnStateUserId: userId,
        };

        await triggerAiMutation.mutateAsync(updatedData);
    };

    const values = form.watch();

    const useEditableField = values.isAiStateCorrect !== true || !mandate.aiState;

    return (
        <ModalDialog
            title="Edit Mandate State"
            show
            close={onClose}
            okAction={form.handleSubmit(handleSubmit)}
            okText="Update State"
            isLoading={triggerAiMutation.isLoading}
            size="md"
        >
            <Form onSubmit={form.handleSubmit(handleSubmit)}>
                <Table>
                    <TBody>
                        <Tr>
                            <Td style={{ color: "gray", width: 220 }}>Confirmed State</Td>
                            <Td style={{ fontWeight: 800, paddingTop: 0, paddingBottom: 0 }}>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        width: "100%",
                                    }}
                                >
                                    <div className="w-full">
                                        {useEditableField ? (
                                            <SelectField
                                                options={states.map((s) => ({
                                                    value: s.name,
                                                    label: s.name,
                                                }))}
                                                isRequired={values.isAiStateCorrect === false}
                                                {...form.getFieldProps("humanState")}
                                            />
                                        ) : (
                                            <>
                                                {values.isAiStateCorrect === true
                                                    ? mandate.aiStateAtFeedbackTime || mandate.aiState
                                                    : mandate.state || "-"}
                                            </>
                                        )}
                                    </div>
                                </div>
                            </Td>
                        </Tr>
                        <Tr>
                            <Td style={{ color: "gray", width: 185 }}>AI Suggested State</Td>
                            <Td style={{ fontWeight: 500, paddingTop: 0, paddingBottom: 0 }}>
                                <div style={{ display: "flex", flexDirection: "row", gap: 6, alignItems: "center" }}>
                                    <div>
                                        {mandate.isAiStateCorrect === true
                                            ? mandate.aiStateAtFeedbackTime
                                            : mandate.aiState || "-"}
                                    </div>
                                </div>
                            </Td>
                        </Tr>
                        <Tr>
                            <Td style={{ color: "gray", width: 180 }}>Is the AI suggestion correct?</Td>
                            <Td style={{ fontWeight: 500, paddingTop: 0, paddingBottom: 0 }}>
                                <div style={{ display: "flex", flexDirection: "row", gap: 6, alignItems: "center" }}>
                                    <RadioField
                                        disabled={!mandate.aiState}
                                        {...form.getFieldProps("isAiStateCorrect")}
                                        options={[
                                            { label: "Yes", value: true },
                                            { label: "No", value: false },
                                        ]}
                                    />
                                </div>
                            </Td>
                        </Tr>
                        <Tr>
                            <Td style={{ color: "gray", width: 185 }}>Google Sheet State</Td>
                            <Td style={{ fontWeight: 500, paddingTop: 0, paddingBottom: 0 }}>
                                <div style={{ display: "flex", flexDirection: "row", gap: 6, alignItems: "center" }}>
                                    <div>{mandate.googleSheetState || "-"}</div>
                                </div>
                            </Td>
                        </Tr>
                    </TBody>
                </Table>
                <TextAreaField label="Notes" {...form.getFieldProps("stateNotes")} />
            </Form>
        </ModalDialog>
    );
};
