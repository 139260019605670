/**
 *
 *
 * @author Matthew Riddell <matt@neogen.ai>
 * @date 8/25/20, 6:24 AM
 *
 */

import APIService from "./api.service";
import { ProcessflowStageRuleGroup } from "../typings/api/processflow-stage-rule-group";

class ProcessflowStageRuleGroupService extends APIService<ProcessflowStageRuleGroup> {
    constructor() {
        super("processflow-stage-rule-groups");
    }
}

export default new ProcessflowStageRuleGroupService();
