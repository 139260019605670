/* eslint-disable react/display-name */
import { ExclamationCircleIcon } from "@heroicons/react/24/solid";
import { forwardRef, TextareaHTMLAttributes, useState } from "react";

export type TextAreaInputProps = Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, "form">;

export const TextAreaInput = forwardRef(
    (
        props: Omit<TextAreaInputProps, "onBlur"> & { hasError?: boolean; onBlur?: (e?: any, v?: any) => void },
        ref: any,
    ) => {
        const isControlled = props.value !== undefined;
        const [value, setValue] = useState(isControlled ? props.value || "" : props.defaultValue);

        const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
            if (props.onChange) {
                props.onChange(e);
            }

            if (!isControlled) {
                setValue(e.target.value);
            }
        };

        return (
            <div style={{ position: "relative" }}>
                {props.hasError && (
                    <div className={"absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none "}>
                        <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                    </div>
                )}
                <textarea
                    value={isControlled ? props.value : value}
                    onChange={handleChange}
                    {...props}
                    style={{ minHeight: "80px" }}
                    onBlur={(e) => {
                        if (props.onBlur) {
                            props.onBlur(e, value);
                        }
                    }}
                    className={
                        " dark:bg-gray-800  " +
                        (props.hasError
                            ? "block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                            : "shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md dark:bg-gray-900  dark:border-gray-900 dark:text-gray-300")
                    }
                    ref={ref}
                />
            </div>
        );
    },
);
