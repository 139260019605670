import { makeRequest } from "../../../jason-proof-of-concept/shared/utils";
import { Referrer } from "../domain/referrer";

export type CreateReferrerData = Partial<Referrer>;

export const createReferrer = async ({ authToken, data }: { authToken: string; data: CreateReferrerData }) => {
    const response = await makeRequest({
        method: "post",
        path: `/referrers`,
        authToken,
        data,
    });

    return response.data;
};
