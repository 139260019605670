import { useQuery } from "@tanstack/react-query";
import ModalDialog from "../../../layout/modal-dialog";
import PrintPre from "../../../layout/print-pre";
import industryService from "../../../services/industry.service";
import { useEffect, useState } from "react";
import API from "../../../services/api.service";
import randdService from "../../../services/randd.service";

function RandDModal({
    show,
    close,
    rAndDData,
}: {
    show: boolean;
    close: () => void;
    rAndDData: { industry: number[]; industryAlternate: string };
}): JSX.Element {
    const [industryNames, setIndustryNames] = useState<string[]>([]);
    const industryQuery = useQuery(["industry"], async () => {
        const response = await industryService.getAll();
        if (response) {
            return response.data;
        }
    });
    const aiQuery = useQuery(
        ["rAndDAI", show],
        async () => {
            console.log(industryNames);
            const response = await randdService.postURL("get-r-and-d", {
                businesstype: industryNames,
            });
            // const response = await fetch("http://localhost:4000/get-r-and-d", {
            //     method: "POST",
            //     headers: {
            //         "Content-Type": "application/json",
            //     },
            //     body: JSON.stringify({ businesstype: industryNames.join(" or ") }),
            // });
            console.log(response);
            if (response) {
                return response.data;
            }
        },
        { enabled: industryNames.length > 0 },
    );

    useEffect(() => {
        let industryNamesTemp;
        if (rAndDData.industry?.length > 0 && industryQuery.data) {
            industryNamesTemp = rAndDData.industry.map((industry) => {
                const industryName = industryQuery.data?.find((industryData) => industryData.id === industry);
                return industryName ? industryName.name : "";
            });
            setIndustryNames(industryNamesTemp);
        } else if (rAndDData.industryAlternate) {
            industryNamesTemp = [rAndDData.industryAlternate];
            setIndustryNames(industryNamesTemp);
        } else {
            console.log({ rAndDData });
        }
        console.log(industryNamesTemp);
        // Now go to the API and get the R&D data
    }, [industryQuery.data, rAndDData, rAndDData.industry, rAndDData.industryAlternate]);
    if (industryQuery.isLoading || aiQuery.isLoading) {
        return (
            <ModalDialog close={() => close()} show={show} title={"How Might I Qualify"}>
                Loading...
            </ModalDialog>
        );
    }
    return (
        <ModalDialog close={() => close()} show={show} title={"How Might I Qualify"} size="xl">
            {aiQuery.data?.["options"]?.map((option: any, index: number) => (
                <div key={index} className="my-5">
                    <div className="text-lg font-bold mb-3">Example {index + 1}</div>
                    <div>Option: {option.option}</div>

                    <div>Description: {option.description}</div>

                    <div className="ml-5 mt-5">
                        Examples:{" "}
                        {option.examples.map((ex: any) => (
                            <>
                                <div>{ex}</div>
                            </>
                        ))}
                    </div>
                </div>
            ))}
        </ModalDialog>
    );
}

export default RandDModal;
