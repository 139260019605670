import { Dispatch, SetStateAction } from "react";
import ButtonNeoGen from "./button-neogen";
import CheckBoxNeoGenControlled from "./checkbox-controlled";
import InputControlled from "./input-controlled";
import SelectNeoGen from "./select-neogen";
import TextAreaNeoGenControlled from "./text-area-controlled";
import Toggle from "./toggle";
import { ClearERCUser } from "../typings/api/clear-erc-user";
import { RoleGroup } from "../typings/api/role-group";

export type AlertUser = {
    id: number;
    userId: string | undefined;
    roleGroupId: number | undefined;
    alertTypes: { email: boolean; inApp: boolean; twilio: boolean };
    alertTitle: string | undefined;
    alertMessage: string | undefined;
    alertLink: string | undefined;
    alertUserType: "user" | "roleGroup";
};

const AlertItem = ({
    id,
    alertUsers,
    setAlertUsers,
    users,
    roleGroups,
}: {
    id: number;
    alertUsers: AlertUser[];
    setAlertUsers: Dispatch<SetStateAction<AlertUser[]>>;
    users: ClearERCUser[];
    roleGroups: RoleGroup[];
}) => {
    const alertUser = alertUsers.find((au) => au.id === id);
    return (
        <>
            <div style={{ display: "flex", justifyContent: "space-between", marginBottom: 10 }}>
                <Toggle
                    options={["User", "Role Group"]}
                    setSelectedIndex={(number) =>
                        setAlertUsers((au) => {
                            return au.map((a) =>
                                a.id === id ? { ...a, alertUserType: number === 0 ? "user" : "roleGroup" } : a,
                            );
                        })
                    }
                    selectedIndex={alertUser?.alertUserType === "user" ? 0 : 1}
                    className="min-w-[200px]"
                />
                <ButtonNeoGen
                    className="text-red-600"
                    type="outline-danger"
                    onClick={() => setAlertUsers((au) => au.filter((a) => a.id !== id))}
                >
                    x
                </ButtonNeoGen>
            </div>
            {alertUser?.alertUserType === "user" ? (
                <SelectNeoGen
                    options={users?.map((user) => {
                        return {
                            id: user.id,
                            name: `${user.firstName || ""} ${user.lastName || ""} (${user.email})`,
                        };
                    })}
                    label={"User to alert"}
                    value={alertUser?.userId}
                    onChange={(value: string | number) =>
                        setAlertUsers((au) => {
                            return au.map((a) => (a.id === id ? { ...a, userId: value as string } : a));
                        })
                    }
                    className="mb-5"
                    key={`user-${id}`}
                />
            ) : (
                <SelectNeoGen
                    options={roleGroups?.map((rg) => {
                        return {
                            id: rg.id,
                            name: rg.name,
                        };
                    })}
                    label={"Role group to alert"}
                    value={alertUser?.roleGroupId}
                    onChange={(value: string | number) =>
                        setAlertUsers((au) => {
                            return au.map((a) => (a.id === id ? { ...a, roleGroupId: value as number } : a));
                        })
                    }
                    className="mb-5"
                    key={`user-${id}`}
                />
            )}
            <InputControlled
                setValue={(e: string) =>
                    setAlertUsers((au) => {
                        return au.map((a) => (a.id === id ? { ...a, alertTitle: e } : a));
                    })
                }
                value={alertUser?.alertTitle ?? ""}
                label="Alert title"
                name={`alertTitle-${id}`}
                key={`alertTitle-${id}`}
            />
            <div className="relative">
                <TextAreaNeoGenControlled
                    setValue={(e: string) => {
                        setAlertUsers((au) => {
                            return au.map((a) => (a.id === id ? { ...a, alertMessage: e } : a));
                        });
                    }}
                    value={alertUser?.alertMessage ?? ""}
                    label="Alert message"
                    name={`alertMessage-${id}`}
                    key={`alertMessage-${id}`}
                />
                <p className="absolute top-36 text-sm text-gray-400">
                    Insert variable:{" "}
                    <span
                        className="text-blue-400 font-bold cursor-pointer"
                        onClick={() =>
                            setAlertUsers((au) => {
                                return au.map((a) =>
                                    a.id === id ? { ...a, alertMessage: a.alertMessage + "{{firstName}}" } : a,
                                );
                            })
                        }
                    >
                        firstName
                    </span>{" "}
                    <span
                        className="text-blue-400 cursor-pointer font-bold"
                        onClick={() =>
                            setAlertUsers((au) => {
                                return au.map((a) =>
                                    a.id === id ? { ...a, alertMessage: a.alertMessage + "{{lastName}}" } : a,
                                );
                            })
                        }
                    >
                        lastName
                    </span>{" "}
                    <span
                        className="text-blue-400 cursor-pointer font-bold"
                        onClick={() =>
                            setAlertUsers((au) => {
                                return au.map((a) =>
                                    a.id === id ? { ...a, alertMessage: a.alertMessage + "{{email}}" } : a,
                                );
                            })
                        }
                    >
                        email
                    </span>{" "}
                    <span
                        className="text-blue-400 cursor-pointer font-bold"
                        onClick={() =>
                            setAlertUsers((au) => {
                                return au.map((a) =>
                                    a.id === id ? { ...a, alertMessage: a.alertMessage + "{{companyName}}" } : a,
                                );
                            })
                        }
                    >
                        companyName
                    </span>{" "}
                    <span
                        className="text-blue-400 cursor-pointer font-bold"
                        onClick={() =>
                            setAlertUsers((au) => {
                                return au.map((a) =>
                                    a.id === id ? { ...a, alertMessage: a.alertMessage + "{{companyId}}" } : a,
                                );
                            })
                        }
                    >
                        companyId
                    </span>
                </p>
            </div>
            <p className="mb-2 mt-8 text-sm text-gray-400">Alert types to activate:</p>
            <div className="flex gap-5">
                <CheckBoxNeoGenControlled
                    setValue={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setAlertUsers((au) => {
                            return au.map((a) =>
                                a.id === id
                                    ? { ...a, alertTypes: { ...a.alertTypes, email: !!e.currentTarget?.checked } }
                                    : a,
                            );
                        });
                    }}
                    value={alertUser?.alertTypes.email ?? false}
                    name={`email-${id}`}
                    key={`email-${id}`}
                    label={"Email"}
                />
                <CheckBoxNeoGenControlled
                    setValue={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setAlertUsers((au) => {
                            return au.map((a) =>
                                a.id === id
                                    ? { ...a, alertTypes: { ...a.alertTypes, inApp: !!e.currentTarget?.checked } }
                                    : a,
                            );
                        });
                    }}
                    value={alertUser?.alertTypes.inApp ?? false}
                    name={`inApp-${id}`}
                    key={`inApp-${id}`}
                    label={"In App"}
                />
                <CheckBoxNeoGenControlled
                    setValue={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setAlertUsers((au) => {
                            return au.map((a) =>
                                a.id === id
                                    ? { ...a, alertTypes: { ...a.alertTypes, twilio: !!e.currentTarget?.checked } }
                                    : a,
                            );
                        });
                    }}
                    value={alertUser?.alertTypes.twilio ?? false}
                    name={`twilio-${id}`}
                    key={`twilio-${id}`}
                    label={"Text message (not available for role group level alerts)"}
                />
            </div>
            <div className="relative mb-8">
                <InputControlled
                    setValue={(e: string) =>
                        setAlertUsers((au) => {
                            return au.map((a) => (a.id === id ? { ...a, alertLink: e } : a));
                        })
                    }
                    value={alertUser?.alertLink ?? ""}
                    label="Alert Link"
                    name={`alertLink-${id}`}
                    key={`alertLink-${id}`}
                />
                <p className="absolute top-16 text-sm text-gray-400">
                    Eg. {`/companies/{{companyId}}`}{" "}
                    <span
                        className="text-blue-400 font-bold cursor-pointer"
                        onClick={() =>
                            setAlertUsers((au) => {
                                return au.map((a) =>
                                    a.id === id
                                        ? {
                                              ...a,
                                              alertLink: a.alertLink + `{{companyId}}`,
                                          }
                                        : a,
                                );
                            })
                        }
                    >
                        companyId
                    </span>{" "}
                </p>
            </div>
            <div className="border-b-gray-300 border-b-2 border-dotted my-8 mx-40" />
        </>
    );
};

export const AlertField = ({
    alertUsers,
    setAlertUsers,
    users,
    roleGroups,
}: {
    alertUsers: AlertUser[];
    setAlertUsers: Dispatch<SetStateAction<AlertUser[]>>;
    users: ClearERCUser[];
    roleGroups: RoleGroup[];
}) => {
    return (
        <div className="border-gray-200 border-2 rounded-lg p-4">
            {alertUsers.map((alertUser) => (
                <AlertItem
                    key={alertUser.id}
                    id={alertUser.id}
                    alertUsers={alertUsers}
                    setAlertUsers={setAlertUsers}
                    users={users}
                    roleGroups={roleGroups as RoleGroup[]}
                />
            ))}
            <div style={{ display: "flex", justifyContent: "center" }}>
                <ButtonNeoGen
                    onClick={() => {
                        setAlertUsers([
                            ...alertUsers,
                            {
                                id: alertUsers.length,
                                roleGroupId: undefined,
                                userId: undefined,
                                alertTypes: { email: false, inApp: false, twilio: false },
                                alertTitle: undefined,
                                alertMessage: undefined,
                                alertLink: undefined,
                                alertUserType: "user",
                            },
                        ]);
                    }}
                >
                    Add user to receive alert
                </ButtonNeoGen>
            </div>
        </div>
    );
};
