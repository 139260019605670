import ModalDialog from "../../layout/modal-dialog";
import { useAuth } from "../../auth/use-auth";
import { z } from "zod";
import { Form } from "../../layout/form/form";
import { useForm } from "../../hooks/useForm";
import { TextAreaField } from "../../layout/form/text-area-field";
import { MandateMandateEffect } from "../../mandate-mandate-effects/domain/mandate-mandate-effect";

const schema = z.object({
    incorrectReason: z.string().nullish(),
});

type Data = z.infer<typeof schema>;

export const IncorrectMandateMandateEffectModal = ({
    onSubmit,
    onClose,
}: {
    onSubmit: (reason: any) => void;
    onClose: () => void;
}) => {
    const auth = useAuth();
    const authToken = auth.expectAuthToken();

    const form = useForm({
        schema,
    });

    const handleSubmit = async (data: Partial<MandateMandateEffect>) => {
        onSubmit(data.incorrectReason);
        onClose();
    };

    const values = form.watch();

    return (
        <ModalDialog
            title="Submit Incorrect Mandate Effect"
            show
            close={onClose}
            okAction={form.handleSubmit(handleSubmit)}
            okText="Submit"
        >
            <Form onSubmit={form.handleSubmit(handleSubmit)}>
                <TextAreaField
                    label="Why is this mandate effect incorrect?"
                    {...form.getFieldProps("incorrectReason")}
                />
            </Form>
        </ModalDialog>
    );
};
