import { z } from "zod";
import { userSchema } from "../../../jason-proof-of-concept/users/domain/user";

export const referrerSchema = z.object({
    id: z.number(),
    createdAt: z.coerce.date(),
    updatedAt: z.coerce.date(),
    referrerId: z.string(),
    affiliateId: z.string(),
    referrerUser: userSchema.nullish(),
    affiliateUser: userSchema.nullish(),
    commissionPercentage: z.number(),
});

export type Referrer = z.infer<typeof referrerSchema>;
